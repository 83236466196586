import {
  AppstoreOutlined,
  IdcardOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React, { useState } from "react";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { isAllowedService } from "../../../utils/services";
import SubMenu from "antd/es/menu/SubMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
//const { SubMenu } = Menu;
const ShopSidebar = ({ shopId }: any): ReactElement => {
  const pathname = window.location?.pathname;
  // new add start
  const [openKeys, setOpenKeys] = useState<any>(['sub1']);
  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  // new add end
  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 h-full">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} >
            <Menu.Item
              key="/details"
              className={`hover:text-white hover:bg-gray-100 ${
                pathname.includes("/details") ? "active bg-gray-100" : ""
              }`}
              icon={<IdcardOutlined />}
            >
              <Link
                to={`/shops/${shopId}/details`}
                className="hover:text-white"
              >
                Basic Information
              </Link>
            </Menu.Item>
            {isAllowedService(`Shops Products`) && (
              <Menu.Item
                key="/products"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/products") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/products`}>Product Management</Link>
              </Menu.Item>
            )}
            {isAllowedService(`Shops Orders`) && (
              <Menu.Item
                key="/orders"
                icon={<ShoppingCartOutlined />}
                className={`hover:text-white hover:bg-gray-100 ${
                  pathname.includes("/orders") ? "active bg-gray-100" : ""
                }`}
              >
                <Link to={`/shops/${shopId}/orders`}>Orders History</Link>
              </Menu.Item>
            )}

            <Menu.Item
              key="/out-of-stock"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/out-of-stock") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/shops/${shopId}/out-of-stock`}>Out of Stock</Link>
            </Menu.Item>

            {isAllowedService(`Shops Categories`) && (
              <Menu.Item
                key="/categories"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/categories") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/categories`}>
                  Category Management
                </Link>
              </Menu.Item>
            )}

            {isAllowedService(`Shops Products`) && (
              <Menu.Item
                key="/brands"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/brands") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/brands`}>Brand List</Link>
              </Menu.Item>
            )}
             {isAllowedService(`Shops Products`) && (
            <Menu.Item
              key="/tags"
              icon={<SolutionOutlined />}
              className={pathname.includes("/tags") ? "active bg-gray-100" : ""}
            >
              <Link to={`/shops/${shopId}/tags`}>Tag Management</Link>
            </Menu.Item>
            )}

            <SubMenu
              key="sub1"
              icon={<AppstoreOutlined />}
              title={"Shipping & Delivery Settings"}
            >
              <Menu.Item
                key="/shipping-setting"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/shipping-setting") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/shipping-setting`}>
                  Shipping Setting
                </Link>
              </Menu.Item>
              <Menu.Item
                key="/my-shipping-profile"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/my-shipping-profile") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/my-shipping-profile`}>
                  Shipping Profile
                </Link>
              </Menu.Item>

              {/* <Menu.Item
              key="/my-custom-shipping"
              icon={<SolutionOutlined />}
              className={pathname.includes("/my-custom-shipping") ? "active" : ""}
            >
              <Link to={`/shops/${shopId}/my-custom-shipping`}>Profile Configuration</Link>
            </Menu.Item> */}
              {/* <Menu.Item
                key="/my-shipping-zone"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/my-shipping-zone") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/my-shipping-zone`}>
                  Profile Shipping Zone
                </Link>
              </Menu.Item> */}
              <Menu.Item
                key="/shipping-zone"
                icon={<SolutionOutlined />}
                className={pathname.includes("/shipping-zone") ? "active bg-gray-100" : ""}
              >
                <Link to={`/shops/${shopId}/shipping-zone`}>General Shipping</Link>
              </Menu.Item>
              <Menu.Item
                key="/package-list"
                icon={<SolutionOutlined />}
                className={pathname.includes("/package-list") ? "active bg-gray-100" : ""}
              >
                <Link to={`/shops/${shopId}/package-list`}>Packages</Link>
              </Menu.Item>
              <Menu.Item
                key="/shop-pickup-zone"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/shop-pickup-zone") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/shop-pickup-zone`}>
                  Pickup Zone
                </Link>
              </Menu.Item>
            </SubMenu>

            {/* <Menu.Item
              key="/add-rate"
              icon={<SolutionOutlined />}
              className={pathname.includes("/add-rate") ? "active" : ""}
            >
              <Link to={`/shops/${shopId}/shop-add-rate`}>Add Rate</Link>
            </Menu.Item> */}
          </Menu>
        </div>
      </div>
    </>
  );
};

export default ShopSidebar;
