import { Image } from "antd";
import React from "react";
import { ReactElement } from "react";

const SingleBanner = ({
    VehicleDetails,
}: {
    VehicleDetails: any;
}): ReactElement => {
  return (
    <>
      <div className="">
        <Image
          height={140}
          width="auto"
          src={VehicleDetails?.vehicleImages?.[0]}
          className="rounded-lg shadow-lg"
        />

        <div className="flex flex-no-wrap py-2 mt-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Brand Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.brandName}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold"> Brand Model Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.brandModelName}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold"> Vehicle Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.vehicleType}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold"> Vehicle Reg No</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.vehicleRegNo}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold"> Brand Year</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.year}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">City Mpg</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.cityMpg}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Hwy Mpg</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.hwyMpg}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Gas Grade Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.gasGradeName}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Fuel Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.fuelTypeName}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Brta Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.rtaName}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Tax Token Number</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.taxTokenNumber}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Tax Token Image</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
          <Image
          height={140}
          width="auto"
          src={VehicleDetails?.taxTokenImage}
          className="rounded-lg shadow-lg"
        />
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Registration Paper Back Image</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
          <Image
          height={140}
          width="auto"
          src={VehicleDetails?.registrationPaperImage?.backUrl}
          className="rounded-lg shadow-lg"
        />
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Registration Paper Front Image</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
          <Image
          height={140}
          width="auto"
          src={VehicleDetails?.registrationPaperImage?.frontUrl}
          className="rounded-lg shadow-lg"
        />
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Fitness Certificate Number</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.fitnessCertificateNumber}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Fitness Certificate Expiry Date</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{VehicleDetails?.fitnessCertificateExpiryDate}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Fitness Certificate Image</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
          <Image
          height={140}
          width="auto"
          src={VehicleDetails?.fitnessCertificateImage}
          className="rounded-lg shadow-lg"
        />
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBanner;
