import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopShippingProfileDetails from "../../../components/Shop/ShippingProfile/MyShippingProfileDetails";

const ShopShippingProfileDetailsPage = () => {
  return (
    <MainLayout>
      <ShopShippingProfileDetails />
    </MainLayout>
  );
};

export default ShopShippingProfileDetailsPage;
