import {
  Button,
  Card,
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import {
  CloseOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import BreadCrumb from "../Layouts/Breadcrumb";
import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import { ImageUploader, SubmitResetBtn } from "../common";
import { jodiConfig } from "../../utils/editor-config";
import { bloodGroupType } from "../../utils";
import { regions } from "../common/Region";

const AddTeacher = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const [bloodGroup, setBloodGroup] = useState<any>("");
  const [appsImgUrl, setAppsImgUrl] = useState<string>();
  const [webImgUrl, setWebImgUrl] = useState<string>();
  const [discountType, setDiscountType] = useState("PERCENTAGE");
  const [dateOfBirth, setDateOfBirth] = useState<DatePickerProps["value"]>();
  const [startingDate, setStartingDate] = useState<DatePickerProps["value"]>();
  const [endingDate, setEndingDate] = useState<DatePickerProps["value"]>();
  const [isFlashSale, setIsFlashSale] = useState<boolean>(false);
  const [variationImageUrl, setVariationImageUrl] = useState<string[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState<boolean>(false);

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [teacherData, setTeacherData] = useState<any>({
    loading: false,
    data: null,
  });
  const fetchTeacherDetails = useCallback((getTeacherId: any) => {
    if (getTeacherId) {
      try {
        setTeacherData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_ELARNING_API}/admin/teacher/details?id=${getTeacherId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setTeacherData({
                loading: false,
                data: data?.data?.teacher,
              });
            } else {
              setTeacherData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setTeacherData({ loading: false, data: null });
          });
      } catch (error) {
        setTeacherData({ loading: false, data: null });
      }
    }
  }, []);

  const paymentTimePreventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const [error, setError] = useState("");

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form?.submit();
    }
  }, [startUpload]);

  const onSubmit = async (data: any) => {
    if (appsImgUrl || webImgUrl) {
      setLoading(true);

      const readyData = data && {
        email: data.email,
        fullName: data.fullName,
        lastEducationDegree: data.lastEducationDegree,
        lastInstitute: data.lastInstitute,
        mobileNumber: data.mobileNumber,
        password: data.password,
        profilePicUrl: appsImgUrl ? appsImgUrl : data?.profilePicUrl || "NULL",
        teacherDetails: {
          address: {
            area: data.area,
            country: data.country,
            district: data.district,
            flatNo: data.flatNo,
            houseNo: data.houseNo,
            postCode: data?.postCode,
            roadNo: data?.roadNo,
            thana: data?.thana,
          },
          bloodGroup: bloodGroup || data.bloodGroup,
          dateOfBirth: dateOfBirth || data.dateOfBirth,
          educationalQualificationList: data?.qualifications?.map(
            (qualification: any, qualificationsId: string) => ({
              board: qualification.board,
              degree: qualification.degree,
              gpa: qualification.gpa,
              instituteName: qualification.instituteName,
              letterGrade: qualification.letterGrade,
              major: qualification.major,
              resultType: qualification.resultType,
              yearOfPassing: qualification.yearOfPassing,
            })
          ),
          emergencyContactNumber: data.emergencyContactNumber,
          experienceList: data?.experienceList?.map(
            (experience: any, experienceId: string) => ({
              endDate: experience.endDate,
              isCurrentlyWorking: experience.isCurrentlyWorking,
              organizationName: experience.organizationName,
              startDate: experience.startDate,
              teacherJobDescription: experience.teacherJobDescription,
              title: experience.title,
              yearsOfTeachingExperience: experience.yearsOfTeachingExperience,
            })
          ),
          expertiseList: data?.expertiseList?.map(
            (expertise: any, expertiseId: string) => ({
              skillDescription: expertise.skillDescription,
              skillLevel: expertise.skillLevel,
              skillName: expertise.skillName,
            })
          ),
          gender: data.gender,
          nationalId: data.nationalId,
          passport: data.passport,
        },
      };

      if (teacherData.data) {
        await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/teacher`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: teacherData?.data?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Teacher Updated Successfully", "success");
              form.resetFields();
              navigate("/teacher");
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/teacher`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            deleted: false,
            status: true,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Teacher Create Successfully", "success");
              form.resetFields();
              navigate("/teacher");
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  useEffect(() => {
    if (teacherData.data) {
      form.resetFields(Object.keys(teacherData.data as any));
    }
  }, [form, teacherData]);

  useEffect(() => {
    fetchTeacherDetails((route as any)?.teacherId);
  }, [fetchTeacherDetails]);

  useEffect(() => {
    if (teacherData?.data) {
      form.resetFields();
      setAppsImgUrl(teacherData?.data?.profilePicUrl);
      setStartingDate(teacherData?.data?.startAt);
      setDateOfBirth(teacherData?.data?.teacherDetails?.dateOfBirth);
      setEndingDate(teacherData?.data?.endAt);
      setDiscountType(teacherData?.data?.minimumDiscountType);
      setIsFlashSale(teacherData?.data?.isFlashSale);
      setIsCurrentlyWorking(teacherData?.data?.isCurrentlyWorking);
    }
  }, [teacherData]);

  const resetData = () => {
    form?.resetFields();
    setAppsImgUrl(undefined);
    setWebImgUrl(undefined);
    setStartUpload(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={teacherData?.data ? "Edit Teacher" : "Add Teacher"}
        extra={
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
            }}
            onClickReset={resetData}
            disabled={loading || startUpload === "Uploading"}
            loading={loading || startUpload === "Uploading"}
          />
        }
      />

      <div className="content-body bg-white rounded-2xl p-4 shadow">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          hideRequiredMark
          initialValues={{
            ...teacherData?.data,
            dateOfBirth: dayjs(teacherData?.data?.teacherDetails?.dateOfBirth),
            endDate: dayjs(teacherData?.data?.endAt),
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <div>
              <Form.Item
                hasFeedback
                label="Full Name"
                rules={[
                  {
                    required: true,
                    message: "Full Name is Required!",
                  },
                ]}
                name="fullName"
              >
                <Input
                  id="fullName"
                  type="text"
                  placeholder="Enter Full Name"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="E-mail"
                rules={[
                  {
                    required: true,
                    message: "E-mail is Required!",
                  },
                ]}
                name="email"
              >
                <Input id="email" type="text" placeholder="Enter E-mail" />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Mobile Number"
                rules={[
                  {
                    required: true,
                    message: "Mobile Number is Required!",
                  },
                  {
                    validator: async (_, names) => {
                      if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                        return Promise.reject(
                          new Error("Enter valid mobile number")
                        );
                      }
                    },
                  },
                ]}
                name="mobileNumber"
                initialValue={"01"}
              >
                <Input addonBefore={"+88"} size="large" />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Emergency Contact Number"
                rules={[
                  {
                    required: true,
                    message: "Emergency Contact Number is Required!",
                  },
                  {
                    validator: async (_, names) => {
                      if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                        return Promise.reject(
                          new Error("Enter valid Emergency Contact Number")
                        );
                      }
                    },
                  },
                ]}
                name="emergencyContactNumber"
                initialValue={teacherData?.data?.teacherDetails?.emergencyContactNumber || "01"}
              
              >
                <Input addonBefore={"+88"} size="large" />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="National ID"
                rules={[
                  {
                    required: false,
                    message: "National ID is Required!",
                  },
                ]}
                initialValue={teacherData?.data?.teacherDetails?.nationalId}
                name="nationalId"
              >
                <Input
                  id="nationalId"
                  type="number"
                  min={0}
                  placeholder="Enter National ID"
                />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Passport"
                rules={[
                  {
                    required: false,
                    message: "Passport is Required!",
                  },
                ]}
                initialValue={teacherData?.data?.teacherDetails?.passport}
                name="passport"
              >
                <Input id="passport" type="text" placeholder="Enter Passport" />
              </Form.Item>
            </div>
            <div>
              <Form.Item hasFeedback label="Date Of Birth" name="dateOfBirth">
                <DatePicker
                showTime
                  placeholder="Enter Date Of Birth"
                  className="w-full"
                  showNow={false}
                  onChange={(val: DatePickerProps["value"]) =>
                  setDateOfBirth(val)
                }
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Gender"
                name="gender"
                initialValue={teacherData?.data?.teacherDetails?.gender}
              >
                <Select
                  placeholder="Select Gender"
                  options={[
                    {
                      value: "MALE",
                      label: "MALE",
                    },
                    {
                      value: "FEMALE",
                      label: "FEMALE",
                    },
                    {
                      value: "OTHER",
                      label: "OTHER",
                    },
                  ]}
                ></Select>
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Blood Group"
                rules={[
                  {
                    required: false,
                    message: "Blood Group is Required!",
                  },
                ]}
                initialValue={teacherData?.data?.teacherDetails?.bloodGroup}
                name="bloodGroup"
              >
                <Select
                  showSearch
                  placeholder="Select Blood Group"
                  onChange={(val) => setBloodGroup(val)}
                >
                  {Object.values(bloodGroupType)?.map((bloodGroup, bIndex) => (
                    <Option value={bloodGroup} key={bIndex}>
                      {bloodGroup?.split("_").join(" ")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Last Institute Name"
                rules={[
                  {
                    required: false,
                    message: "Last Institute is Required!",
                  },
                ]}
                name="lastInstitute"
              >
                <Input
                  id="lastInstitute"
                  type="text"
                  placeholder="Enter Last Institute Name"
                />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Last Education Degree"
                rules={[
                  {
                    required: false,
                    message: "Last Education Degree is Required!",
                  },
                ]}
                name="lastEducationDegree"
              >
                <Input
                  id="lastEducationDegree"
                  type="text"
                  placeholder="Enter Last Education Degree"
                />
              </Form.Item>
            </div>
            {!teacherData && (
            <div className="">
              <Form.Item
                hasFeedback
                label="Password"
                rules={[
                  {
                    required: false,
                    message: "Password is Required!",
                  },
                ]}
                name="password"
              >
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter Password"
                />
              </Form.Item>
            </div>
            )}


            <div className="col-span-2">
              <h3 className="mb-4 text-xl font-bold">Address</h3>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Area"
                name="area"
                initialValue={teacherData?.data?.teacherDetails?.address?.area}
              >
                <Input id="area" type="text" placeholder="Enter shops area" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                initialValue={
                  teacherData?.data?.teacherDetails?.address?.district
                }
              >
                {/* <Input id="city" type="text" placeholder="Enter shops city" /> */}

                <Select
                  showSearch
                  placeholder="Filter by Status"
                  optionFilterProp="children"
                  filterOption={() => {
                    return true;
                  }}
                >
                  {Object.values(regions.City)?.map((city, i) => (
                    <Select.Option value={city} key={i}>
                      {city}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Country"
                name="country"
                initialValue={
                  teacherData?.data?.teacherDetails?.address?.country
                }
                // initialValue="BD"
              >
                <Select
                  id="country"
                  placeholder="Select Country"
                  optionFilterProp="children"
                  allowClear
                >
                  <Select options={[{ value: "BD", label: "Bangladesh" }]} />
                </Select>
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Flat No"
                rules={[
                  {
                    required: false,
                    message: "Flat No is Required!",
                  },
                ]}
                initialValue={
                  teacherData?.data?.teacherDetails?.address?.flatNo
                }
                name="flatNo"
              >
                <Input id="flatNo" type="text" placeholder="Enter Flat No" />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="House No"
                rules={[
                  {
                    required: false,
                    message: "House No is Required!",
                  },
                ]}
                initialValue={
                  teacherData?.data?.teacherDetails?.address?.houseNo
                }
                name="houseNo"
              >
                <Input id="houseNo" type="text" placeholder="Enter House No" />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Post Code"
                rules={[
                  {
                    required: false,
                    message: "Post Code is Required!",
                  },
                ]}
                initialValue={
                  teacherData?.data?.teacherDetails?.address?.postCode
                }
                name="postCode"
              >
                <Input
                  id="postCode"
                  type="number"
                  min={0}
                  placeholder="Enter Post Code"
                />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Road No"
                rules={[
                  {
                    required: false,
                    message: "Road No is Required!",
                  },
                ]}
                initialValue={
                  teacherData?.data?.teacherDetails?.address?.roadNo
                }
                name="roadNo"
              >
                <Input id="roadNo" type="text" placeholder="Enter Road No" />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Thana"
                rules={[
                  {
                    required: false,
                    message: "Thana is Required!",
                  },
                ]}
                name="thana"
                initialValue={teacherData?.data?.teacherDetails?.address?.thana}
              >
                <Input id="thana" type="text" placeholder="Enter Thana" />
              </Form.Item>
            </div>
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
              <h3 className="mb-4 text-xl font-bold">
                Educational Qualification List
              </h3>

              <Form.List
                name="qualifications"
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(
                          new Error(
                            "At Least 1 Educational Qualification List Required!"
                          )
                        );
                      }
                    },
                  },
                ]}
                initialValue={[{}]}
              >
                {(
                  educationalQualificationListFields,
                  { add, remove },
                  { errors }
                ) => (
                  <>
                    {educationalQualificationListFields.map(
                      ({
                        educationalKey,
                        name,
                        educationalFieldKey,
                        ...educationalRestField
                      }: any) => (
                        <Card className="mb-2" size="small" key={key}>
                          <Space wrap align="center">
                            <Form.Item
                              {...educationalRestField}
                              name={[name, "instituteName"]}
                              fieldKey={[educationalFieldKey, "instituteName"]}
                              hasFeedback
                              label="Institute Name"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.educationalQualificationList?.[name]
                                  ?.instituteName
                              }
                            >
                              <Input placeholder="Enter Institute Name" />
                            </Form.Item>
                            <Form.Item
                              {...educationalRestField}
                              name={[name, "board"]}
                              fieldKey={[educationalFieldKey, "board"]}
                              hasFeedback
                              label="Board"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.educationalQualificationList?.[name]?.board
                              }
                            >
                              <Input placeholder="Enter Board" />
                            </Form.Item>
                            <Form.Item
                              {...educationalRestField}
                              name={[name, "degree"]}
                              fieldKey={[educationalFieldKey, "degree"]}
                              hasFeedback
                              label="Degree"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.educationalQualificationList?.[name]?.degree
                              }
                            >
                              <Input placeholder="Enter Degree" />
                            </Form.Item>
                            <Form.Item
                              {...educationalRestField}
                              name={[name, "major"]}
                              fieldKey={[educationalFieldKey, "major"]}
                              hasFeedback
                              label="Major"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.educationalQualificationList?.[name]?.major
                              }
                            >
                              <Input placeholder="Enter Major" />
                            </Form.Item>
                            <Form.Item
                              {...educationalRestField}
                              name={[name, "resultType"]}
                              fieldKey={[educationalFieldKey, "resultType"]}
                              hasFeedback
                              label="Result Type"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.educationalQualificationList?.[name]
                                  ?.resultType
                              }
                            >
                              <Input placeholder="Enter Result Type" />
                            </Form.Item>
                            <Form.Item
                              {...educationalRestField}
                              name={[name, "gpa"]}
                              fieldKey={[educationalFieldKey, "gpa"]}
                              hasFeedback
                              label="Gpa"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.educationalQualificationList?.[name]?.gpa
                              }
                            >
                              <Input placeholder="Enter Gpa" />
                            </Form.Item>
                            <Form.Item
                              {...educationalRestField}
                              name={[name, "letterGrade"]}
                              fieldKey={[educationalFieldKey, "letterGrade"]}
                              hasFeedback
                              label="Letter Grade"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.educationalQualificationList?.[name]
                                  ?.letterGrade
                              }
                            >
                              <Input placeholder="Enter Letter Grade" />
                            </Form.Item>
                            <Form.Item
                              {...educationalRestField}
                              name={[name, "yearOfPassing"]}
                              fieldKey={[educationalFieldKey, "yearOfPassing"]}
                              hasFeedback
                              label="Year Of Passing"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.educationalQualificationList?.[name]
                                  ?.yearOfPassing
                              }
                            >
                              <Input placeholder="Enter Year Of Passing" />
                            </Form.Item>
                          </Space>

                          <Button
                            type="primary"
                            danger
                            style={{
                              position: "absolute",
                              right: 0,
                              bottom: 0,
                            }}
                            onClick={() => {
                              remove(name);
                              setVariationImageUrl((prev) => {
                                const newArr = prev.filter(
                                  (_, i) => i !== name
                                );
                                return newArr;
                              });
                            }}
                          >
                            <CloseOutlined />
                          </Button>
                        </Card>
                      )
                    )}

                    <Form.Item
                      style={{
                        width: "100%",
                        flex: "1 1 100%",
                        marginBottom: 5,
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                          setVariationImageUrl((prev) => {
                            const newArr = Array.from(prev);
                            newArr.push("");
                            return newArr;
                          });
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Educational Qualification List
                      </Button>
                    </Form.Item>

                    <Form.ErrorList errors={errors} />
                  </>
                )}
              </Form.List>
            </div>
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
              <h3 className="mb-4 text-xl font-bold">Experience List</h3>

              <Form.List
                name="experienceList"
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(
                          new Error("At Least 1 Experience List List Required!")
                        );
                      }
                    },
                  },
                ]}
                initialValue={[{}]}
              >
                {(experienceListFields, { add, remove }, { errors }) => (
                  <>
                    {experienceListFields.map(
                      ({
                        experienceKey,
                        name,
                        experienceFieldKey,
                        ...experienceRestField
                      }: any) => (
                        <Card
                          className="mb-2"
                          size="small"
                          key={experienceFieldKey}
                        >
                          <Space wrap align="center">
                            <Form.Item
                              {...experienceRestField}
                              name={[name, "title"]}
                              fieldKey={[experienceFieldKey, "title"]}
                              hasFeedback
                              label="Title"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.experienceList?.[name]?.title
                              }
                            >
                              <Input placeholder="Enter Title" />
                            </Form.Item>
                            <Form.Item
                              {...experienceRestField}
                              name={[name, "organizationName"]}
                              fieldKey={[
                                experienceFieldKey,
                                "organizationName",
                              ]}
                              hasFeedback
                              label="Organization Name"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.experienceList?.[name]?.organizationName
                              }
                            >
                              <Input placeholder="Enter Organization Name" />
                            </Form.Item>
                            <Form.Item
                              {...experienceRestField}
                              name={[name, "yearsOfTeachingExperience"]}
                              fieldKey={[
                                experienceFieldKey,
                                "yearsOfTeachingExperience",
                              ]}
                              hasFeedback
                              label="Years Of Teaching Experience"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.experienceList?.[name]
                                  ?.yearsOfTeachingExperience
                              }
                            >
                              <Input placeholder="Enter Years Of Teaching Experience" />
                            </Form.Item>
                            <Form.Item
                              {...experienceRestField}
                              name={[name, "startDate"]}
                              fieldKey={[experienceFieldKey, "startDate"]}
                              hasFeedback
                              label="Start Date"
                              style={{ width: 300 }}
                              initialValue={
                                teacherData?.data?.experienceList?.[name]
                                  ?.startDate
                              }
                            >
                              <DatePicker
                                placeholder="Enter Start Date"
                                className="w-full"
                              />
                            </Form.Item>
                            <Form.Item
                              {...experienceRestField}
                              name={[name, "isCurrentlyWorking"]}
                              fieldKey={[
                                experienceFieldKey,
                                "isCurrentlyWorking",
                              ]}
                              hasFeedback
                              label="Is Currently Working"
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.experienceList?.[name]?.isCurrentlyWorking
                              }
                              className="mr-5"
                            >
                              <Switch
                                checkedChildren={`true`}
                                unCheckedChildren={`false`}
                                defaultChecked={
                                  (teacherData as any)?.data?.teacherDetails
                                    ?.withCourseDisplay
                                }
                                onChange={() =>
                                  setIsCurrentlyWorking(!isCurrentlyWorking)
                                }
                              />
                            </Form.Item>
                            {isCurrentlyWorking && (
                              <>
                                <Form.Item
                                  {...experienceRestField}
                                  name={[name, "endDate"]}
                                  fieldKey={[experienceFieldKey, "endDate"]}
                                  hasFeedback
                                  label="End Date"
                                  style={{ width: 300 }}
                                  initialValue={
                                    teacherData?.data?.teacherDetails
                                      ?.experienceList?.[name]?.endDate
                                  }
                                >
                                  <DatePicker
                                    placeholder="Enter End Date"
                                    className="w-full"
                                  />
                                </Form.Item>
                              </>
                            )}
                            <Form.Item
                              {...experienceRestField}
                              name={[name, "teacherJobDescription"]}
                              fieldKey={[
                                experienceFieldKey,
                                "teacherJobDescription",
                              ]}
                              hasFeedback
                              label="Teacher Job Description"
                              style={{ width: 450 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.experienceList?.[name]
                                  ?.teacherJobDescription
                              }
                            >
                              <Input placeholder="Enter Teacher Job Description" />
                            </Form.Item>
                          </Space>

                          <Button
                            type="primary"
                            danger
                            style={{
                              position: "absolute",
                              right: 0,
                              bottom: 0,
                            }}
                            onClick={() => {
                              remove(name);
                              setVariationImageUrl((prev) => {
                                const newArr = prev.filter(
                                  (_, i) => i !== name
                                );
                                return newArr;
                              });
                            }}
                          >
                            <CloseOutlined />
                          </Button>
                        </Card>
                      )
                    )}

                    <Form.Item
                      style={{
                        width: "100%",
                        flex: "1 1 100%",
                        marginBottom: 5,
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                          setVariationImageUrl((prev) => {
                            const newArr = Array.from(prev);
                            newArr.push("");
                            return newArr;
                          });
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Experience List
                      </Button>
                    </Form.Item>

                    <Form.ErrorList errors={errors} />
                  </>
                )}
              </Form.List>
            </div>
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
              <h3 className="mb-4 text-xl font-bold">Expertise List</h3>

              <Form.List
                name="expertiseList"
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(
                          new Error("At Least 1 Expertise List Required!")
                        );
                      }
                    },
                  },
                ]}
                initialValue={[{}]}
              >
                {(expertiseListFields, { add, remove }, { errors }) => (
                  <>
                    {expertiseListFields.map(
                      ({
                        expertiseKey,
                        name,
                        expertiseFieldKey,
                        ...expertiseRestField
                      }: any) => (
                        <Card className="mb-2" size="small" key={expertiseKey}>
                          <Space wrap align="center">
                            <Form.Item
                              {...expertiseRestField}
                              name={[name, "skillName"]}
                              fieldKey={[expertiseFieldKey, "skillName"]}
                              hasFeedback
                              label="Skill Name"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.expertiseList?.[name]?.skillName
                              }
                            >
                              <Input placeholder="Enter Skill Name" />
                            </Form.Item>
                            <Form.Item
                              {...expertiseRestField}
                              name={[name, "skillLevel"]}
                              fieldKey={[expertiseFieldKey, "skillLevel"]}
                              hasFeedback
                              label="Skill Level"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.expertiseList?.[name]?.skillLevel
                              }
                            >
                              <Input placeholder="Enter Skill Level" />
                            </Form.Item>
                            <Form.Item
                              {...expertiseRestField}
                              name={[name, "skillDescription"]}
                              fieldKey={[expertiseFieldKey, "skillDescription"]}
                              hasFeedback
                              label="Skill Description"
                              style={{ width: 350 }}
                              initialValue={
                                teacherData?.data?.teacherDetails
                                  ?.expertiseList?.[name]?.skillDescription
                              }
                            >
                              <Input placeholder="Enter Skill Description" />
                            </Form.Item>
                          </Space>

                          <Button
                            type="primary"
                            danger
                            style={{
                              position: "absolute",
                              right: 0,
                              bottom: 0,
                            }}
                            onClick={() => {
                              remove(name);
                              setVariationImageUrl((prev) => {
                                const newArr = prev.filter(
                                  (_, i) => i !== name
                                );
                                return newArr;
                              });
                            }}
                          >
                            <CloseOutlined />
                          </Button>
                        </Card>
                      )
                    )}

                    <Form.Item
                      style={{
                        width: "100%",
                        flex: "1 1 100%",
                        marginBottom: 5,
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                          setVariationImageUrl((prev) => {
                            const newArr = Array.from(prev);
                            newArr.push("");
                            return newArr;
                          });
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Expertise List
                      </Button>
                    </Form.Item>

                    <Form.ErrorList errors={errors} />
                  </>
                )}
              </Form.List>
            </div>
          </div>
          <Row gutter={20} className="mb-6">
            <Col span={12}>
              <Divider orientation="left">
                profile picture
                <Tooltip
                  placement="bottom"
                  title={"Web image should 500px/180px (Ratio 2.8)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                imgUrl={appsImgUrl || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => setStartUpload(val)}
                setGeneratedImgUrl={(url: any) => setAppsImgUrl(url)}
                // crop={2.8}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default AddTeacher;
