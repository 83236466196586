import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Select,
  Skeleton,
  Switch,
} from "antd";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import _ from "lodash";
import moment from "moment";
import { isAllowedService } from "../../utils/services";
import React from "react";
import DirectoryTree from "antd/es/tree/DirectoryTree";
import { AddBtn, LoaderFull, Pagination } from "../common";
const { RangePicker } = DatePicker;
const { Option } = Select;

const VehicleList = (): ReactElement => {
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const fetchRef = useRef(0);
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = 16;
  const [driverId, setDriverId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [showSearch, setShowSearch] = useState(true);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [sortBy, setSortBy] = useState<any>("createdAt");
  const [sortOrder, setSortOrder] = useState<any>("DESC");
  const [vehicleData, setVehicleData] = useState<any>({
    loading: false,
    data: null,
  });
  const [driverOptions, setDriverOptions] = useState({
    loading: false,
    list: [],
  });
  const getVehicles = useCallback(async () => {
    setVehicleData({ loading: true, data: null });
    const encodedUrl = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/vehicle/all-vehicle?limit=${limit}` +
        (driverId ? `&driverId=${driverId}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``) +
        (sortBy ? `&sortBy=${sortBy}` : ``) +
        (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (page
            ? `&page=${
                page == vehicleData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setVehicleData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setVehicleData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [driverId,isActive,sortBy,sortOrder,page, limit]);
  const getDriverOptions = useCallback(async () => {
    setDriverOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/driver?page=0&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setDriverOptions({
      loading: false,
      list: res?.data?.drivers?.map((driver: any, driverIndex: any) => {
        return {
          value: driver?.id,
          label: driver?.name,
        };
      }),
    });
  }, []);

  useEffect(() => {
    getVehicles();
    getDriverOptions();
  }, [getVehicles,getDriverOptions]);
  const onClose = () => {
    getVehicles();
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const reseAllFieldData = () => {
    setDriverId("");
    setSortBy("");
    setSortOrder("");
    setIsActive("");
    form.resetFields();
  };

  return (
    <>
      <BreadCrumb
        title="Vehicle List"
        subTitle={`${
          vehicleData?.data?.totalElements ||
          vehicleData?.data?.vehicleResponses?.length
        } Vehicle(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,

          <AddBtn onClick={() => navigate("/vehicles/vehicle-add")} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form}>
            <Form.Item name="driver_search">
            <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by Driver`}
                  optionFilterProp="children"
                  onChange={(val) => setDriverId(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={driverOptions?.list}
                ></Select>
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && loading && <LoaderFull />}
              {vehicleData?.loading ? (
                <LoaderFull />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Vehicle</th>
                      <th scope="col">Brand</th>
                      <th scope="col">Brand Model</th>
                      <th scope="col">Fuel Type</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {vehicleData?.data?.vehicleResponses?.length ? (
                      vehicleData?.data?.vehicleResponses?.map(
                        (vehicle: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                            onClick={() =>
                              navigate(
                                `/vehicles/${vehicle?.id}/vehicleId/details`
                              )
                            }
                            draggable="true"
                          >
                            <td>
                              <div className="flex items-center">
                                <Avatar.Group
                                  maxCount={3}
                                  size="large"
                                  maxStyle={{
                                    color: "#f56a00",
                                    backgroundColor: "#fde3cf",
                                  }}
                                >
                                  <Avatar
                                    style={{
                                      backgroundColor:
                                        vehicle?.fitnessCertificateImage ||
                                        "#f56a00",
                                    }}
                                  />
                                  <Avatar src={vehicle?.taxTokenImage} />
                                  <Avatar
                                    src={
                                      vehicle?.registrationPaperImage?.backUrl
                                    }
                                  />
                                  <Avatar
                                    src={
                                      vehicle?.registrationPaperImage?.frontUrl
                                    }
                                  />
                                </Avatar.Group>
                                <div className="flex flex-col ml-2">
                                  <span className="font-medium text-gray-500">
                                    {vehicle?.vehicleRegNo}
                                  </span>
                                  <small className="font-light text-gray-500">
                                    {vehicle?.type?.split("_").join(" ")}
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {vehicle?.brandName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {vehicle?.brandModelName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {vehicle?.fuelTypeName}
                              </span>
                            </td>
                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigate(`/vehicles/${vehicle?.id}/edit`);
                                }}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...vehicleData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default VehicleList;
