import React from "react";
import FuelTypeList from "../../../components/Vehicles/FuelType/index";
import MainLayout from "../../../components/Layouts";

const FuelTypeListPage = () => {
  return (
    <MainLayout>
      <FuelTypeList />
    </MainLayout>
  );
};

export default FuelTypeListPage;
