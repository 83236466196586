import {
  Divider,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  DatePickerProps,
  InputRef,
  Tag,
  DatePicker,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getQueryParams } from "../../utils";
import { responseNotification } from "../../utils/notify";
import { useNavigate, useParams } from "react-router";
import BreadCrumb from "../Layouts/Breadcrumb";
import { useSelector } from "react-redux";
import {
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { ImageUploader, SubmitResetBtn } from "../common";
import cn from "classnames";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";

const Option = Select;

const AddBook = (props: any) => {
  // text editor end
  const editor = useRef(null);
  const { inShop, getShopId } = props;
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);

  // new add
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);
  const [publicationDate, setPublicationDate] =
    useState<DatePickerProps["value"]>();
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue("");
  };

  const tagInputStyle: React.CSSProperties = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: "top",
  };

  const tagPlusStyle: React.CSSProperties = {
    height: 22,
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };
  // new add end
  const [convertedText, setConvertedText] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [saveAndNew, setSaveAndNew] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [variationImageUrl, setVariationImageUrl] = useState<string[]>([]);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [generatedThumbUrl, setGeneratedThumbUrl] = useState<string>();

  //new text editor

  const [discountType, setDiscountType] = useState<{
    [key: string]: {
      type: "disFlat" | "disPercentage" | null;
      value: undefined | number;
    };
  }>({});

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [singleProductInfo, setSingleProductInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const route = useParams();
  const multi = (getQueryParams() as any)?.for === "multiple-shop";

  const fetchBookDetails = useCallback((productSlug: any) => {
    if (productSlug) {
      try {
        setSingleProductInfo({ loading: true, data: null });
        axios 
          .get( 
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/product/details?slug=${productSlug}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleProductInfo({
                loading: false,
                data: data?.data?.product,
              });
              setConvertedText(data?.data?.product?.description || "");
              setVariationImageUrl(
                data?.data?.product?.variations?.map(
                  (item: any) => item?.imageUrl
                )
              );

           
              setIsActive(data?.data?.product?.isActive);
              setGeneratedImgUrl(singleProductInfo?.data?.images || []);
              setGeneratedThumbUrl(singleProductInfo?.data?.productImage);

              const diss = {} as any;
              data?.data?.product?.variations?.map((va: any, i: string) => {
                diss[i] = {
                  type: va?.productDiscount?.flat
                    ? "disFlat"
                    : va?.productDiscount?.percentage
                    ? "disPercentage"
                    : null,
                  value:
                    va?.productDiscount?.flat ||
                    va?.productDiscount?.percentage ||
                    0,
                };
              });
              const qtyDis = {} as any;
              data?.data?.product?.variations?.map((va: any, i: string) => {
                qtyDis[i] = {
                  type: va?.quantitativeProductDiscount?.freeProduct
                    ? "freeProduct"
                    : va?.quantitativeProductDiscount?.productDiscount?.flat
                    ? "qtyFlat"
                    : va?.quantitativeProductDiscount?.productDiscount
                        ?.percentage
                    ? "qtyPercentage"
                    : null,
                  value:
                    va?.quantitativeProductDiscount?.freeProduct ||
                    va?.quantitativeProductDiscount?.productDiscount?.flat ||
                    va?.quantitativeProductDiscount?.productDiscount
                      ?.percentage ||
                    0,
                  minimumQuantity:
                    va?.quantitativeProductDiscount?.minimumQuantity,
                };
              });

              setDiscountType(diss);
            } else {
              setSingleProductInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleProductInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleProductInfo({ loading: false, data: null });
      }
    }
  }, []);
  useEffect(() => {
    if (saveAndNew) {
      setStartUpload("Uploading");
      setStartUpload2("Uploading");
    }
  }, [saveAndNew]);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    let readyData = data && {
      authorName: data.authorName,
      colorCode: data.colorCode,
      language: data.language,
      name: data.name,
      numberOfDownloads: data.numberOfDownloads,
      numberOfPages: data.numberOfPages,
      price: data.price,
      publicationDate: data.publicationDate,
      type: type,
      description: convertedText || "<p></p>",
      uploadBookDetails: {
        bannerImageUrl: generatedThumbUrl,
        pdfFileUrl: generatedThumbUrl,
        videoUrl: data.videoUrl || "NULL",
        title: data.name,
      },
      bookGenre: tags,
    };

    if (singleProductInfo?.data) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product${
          multi ? `/multiple-shop` : ``
        }`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: singleProductInfo?.data?.id,
            // isActive: isActive,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Product Updated Successfully", "success");
            form.resetFields();
            fetchBookDetails((route as any)?.slug);
            // if (history?.length === 1) {
            //   navigate(`/products`);
            // } else {
            //   navigate(-1);
            // }
            navigate(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/book`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Book Create Successfully", "success");

            form.resetFields();
            navigate(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  // **********************************
  // Search Options
  // **********************************







  const fetchRef = useRef(0);




  useEffect(() => {
    fetchBookDetails((route as any)?.slug);
  }, [fetchBookDetails, route]);

  //uploadBookDetails
  const resetData = () => {
    form?.resetFields();
    setVariationImageUrl([]);
    setGeneratedImgUrl(singleProductInfo?.data?.images || []);
    setGeneratedThumbUrl(singleProductInfo?.data?.productImage);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={singleProductInfo?.data ? "Edit Book" : "Add Book"}
        extra={[
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={loading}
            loading={loading}
          />,
        ]}
      />

      <div className="content-body rounded-2xl">
        <div className="">
          <Form
            name="control-hooks"
            form={form} // like ref
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
            initialValues={{
              ...singleProductInfo?.data,
              type: type || singleProductInfo?.data?.shop?.type,
              paymentTimeInSeconds:
                singleProductInfo?.data?.paymentTimeInSeconds / 60 / 60,
            }}
            layout="vertical"
            autoComplete="off"
          >
            <div
              className={
                inShop
                  ? "grid grid-cols-2 xl:grid-cols-1 xl:gap-6"
                  : "grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6"
              }
            >
              <div
                className={cn(
                  `grid grid-cols-2 md:grid-cols-2 xl:grid-cols-1 col-span-3 md:col-span-3 lg:col-span-1 gap-6 grid-flow-row auto-rows-max mb-6`,
                  { ["grid grid-cols-2 xl:grid-cols-2 xl:gap-6"]: inShop }
                )}
              >
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl px-4 pb-2">
                  <Divider orientation="left">
                    Banner Image
                    <Tooltip
                      placement="bottom"
                      title={
                        "Banner Image should be square. We prefer height 400px and width 400px (Ratio 1)"
                      }
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>
                  <ImageUploader
                    // crop={1}
                    imgUrl={generatedThumbUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => setStartUpload(val)}
                    setGeneratedImgUrl={(url: any) => {
                      setGeneratedThumbUrl(url);
                    }}
                  />
                  <Divider orientation="left">
                    Image Gallery
                    <Tooltip
                      placement="bottom"
                      title={
                        "Product Image should be square. We prefer height 400px and width 400px (Ratio 1)"
                      }
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>
                  <ImageUploader
                    multiple
                    imgUrl={generatedImgUrl || []}
                    startUpload={startUpload2}
                    setStartUpload={(val: any) => setStartUpload2(val)}
                    setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
                  />
                </div>
              </div>

              <div
                className={
                  inShop ? "col-span-full" : `grid-cols-3 lg:col-span-2`
                }
              >
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 grid grid-cols-2 gap-x-4">
                  <h3 className="mb-4 text-xl font-bold col-span-2">
                    General Info
                  </h3>

                  <div>
                    <Form.Item
                      hasFeedback
                      label="Author Name"
                      name="authorName"
                    >
                      <Input
                        id="authorName"
                        type="text"
                        placeholder="Author Name"
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Divider orientation="left" className="">
                      Book Genre
                    </Divider>
                    <Space size={[0, 8]} wrap>
                      {tags.map((tag, index) => {
                        if (editInputIndex === index) {
                          return (
                            <Input
                              ref={editInputRef}
                              key={tag}
                              size="small"
                              style={tagInputStyle}
                              value={editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                            />
                          );
                        }
                        const isLongTag = tag.length > 20;
                        const tagElem = (
                          <Tag
                            key={tag}
                            closable={index !== 0}
                            style={{ userSelect: "none" }}
                            onClose={() => handleClose(tag)}
                          >
                            <span
                              onDoubleClick={(e) => {
                                if (index !== 0) {
                                  setEditInputIndex(index);
                                  setEditInputValue(tag);
                                  e.preventDefault();
                                }
                              }}
                            >
                              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                            </span>
                          </Tag>
                        );
                        return isLongTag ? (
                          <Tooltip title={tag} key={tag}>
                            {tagElem}
                          </Tooltip>
                        ) : (
                          tagElem
                        );
                      })}
                      {inputVisible ? (
                        <Input
                          ref={inputRef}
                          type="text"
                          size="small"
                          style={tagInputStyle}
                          value={inputValue}
                          onChange={handleInputChange}
                          onBlur={handleInputConfirm}
                          onPressEnter={handleInputConfirm}
                        />
                      ) : (
                        <Tag
                          style={tagPlusStyle}
                          icon={<PlusOutlined />}
                          onClick={showInput}
                        >
                          New Genre
                        </Tag>
                      )}
                    </Space>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Color Code"
                      rules={[
                        {
                          required: true,
                          message: "Color Code is Required!",
                        },
                      ]}
                      name="color"
                    >
                      <Input
                        id="colorCode"
                        type="color"
                        placeholder="Enter color code"
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Language"
                      rules={[
                        {
                          required: true,
                          message: "Language is Required!",
                        },
                      ]}
                      name="language"
                    >
                      <Input
                        id="language"
                        type="text"
                        placeholder="Enter Book Language"
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item hasFeedback label="Book Name" name="name">
                      <Input
                        id="name"
                        type="text"
                        placeholder="Enter Book Name"
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Number Of Downloads"
                      name="numberOfDownloads"
                    >
                      <Input
                        id="numberOfDownloads"
                        type="number"
                        placeholder="Enter Number Of Downloads"
                      />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Number Of Pages"
                      rules={[
                        {
                          required: true,
                          message: "Number Of Pages is Required!",
                        },
                      ]}
                      name="numberOfPages"
                    >
                      <Input
                        id="numberOfPages"
                        type="text"
                        placeholder="Enter Number Of Pages"
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Price"
                      rules={[
                        {
                          required: true,
                          message: "Price is Required!",
                        },
                      ]}
                      name="price"
                    >
                      <Input
                        id="price"
                        type="number"
                        placeholder="Enter price"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      hasFeedback
                      label="Publication Date"
                      rules={[
                        {
                          required: true,
                          message: "Publication Date is Required!",
                        },
                      ]}
                      name="publicationDate"
                    >
                      <DatePicker
                        showTime
                        style={{ minWidth: "100%" }}
                        showNow={false}
                        placeholder="Publication Date"
                        onChange={(val: DatePickerProps["value"]) =>
                          setPublicationDate(val)
                        }
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      hasFeedback
                      label="Video Url"
                      rules={[
                        {
                          required: false,
                          message: "Video Url is Required!",
                        },
                      ]}
                      name="videoUrl"
                    >
                      <Input
                        id="videoUrl"
                        type="text"
                        placeholder="Enter videoUrl Url"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2 mb-8">
                    <h4 className="font-semibold mb-1">Book Details</h4>
                    <JoditEditor
                      ref={editor}
                      value={convertedText}
                      config={jodiConfig as any}
                      // tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
                      onChange={(newContent) => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddBook;
