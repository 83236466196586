import { Alert, Button, Divider, Form, Input, Select, Switch } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { ImageUploader, SubmitResetBtn, TreeSelectSlugCat } from "../common";
import axios from "axios";
import { debounce } from "lodash";

const AddCity = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [showSearch, setShowSearch] = useState(true);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState(true);
  const [cityData, setCityData] = useState<any>(undefined);
  const [key, setKey] = useState("");
  const [countryId, setCountryId] = useState<any>();
  const [stateId, setStateId] = useState<any>();
  const [dataOptions, setDataOptions] = useState({
    loading: false,
    list: [],
  });
  const [stateOptions, setStateOptions] = useState({
    loading: false,
    list: [],
  });


  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
      setLoading(true);
      const readyData = data && {
        countryId: countryId,
        latitude: data.latitude,
        longitude: data.longitude,
        name: data.name,
        stateId: stateId,
      };

      if (cityData) {
        await fetch(
          `${process.env.REACT_APP_RIDER_API}/admin/country-state/update-city`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...readyData,
              id: cityData?.id,
              deleted: false,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("City Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(
          `${process.env.REACT_APP_RIDER_API}/admin/country-state/add-city`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(readyData),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("City Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }

  };

  const getCountryOptions = useCallback(
    async (key: any) => {
      setDataOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/countries${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDataOptions({
            loading: false,
            list: res.data?.countries?.map((country:any) => ({
              label: country.name,
              value: country.id,
            })),
          });
        })
        .catch((err) => {
          setDataOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [key]
  );

  const getStateOptions = useCallback(
    async (key: any) => {
      setStateOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?page=0&limit=20` +
        (countryId ? `&countryId=${countryId}` : ``)+
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/states${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStateOptions({
            loading: false,
            list: res.data?.states?.map((state:any) => ({
              label: state.name,
              value: state.id,
            })),
          });
        })
        .catch((err) => {
          setStateOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [countryId,key]
  );

  useEffect(() => {
    if (visibleData) {
      setCityData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (cityData) {
      setCountryId(cityData?.countryId);
      setStateId(cityData?.stateId);
      form.resetFields(Object.keys(visibleData));
      form.resetFields();
    }
  }, [cityData, form, visibleData]);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "country") getCountryOptions(value);
        if (field === "state") getStateOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCountryOptions,getStateOptions]);

  useEffect(() => {
      getCountryOptions("");
      getStateOptions("");
    
  }, [getCountryOptions,getStateOptions]);




  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...cityData,
        }}
      >
        <Form.Item
          name="product_search"
          label="Country Name"
        >
          <Select
            allowClear
            showSearch
            placeholder={`Filter by country`}
            optionFilterProp="children"
            defaultValue={countryId}
            onChange={(val) => setCountryId(val)}
            onSearch={(e) => handleSearch(e, "country")}
            filterOption={() => {
              return true;
            }}
            options={dataOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item
          name="state_search"
          label="State Name"
        >
          <Select
            allowClear
            showSearch
            placeholder={`Filter by State`}
            optionFilterProp="children"
            defaultValue={stateId}
            onChange={(val) => setStateId(val)}
            onSearch={(e) => handleSearch(e, "state")}
            filterOption={() => {
              return true;
            }}
            options={stateOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          label="City Name"
          rules={[
            {
              required: !visibleData,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter City Name" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Latitude"
          rules={[
            {
              required: false,
              message: "Latitude is Required!",
            },
          ]}
          name="latitude"
        >
          <Input id="latitude" type="text" placeholder="Enter City Latitude" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Longitude"
          rules={[
            {
              required: false,
              message: "Longitude is Required!",
            },
          ]}
          name="longitude"
        >
          <Input id="longitude" type="text" placeholder="Enter City Longitude" />
        </Form.Item>

        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddCity;
