import { Button, DatePicker, Empty, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import Loader from "../common/Loader";
import { debounce } from "lodash";
import Pagination from "../common/Pagination/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";
const { RangePicker } = DatePicker;

const OrderSalesReportList = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [showSearch, setShowSearch] = useState(true);

  const [transactionData, setTransactionData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const onClose = () => {
    setVisibleDetails(false);
  };

  const getTransactions = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setTransactionData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/transaction/history` +
          `?page=${page || 0}` +
          `&limit=${limit || 16}` +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTransactionData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTransactionData({ loading: false, data: [] });
        console.error("Drivers: Error", err);
      });
  }, [range.from, range.to, page, limit]);
  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
      }
    };

    return debounce(loadOptions, 800);
  }, []);

  useEffect(() => {
    if (showSearch) {
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    form?.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <>
      <BreadCrumb
        title="Transaction List"
        subTitle={`${transactionData?.data?.totalElements} Transaction(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox} `}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {transactionData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Transaction Id</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {transactionData?.data?.transactions?.length ? (
                      transactionData?.data?.transactions?.map(
                        (transaction: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="font-medium">
                                {transaction?.transactionId}
                              </span>
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    transaction?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(transaction?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(transaction?.createdAt).format("lll")}
                              </span>
                            </td>

                            <td>&#2547;{parseInt(transaction?.amount)}</td>
                            <td>
                              <span
                                className={`radius-btn ${transaction?.type?.toLowerCase()}-btn`}
                              >
                                {transaction?.type?.split("-").join(" ")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...transactionData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default OrderSalesReportList;
