import { ReactElement } from 'react'
import DriverOrdersList from '../../../components/Delivery/DeliveryHero/orders';
import MainLayout from '../../../components/Layouts'
import React from 'react';

const DriverOrdersPage = (): ReactElement => {
  return (
    <MainLayout>
      <DriverOrdersList />
    </MainLayout>
  )
}

export default DriverOrdersPage;
