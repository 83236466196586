import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import CategoryLayout from "../Layout";
import ProductList from "../../Product/index";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

const CategoryProductList = (): ReactElement => {
  const { categoryId } = useParams();
  return (
    <React.Fragment>
      <CategoryLayout
        categoryId={categoryId as any}
        title="Product List"
        subTitle="Details"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <ProductList getCategoryId={categoryId} />
          </div>
        </div>
      </CategoryLayout>
    </React.Fragment>
  );
};

export default CategoryProductList;
