import React from "react";
import MainLayout from "../../components/Layouts";
import ShopList from "../../components/Shop";

const ShopPage = () => {
  return (
    <MainLayout>
      <ShopList />
    </MainLayout>
  );
};

export default ShopPage;
