import React from "react";
import MainLayout from "../../components/Layouts";
import AddBag from "../../components/Bags/AddBag";

const BagEditPage = () => {
  return (
    <MainLayout>
      <AddBag />
    </MainLayout>
  );
}; 
 
export default BagEditPage;
