import { DownOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Divider,
  Form,
  Input,
  Select,
  Spin,
  TimePicker,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {  MapBox, ImageUploader } from "../../common";
import { regions } from "../../common/Region";

const ShopInfo = ({ form }: { form: any }) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [businessHourFrom, setBusinessHourFrom] = useState<any>("00:00");
  const [businessHourTo, setBusinessHourTo] = useState<any>("00:00");
  const [startAt, setStartAt] = useState<any>("00:00");
  const [endAt, setEndAt] = useState<any>("00:00");
  const [storeLogo, setStoreLogo] = useState<string>();
  const [storeBanner, setStoreBanner] = useState<string>();
  const [storeAddress, setStoreAddress] = useState<any>({
    address: null,
    city: null,
    district: null,
    postcode: null,
  });

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const format = "HH:mm";
  // **************************************
  const [geoAddress, setGeoAddress] = useState<any>({
    loading: false,
    data: null,
  });

  const [locationOptions, setLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [locationListOptions, setLocationListOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [currentLocation, setCurrentLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });

  const getLocationOptions = useCallback(async (key?: string) => {
    setLocationOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_MAP_API}`;
    const res = await axios.get(
      `${encodedUri}/autocomplete` + (key ? `?key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setLocationOptions({
      loading: false,
      list: res?.data?.places,
    });

    setLocationListOptions({
      loading: false,
      list: res?.data?.places?.map((place: any) => {
        return {
          value: place?.address,
          label: place?.address,
        };
      }),
    });
  }, []);

  const fetchGeoAddress = useCallback(() => {
    try {
      setGeoAddress({ loading: true, data: null });
      axios
        .get(
          `${process.env.REACT_APP_MAP_API}/geocode?latitude=${currentLocation?.lat}&longitude=${currentLocation?.lng}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setGeoAddress({
              loading: false,
              data: data?.data?.place,
            });
          } else {
            setGeoAddress({ loading: false, data: null });
          }
        })
        .catch((err) => {
          setGeoAddress({ loading: false, data: null });
        });
    } catch (error) {
      setGeoAddress({ loading: false, data: null });
    }
  }, [currentLocation?.lat, currentLocation?.lng]);

  useEffect(() => {
    fetchGeoAddress();
  }, [fetchGeoAddress, currentLocation]);

  useEffect(() => {
    form.resetFields(["latitude", "longitude", "address"]);
  }, [currentLocation]);

  useEffect(() => {
    form.resetFields(["address"]);
  }, [geoAddress]);

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        businessHourFrom: moment(businessHourFrom || "00:00").format("HH:mm"),
        businessHourTo: moment(businessHourTo || "00:00").format("HH:mm"),
        storeBanner: storeBanner,
        storeLogo: storeLogo,
        storeAddress: storeAddress,
        storeLocation: {
          latitude: currentLocation.lat,
          longitude: currentLocation.lng,
        },
      });
    }
  }, [
    currentLocation,
    storeBanner,
    storeLogo,
    storeAddress,
    businessHourFrom,
    businessHourTo,
  ]);

  // **************************************

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-x-6">
        <div>
          <Form.Item
            hasFeedback
            label="type"
            name="type"
            initialValue={type}
            hidden
          >
            <Input
              id="type"
              type="text"
              placeholder="Enter type"
              value={type}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label="Business Type"
            rules={[
              {
                required: true,
                message: "Business Type is Required!",
              },
            ]}
            name="businessType"
          >
            <Input
              id="businessType"
              type="text"
              placeholder="Select Business Type"
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label="Store Name"
            rules={[
              {
                required: true,
                message: "Store Name is Required!",
              },
            ]}
            name="storeName"
          >
            <Input id="storeName" type="text" placeholder="Enter Store Name" />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label="Mobile Number (Login)"
            rules={[
              {
                required: true,
                message: "Mobile Number is required!",
              },
              {
                validator: async (_, names) => {
                  if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                    return Promise.reject(
                      new Error("Enter valid mobile number")
                    );
                  }
                },
              },
            ]}
            name="authorizationMobileNumbers"
            initialValue={"01"}
          >
            <Input
              id="authorizationMobileNumbers"
              type="contact"
              addonBefore={"+88"}
              placeholder="Enter Mobile Number"
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item hasFeedback label="Business Days" name="businessDays">
            <Select
              mode="multiple"
              allowClear
              placeholder="Please select"
              options={[
                { value: "SUN", label: "SUN" },
                { value: "MON", label: "MON" },
                { value: "TUE", label: "TUE" },
                { value: "WED", label: "WED" },
                { value: "THU", label: "THU" },
                { value: "FRI", label: "FRI" },
                { value: "SAT", label: "SAT" },
              ]}
            ></Select>
          </Form.Item>
        </div>

        <Form.Item hasFeedback label={`Opening Time (${businessHourFrom})`}>
          <TimePicker
            format="HH:mm"
            // showNow={false}
            value={moment(startAt, "HH:mm")}
            suffixIcon={<DownOutlined />}
            onSelect={(val) => {
              setStartAt(moment(val as any).format("HH:mm"));
            }}
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          label={`Closing Time (${businessHourTo})`}
          // name="businessHourTo"
        >
          <TimePicker
            format="HH:mm"
            // showNow={false}
            value={moment(endAt, "HH:mm")}
            suffixIcon={<DownOutlined />}
            onSelect={(val) => {
              setEndAt(moment(val as any).format("HH:mm"));
            }}
          />
        </Form.Item>

        <div className="col-span-2">
          <Form.Item hasFeedback label="Description" name="description">
            <Input.TextArea
              rows={5}
              id="description"
              placeholder="Enter Description"
            />
          </Form.Item>
        </div>
      </div>

      <div className="bg-white mb-6">
        <h3 className="mb-4 text-xl font-bold">Address</h3>
        <div className="grid grid-cols-2 gap-x-6">
          <div>
            <Form.Item
              hasFeedback
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Address is required!",
                },
              ]}
              initialValue={geoAddress?.data?.address}
            >
              <AutoComplete
                autoClearSearchValue={false}
                allowClear
                showSearch
                onSearch={(val) => {
                  getLocationOptions(val);
                }}
                onSelect={(val: any) => {
                  const matchData = locationOptions?.list?.find(
                    (place: any) => val === place.address
                  );
                  setCurrentLocation({
                    lat: matchData?.location?.latitude,
                    lng: matchData?.location?.longitude,
                  });
                }}
                options={locationListOptions?.list}
                notFoundContent={
                  locationListOptions?.loading ? <Spin size="small" /> : null
                }
                // loading={locationListOptions.loading}
                placeholder="Search Address"
                onBlur={(val) => setStoreAddress({ address: val })}
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item hasFeedback label="Area" name="area">
              <Input id="area" type="text" placeholder="Enter shops area" />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              hasFeedback
              label="City"
              name="city"
              initialValue="Dhaka"
            >
              {/* <Input id="city" type="text" placeholder="Enter shops city" /> */}

              <Select
                showSearch
                placeholder="Filter by Status"
                optionFilterProp="children"
                filterOption={() => {
                  return true;
                }}
              >
                {Object.values(regions.City)?.map((city, i) => (
                  <Select.Option value={city} key={i}>
                    {city}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div>
            <Form.Item
              hasFeedback
              label="District"
              name="district"
              initialValue="Dhaka"
            >
              <Select
                showSearch
                placeholder="Filter District"
                optionFilterProp="children"
                filterOption={() => {
                  return true;
                }}
              >
                {Object.values(regions.Dhaka)?.map((district, i) => (
                  <Select.Option value={district} key={i}>
                    {district}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div>
            <Form.Item hasFeedback label="Postcode" name="postcode">
              <Input id="postcode" type="text" placeholder="Enter Postcode" />
            </Form.Item>
          </div>

          {/* <div>
            <Form.Item
              hasFeedback
              label="Latitude"
              name="latitude"
              initialValue={currentLocation?.lat}
            >
              <Input id="latitude" type="text" placeholder="23.86450" />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              hasFeedback
              label="Longitude"
              name="longitude"
              initialValue={currentLocation?.lng}
            >
              <Input id="longitude" type="text" placeholder="90.53680" />
            </Form.Item>
          </div> */}

          <div className="col-span-2">
            <Form.Item name="storeLocation">
              <MapBox
                draggable={true}
                coordinate={currentLocation}
                setCoordinator={(val: any) => setCurrentLocation(val)}
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="flow-root mt-8">
        <div className="grid grid-cols-3 gap-6">
          <div className="mr-4">
            <Divider orientation="left">Store Logo</Divider>
            <Form.Item name="storeLogo">
              <ImageUploader
                imgUrl={storeLogo || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => {
                  setStartUpload(val);
                  setStartUpload("Uploading");
                }}
                setGeneratedImgUrl={(url: any) => {
                  setStoreLogo(url);
                }}
              />
            </Form.Item>
          </div>

          <div className="">
            <Divider orientation="left">Store Banner</Divider>
            <Form.Item name="storeBanner">
              <ImageUploader
                imgUrl={storeBanner || ""}
                startUpload={startUpload2}
                setStartUpload={(val: any) => {
                  setStartUpload2(val);
                  setStartUpload2("Uploading");
                }}
                setGeneratedImgUrl={(url: any) => setStoreBanner(url)}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopInfo;
