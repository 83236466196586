import React from "react";
import MainLayout from "../../components/Layouts";
import ShippingZoneList from "../../components/DeliveryShipping/ShippingZoneList";

const ShippingZoneListPage = () => {
  return (
    <MainLayout>
      <ShippingZoneList />
    </MainLayout>
  );
};

export default ShippingZoneListPage;
