import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopShippingAddPackage from "../../../components/Shop/ShippingPackage/Add";

const ShopShippingAddPackagePage = () => {
  return (
    <MainLayout>
      <ShopShippingAddPackage />
    </MainLayout>
  );
};

export default ShopShippingAddPackagePage;
