import { Alert, Button, Divider, Form, Input, Select, Switch } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import ImageUploader from "../../common/ImageUploader";
import SubmitResetBtn from "../../common/SubmitBtn";
import styles from "../../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";
import { jcTypeArray } from "../../../utils";
const { TextArea } = Input;

const AddVehicleBrandModel = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [serviceType, setServiceType] = useState<any>("JC_COMMERCE");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [veichileBrandData, setVeichileBrandData] = useState<any>(undefined);
  const [key, setKey] = useState("");
  const [vehicleTypeId, setVehicleTypeId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [vehicleOptions, setVehicleOptions] = useState({
    loading: false,
    list: [],
  });
  const [brandsOptions, setBrandsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);
  const onSubmit = async (data: any) => {
    if (generatedImgUrl) { 
      setLoading(true);
      const readyData = data && {
        name: data.name,
        description: data.description,
        vehicleTypeId: vehicleTypeId,
        brandId: brandId,
        isActive: isActive,
        logo: generatedImgUrl,
      };
 
      if (veichileBrandData) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/brand-model`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: veichileBrandData?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Banner Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/brand-model`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Banner Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  const getVehicleTypeOptions = useCallback(
    async (key: any) => {
      setVehicleOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url = `?page=0&limit=20` + (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/vehicle-type/all${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setVehicleOptions({
            loading: false,
            list: res.data?.vehicleTypes?.map((vehicle: any) => ({
              label: vehicle.vehicleType,
              value: vehicle.id,
            })),
          });
        })
        .catch((err) => {
          setVehicleOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [key]
  );
  const getBrandsOptions = useCallback(async (val?: string) => {
    setBrandsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/brand/search?type=${serviceType}&page=0&limit=20` +
        (val ? `&key=${val}` : ``),

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandsOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => {
        return {
          value: brand?.id,
          label: brand?.name,
        };
      }),
    });
  }, [serviceType]);
 
  useEffect(() => {
    if (visibleData) {
      setVeichileBrandData(visibleData);
      //setVehicleTypeId(visibleData?.vehicleType);
    }
  }, [visibleData]);

  useEffect(() => {
    if (veichileBrandData) {
      setServiceType(veichileBrandData?.type);
     setVehicleTypeId(veichileBrandData?.vehicleType);
     setBrandId(veichileBrandData?.brand?.id);
     setGeneratedImgUrl(veichileBrandData?.logo);
     setIsActive(veichileBrandData?.isActive);
      form.resetFields(Object.keys(visibleData));
      form.resetFields();
    }
  }, [veichileBrandData, form, visibleData]);
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "vehicle") getVehicleTypeOptions(value);
        if (field === "brand") getBrandsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getVehicleTypeOptions]);

  useEffect(() => {
    getVehicleTypeOptions("");
    getBrandsOptions("");
  }, [getVehicleTypeOptions,getBrandsOptions]);
  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...veichileBrandData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Vehicle Brand Model Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input
            id="name"
            type="text"
            placeholder="Enter Vehicle Brand Name"
          />
        </Form.Item>
        <Form.Item hasFeedback label="Description" name="description">
          <Input.TextArea
            id="description"
            placeholder="Enter Description"
            rows={3}
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Service Type"
          rules={[
            {
              required: true,
              message: "Type is required!",
            },
          ]}
          name="serviceType"
          initialValue={"JC_COMMERCE"}
        >
          <Select
           allowClear
           showSearch
            placeholder="Service Type" 
            defaultValue={serviceType}
            onChange={(val) => setServiceType(val)}
            filterOption={() => {
              return true;
            }}
          >
            {Object.values(jcTypeArray)?.map((type, i) => (
              <Select.Option value={type} key={i}>
                {type?.replace("_", " ")}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
              
        <Form.Item
          name="brandId"
          label="Brand"
          rules={[
            {
              required: true,
              message: "Brand is Required!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder={`Brand`}
            optionFilterProp="children"
            defaultValue={brandId}
            onChange={(val) => setBrandId(val)}
            onSearch={(e) => handleSearch(e, "brand")}
            filterOption={() => {
              return true;
            }}
            options={brandsOptions?.list}
          ></Select>
        </Form.Item>
        
        <Form.Item
          name="vehicleTypeId"
          label="Vehicle Type"
          rules={[
            {
              required: true,
              message: "value is Required!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder={`Filter by vehicle type`}
            optionFilterProp="children"
            defaultValue={vehicleTypeId}
            onChange={(val) => setVehicleTypeId(val)}
            onSearch={(e) => handleSearch(e, "vehicle")}
            filterOption={() => {
              return true;
            }}
            options={vehicleOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Is Active?"
          name="isActive"
          className="mr-5"
        >
          <Switch
            checkedChildren={`true`}
            unCheckedChildren={`false`}
            defaultChecked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
        </Form.Item>
        <div className="">
          <ImageUploader
            imgUrl={generatedImgUrl || ""}
            startUpload={startUpload}
            setStartUpload={(val: any) => setStartUpload(val)}
            setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            // crop={1}
          />
        </div>
        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
          }}
          onClickReset={() => {
            form?.resetFields();
            setGeneratedImgUrl(undefined);
            setStartUpload(undefined);
            // setBannerUrl(undefined);
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddVehicleBrandModel;
