import {
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import BreadCrumb from "../Layouts/Breadcrumb";
import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import { ImageUploader, SubmitResetBtn } from "../common";
import { jodiConfig } from "../../utils/editor-config";
import { debounce } from "lodash";
const AddCourse = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const navigate = useNavigate();
  const editor = useRef(null);
  const [appsImgUrl, setAppsImgUrl] = useState<string>();
  const [webImgUrl, setWebImgUrl] = useState<string>();
  const [courseType, setCourseType] = useState("DISCOUNT");
  const [discountType, setDiscountType] = useState("PERCENTAGE");
  const [startingDate, setStartingDate] = useState<DatePickerProps["value"]>();
  const [endingDate, setEndingDate] = useState<DatePickerProps["value"]>();
  const [isFlashSale, setIsFlashSale] = useState<boolean>(false);
  const [curriculumId, setCurriculumId] = useState<any>("");
  const [teacherId, setTeacherId] = useState<any>([]);
  const [disciplineId, setDisciplineId] = useState<any>([]);
  const [programId, setProgramId] = useState<any>("");
  const [menuId, setMenuId] = useState<any>("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isPayFirst, setIsPayFirst] = useState<boolean>(false);

  
  const [curriculumOptions, setCurriculumOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [teacherOptions, setTeacherOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [disciplineOptions, setDisciplineOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [programOptions, setProgramOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [menuOptions, setMenuOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [subMenuOptions, setSubMenuOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [shopId, setShopId] = useState<any>("");
  const [convertedText, setConvertedText] = useState("");
  const [convertedTextForTnC, setConvertedTextForTnC] = useState("");
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const paymentTimePreventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const [error, setError] = useState("");
  const [courseData, setCourseData] = useState<any>({
    loading: false,
    data: null,
  });
  const fetchCourseDetails = useCallback((getCourseId: any) => {
    if (getCourseId) {
      try {
        setCourseData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_ELARNING_API}/admin/course/details?courseId=${getCourseId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setCourseData({
                loading: false,
                data: data?.data?.course,
              });
            } else {
              setCourseData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setCourseData({ loading: false, data: null });
          });
      } catch (error) {
        setCourseData({ loading: false, data: null });
      }
    }
  }, []);
  const getCurriculumOptions = useCallback(async (key?: any) => {
    setCurriculumOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/curriculum?page=0&limit=20` +
      (programId ? `&programId=${programId}` : ``)+ 
      (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCurriculumOptions({
      loading: false,
      list: res?.data?.curriculums?.map((curriculum: any) => {
        return {
          value: curriculum?.id,
          label: curriculum?.name,
        };
      }),
    });
  }, [programId]);
  useEffect(() => {
    getCurriculumOptions();
  }, [getCurriculumOptions]);
  const getTeacherOptions = useCallback(async (key?: any) => {
    setTeacherOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/teacher?page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTeacherOptions({
      loading: false,
      list: res?.data?.teachers?.map((teacher: any) => {
        return {
          value: teacher?.id,
          label: teacher?.fullName,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getTeacherOptions();
  }, [getTeacherOptions]);
  const getDisciplineOptions = useCallback(async (key?: any) => {
    setDisciplineOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/discipline?page=0&limit=20` +
      (programId ? `&programId=${programId}` : ``)+
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setDisciplineOptions({
      loading: false,
      list: res?.data?.disciplines?.map((discipline: any) => {
        return {
          value: discipline?.id,
          label: discipline?.name,
        };
      }),
    });
  }, [curriculumId]);
  useEffect(() => {
    getDisciplineOptions();
  }, [getDisciplineOptions]);
 
  const getProgramOptions = useCallback(async (programId?: string) => {
    setProgramOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/program?page=0&limit=20` +
        (programId ? `&name=${programId}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setProgramOptions({
      loading: false,
      list: res?.data?.programs?.map((program: any) => {
        return {
          value: program?.id,
          label: program?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getProgramOptions();
  }, [getProgramOptions]);
  
  const getMenuOptions = useCallback(async (menuId?: string) => {
    setMenuOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/menu/menus-list?page=0&limit=20` +
        (menuId ? `&name=${menuId}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMenuOptions({
      loading: false,
      list: res?.data?.menuList?.map((menu: any) => {
        return {
          value: menu?.id,
          label: menu?.name,
        };
      }),
    });
  }, []);




  useEffect(() => {


    getMenuOptions();
  }, [getMenuOptions]);
 
  const getShopsOptions = useCallback(async (key?: any) => {
    setShopsOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=JC_COMMERCE&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShopsOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getShopsOptions();
  }, [getShopsOptions]);
  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "curriculum") getCurriculumOptions(value);
          if (field === "discipline") getDisciplineOptions(value);
          if (field === "program") getProgramOptions(value);
          if (field === "menu") getMenuOptions(value);
          if (field === "shop") getShopsOptions(value);
          if (field === "teacher") getTeacherOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };
    return debounce(loadOptions, 800);
  }, [
    getCurriculumOptions,
    getDisciplineOptions,
    getProgramOptions,
    getMenuOptions,
    getShopsOptions,
    getTeacherOptions,
    type,
  ]);
  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);
  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      description: convertedText || data.description,
      courseDurationInDay: data.courseDurationInDay,
      curriculumId: curriculumId || data.curriculumId,
      disciplineIdList: disciplineId,
      durationInMinutes: data.durationInMinutes,
      startFrom: startingDate || data.startFrom,
      endAt: endingDate || data.endAt,
      isExclusiveLiveCourse: data.isExclusiveLiveCourse,
      isPopular: data.isPopular,
      numberOfCheatSheet: data.numberOfCheatSheet,
      numberOfLectures: data.numberOfLectures,
      numberOfNotes: data.numberOfNotes,
      numberOfQuiz: data.numberOfQuiz,
      numberOfVideos: data.numberOfVideos,
      programId: programId || data.programId,
      menuId: menuId || data.menuId,
      shopId: shopId || data.shopId,
      teacherIdList:teacherId,
      price: {
        discountedPrice: Math.ceil(data.discountedPrice) * 1,
        mrp: Math.ceil(data.mrp) * 1,
        tp: Math.ceil(data.tp) * 1,
      },
      uploadDetails: {
        bannerImageUrl: appsImgUrl ? appsImgUrl : data?.uploadDetails?.bannerImage || "NULL",
        imageUrl: webImgUrl ? webImgUrl : data?.uploadDetails?.imageUrl || "NULL",
        title: data.name,
      },
    };
    if (courseData.data) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/course`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: courseData?.data?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification("Course Updated Successfully", "success");
            form.resetFields();
            navigate("/course");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/course`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          status: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification("course Create Successfully", "success");
            form.resetFields();
            navigate("/course");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    fetchCourseDetails((route as any)?.courseId);
  }, [fetchCourseDetails]);
  useEffect(() => {
    if (courseData?.data) {
      form.resetFields();
      setAppsImgUrl(courseData?.data?.uploadDetails?.imageUrl);
      setWebImgUrl(courseData?.data?.uploadDetails?.bannerImageUrl);
      setStartingDate(courseData?.data?.startAt);
      setEndingDate(courseData?.data?.endAt);
      setCourseType(courseData?.data?.ourseType);
      setDiscountType(courseData?.data?.minimumDiscountType);
      setIsFlashSale(courseData?.data?.isFlashSale);
      setIsPayFirst(courseData?.data?.groupBuy?.isPayFirst);
      setTeacherId([courseData?.data?.teachersAssigned?.id]);
    }
  }, [courseData]);
  const resetData = () => {
    form?.resetFields();
    setMenuId(undefined);
    setAppsImgUrl(undefined);
    setWebImgUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };
  return (
    <>
      <BreadCrumb 
        title={courseData?.data ? "Edit Course" : "Add Course"}
        extra={
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
            loading={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
          />
        }
      />
      <div className="content-body bg-white rounded-2xl p-4 shadow">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          hideRequiredMark
          initialValues={{
            ...courseData?.data,
            startDate: dayjs(courseData?.data?.startAt),
            endDate: dayjs(courseData?.data?.endAt),
            lastPaymentTimeInMinute:
              courseData?.data?.groupBuy?.lastPaymentTimeInMinute / 60,
            noOfParticipatorEachGroup:
              courseData?.data?.groupBuy?.noOfParticipatorEachGroup,
            courseType: courseType,
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <div className="">
              <Form.Item
                hasFeedback
                label="Course Name"
                rules={[
                  {
                    required: true,
                    message: "Course Name is Required!",
                  },
                ]}
                name="name"
              >
                <Input id="name" type="text" placeholder="Enter Course Name" />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Teacher List"
                rules={[
                  {
                    required: true,
                    message: "Teacher is required!",
                  },
                ]}
                name="teacherIdList"
              >
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select Teacher"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "teacher");
                  }}
                  onChange={(val) => setTeacherId(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={teacherOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="col-span-2">
              <Form.Item hasFeedback label="Description" name="description">
                <JoditEditor
                  ref={editor}
                  value={convertedText}
                  config={jodiConfig as any}
                  onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
                />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Menu"
                rules={[
                  {
                    required: false,
                    message: "Menu is required!",
                  },
                ]}
                name="menuId"
              >
                <Select
                  showSearch
                  placeholder="Select Menu"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "menu");
                  }}
                  onChange={(val) => setMenuId(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={menuOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Program"
                rules={[
                  {
                    required: false,
                    message: "Program is required!",
                  },
                ]}
                name="programId"
              >
                <Select
                  showSearch
                  placeholder="Select Program"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "program");
                  }}
                  onChange={(val) => setProgramId(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={programOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Curriculum"
                rules={[
                  {
                    required: false,
                    message: "Curriculum is required!",
                  },
                ]}
                name="curriculumId"
              >
                <Select
                  showSearch
                  placeholder="Select Curriculum"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "curriculum");
                  }}
                  onChange={(val) => setCurriculumId(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={curriculumOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Discipline"
                rules={[
                  {
                    required: false,
                    message: "Discipline is required!",
                  },
                ]}
                name="disciplineId"
              >
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select Discipline"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "discipline");
                  }}
                  onChange={(val) => setDisciplineId(val)}
                  filterOption={() => {
                    return true;
                  }} 
                  options={disciplineOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            
            <div className="">
              <Form.Item
                hasFeedback
                label="Organization"
                rules={[
                  {
                    required: false,
                    message: "Organization is required!",
                  },
                ]}
                name="shopId"
              >
                <Select
                  showSearch
                  placeholder="Select Organization"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "shop");
                  }}
                  onChange={(val) => setShopId(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={shopsOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Starting Date"
                rules={[
                  {
                    required: true,
                    message: "Starting Date is Required!",
                  },
                ]}
                name="startDate"
              >
                <DatePicker
                  showTime
                  style={{ minWidth: "100%" }}
                  showNow={false}
                  placeholder="Starting Date"
                  onChange={(val: DatePickerProps["value"]) =>
                    setStartingDate(val)
                  }
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Ending Date"
                rules={[
                  {
                    required: true,
                    message: "Ending Date is Required!",
                  },
                ]}
                name="endDate"
              >
                <DatePicker
                  showTime
                  id="endDate"
                  placeholder="Enter Ending Date"
                  showNow={false}
                  style={{ minWidth: "100%" }}
                  onChange={(val: DatePickerProps["value"]) =>
                    setEndingDate(val)
                  }
                  disabled={!startingDate}
                  disabledDate={(currentDate) =>
                    currentDate.isBefore(dayjs(startingDate))
                  }
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Course Duration In Day"
                name="courseDurationInDay"
              >
                <Input
                  id="courseDurationInDay"
                  type="number"
                  placeholder="Enter Course Duration In Day"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Duration In Minutes"
                name="durationInMinutes"
              >
                <Input
                  id="durationInMinutes"
                  type="number"
                  placeholder="Enter Duration In Minutes"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Number Of Cheat Sheet"
                name="numberOfCheatSheet"
              >
                <Input
                  id="numberOfCheatSheet"
                  type="number"
                  placeholder="Enter Number Of Cheat Sheet"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Number Of Lectures"
                name="numberOfLectures"
              >
                <Input
                  id="numberOfLectures"
                  type="number"
                  placeholder="Enter Number Of Lectures"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Number Of Notes"
                name="numberOfNotes"
              >
                <Input
                  id="numberOfNotes"
                  type="text"
                  placeholder="Enter Number Of Notes"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item hasFeedback label="Number Of Quiz" name="numberOfQuiz">
                <Input
                  id="numberOfQuiz"
                  type="text"
                  placeholder="Enter Number Of Quiz"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Number Of Videos"
                name="numberOfVideos"
              >
                <Input
                  id="numberOfVideos"
                  type="text"
                  placeholder="Enter Number Of Videos"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Is Exclusive Live Course?"
                name="isExclusiveLiveCourse"
                className="mr-5"
              >
                <Switch
                  checkedChildren={`true`}
                  unCheckedChildren={`false`}
                  defaultChecked={
                    (courseData as any)?.data?.isExclusiveLiveCourse
                  }
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Is Popular?"
                name="isPopular"
                className="mr-5"
              >
                <Switch
                  checkedChildren={`true`}
                  unCheckedChildren={`false`}
                  defaultChecked={(courseData as any)?.data?.isPopular}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Discounted Price"
                name="discountedPrice"
                initialValue={courseData?.data?.price?.discountedPrice}
              >
                <Input
                  id="discountedPrice"
                  type="number"
                  placeholder="Enter Discounted Price"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item hasFeedback label="MRP Price" name="mrp" initialValue={courseData?.data?.price?.mrp}>
                <Input id="mrp" type="number" placeholder="Enter MRP" />
              </Form.Item>
            </div>
            <div>
              <Form.Item hasFeedback label="TP Price" name="tp" initialValue={courseData?.data?.price?.tp}>
                <Input
                  id="numberOfQuiz"
                  type="text"
                  placeholder="Enter TP Price"
                />
              </Form.Item>
            </div>
          </div>
          <Row gutter={20} className="mb-6">
            <Col span={12}>
              <Divider orientation="left">
                Banner Image
                <Tooltip
                  placement="bottom"
                  title={"Banner Image should 500px/180px (Ratio 2.8)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                imgUrl={appsImgUrl || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => setStartUpload(val)}
                setGeneratedImgUrl={(url: any) => setAppsImgUrl(url)}
                // crop={2.8}
              />
            </Col>
            <Col span={12}>
              <Divider orientation="left">
                Web Image
                <Tooltip
                  placement="bottom"
                  title={"Web image should 1200px/750px (Ratio 1.6)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                // crop={1.6}
                imgUrl={webImgUrl || ""}
                startUpload={startUpload2}
                setStartUpload={(val: any) => setStartUpload2(val)}
                setGeneratedImgUrl={(url: any) => setWebImgUrl(url)}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
export default AddCourse;