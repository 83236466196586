import { Button, DatePicker, Divider, Empty, Form, Select } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import { getPage, getParamValue } from "../../utils";
import { debounce } from "lodash";
import Loader from "../common/Loader";
import LoaderFull from "../common/LoaderFull";
import Pagination from "../common/Pagination/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const CompanyTopUpHistoryReport = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const router = useLocation();
  const [showSearch, setShowSearch] = useState(true);
  const [loading, setLoading] = useState<any>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [corporateOptions, setCorporateOptions] = useState<any>({
    loading: false,
    list: [],
  });
  const [key, setKey] = useState(getParamValue(router.search, "key"));

  const [downloadData, setDownloadData] = useState<any>({
    loading: false,
    data: null,
  });

  const getCorporateOptions = useCallback(async (key?: string) => {
    setCorporateOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    const res = await axios.get(
      `${encodedUri}/company/all?` +
        (page ? `&page=${page}` : ``) +
        (limit ? `&limit=${limit}` : ``) +
        +(key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCorporateOptions({
      loading: false,
      list: res?.data?.companies?.map((company: any) => ({
        label: company.name,
        value: company.ownerId,
      })),
    });
  }, []);

  const getCompanyTopUpHistoryData = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setDownloadData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(
        `${encodedUri}/admin/topup/company-history?` +
          (key ? `companyId=${key}` : ``) +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDownloadData({ loading: false, data: res.data });
        setLoading(false);
      })
      .catch((err) => {
        setDownloadData({ loading: false, data: null });
        console.error("Promo: Error", err);
      });
  }, [range]);

  useEffect(() => {
    getCompanyTopUpHistoryData();
  }, [range, getCompanyTopUpHistoryData]);

  const simpleFileDownload = () => {
    window.location.href = downloadData?.data?.url;
  };

  const reseAllFieldData = () => {
    setKey("");
    form.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "corporate") getCorporateOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCorporateOptions]);

  useEffect(() => {
    if (showSearch) {
      getCorporateOptions("");
    }
  }, [showSearch]);
  const fetchRef = useRef(0);
  return (
    <React.Fragment>
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form
            layout="inline"
            form={form}
            className={styles.formInline}
          ></Form>
          <Form
            name="control-hooks"
            className={styles.formInline}
            onFinish={getCompanyTopUpHistoryData}
            form={form} // like ref
            layout="inline"
            autoComplete="off"
          >
            <Form.Item name="search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Name"
                optionFilterProp="children"
                onChange={(val) => setKey(val)}
                onSearch={(e) => handleSearch(e, "corporate")}
                filterOption={() => {
                  return true;
                }}
                options={corporateOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
            <Button
              disabled={downloadData?.loading && loading}
              loading={downloadData?.loading && loading}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>

            {downloadData?.data?.url && (
              <>
                {" "}
                <Divider />
                <Button
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  icon={<DownloadOutlined />}
                  size="large"
                  onClick={simpleFileDownload}
                >
                  Download
                </Button>{" "}
                <Button
                  danger
                  size="large"
                  type="dashed"
                  shape="round"
                  onClick={reseAllFieldData}
                  className="mx-2"
                >
                  Reset
                </Button>
              </>
            )}
          </Form>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {downloadData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">SL</th>
                      <th scope="col">Date</th>
                      <th scope="col">Number Of Request</th>
                      <th scope="col">Total Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {downloadData?.data?.companyTopUps?.length ? (
                      downloadData?.data?.companyTopUps?.map(
                        (companyTopUp: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                            onClick={() =>
                              navigate(`/reports/${companyTopUp?.id}/details`)
                            }
                          >
                            <td>{index + 1}</td>
                            <td>
                              {" "}
                              <span className="name">
                                {moment(companyTopUp?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>{companyTopUp?.numberOfRequest}</td>
                            <td>
                              &#2547;{parseInt(companyTopUp?.totalAmount)}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...downloadData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </React.Fragment>
  );
};

export default CompanyTopUpHistoryReport;
