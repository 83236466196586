import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Empty, Popconfirm, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleZone from "./ZoneDetails";
import AddZone from "./ShippingZone";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";

const ZoneList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [isForLanding, setIsForLanding] = useState<boolean>(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedZone, setSelectedZone] = useState(undefined);
  const [selectedZoneForEdit, setSelectedZoneForEdit] = useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [zoneData, setZoneData] = useState<any>({
    loading: false,
    data: null,
  });

  const getZones = useCallback(async () => {
    setZoneData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/delivery-zone/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setZoneData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setZoneData({ loading: false, data: [] });
        console.error("Zone: Error", err);
      });
  }, []);

  //
  const onDelete = async (id: any, val: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: !val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getZones();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const onZoneRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Zone Remove Successfully", "success");
            getZones();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    getZones();
  }, [getZones]);

  const onClose = () => {
    getZones();
    setVisible(undefined);
    setSelectedZone(undefined);
    setSelectedZoneForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Zone List"
        subTitle={`${
          zoneData?.data?.totalElements || 0
        } Zone(s)`}
        extra={[
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {zoneData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Zone</th>
                      <th scope="col">Country</th>
                      <th scope="col">State</th>
                      <th scope="col">City</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {zoneData?.data?.deliveryZones?.length ? (
                      zoneData?.data?.deliveryZones?.map(
                        (zone: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedZone(zone)}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {zone?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {zone?.countryName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {zone?.stateName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {zone?.cityName}
                              </span>
                            </td>
                            
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(zone?.updatedAt, "minutes") >=
                                  60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(zone?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(zone?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {zone?.updatedBy?.name ||
                                  zone?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedZoneForEdit(zone)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={zone?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onZoneRemove(zone?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(zone?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...zoneData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Zone"
            : selectedZoneForEdit
            ? "Edit Zone"
            : "Zone Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedZone || selectedZoneForEdit}
        width={450}
      >
        {visible || selectedZoneForEdit ? (
          <AddZone
            onCloseMethod={onClose}
            visibleData={selectedZoneForEdit}
          />
        ) : (
          <SingleZone ZoneDetails={selectedZone} />
        )}
      </Drawer>
    </>
  );
};

export default ZoneList;
