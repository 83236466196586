import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { ReactElement } from "react";

const BulkUpload = (props: any): ReactElement => {
  return (
    <div className="search-box-shadow add-search-box-shadow">
      <Button
        {...props}
        type="primary"
        danger
        shape="round"
        className="bg-white flex items-center"
      >
        <DownloadOutlined /> Bulk Upload
      </Button>
    </div>
  );
};
BulkUpload.propTypes = {
  onClick: PropTypes.func,
};

export default BulkUpload;
