import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
  } from "@ant-design/icons";
  import axios from "axios";
  import { ReactElement, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
  import { AutoComplete, Avatar, Button, Drawer, Empty, Form, Input, Popconfirm, Select, Spin, Switch } from "antd";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import { responseNotification } from "../../utils/notify";
  import { getPage, getParamValue } from "../../utils";
  import { useLocation, useNavigate } from "react-router";
  import styles from "../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  import SingleBanner from "./Details"; 
  import AddBanner from "./AddSubUrb";
  import moment from "moment";
  import React from "react";
  import { AddBtn, Loader, Pagination } from "../common";
import BulkUpload from "../common/BulkUpload";
import { debounce } from "lodash";
  
  const SubUrbList = (): ReactElement => {
    const { type, token } = useSelector((state) => (state as any)?.authReducer);
    const loc = useLocation();
    const [form] = Form.useForm();
    const page = getParamValue(loc.search, "page");
    const [limit, setLimit] = useState(16);
    const [status, setStatus] = useState("");
    const [key, setKey] = useState("");
    const [countryId, setCountryId] = useState<any>();
    const [stateId, setStateId] = useState<any>();
    const [cityId, setCityId] = useState<any>();
    const [areaId, setAreaId] = useState<any>();
    const [showSearch, setShowSearch] = useState(true);
    const [isForLanding, setIsForLanding] = useState<boolean>(false);
    const [visible, setVisible] = useState<any>(undefined);
    const [selectedSubUrb, setSelectedSubUrb] = useState(undefined);
    const [selectedSubUrbForEdit, setSelectedSubUrbForEdit] = useState(undefined);
    const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const navigate = useNavigate();
    const showPopconfirm = (id: any) => {
      setVisiblePopconfirm(id);
    };
    const [subUrbData, setSubUrbData] = useState<any>({
      loading: false,
      data: null,
    });
    const [urbOptions, setUrbOptions] = useState({
      loading: false,
      list: [],
    });
    const [countryOptions, setCountryOptions] = useState({
      loading: false,
      list: [],
    });
    const [stateOptions, setStateOptions] = useState({
      loading: false,
      list: [],
    });
    const [cityOptions, setCityOptions] = useState({
      loading: false,
      list: [],
    });
    const [areaOptions, setAreaOptions] = useState({
      loading: false,
      list: [],
    });
    const getSubUrbs = useCallback(async () => {
      setSubUrbData({ loading: true, data: null });
  
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUri}/suburb/all?limit=${limit}`+
          (key ? `&key=${key}` : ``) +
          (countryId ? `&countryId=${countryId}` : ``)+
          (stateId ? `&stateId=${stateId}` : ``)+
          (cityId ? `&cityId=${cityId}` : ``)+
          (areaId ? `&areaId=${areaId}` : ``)+
            (page
                ? `&page=${
                    page == subUrbData.data?.currentPageNumber ? 0 : page || 0
                  }`
                : ``)  ,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setSubUrbData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setSubUrbData({ loading: false, data: [] });
          console.error("SubUrbs: Error", err);
        });
    }, [key,countryId,stateId,cityId,areaId,limit,page]);
  
    //
    const getUrbOptions = useCallback(
      async (key: any) => {
        setUrbOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
        let url =
          `?page=0&limit=20` +
          (key ? `&key=${key}` : ``);
        url = encodeURI(url);
  
        return axios
          .get(`${encodedUri}/suburb/all${url}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setUrbOptions({
              loading: false,
              list: res.data?.suburbs?.map((urb: { name: any }) => ({
                label: urb.name,
                value: urb.name,
              })),
            });
          })
          .catch((err) => {
            setUrbOptions({ loading: false, list: [] });
            console.error("urb: Error", err);
          });
      },
      [key,page, limit]
    );
    const getCountryOptions = useCallback(
      async (key: any) => {
          setCountryOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
        let url =
          `?isOperationEnabled=true&page=0&limit=20` ;
        url = encodeURI(url);
  
        return axios
          .get(`${encodedUri}/admin/country-state/countries${url}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
              setCountryOptions({
              loading: false,
              list: res.data?.countries?.map((country:any) => ({
                label: country.name,
                value: country.id,
              })),
            });
          })
          .catch((err) => {
              setCountryOptions({ loading: false, list: [] });
            console.error("Products: Error", err);
          });
      },
      [key]
    );
    const getStateOptions = useCallback(
      async (key: any) => {
        setStateOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
        let url =
          `?page=0&limit=20` +
          (countryId ? `&countryId=${countryId}` : ``)+
          (key ? `&key=${key}` : ``);
        url = encodeURI(url);
  
        return axios
          .get(`${encodedUri}/admin/country-state/states${url}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setStateOptions({
              loading: false,
              list: res.data?.states?.map((state:any) => ({
                label: state.name,
                value: state.id,
              })),
            });
          })
          .catch((err) => {
            setStateOptions({ loading: false, list: [] });
            console.error("city: Error", err);
          });
      },
      [countryId,key]
    );
    const getCityOptions = useCallback(
      async (key: any) => {
        setCityOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
        let url =
          `?page=0&limit=20` +
          (stateId ? `&stateId=${stateId}` : ``)+
          (key ? `&key=${key}` : ``);
        url = encodeURI(url);
  
        return axios
          .get(`${encodedUri}/admin/country-state/cities${url}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setCityOptions({
              loading: false,
              list: res.data?.cities?.map((city:any) => ({
                label: city.name,
                value: city.id,
              })),
            });
          })
          .catch((err) => {
            setCityOptions({ loading: false, list: [] });
            console.error("city: Error", err);
          });
      },
      [stateId,key]
    );
    const getAreaOptions = useCallback(
      async (key: any) => {
        setAreaOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
        let url =
          `?page=0&limit=20` +
          (countryId ? `&countryId=${countryId}` : ``)+
          (stateId ? `&stateId=${stateId}` : ``)+
          (cityId ? `&cityId=${cityId}` : ``)+
          (key ? `&key=${key}` : ``);
        url = encodeURI(url);
  
        return axios
          .get(`${encodedUri}/area/all${url}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setAreaOptions({
              loading: false,
              list: res.data?.areas?.map((area:any) => ({
                label: area.name,
                value: area.id,
              })),
            });
          })
          .catch((err) => {
            setAreaOptions({ loading: false, list: [] });
            console.error("area: Error", err);
          });
      },
      [countryId,stateId,cityId,key]
    );
    const onStatusChange = async (id: any, val: any) => {
      if (id) {
        await fetch(`${process.env.REACT_APP_RIDER_API}/suburb`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            isActive: val,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.statusCode === 200) {
              responseNotification("Status Updated Successfully", "success");
              getSubUrbs();
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    }; 
    const onSubUrbRemove = async (id: any) => {
      if (id) {
        await fetch(`${process.env.REACT_APP_RIDER_API}/suburb`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            deleted: true,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.statusCode === 200) {
              responseNotification("Sub Urb Remove Successfully", "success");
              getSubUrbs();
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    };
    useEffect(() => {
      if (showSearch) {
        getUrbOptions("");
      }
    }, [showSearch]);
    useEffect(() => {
      getSubUrbs();
      getCountryOptions("");
      getStateOptions("");
      getCityOptions("");
      getAreaOptions("");
    }, [getSubUrbs,getCountryOptions,getStateOptions,getCityOptions,getAreaOptions]);
  

    const onClose = () => {
      getSubUrbs();
      setVisible(undefined);
      setSelectedSubUrb(undefined);
      setSelectedSubUrbForEdit(undefined);
    };
    const handleCancel = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setVisiblePopconfirm(undefined);
    };
    const fetchRef = useRef(0);
    const handleSearch = useMemo(() => {
      const loadOptions = (value: string, field: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
  
        if (fetchId !== fetchRef.current) {
          return;
        }
        if (value) {
          if (field === "urb") getUrbOptions(value);
          if (field === "country") getCountryOptions(value);
          if (field === "state") getStateOptions(value);
          if (field === "city") getCityOptions(value);
          if (field === "area") getAreaOptions(value);
        }
      };
  
      return debounce(loadOptions, 800);
    }, [getUrbOptions,getCountryOptions,getStateOptions,getCityOptions,getAreaOptions]);
  
    const reseAllFieldData = () => {
      form.resetFields();
      setKey("");
      setCountryId("");
      setStateId("");
      setCityId("");
      setAreaId("");
    };
    return (
      <>
        <BreadCrumb
          title="Sub-Urb List"
          subTitle={`${
            subUrbData?.data?.totalElements ||
            subUrbData?.data?.suburbs?.length ||
            0
          } Sub-Urb(s)`}
          extra={[
            <AddBtn onClick={() => setVisible(true)} key={2} />,
            <BulkUpload  onClick={() => {
              navigate(`/country/suburb-bulk-upload`);
            }} key={2} />,
          ]}
        />
                {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
          <Form.Item name="search" className="pb-4">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "suburb")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={urbOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  urbOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={urbOptions.loading}
                />
              </AutoComplete>
          </Form.Item>   
          <Form.Item name="country_search" className="pb-4">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Country"
                optionFilterProp="children"
                onChange={(val) => setCountryId(val)}
                onSearch={(e) => handleSearch(e, "country")}
                filterOption={() => {
                  return true;
                }}
                options={countryOptions?.list}
              ></Select>
            </Form.Item>              
         
          
          <Form.Item name="state_search" className="pb-4">
              <Select
                allowClear
                showSearch
                placeholder="Filter by State"
                optionFilterProp="children"
                onChange={(val) => setStateId(val)}
                onSearch={(e) => handleSearch(e, "state")}
                filterOption={() => {
                  return true;
                }}
                options={stateOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="city_search" className="pb-4">
              <Select
                allowClear
                showSearch
                placeholder="Filter by city"
                optionFilterProp="children"
                onChange={(val) => setCityId(val)}
                onSearch={(e) => handleSearch(e, "city")}
                filterOption={() => {
                  return true;
                }}
                options={cityOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="area_search" className="pb-4">
              <Select
                allowClear
                showSearch
                placeholder="Filter by area"
                optionFilterProp="children"
                onChange={(val) => setAreaId(val)}
                onSearch={(e) => handleSearch(e, "area")}
                filterOption={() => {
                  return true;
                }}
                options={areaOptions?.list}
              ></Select>
            </Form.Item>
            </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {subUrbData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Sub-Urb</th>
                        <th scope="col">Status</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {subUrbData?.data?.suburbs?.length ? (
                        subUrbData?.data?.suburbs?.map(
                          (suburb: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                              onClick={() => setSelectedSubUrb(suburb)}
                            > 
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {suburb?.name}
                                </span>
                              </td>
                              <td>
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={suburb?.isActive}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onStatusChange(suburb?.id, val);
                                  }}
                                />
                              </td>
                              <td>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(suburb?.updatedAt, "minutes") >=
                                    60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(suburb?.updatedAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(suburb?.updatedAt).format("lll")}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {suburb?.updatedBy?.name ||
                                    suburb?.updatedBy?.mobileNumber}
                                </span>
                              </td>
                              <td>
                                <div className="flex gap-2">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={() =>
                                      setSelectedSubUrbForEdit(suburb)
                                    }
                                  >
                                    <EditOutlined />
                                  </Button>
                                  <Popconfirm
                                    placement="left"
                                    title="Are you sure to remove?"
                                    visible={suburb?.id === visiblePopconfirm}
                                    onConfirm={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onSubUrbRemove(suburb?.id);
                                    }}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                      className: "bg-blue-400",
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      className="bg-white"
                                      type="dashed"
                                      danger
                                      shape="round"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showPopconfirm(suburb?.id);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </Button>
                                  </Popconfirm> 
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
  
          <Pagination
            {...subUrbData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
  
        <Drawer
          title={
            visible
              ? "Add Sub-Urb"
              : selectedSubUrbForEdit
              ? "Edit Sub-Urb"
              : "Sub-Urb Details"
          }
          placement="right"
          onClose={onClose}
          open={visible || selectedSubUrb || selectedSubUrbForEdit}
          width={450}
        >
          {visible || selectedSubUrbForEdit ? (
            <AddBanner
              onCloseMethod={onClose}
              visibleData={selectedSubUrbForEdit}
            />
          ) : (
            <SingleBanner SubUrbDetails={selectedSubUrb} />
          )}
        </Drawer>
      </>
    );
  };
  
  export default SubUrbList;
  