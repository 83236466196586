import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Input,
  Select,
  Spin,
  Switch,
} from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getPage, getParamValue, jcTypeArray } from "../../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import _, { debounce } from "lodash";
import { isAllowedService } from "../../../utils/services";
import { responseNotification } from "../../../utils/notify";
import { AddBtn, Loader, LoaderFull, Pagination } from "../../common";
import SingleVehivleType from "./Details";
import AddVehivleType from "./AddFuelGrade";

const FuelGradeList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [isActive, setIsActive] = useState<any>(undefined);
  const [vehicleFuelTypeId, setVehicleFuelTypeId] = useState<any>();
  const [key, setKey] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [visible, setVisible] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [selectedFuelGrade, setSelectedFuelGrade] = useState(undefined);
  const [selectedFuelGradeForEdit, setSelectedFuelGradeForEdit] =
    useState(undefined);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [fuelGradeData, setFuelGradeData] = useState<any>({
    loading: false,
    data: null,
  });
  const [fuelTypeOptions, setFuelTypeOptions] = useState({
    loading: false,
    list: [],
  });
  const isParent = "false";
  const getFuelGradeAll = useCallback(async () => {
    setFuelGradeData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/fuel-grade/all?limit=${limit}` +
          (isActive ? `&isActive=${isActive}` : ``) +
          (vehicleFuelTypeId ? `&vehicleFuelTypeId=${vehicleFuelTypeId}` : ``) +
          (page
            ? `&page=${
                page == fuelGradeData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setFuelGradeData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setFuelGradeData({ loading: false, data: [] });
        console.error("Page: Error", err);
      });
  }, [isActive, vehicleFuelTypeId, limit, page]);

  const onStatusChange = async (id: any, val: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/admin/fuel-grade
`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            isActive: val,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getFuelGradeAll();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const getVehicleFuelTypeOptions = useCallback(
    async (key: any) => {
      setFuelTypeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/admin/fuel-type/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFuelTypeOptions({
            loading: false,
            list: res.data?.vehicleFuelTypes?.map(
              (vehicle: { name: any; id: any }) => ({
                label: vehicle.name,
                value: vehicle.id,
              })
            ),
          });
        })
        .catch((err) => {
          setFuelTypeOptions({ loading: false, list: [] });
          console.error("Vehicles: Error", err);
        });
    },
    [key, page, limit]
  );
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "fuelType") getVehicleFuelTypeOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getVehicleFuelTypeOptions]);

  useEffect(() => {
    if (showSearch) {
      getVehicleFuelTypeOptions("");
    }
  }, [showSearch]);
  useEffect(() => {
    getFuelGradeAll();
  }, [getFuelGradeAll]);
  const reseAllFieldData = () => {
    form?.resetFields();
    setType("");
    setVehicleFuelTypeId("");
    setIsActive("");
  };

  const onClose = () => {
    getFuelGradeAll();
    setVisible(undefined);
    setSelectedFuelGrade(undefined);
    setSelectedFuelGradeForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Fuel Grade List"
        subTitle={`${
          fuelGradeData?.data?.totalElements ||
          fuelGradeData?.data?.vehicleTypes?.length ||
          0
        } Type(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form}>
            <Form.Item name="search">
              <Select
                allowClear
                showSearch
                placeholder={`Filter by vehicle type`}
                optionFilterProp="children"
                defaultValue={vehicleFuelTypeId}
                onChange={(val) => setVehicleFuelTypeId(val)}
                onSearch={(e) => handleSearch(e, "fuelType")}
                filterOption={() => {
                  return true;
                }}
                options={fuelTypeOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className={`content-body`}>
              {confirmLoading && <LoaderFull />}
              {fuelGradeData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Fuel Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {fuelGradeData?.data?.vehicleFuelGrades?.length ? (
                      fuelGradeData?.data?.vehicleFuelGrades?.map(
                        (vehicle: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                          >
                            <td>
                              <span className="font-medium text-gray-500">
                                {vehicle?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {vehicle?.vehicleFuelType?.name}
                              </span>
                            </td>

                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={vehicle?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(vehicle?.id, val);
                                }}
                              />
                            </td>

                            <td>
                              <div className="flex flex-grow">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedFuelGradeForEdit(vehicle)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title={
          visible
            ? "Add Fuel Grade"
            : selectedFuelGradeForEdit
            ? "Edit Fuel Grade"
            : "Fuel Grade Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedFuelGrade || selectedFuelGradeForEdit}
        width={450}
      >
        {visible || selectedFuelGradeForEdit ? (
          <AddVehivleType
            onCloseMethod={onClose}
            visibleData={selectedFuelGradeForEdit}
          />
        ) : (
          <SingleVehivleType VehivleTypeDetails={selectedFuelGrade} />
        )}
      </Drawer>
    </>
  );
};

export default FuelGradeList;
