import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Input,
  Popconfirm,
  Select,
  Spin,
  Switch,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleState from "./Details";
import AddState from "./AddState";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import { debounce } from "lodash";
import BulkUpload from "../common/BulkUpload";
const StateList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const navigate = useNavigate();
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const [countryId, setCountryId] = useState<any>("65bccb6ab0a4b0fd8029ed12");
  const [showSearch, setShowSearch] = useState(true);
  const [isForLanding, setIsForLanding] = useState<boolean>(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedState, setSelectedState] = useState(undefined);
  const [selectedStateForEdit, setSelectedStateForEdit] = useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [stateData, setStateData] = useState<any>({
    loading: false,
    data: null,
  });
  const [countryOptions, setCountryOptions] = useState({
    loading: false,
    list: [],
  });

  const [stateOptions, setStateOptions] = useState({
    loading: false,
    list: [],
  });
  const getStates = useCallback(async () => {
    setStateData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/country-state/states?limit=${limit}` +
          (countryId ? `&countryId=${countryId}` : ``)+
          (key ? `&key=${key}` : ``)+
          (page
            ? `&page=${
                page == stateData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setStateData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setStateData({ loading: false, data: [] });
        console.error("States: Error", err);
      });
  }, [limit,countryId,key,page]);

  const getStateOptions = useCallback(
    async (key: any) => {
      setStateOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?page=0&limit=20` +
        (countryId ? `&countryId=${countryId}` : ``)+
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);
      return axios
        .get(`${encodedUri}/admin/country-state/states?${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStateOptions({
            loading: false,
            list: res.data?.states?.map((state: { name: any }) => ({
              label: state.name,
              value: state.name,
            })),
          });
        })
        .catch((err) => {
          setStateOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [countryId,key,page, limit]
  );

  const getCountryOptions = useCallback(
    async (key: any) => {
      setCountryOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url = `?isOperationEnabled=true&page=0&limit=20` + (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/countries${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCountryOptions({
            loading: false,
            list: res.data?.countries?.map((country: any) => ({
              label: country.name,
              value: country.id,
            })),
          });
        })
        .catch((err) => {
          setCountryOptions({ loading: false, list: [] });
          console.error("country: Error", err);
        });
    },
    [key]
  );

  const onStateRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/country-state/update-state`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("State Remove Successfully", "success");
            getStates();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
 
  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/country-state/update-state`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getStates();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };


  useEffect(() => {
    if (showSearch) {
      getCountryOptions("");
      getStateOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    getStates();
  }, [getStates]);

  const onClose = () => {
    getStates();
    setVisible(undefined);
    setSelectedState(undefined);
    setSelectedStateForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "country") getCountryOptions(value);
        if (field === "state") getStateOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCountryOptions,getStateOptions]);

  const reseAllFieldData = () => {
    setCountryId("");
    setKey("");
    form.resetFields();
  };
  return (
    <>
      <BreadCrumb
        title="State List"
        subTitle={`${
          stateData?.data?.totalElements || stateData?.data?.states?.length || 0
        } State(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />,
          <BulkUpload  onClick={() => {
            navigate(`/country/state-bulk-upload`);
          }} key={2} />,
        ]}
      />
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="country_search" className="pb-4">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Country"
                optionFilterProp="children"
                onChange={(val) => setCountryId(val)}
                onSearch={(e) => handleSearch(e, "country")}
                filterOption={() => {
                  return true;
                }}
                options={countryOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="search state name" className="pb-4">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "state")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={stateOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  stateOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={stateOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {stateData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">State</th>
                      <th scope="col">State Code</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {stateData?.data?.states?.length ? (
                      stateData?.data?.states?.map((state: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() => setSelectedState(state)}
                        >
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {state?.name}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {state?.state_code}
                            </span>
                          </td>
                          <td>
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={state?.isActive}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onStatusChange(state?.id, val);
                                  }}
                                  loading={
                                    confirmLoading &&
                                    state?.id === confirmLoading
                                  }
                                />
                              </td>
                          <td>
                            <small
                              className={`font-capitalize ${
                                moment().diff(state?.updatedAt, "minutes") >= 60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(state?.updatedAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(state?.updatedAt).format("lll")}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {state?.updatedBy?.name ||
                                state?.updatedBy?.mobileNumber}
                            </span>
                          </td>
                          <td>
                            <div className="flex gap-2">
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={() => setSelectedStateForEdit(state)}
                              >
                                <EditOutlined />
                              </Button>
                              <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={state?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onStateRemove(state?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(state?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...stateData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add State"
            : selectedStateForEdit
            ? "Edit State"
            : "State Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedState || selectedStateForEdit}
        width={450}
      >
        {visible || selectedStateForEdit ? (
          <AddState
            onCloseMethod={onClose}
            visibleData={selectedStateForEdit}
          />
        ) : (
          <SingleState StateDetails={selectedState} />
        )}
      </Drawer>
    </>
  );
};

export default StateList;
