import { Button, Form, Input, Upload } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { responseNotification } from "../../utils/notify";
// @ts-ignore
import builkcsv from "../../demo-files/test.csv";
import * as d3 from "d3";
import { SubmitResetBtn } from "../common";
import BreadCrumb from "../Layouts/Breadcrumb";
import { useSelector } from "react-redux";

type CSVData = {
  mobileNumbers: string[];
};

const SMSNotification = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [loading, setLoading] = useState(false);
  const [fetchedCSVData, setFetchedCSVdata] = useState<CSVData | any>();

  const [form] = Form.useForm();

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      message: data.message,
      mobileNumbers: fetchedCSVData?.mobileNumbers || [],
    };

    await fetch(
      `${process.env.REACT_APP_NOTIFICATION_API}/sms/bulk-with-mask`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("SMS Sent Successfully", "success");
          resetData();
          setFetchedCSVdata(null);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const resetData = () => {
    // form?.resetFields();
    setFetchedCSVdata(null);
  };

  const simpleFileDownload = () => {
    window.location.href = `${builkcsv}`;
  };

  const handleUpload = async (data: any) => {
    const filteredData: any = [];
    data?.forEach((row: any) => {
      const mob = row.mobileNumber?.toString();
      if (mob) {
        filteredData.push(
          mob?.length === 10
            ? `+880${mob}`
            : mob?.length === 11
            ? `+88${mob}`
            : mob?.length === 13
            ? `+${mob}`
            : mob
        );
      }
    });
    setFetchedCSVdata({
      mobileNumbers: filteredData,
    });
  };

  const readUploadFile = (val: any) => {
    const mainFile = val?.fileList[0]?.originFileObj;

    if (mainFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data: any = e.target.result;
        d3.csv(data).then((res) => {
          handleUpload(res);
        });
      };
      reader.readAsDataURL(mainFile);
    }
  };

  return (
    <React.Fragment>
      <BreadCrumb
        title="Send SMS"
        extra={
          <Button onClick={simpleFileDownload} type="dashed" shape="round">
            <DownloadOutlined />
            Sample File
          </Button>
        }
      />
      <div className="grid p-8">
        <div className="shadow-md rounded-lg bg-white p-4 mx-auto w-96 max-w-full">
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <Form.Item
              hasFeedback
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Message is required",
                },
              ]}
            >
              <Input.TextArea
                rows={8}
                id="message"
                placeholder="Notification Message"
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              label=""
              name="upload"
              rules={[
                {
                  required: true,
                  message: "File is required",
                },
              ]}
            >
              <Upload onChange={readUploadFile}>
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  danger
                  htmlType="button"
                  icon={<UploadOutlined />}
                  block
                >
                  Upload Contacts (.csv)
                </Button>
              </Upload>
            </Form.Item>
            {/* <CSVReader
                    cssClass="react-csv-input"
                    label="Upload CSV File"
                    onFileLoaded={handleUpload}
                    parserOptions={papaparseOptions}
                  />{" "} */}

            {fetchedCSVData?.mobileNumbers?.length >= 0 && (
              <div className="mb-2 txt-red accent-txt">
                Uploaded {fetchedCSVData?.mobileNumbers?.length} contacts
              </div>
            )}

            <div className="mt-12">
              <SubmitResetBtn />
            </div>
          </Form>
        </div>
      </div>{" "}
    </React.Fragment>
  );
};

export default SMSNotification;
