import { Avatar, Layout, Rate } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { getImgUrl } from "../../../utils";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import noImage from "../../../images/noImage.png";
const { Content } = Layout;

const ShippingLayout = ({
  children,
  shopId,
  title,
  extra,
  subTitle,
}: {
  children: ReactElement;
  shopId: string;
  title: string;
  subTitle: string;
  extra: ReactElement;
}): ReactElement => {
  const navigate = useNavigate();
  const { shopInfo,shippingProfileInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <main>
          <div className="grid grid-cols-3 px-2 pt-4 xl:grid-cols-10 gap-4 xl:gap-4">
            <div className="col-span-full">
              <PageHeader 
                className="site-page-header px-0 py-0"
                onBack={() => navigate(-1)}
                title={
                  <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                    {title}
                  </h1>
                }
                extra={extra}
              />
              <nav className="flex mb-0" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2">
                  <li className="inline-flex items-center">
                    <Link
                      to="/shops"
                      className="inline-flex items-center text-gray-700 hover:text-gray-900"
                    >
                      Shop
                    </Link>
                  </li>
                  
                  <li>
                    <div className="flex items-center">
                      <svg
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span
                        className="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                        aria-current="page"
                      >
                        {subTitle}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="col-span-full xl:col-span-3">
              <div className="grid grid-cols-2 md:grid-cols-2 gap-4 xl:grid-cols-1 min-h-full">
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl">
                  <div className="sm:flex__ xl:block sm:space-x-4 xl:space-x-0">
                   
                    <div className="px-4 pb-2">
                      
                      <div>
                        <h2 className="text-xl font-bold">
                          {shippingProfileInfo?.name?.toUpperCase()}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                {shopInfo?.id && <Sidebar shopId={shopId} />}
              </div>
            </div>
            <div className="col-span-3 xl:col-span-7">
              <Layout>
                <Content className="main-content-layout">{children}</Content>
              </Layout>
            </div>
          </div>
        </main> 
      </Layout>
    </React.Fragment>
  );
};

ShippingLayout.propType = {
  children: PropTypes.element,
};

export default ShippingLayout;
