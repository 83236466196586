import React from "react";
import MainLayout from "../../../components/Layouts";
import CorporateTopupRequestList from "../../../components/Corporate/TopUp/TopUpRequest";

const CorporateTopUpRequestPage = () => {
  return (
    <MainLayout>
      <CorporateTopupRequestList />
    </MainLayout>
  );
};

export default CorporateTopUpRequestPage;
