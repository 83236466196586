import React from "react";
import MainLayout from "../../../components/Layouts";
import CustomShippingProfile from "../../../components/Shop/ShippingProfile/MyCustomShippingProfile";

const MyCustomShippingProfilePage = () => {
  return (
    <MainLayout>
      <CustomShippingProfile />
    </MainLayout>
  );
};

export default MyCustomShippingProfilePage;
