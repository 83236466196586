import React from "react";
import TimeSlotList from "../../components/Delivery/TimeSlot";
import MainLayout from "../../components/Layouts";

function TimeSlotPage() {
  return (
    <MainLayout>
      <TimeSlotList />
    </MainLayout>
  );
}

export default TimeSlotPage;
