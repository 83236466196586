import {
  ApartmentOutlined,
  CheckCircleFilled,
  CopyOutlined,
  DeleteFilled,
  IssuesCloseOutlined,
  QuestionCircleOutlined,
  SoundOutlined,
  UserOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  Drawer,
  Radio,
  Alert,
  Space,
} from "antd";
import axios from "axios";
import { TextLoop } from "react-text-loop-next";
import { responseNotification } from "../../../utils/notify";
import { getParamValue } from "../../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { SHOW_SEARCHBAR } from "../../../redux/search/searchType";
import React from "react";
import moment from "moment";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons";

const ProductExtra = ({ barcodes }: { barcodes?: [] }): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const route = useParams();
  const dispatch = useDispatch();
  const [submitForm, setSubmitForm] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const pageType: any = getParamValue(loc.search, "page-type");
  const [key, setKey] = useState("");
  const [isActive, setIsActive] = useState<any>(undefined);
  const [sortBy, setSortBy] = useState<any>(undefined);
  const [sortOrder, setSortOrder] = useState<any>(undefined);
  const [shopToShopVisible, setShopToShopVisible] = useState(false);

  const [open, setOpen] = useState(false);
  // --------------------------------
  const [activeClone, setActiveClone] = useState(true);
  const [changeCat, setChangeCat] = useState<boolean>(false);
  const [assignSupplier, setAssignSupplier] = useState(false);
  const [assignCampaign, setAssignCampaign] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [changeStatus, setChangeStatus] = useState<any>(undefined);

  // --------------------------------

  const [barCode, setBarCode] = useState("");


  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });


  const isDeleted = "false";
  // ************************
  const fetchRef = useRef(0);


  const onClose = () => {
    setSubmitForm(false);
    setOpen(false);
    setShopToShopVisible(false);
  };

  const onSubmitShopToShop = async (data: any) => {
    setLoading(true);
      const readyData = data && {
        name: data.name,
        shopId: route?.shopId,
        productIds: barcodes,
      };
        await fetch(
          `${process.env.REACT_APP_ORDER_API}/delivery-configuration-create-profile`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(readyData),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification(
                "Product assign Successfully in Selected Shop(s)",
                "success"
              );
              onClose();
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      
    
  };
  //new add


  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmitShopToShop}
        form={form} // like ref
        layout="vertical"
        autoComplete="off"
        initialValues={{
          shopId: !activeClone ? route?.shopId : undefined,
        }}
      >
        <Form.Item label="Product ID">
          <Input.TextArea value={barcodes} disabled rows={8} />
        </Form.Item>

        <div className="mt-4">
          <Form.Item
            hasFeedback
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
            name="name"
          >
            <Input
              id="name"
              type="text"
              placeholder="Type your Shipping profile name"
            />
          </Form.Item>
        </div>
          <Button
            type="primary"
            danger
            title="Clone Selected Items"
            htmlType="submit"
            size="large"
            disabled={
              (!changeCat &&
                !activeClone &&
                !assignSupplier &&
                !assignCampaign) ||
              loading
            }
            loading={(loading ? "loading" : undefined) as any}
          >
            Submit
          </Button>
     
      </Form>

 
    </>
  );
};

export default ProductExtra;
