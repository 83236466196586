import { Form, Input } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SubmitResetBtn } from "../components/common";
import MainLayout from "../components/Layouts";

const UrlCheckerPage = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    let timerId;
    if (loading) {
      timerId = setInterval(() => {
        setDuration((prev) => prev + 1);
      }, 1);
    }

    return function cleanup() {
      clearInterval(timerId);
    };
  }, [loading]);

  const onSubmitUrl = async (data) => {
    setDuration(0);
    setLoading(true);
    axios
      .get(data?.url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("URL: Error", err);
      });
  };

  const resetAllFields = () => {
    setDuration(0);
    form?.resetFields();
  };

  return (
    <MainLayout>
      <div className="max-w-md ma-auto bg-white p-6 mt-8 shadow-md rounded-xl">
        <div className="text-center mb-6 flex flex-col gap-2">
          <span>API Performance Test!</span>
          <div className="flex justify-center items-center gap-4">
            <div className="shadow-md rounded-md p-4 w-36 bg-slate-50">
              {
                duration >= 0 ? (
                  <span>
                    <span className="font-bold text-3xl">{duration * 10}</span>{" "}
                    ms
                  </span>
                ) : undefined
                // || setDuration(0)
              }
            </div>
            <div className="shadow-md rounded-md p-4 w-36 bg-slate-50">
              {duration >= 0 ? (
                <span>
                  <span className="font-bold text-3xl">{duration / 100}</span> s
                </span>
              ) : undefined}
            </div>
          </div>
        </div>
        <Form
          onFinish={onSubmitUrl}
          name="control-hooks"
          form={form} // like ref
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
        //   autoComplete="off"
        >
          <div className="flex flex-col items-center w-full">
            <Form.Item hasFeedback name="url" className="w-full" required>
              <Input placeholder="Enter Url" required />
            </Form.Item>
            <SubmitResetBtn onClickReset={resetAllFields} />
          </div>
        </Form>
      </div>
    </MainLayout>
  );
};

export default UrlCheckerPage;
