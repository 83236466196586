import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import MerchantUserGroupList from "../../components/MerchantAdministration/userGroup/index";
import React from "react";
const MerchantUserGroupPage = (): ReactElement => {
  return (
    <MainLayout>
      <MerchantUserGroupList />
    </MainLayout>
  );
};

export default MerchantUserGroupPage;
