import React from "react";

import {
  Text,
  Document,
  Page,
  StyleSheet,
  Image,
  View,
} from "@react-pdf/renderer";
import logo from "../../images/logo.png";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: 20,
  },
  section: {
    flexGrow: 1,
  },
  headerArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 15,
  },
  headerLeft: {
    display: "flex",
    flexDirection: "row",
    width: "45%",
  },
  logo: {
    flexDirection: "row",
    height: 40,
    width: 125,
  },
  headerRight: {
    width: "45%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  shopName: {
    fontSize: 10,
    marginBottom: 5,
    textAlign: "right",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  shopText: {
    marginBottom: 3,
    fontSize: 10,
    fontWeight: 300,
    textAlign: "right",
    maxWidth: 200,
  },
  titleArea: {
    backgroundColor: "#E5E5E5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    marginBottom: 10,
  },
  title: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1,
  },
  middleSection: {
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    // flexDirection: "row",
    //marginBottom: 30,
    width: "100%",
    height: "80%",
  },
  acountAreaPara: {
    // textAlign: "right",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "row",
    // width: 180,
    padding: "0px 5px",
  },
  //footer style

  footerArea: {
    textAlign: "left",
    width: "100%",
    display: "flex",
    alignSelf: "baseline",
    justifyContent: "flex-end",
    borderTop: "1px",
  },
  footerText: {
    // display: "flex",
    flexDirection: "row",
    fontSize: 10,
    fontWeight: 200,
    marginTop: 5,
  },
  textRed: {
    color: "#cd113b",
  },
  //new end
  container: {
    flex: 1,
    flexDirection: "row",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  image: {
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: "column",
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
    "@media orientation: landscape": {
      width: 200,
    },
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 25,
    paddingTop: 10,
    borderWidth: 3,
    borderColor: "gray",
    borderStyle: "dashed",
    "@media orientation: landscape": {
      marginTop: 10,
    },
  },
});

const MerchantAcquisitionPDF = (props: any) => {
  return (
    <Document
      author="Luke Skywalker"
      keywords="awesome, resume, start wars"
      subject="The resume of Luke Skywalker"
      title="Resume"
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerArea}>
            <View style={styles.headerLeft}>
              <Image src={logo} style={styles.logo} />
            </View>

            <View style={styles.headerRight}>
              <View>
                <Text style={styles.shopName}>jachai.com Ltd</Text>
              </View>
              <View>
                <Text style={styles.shopText}>
                  House# 26, Road# 7, Block# C, Niketon, Gulshan -1, Dhaka-1212.
                </Text>
              </View>
              <View>
                <Text style={styles.shopText}>support@jachai.com</Text>
              </View>
            </View>
          </View>
          <View style={styles.titleArea}>
            <Text style={styles.title}>MERCHANT ACQUISITION</Text>
          </View>
          <View style={styles.middleSection}>
            <View style={styles.acountAreaPara}>
              <Text>
                Start Date : {moment(props?.range?.from).format("lll")}{" "}
              </Text>
            </View>
            <View style={styles.acountAreaPara}>
              <Text>End Date : {moment(props?.range?.to).format("lll")}</Text>
            </View>
            <View style={styles.acountAreaPara}>
              <Text>Total Merchant Acquisition : {props.data}</Text>
            </View>
          </View>
          <View style={styles.footerArea}>
            <View>
              <Text style={styles.footerText}>
                <b>*Shipping:</b> Shipping charges will be calculated based on
                the number of items, item type, weight and volume.
              </Text>
              <Text style={styles.footerText}>
                <b>
                  Have a great day! Thank You for shopping on{" "}
                  <span style={styles.textRed}>Jachai.com</span>
                </b>
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default MerchantAcquisitionPDF;
