import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import ServiceGroupList from "../../components/MerchantAdministration/ServiceGroup/index";
import React from "react";
const ServiceGroupPage = (): ReactElement => {
  return (
    <MainLayout>
      <ServiceGroupList />
    </MainLayout>
  );
};

export default ServiceGroupPage;
