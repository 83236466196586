import React from "react";
import AreaList from "../../components/Area/index";
import MainLayout from "../../components/Layouts";

const AreaPage = () => {
  return (
    <MainLayout>
      <AreaList />
    </MainLayout>
  );
};

export default AreaPage;
