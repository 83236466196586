import { Alert, Button, Divider, Form, Input, Select, Switch } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import ImageUploader from "../../common/ImageUploader";
import SubmitResetBtn from "../../common/SubmitBtn";
import styles from "../../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";
const { TextArea } = Input;

const AddFuelGrade = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [fuelTypeId, setFuelTypeId] = useState<any>();
  const [fuelGradeData, setFuelGradeData] = useState<any>(undefined);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [cropImage, setCropImage] = useState(true);
  const [fuelTypeOptions, setFuelTypeOptions] = useState({
    loading: false,
    list: [],
  });
  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);
  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      name: data.name,
      fuelTypeId: fuelTypeId,
    };

    if (fuelGradeData) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/fuel-grade`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: fuelGradeData?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/fuel-grade`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const getFuelTypeOptions = useCallback(
    async (key: any) => {
      setFuelTypeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url = `?page=0&limit=20` + (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/fuel-type/all${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFuelTypeOptions({
            loading: false,
            list: res.data?.vehicleFuelTypes?.map((vehicle: any) => ({
              label: vehicle.name,
              value: vehicle.id,
            })),
          });
        })
        .catch((err) => {
          setFuelTypeOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [key]
  );

  useEffect(() => {
    if (visibleData) {
      setFuelGradeData(visibleData);
      setGeneratedImgUrl(visibleData?.vehicleLogo);
    }
  }, [visibleData]);

  useEffect(() => {
    if (fuelGradeData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.vehicleLogo);
    }
  }, [fuelGradeData, form, visibleData]);
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "fuelType") getFuelTypeOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getFuelTypeOptions]);

  useEffect(() => {
    getFuelTypeOptions("");
  }, [getFuelTypeOptions]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...fuelGradeData,
        }}
      >
        <Form.Item name="fuelType" label="Fuel Type">
          <Select
            allowClear
            showSearch
            placeholder={`Filter by Fuel Type`}
            optionFilterProp="children"
            onChange={(val) => setFuelTypeId(val)}
            onSearch={(e) => handleSearch(e, "fuelType")}
            filterOption={() => {
              return true;
              ß;
            }}
            options={fuelTypeOptions?.list}
          ></Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Fuel Grade Name"
          rules={[
            {
              required: true,
              message: "Fuel Grade Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Fuel Grade Name" />
        </Form.Item>

        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddFuelGrade;
