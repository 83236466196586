import { Alert, Button, Divider, Form, Input, Select, Switch } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import ImageUploader from "../../common/ImageUploader";
import SubmitResetBtn from "../../common/SubmitBtn";
import styles from "../../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";
const { TextArea } = Input;

const AddShoppingMall = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [vehicleTypeId, setVehicleTypeId] = useState<any>();
  const [veichileTypeData, setVeichileTypeData] = useState<any>(undefined);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [cropImage, setCropImage] = useState(true);
  const [vehicleOptions, setVehicleOptions] = useState({
    loading: false,
    list: [],
  });
  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);
  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      value: data.value,
      vehicleTypeId: vehicleTypeId,
    };
    if (veichileTypeData) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/admin/vehicle/update-vehicle-features`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: veichileTypeData?.id,
            deleted: false,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/admin/vehicle/added-vehicle-features`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification("Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const getVehicleTypeOptions = useCallback(
    async (key: any) => {
      setVehicleOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url = `?page=0&limit=20` + (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/vehicle-type/all${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setVehicleOptions({
            loading: false,
            list: res.data?.vehicleTypes?.map((vehicle: any) => ({
              label: vehicle.vehicleType,
              value: vehicle.id,
            })),
          });
        })
        .catch((err) => {
          setVehicleOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [key]
  );

  useEffect(() => {
    if (visibleData) {
      setVeichileTypeData(visibleData);
      setGeneratedImgUrl(visibleData?.vehicleLogo);
    }
  }, [visibleData]);

  useEffect(() => {
    if (veichileTypeData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.vehicleLogo);
    }
  }, [veichileTypeData, form, visibleData]);
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "vehicle") getVehicleTypeOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getVehicleTypeOptions]);

  useEffect(() => {
    getVehicleTypeOptions("");
  }, [getVehicleTypeOptions]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...veichileTypeData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Value"
          rules={[
            {
              required: true,
              message: "value is Required!",
            },
          ]}
          name="value"
        >
          <Input id="value" type="text" placeholder="Enter value" />
        </Form.Item>
        <Form.Item
          name="vehicleTypeId"
          label="Vehicle Type"
          rules={[
            {
              required: true,
              message: "value is Required!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder={`Filter by vehicle type`}
            optionFilterProp="children"
            onChange={(val) => setVehicleTypeId(val)}
            onSearch={(e) => handleSearch(e, "vehicle")}
            filterOption={() => {
              return true;
            }}
            options={vehicleOptions?.list}
          ></Select>
        </Form.Item>
        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddShoppingMall;
