import React from "react";
import MainLayout from "../../../components/Layouts";
import MembershipLevelList from "../../../components/Merchant/Membership/index";

const MembershipLevelPage = () => {
  return (
    <MainLayout>
      <MembershipLevelList />
    </MainLayout>
  );
};

export default MembershipLevelPage;
