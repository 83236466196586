import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Select,
  Skeleton,
  Switch,
} from "antd";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import _ from "lodash";
import moment from "moment";
import { isAllowedService } from "../../utils/services";
import React from "react";
import DirectoryTree from "antd/es/tree/DirectoryTree";
import { AddBtn, LoaderFull, Pagination } from "../common";
const { Option } = Select;

const CourseList = (): ReactElement => {
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const fetchRef = useRef(0);
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = 16;
  const [loading, setLoading] = useState(false);
  const [programId, setProgramId] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [showSearch, setShowSearch] = useState(true);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);
  const [isFlashSale, setIsFlashSale] = useState<any>();
  const [isActive, setIsActive] = useState<any>("true");
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const [curriculumId, setCurriculumId] = useState<any>();
  const [disciplineId, setDisciplineId] = useState<any>();
  const [teacherId, setTeacherId] = useState<any>();
  const [shopId, setShopId] = useState<any>("");
  const [courseData, setCourseData] = useState<any>({
    loading: false,
    data: null,
  });
  const [courseOptions, setCourseOptions] = useState({
    loading: false,
    list: [],
  });
  const [programOptions, setProgramOptions] = useState({
    loading: false,
    list: [],
  });
  const [curriculumOptions, setCurriculumOptions] = useState({
    loading: false,
    list: [],
  });
  const [disciplineOptions, setDisciplineOptions] = useState({
    loading: false,
    list: [],
  });
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [teacherOptions, setTeacherOptions] = useState({
    loading: false,
    list: [],
  });
  const getCourses = useCallback(async () => {
    setCourseData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_ELARNING_API}`;
    axios
      .get(
        `${encodedUrl}/admin/course?page=0&limit=20` +
          (key ? `&name=${key}` : ``) +
          (programId ? `&programId=${programId}` : ``) +
          (curriculumId ? `&curriculumId=${curriculumId}` : ``) +
          (disciplineId ? `&disciplineId=${disciplineId}` : ``) +
          (teacherId ? `&teacherId=${teacherId}` : ``) +
          (shopId ? `&shopId=${shopId}` : ``) +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCourseData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCourseData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [key, programId,curriculumId,disciplineId,teacherId,shopId,isActive, sortBy, sortOrder, page, limit]);

  const getCourseOptions = useCallback(async (key?: string) => {
    setCourseOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    const res = await axios.get(`${encodedUri}/admin/course?page=0&limit=20`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setCourseOptions({
      loading: false,
      list: res?.data?.courses?.map((course: any) => ({
        label: course.name,
        value: course.name,
      })),
    });
  }, []);
  //
  const getTeacherOptions = useCallback(async (key?: string) => {
    setTeacherOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    const res = await axios.get(`${encodedUri}/admin/teacher?page=0&limit=20`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setTeacherOptions({
      loading: false,
      list: res?.data?.teachers?.map((teacher: any) => ({
        label: teacher.fullName,
        value: teacher.id,
      })),
    });
  }, []);

  const getProgramsOptions = useCallback(async (key?: string) => {
    setProgramOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/program?page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setProgramOptions({
      loading: false,
      list: res?.data?.programs?.map((program: any) => ({
        label: program.name,
        value: program.id,
      })),
    });
  }, []);
  const getShopsOptions = useCallback(async (shopId?: any) => {
    setShopsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=JC_COMMERCE&page=0&limit=20` +
        (shopId ? `&key=${shopId}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShopsOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, []);
  const getDisciplinesOptions = useCallback(async (key?: string) => {
    setDisciplineOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/discipline?type=${type}&page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setDisciplineOptions({
      loading: false,
      list: res?.data?.disciplines?.map((discipline: any) => ({
        label: discipline.name,
        value: discipline.id,
      })),
    });
  }, []);

  const getCurriculumOptions = useCallback(async (key?: string) => {
    setCurriculumOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/curriculum?type=${type}&page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCurriculumOptions({
      loading: false,
      list: res?.data?.curriculums?.map((getCurriculum: any) => ({
        label: getCurriculum.name,
        value: getCurriculum.id,
      })),
    });
  }, []);
  const onStatusChange = async (id: number, val: boolean) => {
    setLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/course`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getCourses();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    getProgramsOptions();
  }, [getProgramsOptions]);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "course") getCourseOptions(value);
        if (field === "program") getProgramsOptions(value);
        if (field === "curriculum") getCurriculumOptions(value);
        if (field === "discipline") getDisciplinesOptions(value);
        if (field === "campaign") getTeacherOptions(value);
        if (field === "shop") getShopsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCourseOptions, getProgramsOptions, getCurriculumOptions,getDisciplinesOptions,getTeacherOptions,getShopsOptions]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  useEffect(() => {
    if (showSearch) {
      getCourseOptions();
      getCurriculumOptions("");
      getDisciplinesOptions("");
      getTeacherOptions("");
      getShopsOptions("");
    }
  }, [showSearch]);

  const onClose = () => {
    getCourses();
    setReArrangeDrawer(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const reseAllFieldData = () => {
    setIsFlashSale(false);
    setSortBy("");
    setShopId("");
    setCurriculumId("");
    setProgramId("");
    setDisciplineId("");
    setTeacherId("");
    setSortOrder("");
    setIsActive("");
    form.resetFields();
  };

  return (
    <>
      <BreadCrumb
        title="Courses List"
        subTitle={`${
          courseData?.data?.totalElements || courseData?.data?.courses?.length
        } Courses(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <Button
            shape="round"
            type="primary"
            className="bg-blue-600"
            onClick={() => setReArrangeDrawer(true)}
          >
            <SwapOutlined />
          </Button>,

          <AddBtn onClick={() => navigate("/course/create")} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form form={form} layout="inline"  className={styles.formInline}>
            <Form.Item name="course_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Course"
                optionFilterProp="children"
                onChange={(val) => setKey(val)}
                onSearch={(e) => handleSearch(e, "course")}
                filterOption={() => {
                  return true;
                }}
                options={courseOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="program_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by program"
                optionFilterProp="children"
                onChange={(val) => setProgramId(val)}
                onSearch={(e) => handleSearch(e, "program")}
                filterOption={() => {
                  return true;
                }}
                options={programOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="curriculum_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by curriculum"
                optionFilterProp="children"
                onChange={(val) => setCurriculumId(val)}
                onSearch={(e) => handleSearch(e, "curriculum")}
                filterOption={() => {
                  return true;
                }}
                options={curriculumOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="discipline_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by discipline"
                optionFilterProp="children"
                onChange={(val) => setDisciplineId(val)}
                onSearch={(e) => handleSearch(e, "discipline")}
                filterOption={() => {
                  return true;
                }}
                options={disciplineOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="teacher_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Teacher"
                optionFilterProp="children"
                onChange={(val) => setTeacherId(val)}
                onSearch={(e) => handleSearch(e, "course")}
                filterOption={() => {
                  return true;
                }}
                options={teacherOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item
            name="shopId"
          >
            <Select
              showSearch
              placeholder="Select Organization"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "shop");
              }}
              onChange={(val) => setShopId(val)}
              filterOption={() => {
                return true;
              }}
              options={shopsOptions?.list}
            ></Select>
          </Form.Item>
            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && loading && <LoaderFull />}
              {courseData?.loading ? (
                <LoaderFull />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">course</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {courseData?.data?.courses?.length ? (
                      courseData?.data?.courses?.map(
                        (course: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                            onClick={() =>
                              navigate(
                                `/promotion/campaign/${course?.id}/products`
                              )
                            }
                            draggable="true"
                          >
                            <td>
                              <div className="flex items-center">
                                <Avatar.Group
                                  maxCount={3}
                                  size="large"
                                  maxStyle={{
                                    color: "#f56a00",
                                    backgroundColor: "#fde3cf",
                                  }}
                                ></Avatar.Group>
                                <div className="flex flex-col ml-2">
                                  <span className="font-medium text-gray-500">
                                    {course?.name}
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td>{moment(course?.startFrom).format("lll")}</td>
                            <td>{moment(course?.endAt).format("lll")}</td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={course?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(course?.id, val);
                                }}
                                loading={loading && course?.id === loading}
                                title="Active/Inactive"
                              />
                            </td>
                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigate(`/course/${course?.id}/edit`);
                                }}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...courseData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default CourseList;
