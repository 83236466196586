import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import { Button, Drawer, Empty, Form, Modal, Pagination, Select, Tabs } from "antd";
import { PlusOutlined, SwapOutlined } from "@ant-design/icons";
import form from "antd/es/form";
import { authenticateToken } from "../../../utils/auth";
import AddZone from "./AddZone";
import { AddBtn, Loader } from "../../common";
import { getPage } from "../../../utils";

const MyShippingZoneList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const route = useParams();
  const shopId = (route as any)?.shopId;
  const navigate = useNavigate();
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedZone, setSelectedZone] = useState(undefined);
  const [selectedZoneForEdit, setSelectedZoneForEdit] = useState(undefined);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [zoneType, setZoneType] = useState<any>("DOMESTIC");
  const [zoneData, setZoneData] = useState<any>({
    loading: false,
    data: null,
  });
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const getZones = useCallback(async () => {
    setZoneData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/delivery-profile-configuration/all?shopId=${shopId}` +
          (zoneType ? `&zoneType=${zoneType}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setZoneData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setZoneData({ loading: false, data: [] });
        console.error("Zones: Error", err);
      });
  }, [zoneType]);

  useEffect(() => {
    getZones();
  }, [getZones]);

  const onClose = () => {
    getZones();
    setVisible(undefined);
    setSelectedZone(undefined);
    setSelectedZoneForEdit(undefined);
    setReArrangeDrawer(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  const reseAllFieldData = () => {
    form.resetFields();
    setZoneType("DOMESTIC");
  };
  return (  
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Profile Shipping Zone List"
        subTitle="Profile Shipping Zone  List"
        extra={[
            <AddBtn onClick={() => setVisible(true)} key={2} />,
          ]}
      >
        {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form}>
            <Form.Item
              name="deleted_search"
              initialValue={
                zoneType == "DOMESTIC"
                  ? "DOMESTIC"
                  : zoneType == "INTERNATIONAL"
                  ? "INTERNATIONAL"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setZoneType(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"DOMESTIC"} title="DOMESTIC">
                  DOMESTIC
                </Select.Option>
                <Select.Option value={"INTERNATIONAL"} title="INTERNATIONAL">
                  INTERNATIONAL
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {zoneData?.loading ? (
                <Loader />
              ) : (
                <>
                  {zoneData?.data?.deliveryProfileConfigurations?.length ? (
                    zoneData?.data?.deliveryProfileConfigurations?.map(
                      (zone: any, index: any) => (
                        <>
                          <h1 className="p-0 text-justify text-lg">
                            {zone?.deliveryZoneName}
                          </h1>
                          <AddBtn
                            onClick={() => {
                              navigate(
                                `/shops/${
                                  (route as any)?.shopId
                                }/shop-shippingprofile-add-rate/${zone?.id}`
                              );
                            }}
                          />
                          <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Your Shipping Rates" key="1">
                              <table className={styles.mainTable}>
                                <thead className="bg-white border-b">
                                  <tr>
                                    <th scope="col">Shipping Rate name</th>
                                    <th scope="col">Condition</th>
                                    <th scope="col">Transit time</th>
                                    <th scope="col">Shipping Price</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {zone?.ownShippingRates?.map(
                                    (
                                      shippingRate: any,
                                      shippingRateIndex: any
                                    ) => (
                                      <>
                                        <tr>
                                          <td>
                                            {shippingRate?.shippingTransitType.replace(
                                              "_",
                                              " "
                                            )}
                                          </td>
                                          <td>{shippingRate?.isConditions}</td>
                                          <td>{shippingRate?.rateId}</td>
                                          <td>{shippingRate?.shippingPrice}</td>
                                          <td>Action</td>
                                        </tr>
                                      </>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </Tabs.TabPane>

                            <Tabs.TabPane
                              tab="Carrier and app Shipping rates"
                              key="2"
                            >
                              
                                  
                                       <table className={styles.mainTable}>
                                <thead className="bg-white border-b">
                                  <tr>
                                    <th scope="col">Shipping Rate name</th>
                                    <th scope="col">Condition</th>
                                    <th scope="col">Transit time</th>
                                    <th scope="col">Shipping Price</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {zone?.carrierShippingRates?.map(
                                (currentShippingRate: any, currentShippingRateIndex: any) => (
                                      <>
                                        <tr>
                                          <td>
                                            {currentShippingRate?.shippingTransitType.replace(
                                              "_",
                                              " "
                                            )}
                                          </td>
                                          <td>{currentShippingRate?.isConditions}</td>
                                          <td>{currentShippingRate?.rateId}</td>
                                          <td>{currentShippingRate?.shippingPrice}</td>
                                          <td>Action</td>
                                        </tr>
                                      </>
                                    )
                                  )}
                                </tbody>
                              </table>
                                  
                            </Tabs.TabPane>
                          </Tabs>
                        </>
                      )
                    )
                  ) : (
                    <Empty />
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...zoneData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
              <Drawer
        title={
          visible
            ? "Add Zone"
            : selectedZoneForEdit
            ? "Edit Zone"
            : "Zone Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedZone || selectedZoneForEdit}
        width={450}
      >
        {visible || selectedZoneForEdit ? (
          <AddZone onCloseMethod={onClose} visibleData={selectedZoneForEdit} />
        ) : (
          ""
        )}
      </Drawer>
      </div> 
      </ShopLayout>
    </React.Fragment>
  );
};

MyShippingZoneList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default MyShippingZoneList;
