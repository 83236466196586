import React from "react";
import MainLayout from "../../../components/Layouts";
import MyAddRateCreate from "../../../components/Shop/ShippingProfile/CustomShippingRate";

const MyAddRateCreatePage = () => {
  return (
    <MainLayout>
      <MyAddRateCreate />
    </MainLayout>
  );
};

export default MyAddRateCreatePage;
