import {
    AppstoreOutlined,
    IdcardOutlined,
    ShoppingCartOutlined,
    SolutionOutlined,
  } from "@ant-design/icons";
  import { Menu } from "antd";
  import React, { useState } from "react";
  import { ReactElement } from "react";
  import { Link, useParams } from "react-router-dom";
  import { isAllowedService } from "../../../utils/services";
  import SubMenu from "antd/es/menu/SubMenu";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  // submenu keys of first level
  const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
  //const { SubMenu } = Menu;
  const ShippingProfileSidebar = ({ shopId }: any): ReactElement => {
    const pathname = window.location?.pathname;
    // new add start
    const route = useParams();
    const shopID = (route as any)?.shopId;
    const profileID = (route as any)?.profileId;
    const [openKeys, setOpenKeys] = useState<any>(['sub1']);
    const onOpenChange = keys => {
      const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(keys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    };
    // new add end
    return (
      <>
        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 h-full">
          <div className="flow-root">
            <h3 className="text-xl font-bold mb-4">Menus</h3>
  
            <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} >
              <Menu.Item
                key="/my-shipping-profile-details"
                className={`hover:text-white hover:bg-gray-100 ${
                  pathname.includes("/my-shipping-profile-details") ? "active bg-gray-100" : ""
                }`}
                icon={<IdcardOutlined />}
              >
                <Link
                  to={`/shops/${shopID}/my-shipping-profile-details/${profileID}`}
                  className="hover:text-white"
                >
                  My Shipping Zone
                </Link>
              </Menu.Item>
              {isAllowedService(`Shops Products`) && (
                <Menu.Item
                  key="/products"
                  icon={<SolutionOutlined />}
                  className={
                    pathname.includes("/products") ? "active bg-gray-100" : ""
                  }
                >
                  <Link to={`/shops/${shopId}/products`}>Product Management</Link>
                </Menu.Item>
              )}
            </Menu>
          </div>
        </div>
      </>
    );
  };
  
  export default ShippingProfileSidebar;
  