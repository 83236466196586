import React from "react";
import FuelGradeList from "../../../components/Vehicles/FuelGrade/index";
import MainLayout from "../../../components/Layouts";

const FuelGradeListPage = () => {
  return (
    <MainLayout>
      <FuelGradeList />
    </MainLayout>
  );
};

export default FuelGradeListPage;
