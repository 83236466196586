import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Switch,
} from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import styles from "../../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { ImageUploader, SubmitResetBtn, TreeSelectSlugCat } from "../../common";
import axios from "axios";
import { debounce } from "lodash";
import { jcDayArray, jcTypeArray } from "../../../utils";
import { CloseOutlined } from "@ant-design/icons";
import { useParams } from "react-router";

const AddPickupZone = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const route = useParams();
  const [businessDay, setBusinessDay] = useState();
  const [pickupZoneType, setPickupZoneType] = useState("SHOP");
  const [countryId, setCountryId] = useState<any>("");
  const [stateId, setStateId] = useState<any>();
  const [cityId, setCityId] = useState<any>();
  const [areaId, setAreaId] = useState<any>();
  const [subUrbId, setSubUrbId] = useState<any>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState(true);
  // const [pickupZoneData, setPickupZoneData] = useState<any>({
  //   loading: false,
  //   data: null,
  // });

  const [pickupZoneData, setPickupZoneData] = useState<any>(undefined);
  const [serviceType, setServiceType] = useState<any>(null);
  const [shopSlug, setShopSlug] = useState<any>();
  const [typeId, setTypeId] = useState<any>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [key, setKey] = useState("");
  const [dataOptions, setDataOptions] = useState({
    loading: false,
    list: [],
  });
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState({
    loading: false,
    list: [],
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [areaOptions, setAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [subUrbOptions, setSubUrbOptions] = useState<any>({
    list: [],
    loading: false,
  });

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      address: data.address,
      packagingTime: data.packagingTime,
      pickupZoneType: pickupZoneType,
      schedules: data?.businessDayes?.map(
        (scheduleList: any, scheduleListId: string) => ({
          businessDayName: scheduleList.businessDay,
          chargeBySchedules: scheduleList?.list?.map(
            (scheduleConfigList: any, scheduleConfigListId: string) => ({
              charge: scheduleConfigList.scheduleCharge,
              scheduleDeliveryTimeFrom:
                scheduleConfigList.scheduleDeliveryTimeFrom,
              scheduleDeliveryTimeTo: scheduleConfigList.scheduleDeliveryTimeTo,
            })
          ),
        })
      ),
      countryId: countryId,
      stateId: stateId,
      cityId: cityId,
      areaId: areaId,
      suburbId: subUrbId,
      shopId: (route as any)?.shopId,
    };
    if (pickupZoneData) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/admin/pickup-zone`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: pickupZoneData?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Pickup Zone Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/pickup-zone/add-zone-v2`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Pickup Zone Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getCountryOptions = useCallback(async (countryName?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (countryName ? `&key=${countryName}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);
  const getStateOptions = useCallback(
    async (key: any) => {
      setStateOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?page=0&limit=20` +
        (countryId ? `&countryId=${countryId}` : ``) +
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/states${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStateOptions({
            loading: false,
            list: res.data?.states?.map((state: any) => ({
              label: state.name,
              value: state.id,
            })),
          });
        })
        .catch((err) => {
          setStateOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [countryId, key]
  );
  const getCityOptions = useCallback(
    async (key: any) => {
      setCityOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?page=0&limit=20` +
        (stateId ? `&stateId=${stateId}` : ``) +
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/cities${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCityOptions({
            loading: false,
            list: res.data?.cities?.map((city: any) => ({
              label: city.name,
              value: city.id,
            })),
          });
        })
        .catch((err) => {
          setCityOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [stateId, key]
  );
  const getAreaOptions = useCallback(
    async (key: any) => {
      setAreaOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/area/all?cityId=${cityId}&page=0&limit=20`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setAreaOptions({
            loading: false,
            list: res.data?.areas?.map((area: any) => ({
              label: area.name,
              value: area.id,
            })),
          });
        })
        .catch((err) => {
          setAreaOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [cityId]
  );
  const getSubUrbOptions = useCallback(
    async (key: any) => {
      setSubUrbOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/suburb/all?areaId=${areaId}&page=0&limit=20`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setSubUrbOptions({
            loading: false,
            list: res.data?.suburbs?.map((suburb: any) => ({
              label: suburb.name,
              value: suburb.id,
            })),
          });
        })
        .catch((err) => {
          setSubUrbOptions({ loading: false, list: [] });
          console.error("suburbs: Error", err);
        });
    },
    [areaId]
  );
  useEffect(() => {
    if (visibleData) {
      setPickupZoneData(visibleData);
      setIsActive(visibleData?.isActive === "true");
    }
  }, [visibleData]);

  useEffect(() => {
    if (pickupZoneData) {
      setPickupZoneType(pickupZoneData?.pickupZoneType);
      setCountryId(pickupZoneData?.countryId);
      setStateId(pickupZoneData?.stateId);
      setCityId(pickupZoneData?.cityId);
      form.resetFields(Object.keys(pickupZoneData));
      form.resetFields();
      setPickupZoneType(pickupZoneData?.data?.productType);
    }
  }, [pickupZoneData, form]);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "country") getCountryOptions(value);
        if (field === "state") getStateOptions(value);
        if (field === "city") getCityOptions(value);
        if (field === "areaes") getAreaOptions(value);
        if (field === "suburb") getSubUrbOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getCountryOptions,
    getStateOptions,
    getCityOptions,
    getAreaOptions,
    getSubUrbOptions,
  ]);
  useEffect(() => {
    getCountryOptions("");
    getStateOptions("");
    getCityOptions("");
    getAreaOptions("");
    getSubUrbOptions("");
  }, [
    getCountryOptions,
    getStateOptions,
    getCityOptions,
    getAreaOptions,
    getSubUrbOptions,
  ]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...pickupZoneData,
        }}
      >
        <div>
          <Form.Item
            hasFeedback
            label="Pickup Zone Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
            name="name"
          >
            <Input id="name" type="text" placeholder="Enter Pickup Zone Name" />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label="Address"
            rules={[
              {
                required: true,
                message: "Address is Required!",
              },
            ]}
            name="address"
          >
            <Input id="address" type="text" placeholder="Enter Address" />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label="Packaging Time"
            rules={[
              {
                required: true,
                message: "packaging Time is Required!",
              },
            ]}
            name="packagingTime"
          >
            <Input
              id="packagingTime"
              type="text"
              placeholder="Enter Packaging Time"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label="Pickup Zone Type"
            rules={[
              {
                required: !visibleData,
                message: "Pickup Zone Type is Required!",
              },
            ]}
            name="pickupZoneType"
            //initialValue={pickupZoneType}
          >
            <Select
              options={[
                { value: "SHOP", label: "SHOP" },
                { value: "WAREHOUSE", label: "WAREHOUSE" },
                { value: "LOCKER", label: "LOCKER" },
              ]}
              placeholder="Enter Pickup Zone Type"
              defaultValue={pickupZoneType}
              onChange={(val) => setPickupZoneType(val)}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label={`Country`}
            rules={[
              {
                required: !visibleData,
                message: "Region is required!",
              },
            ]}
            name="businessCountry"
          >
            <Select
              showSearch
              placeholder="Select Country"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch?.(val, "country");
              }}
              defaultValue={countryId}
              onChange={(val) => setCountryId(val)}
              filterOption={() => {
                return true;
              }}
              options={countryOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item name="state_search" label="State">
            <Select
              allowClear
              showSearch
              placeholder={`Filter by State`}
              optionFilterProp="children"
              defaultValue={stateId}
              onChange={(val) => setStateId(val)}
              onSearch={(e) => handleSearch(e, "state")}
              filterOption={() => {
                return true;
              }}
              options={stateOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label={`City`}
            rules={[
              {
                required: false,
                message: "City is required!",
              },
            ]}
            name="businessCity"
          >
            <Select
              allowClear
              showSearch
              placeholder="Select City"
              optionFilterProp="children"
              defaultValue={cityId}
              onChange={(val) => setCityId(val)}
              onSearch={(val) => {
                handleSearch?.(val, "city");
              }}
              filterOption={() => {
                return true;
              }}
              options={cityOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label={`Area`}
            rules={[
              {
                required: false,
                message: "Area is required!",
              },
            ]}
            name="orderArea"
          >
            <Select
              allowClear
              showSearch
              placeholder="Select Area"
              optionFilterProp="children"
              onChange={(val) => setAreaId(val)}
              onSearch={(val) => {
                handleSearch?.(val, "areaes");
              }}
              filterOption={() => {
                return true;
              }}
              options={areaOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label={`Sub-Urb`}
            rules={[
              {
                required: false,
                message: "Sub-Urb is required!",
              },
            ]}
            name="subUrb"
          >
            <Select
              allowClear
              showSearch
              placeholder="Select Sub-Urb"
              optionFilterProp="children"
              onChange={(val) => setSubUrbId(val)}
              onSearch={(val) => {
                handleSearch?.(val, "suburb");
              }}
              filterOption={() => {
                return true;
              }}
              options={subUrbOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div className="float-left w-full">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
            <h3 className="mb-4 text-xl font-bold">Based on Schedule</h3>

            {/* new schedule add  */}
            <Form.List name="businessDayes">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field, ...restField) => (
                    <Card
                      size="small"
                      title={`Schedule ${field.name + 1}`}
                      key={field.key}
                      extra={
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      }
                    >
                      <Form.Item
                        label="Business Day"
                        name={[field.name, "businessDay"]}
                      >
                        <Select
                          placeholder="Day"
                          onChange={(val) => setBusinessDay(val)}
                          value={businessDay}
                          style={{ width: 250 }}
                        >
                          {Object.values(jcDayArray)?.map(
                            (dayType, dayIndex) => (
                              <Option value={dayType} key={dayIndex}>
                                {dayType.replace("_", " ")}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>

                      {/* Nest Form.List */}
                      <Form.Item label="Based on Schedule Configuration">
                        <Form.List name={[field.name, "list"]}>
                          {(subFields, subOpt) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 16,
                              }}
                            >
                              {subFields.map((subField) => (
                                <Space key={subField.key}>
                                  <Form.Item
                                    hasFeedback
                                    label="Schedule Delivery Time( From)"
                                    noStyle
                                    name={[
                                      subField.name,
                                      "scheduleDeliveryTimeFrom",
                                    ]}
                                  >
                                    <Input placeholder="From Time (12:10)" />
                                  </Form.Item>
                                  <Form.Item
                                    hasFeedback
                                    label="Schedule Delivery Time (to)"
                                    noStyle
                                    name={[
                                      subField.name,
                                      "scheduleDeliveryTimeTo",
                                    ]}
                                  >
                                    <Input placeholder="To Time (16:10)" />
                                  </Form.Item>
                                  <Form.Item
                                    hasFeedback
                                    label="Charge"
                                    noStyle
                                    name={[subField.name, "scheduleCharge"]}
                                  >
                                    <Input placeholder="Charge" />
                                  </Form.Item>
                                  <CloseOutlined
                                    onClick={() => {
                                      subOpt.remove(subField.name);
                                    }}
                                  />
                                </Space>
                              ))}
                              <Button
                                type="dashed"
                                onClick={() => subOpt.add()}
                                block
                              >
                                + Add Schedule
                              </Button>
                            </div>
                          )}
                        </Form.List>
                      </Form.Item>
                    </Card>
                  ))}

                  <Button type="dashed" onClick={() => add()} block>
                    + Add Business Day
                  </Button>
                </div>
              )}
            </Form.List>
            {/* new schedule end */}
          </div>
        </div>

        <SubmitResetBtn
          onClickReset={() => {
            form?.resetFields();
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddPickupZone;
