import React from 'react'
import PathaoStoreList from '../../components/Delivery/PathaoStore'
import MainLayout from '../../components/Layouts'

function PathaoStorePage() {
	return (
		<MainLayout>
			<PathaoStoreList />
		</MainLayout>
	)
}

export default PathaoStorePage
