import { Layout, Menu } from "antd";
import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  adminServices,
  getServicePaths,
  isAllowedService,
} from "../../../utils/services";
import { SIDEBAR_COLLAPSE } from "../../../redux/auth/authType";
import LogoImg from "../../../images/logo.svg";
import IconImg from "../../../images/favicon.png";
import React from "react";
const { Sider } = Layout;
const { SubMenu } = Menu;

const MainSidebar = (): ReactElement => {
  const { sidebarCollapse, type } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  const setCollapsed = (value: boolean) => {
    dispatch({
      type: SIDEBAR_COLLAPSE,
      payload: {
        sidebarCollapse: value,
      },
    });
  };

  return (
    <>
      <Sider
        width={230}
        collapsible
        collapsed={sidebarCollapse}
        onCollapse={(value) => {
          setCollapsed(value);
        }}
      >
        <div className="logo sticky top-0 z-10">
          <Link to="/">
            <img
              src={sidebarCollapse ? IconImg : LogoImg}
              alt="jachai parcel"
              className="logoImg"
            />
          </Link>
        </div>
        <Menu
          defaultOpenKeys={[getServicePaths(pathname)]}
          mode="inline"
          // inlineCollapsed={this?.state?.collapsed}
          // onOpenChange={this.onOpenChange}
          theme="dark"
        >
          {adminServices?.map((service, i) => {
            if (service?.sub && service?.sub?.length) {
              if (
                service?.sub?.filter((item) => {
                  const excludeIcon = item;
                  return (
                    excludeIcon?.icon &&
                    isAllowedService(`${service.name} ${item.name}`) 
                  );
                })?.length === 1 &&
                service?.sub?.find((item) => {
                  return item.isParent;
                })
              ) {
                const actualPath = `${service?.path}${service?.sub?.[0]?.path}`;

                return (
                  <Menu.Item
                    key={service?.path}
                    icon={<FontAwesomeIcon icon={service?.icon} />}
                    className={
                      pathname === actualPath && service?.path ? "active" : ""
                    }
                  > 
                    <Link to={actualPath}>
                      {type === "JC_FOOD" 
                        ? service?.name
                            ?.replace("Shops", "Restaurants")
                            ?.replace("Product", "Menu")
                        : service?.name}
                    </Link>
                  </Menu.Item>
                );
              } else {
                return (
                  service?.sub?.find((item) =>
                    isAllowedService(`${service.name} ${item.name}`)
                  ) && (
                    <SubMenu
                      key={service?.path}
                      icon={<FontAwesomeIcon icon={service?.icon} />}
                      title={service?.name?.replace("Shops", "Restaurants")}
                    >
                      {service?.sub?.map((sub) => {
                        const actualPath = `${service?.path}${sub?.path}`;
                        const excludeIcon = sub;
                        return (
                          !actualPath.includes(":") &&
                          excludeIcon?.icon &&
                          isAllowedService(`${service.name} ${sub.name}`) && (
                            <Menu.Item
                              key={actualPath}
                              icon={<FontAwesomeIcon icon={sub?.icon} />}
                              className={
                                pathname === actualPath ? "active" : ""
                              }
                            >
                              <Link to={actualPath}>{sub?.name}</Link>
                            </Menu.Item>
                          )
                        );
                      })}
                    </SubMenu>
                  )
                );
              }
            } else {
              return (
                isAllowedService(`${service.name}`) && (
                  <Menu.Item
                    key={service?.path}
                    icon={<FontAwesomeIcon icon={service?.icon} />}
                    className={pathname === service?.path ? "active" : ""}
                  >
                    <Link to={service?.path as any}>{service?.name}</Link>
                  </Menu.Item>
                )
              );
            }
          })}
        </Menu>
      </Sider>
    </>
  );
};

export default MainSidebar;
