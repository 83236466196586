import { Alert, Divider, Form, Input, Select, Switch } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { ImageUploader, SubmitResetBtn, TreeSelectSlugCat } from "../common";
import axios from "axios";
import { debounce } from "lodash";
import { jcTypeArray } from "../../utils";

const AddBanner = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >(); 
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm(); 
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState(true);
  const [bannerData, setBannerData] = useState<any>(undefined);
  const [bannerType, setBannerType] = useState<any>(null);
  const [serviceType, setServiceType] = useState<any>(null);
  const [shopSlug, setShopSlug] = useState<any>();
  const [typeId, setTypeId] = useState<any>();
  const [isForLanding, setIsForLanding] = useState<boolean>(
    visibleData?.type === "JC_LANDING"
  );
  const [key, setKey] = useState("");
  const [dataOptions, setDataOptions] = useState({
    loading: false,
    list: [],
  });

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    if (generatedImgUrl) { 
      setLoading(true);
      const readyData = data && {
        bannerName: data.bannerName,
        bannerPosition: data.bannerPosition,
        bannerType: bannerType,
        serviceType: data.serviceType || type,
        typeId: typeId,
        bannerImage: generatedImgUrl ? generatedImgUrl : data?.bannerImage,
        type: isForLanding ? "JC_LANDING" : type,
      };

      if (bannerData) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: bannerData?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Banner Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Banner Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  const getProductOptions = useCallback(
    async (key: any) => {
      setDataOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?page=0&limit=20` +
        (serviceType ? `&type=${serviceType}` : `&type=${type}`) +
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDataOptions({
            loading: false,
            list: res.data?.keys?.map((product: { name: any; slug: any }) => ({
              label: product.name,
              value: product.slug,
            })),
          });
        })
        .catch((err) => {
          setDataOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [key, serviceType]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setDataOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?page=0&limit=20` +
            (serviceType ? `&type=${serviceType}` : `&type=${type}`) +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setDataOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.slug,
            })),
          });
        })
        .catch((err) => {
          setDataOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type, serviceType]
  );

  const getBrandsOptions = useCallback(
    async (key?: string) => {
      setDataOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      const res = await axios.get(
        `${encodedUri}/brand/search?page=0&limit=20` +
          (serviceType ? `&type=${serviceType}` : `&type=${type}`) +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDataOptions({
        loading: false,
        list: res?.data?.brands?.map((brand: any) => ({
          label: brand.name,
          value: brand.slug,
        })),
      });
    },
    [serviceType]
  );

  const getCampaignOptions = useCallback(async (key?: string) => {
    setDataOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/campaign/by-type` +
        (serviceType ? `?type=${serviceType}` : `?type=${type}`),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setDataOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => ({
        label: campaign.name,
        value: campaign.slug,
      })),
    });
  }, []);

  useEffect(() => {
    if (visibleData) {
      setBannerData(visibleData);
      setGeneratedImgUrl(visibleData?.bannerImage);
      setIsForLanding(visibleData?.type === "JC_LANDING");
      setBannerType(visibleData?.bannerType);
    }
  }, [visibleData]);

  useEffect(() => {
    if (bannerData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.bannerImage);
      setIsForLanding(visibleData?.type === "JC_LANDING");
    }
  }, [bannerData, form, visibleData]);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "products") getProductOptions(value);
        else if (field === "shops") getShopOptions(value);
        else if (field === "brands") getBrandsOptions(value);
        else if (field === "campaigns") getCampaignOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, getShopOptions, getBrandsOptions, getCampaignOptions]);

  useEffect(() => {
    if (bannerType === "PRODUCTS") {
      getProductOptions(``);
    }
  }, [bannerType, serviceType]);

  useEffect(() => {
    if (bannerType === "CAMPAIGNS") {
      getCampaignOptions();
    }
  }, [bannerType, serviceType]);

  useEffect(() => {
    if (bannerType === "BRANDS") {
      getBrandsOptions();
    }
  }, [bannerType, serviceType]);

  useEffect(() => {
    if (bannerType === "SHOPS") {
      getShopOptions(``);
    }
  }, [bannerType, serviceType]);

  return ( 
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...bannerData,
        }}
      >
        <Form.Item hasFeedback label="Is Landing" name="isLanding">
          <Switch
            checkedChildren={`Yes`}
            unCheckedChildren={`No`}
            defaultChecked={isForLanding}
            onChange={() => setIsForLanding(!isForLanding)}
          />
        </Form.Item>

        {isForLanding && (
          <>
            <Form.Item
              hasFeedback
              label="Service Type"
              rules={[
                {
                  required: false,
                  message: "Type is required!",
                },
              ]}
              name="serviceType"
              initialValue={type}
            >
              <Select
                placeholder="Service Type"
                onChange={(val) => setServiceType(val)}
              >
                {Object.values(jcTypeArray)?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type?.replace("_", " ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item
          hasFeedback
          label="Banner Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="bannerName"
        >
          <Input id="bannerName" type="text" placeholder="Enter Banner Name" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Position"
          rules={[
            {
              required: false,
              message: "Position is Required!",
            },
          ]}
          name="bannerPosition"
        >
          <Input
            type="number"
            id="bannerPosition"
            min={0}
            placeholder="Enter Position"
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Banner Type"
          rules={[
            {
              required: false,
              message: "Banner Type is Required!",
            },
          ]}
          name="bannerType"
          initialValue={bannerType}
        >
          <Select
            options={[
              { value: "NULL", label: "NONE" },
              { value: "PRODUCTS", label: "PRODUCT" },
              { value: "CAMPAIGNS", label: "CAMPAIGN" },
              { value: "BRANDS", label: "BRAND" },
              { value: "CATEGORIES", label: "CATEGORY" }, 
              { value: "SHOPS", label: "SHOP" },
            ]}
            placeholder="Enter Notification Type"
            onChange={(val) => setBannerType(val)}
          />
        </Form.Item> 

        {bannerType && bannerType !== "CATEGORIES" ? (
          <>
            <Form.Item
              name="product_search"
              label={`Select ${bannerType?.toLowerCase()}`}
            >
              <Select
                allowClear
                showSearch
                placeholder={`Filter by  ${bannerType?.toLowerCase()}`}
                optionFilterProp="children"
                onChange={(val) => setTypeId(val)}
                onSearch={(e) => handleSearch(e, bannerType?.toLowerCase())}
                filterOption={() => {
                  return true;
                }}
                options={dataOptions?.list}
              ></Select>
            </Form.Item>
          </>
        ) : undefined}

        {bannerType === "CATEGORIES" && (
          <>
            <Form.Item name="category_search">
              <TreeSelectSlugCat setCategoryId={setTypeId} />
            </Form.Item>
          </>
        )}

        <Divider orientation="left">Image</Divider>
        <div className="flex items-center pb-4">
          <ImageUploader
            imgUrl={generatedImgUrl || ""}
            startUpload={startUpload}
            setStartUpload={(val: any) => setStartUpload(val)}
            setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            // crop={cropImage ? 2.5 : 0}
          />
          <div className=" pr-8">
            <Switch
              onChange={() => setCropImage(!cropImage)}
              defaultChecked={cropImage}
              checkedChildren={<FontAwesomeIcon icon={faCropAlt} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            />
          </div>
        </div>

        {cropImage && (
          <Alert
            message="Image ratio must be 2.5 (1600/640 PX)"
            type="info"
            showIcon
            className="mb-4 rounded-md shadow-sm"
            banner
          />
        )}
        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
          }}
          onClickReset={() => {
            form?.resetFields();
            setGeneratedImgUrl(undefined);
            setStartUpload(undefined);
            // setBannerUrl(undefined);
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddBanner;
