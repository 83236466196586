import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import BrandLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_BRAND_INFO } from "../../../redux/auth/authType";
import BrandProductList from "../../Product/index";
import { Dropdown, Menu, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const BrandDetails = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);

  const dispatch = useDispatch();
  const route = useParams();
  const [singleBrandInfo, setSingleBrandInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchBrandDetails = useCallback((getBrandId: any) => {
    if (getBrandId) {
      try {
        setSingleBrandInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/brand/detail?brandId=${getBrandId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleBrandInfo({
                loading: false,
                data: data?.data?.brand,
              });

              dispatch({
                type: SET_BRAND_INFO,
                payload: {
                  brandInfo: {
                    id: data?.data?.brand?.id,
                    name: data?.data?.brand?.name,
                    type: data?.data?.brand?.type,
                    logo: data?.data?.brand?.logo,
                    bannerImage: data?.data?.brand?.bannerImage,
                    sellCount: data?.data?.brand?.sellCount,
                  },
                }, 
              });
            } else { 
              setSingleBrandInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBrandInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleBrandInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchBrandDetails((route as any)?.brandId);
  }, [fetchBrandDetails]);

  const brand = singleBrandInfo?.data;

  return (
    <React.Fragment>
      <BrandLayout
        brandId={brand?.id}
        title={`Shop Details`}
        subTitle={`Details`}
        extra={<></>}
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
            <Tabs
              onTabScroll={() => {
                direction: top;
              }}
              tabPosition="top"
              // tabBarExtraContent={
              //   <Link
              //     to={`/brands/${brand?.id}/edit`}
              //     className="rounded-full h-8 w-8 border-2 border-white hover:bg-slate-50 text-center text-red-600 align-center"
              //   >
              //     <FontAwesomeIcon icon={faPenToSquare} />
              //     <span className="ml-2">Edit</span>
              //   </Link>
              // }
            >
              <TabPane tab="GENERAL INFO" key="General">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Brand Name
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {brand?.name}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Brand Slug
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {brand?.slug}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Meta Title
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {brand?.metaTitle}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Brand Meta Description
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {brand?.metaDescription}
                    </dd>
                  </div>{" "}
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Brand keyWord
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {brand?.keyWord}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Description
                    </dt>
                    <dd
                      className="mt-1 space-y-3 text-sm text-gray-500"
                      dangerouslySetInnerHTML={{
                        __html: brand?.description,
                      }}
                    ></dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Brand ID
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {brand?.id}
                    </dd>
                  </div>
                </dl>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </BrandLayout>
    </React.Fragment>
  );
};

BrandDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default BrandDetails;
