import { ReactElement } from "react";
import DriverTransactionList from "../../../components/Delivery/DeliveryHero/transactions";
import MainLayout from "../../../components/Layouts";
import React from "react";

const DriverTransactionPage = (): ReactElement => {
  return (
    <MainLayout>
      <DriverTransactionList />
    </MainLayout>
  );
};

export default DriverTransactionPage;
