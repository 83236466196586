import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Tooltip,
} from "antd";
import axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import dayjs from "dayjs";
import { priceCalculationType, userReportType } from "../../utils";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;
const UserReport = ({ UserDetails, onCloseMethod }: any): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState<any>();
  const adminUserID = adminData?.id;
  const [startingDate, setStartingDate] = useState<DatePickerProps["value"]>();
  const [endingDate, setEndingDate] = useState<DatePickerProps["value"]>();
  const [reportEntityType, setReportEntityType] = useState<any>([]);
  const [reportType, setReportType] = useState<any>("summary");
  const [downloadData, setDownloadData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const getDownloadData = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setLoading(true);
    setDownloadData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/admin/activity-log/count-by-entity?userId==${adminUserID}` +
          (reportEntityType ? `&entityNames=${reportEntityType}` : ``) +
          (from ? `&startDate=${from}` : ``) +
          (to ? `&endDate=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDownloadData({ loading: false, data: res.data });
        setLoading(false);
      })
      .catch((err) => {
        setDownloadData({ loading: false, data: null });
        console.error("Promo: Error", err);
      });
  }, [range]);
  useEffect(() => {
    getDownloadData();
  }, [range, getDownloadData]);
  return (
    <div className="">
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={getDownloadData}
        form={form} // like ref
        layout="vertical"
        // requiredMark="optional"
        autoComplete="off"
        initialValues={{
          ...adminData,
        }}
      >
        <div>
          <Form.Item name="range">
            <RangePicker
              defaultValue={
                range.from != null && range.to != null
                  ? [moment(range.from), moment(range.to)]
                  : null
              }
              onChange={(e, f) => {
                setRange({
                  from: moment.utc(f[0])?.toISOString(),
                  to: moment.utc(f[1])?.toISOString(),
                });
              }}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [
                  moment("00:00:00", "HH:mm:ss"),
                  moment("23:59:59", "HH:mm:ss"),
                ],
              }}
              inputReadOnly
              allowClear
            />
          </Form.Item>
        </div>

        <Form.Item
          label="User Report Type"
          name="entityNames"
          rules={[
            {
              required: true,
              message: "Price Calculation Type is Required!",
            },
          ]}
        >
          <Select
            placeholder="Select User Report Type"
            mode="multiple"
            onChange={(val) => setReportEntityType(val)}
          >
            {Object.values(userReportType)?.map((type, i) => (
              <Option value={type} key={i}>
                {type?.split("_").join(" ")}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Radio.Group
          defaultValue="summary"
          buttonStyle="solid"
          style={{ marginBottom: 16 }}
          onChange={(e) => setReportType(e.target.value)}
        >
          <Radio.Button value="summary">Report Summary</Radio.Button>
          <Radio.Button value="details">Report Details</Radio.Button>
        </Radio.Group>

        <Button
          type="primary"
          htmlType="submit"
          className="add-submit-btn text-center mr-2"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default UserReport;
