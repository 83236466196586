import React from "react";
import MainLayout from "../../components/Layouts";
import VehicleDetails from "../../components/Vehicles/Details/index";

const VehicleDetailsPage = () => {
  return (
    <MainLayout>
      <VehicleDetails />
    </MainLayout>
  );
};

export default VehicleDetailsPage;
