import { Image } from "antd";
import React from "react";
import { ReactElement } from "react";

const SingleCarrier = ({
  PickupZoneDetails,
}: {
  PickupZoneDetails: any;
}): ReactElement => {
  return (
    <>
      <div className="">
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Pickup Zone Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{PickupZoneDetails?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Address</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{PickupZoneDetails?.address}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Packaging Time</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{PickupZoneDetails?.packagingTime}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Pickup Zone Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{PickupZoneDetails?.pickupZoneType}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Country</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{PickupZoneDetails?.country?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">State</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{PickupZoneDetails?.state?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">City</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{PickupZoneDetails?.city?.name}</span>
            </div>
          </div>
        </div>
        <h3 className="mb-4 text-xl text-center font-bold">
          Based on Schedule
        </h3>
        {PickupZoneDetails?.schedules.map((schedule: any, index: any) => (
          <>
            <h3 className="mb-4 text-xl font-bold">Schedule {index+1}</h3>
            <h3 className="mb-4 text-xl text-center font-bold">
              Business Day Name : {schedule?.businessDayName}
            </h3>
            {schedule?.chargeBySchedules.map(
              (chargeBySchedule: any, chargeIndex: any) => (
                <>
                  <h3 className="mb-4 text-xl font-bold">
                    Based on Schedule Configuration
                  </h3>
                  <div className="flex flex-no-wrap py-2 mt-8">
                    <div className="w-4/5 flex-none">
                      <div className="text-gray-500">
                        <span className="font-bold">
                          Schedule Delivery Time From
                        </span>
                      </div>
                    </div>
                    <div className="w-1/5 flex-none">
                      <div className="text-gray-700">
                        <span>
                          {chargeBySchedule?.scheduleDeliveryTimeFrom}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-no-wrap py-2 mt-8">
                    <div className="w-4/5 flex-none">
                      <div className="text-gray-500">
                        <span className="font-bold">
                          Schedule Delivery Time To
                        </span>
                      </div>
                    </div>
                    <div className="w-1/5 flex-none">
                      <div className="text-gray-700">
                        <span>{chargeBySchedule?.scheduleDeliveryTimeTo}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-no-wrap py-2 mt-8">
                    <div className="w-4/5 flex-none">
                      <div className="text-gray-500">
                        <span className="font-bold">Charge</span>
                      </div>
                    </div>
                    <div className="w-1/5 flex-none">
                      <div className="text-gray-700">
                        <span>{chargeBySchedule?.charge}</span>
                      </div>
                    </div>
                  </div>
                </>
              )
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default SingleCarrier;
