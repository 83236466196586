import React from "react";
import MainLayout from "../../components/Layouts";
import VehicleList from "../../components/Vehicles/index";

const VehiclePage = () => {
  return (
    <MainLayout>
      <VehicleList />
    </MainLayout>
  );
};

export default VehiclePage;
