import { Avatar, Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useState, useCallback, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import { getImgUrl } from "../../../utils";
import { SET_CATEGORY_INFO } from "../../../redux/auth/authType";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
const { Content } = Layout;

const CategoryLayout = ({
  children,
  categoryId,
  title,
  extra,
  subTitle,
}: {
  children: ReactElement;
  categoryId: string;
  title: string;
  subTitle: string;
  extra: ReactElement;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [categoryInfo, setCategoryInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useParams();
  const [visible, setVisible] = useState<any>(undefined);
  const onClose = () => {
    setVisible(undefined);
  };

  const fetchCategoryDetails = useCallback((categoryId: any) => {
    if (categoryId) {
      try {
        setCategoryInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/detail?categoryId=${categoryId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setCategoryInfo({
                loading: false,
                data: data?.data?.category,
              });
              dispatch({
                type: SET_CATEGORY_INFO,
                payload: {
                  categoryInfo: {
                    id: data?.data?.category?.id,
                    type: data?.data?.category?.type,
                    title: data?.data?.category?.title,
                    slug: data?.data?.category?.slug,
                    image: data?.data?.category?.image,
                    parent: data?.data?.category?.parent,
                    level: data?.data?.category?.level,
                    description: data?.data?.category?.description,
                    position: data?.data?.category?.position,
                    status: data?.data?.category?.status,
                    deleted: data?.data?.category?.deleted,
                    bannerImage: data?.data?.category?.bannerImage,
                    keyword: data?.data?.category?.keyword,
                  },
                },
              });
            } else {
              setCategoryInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setCategoryInfo({ loading: false, data: null });
          });
      } catch (error) {
        setCategoryInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchCategoryDetails((route as any)?.categoryId);
  }, [fetchCategoryDetails]);

  const category = categoryInfo?.data;

  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <main>
          <div className="grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6">
            <div className="col-span-full">
              <PageHeader
                className="site-page-header px-0 py-0 mb-0"
                onBack={() => navigate(-1)}
                title={
                  <h1 className="text-xl font-medium text-gray-900 sm:text-2xl">
                    Category Details
                  </h1>
                }
                // extra={<AddBtn onClick={() => setVisible(true)} />}
              />
            </div>

            <div className="col-span-full xl:col-auto">
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl mb-6">
                <div className="sm:flex xl:block sm:space-x-4 xl:space-x-0">
                  <img
                    src={category?.bannerImage}
                    alt=""
                    className="rounded-xl"
                  />
                  <div className="px-4 pb-2">
                    <div className="xl:-mt-14">
                      <Avatar
                        size={100}
                        shape="circle"
                        src={getImgUrl(category?.image)}
                        className="mb-5 w-20 h-20 shadow-lg border-4 border-white bg-gray-50"
                      />
                    </div>
                    <div>
                      <h2 className="text-xl font-bold">
                        {category?.title?.toUpperCase()}
                      </h2>
                      <div className="mb-4 sm:flex xl:block">
                        <div className="sm:flex-1">
                          <address className="text-sm not-italic font-normal text-gray-900">
                            <div className="mt-4 font-bold text-gray-700">
                              Slug
                            </div>
                            <div className="mb-2 text-sm font-medium text-gray-500">
                              {category?.slug}
                            </div>
                            <div className="mt-4 font-bold text-gray-700">
                              Category Id
                            </div>
                            <div className="mb-2 text-sm font-medium text-gray-500">
                              {category?.id}
                            </div>

                            {/* <div className="flex gap-4 mt-4">
                              <div className="font-bold text-gray-700">
                                Type
                              </div>
                              <div className="text-sm font-medium text-gray-500">
                                {category?.type?.replace("_", " ")}
                              </div>
                            </div> */}

                            {category?.position ? (
                              <div className="flex gap-4 mt-4">
                                <div className="font-bold text-gray-700">
                                  Position
                                </div>
                                <div className="mb-2 text-sm font-medium text-gray-500">
                                  {category?.position}
                                </div>
                              </div>
                            ) : undefined}

                            {category?.level ? (
                              <div className="flex gap-4 mt-4">
                                <div className="font-bold text-gray-700">
                                  Level
                                </div>
                                <div className="mb-2 text-sm font-medium text-gray-500">
                                  {category?.level}
                                </div>
                              </div>
                            ) : undefined}

                            {category?.description && (
                              <>
                                <div className="mt-4 font-bold text-gray-700">
                                  Description
                                </div>
                                <div className="mb-2 text-sm font-medium text-gray-500">
                                  {category?.description?.trim()}
                                </div>
                              </>
                            )}
                          </address>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Sidebar categoryId={categoryId} />
            </div>
            <div className="col-span-2">
              <Layout>
                <Content className="main-content-layout">{children}</Content>
              </Layout>
            </div>
          </div>
        </main>
      </Layout>
    </React.Fragment>
  );
};

CategoryLayout.propType = {
  children: PropTypes.element,
};

export default CategoryLayout;
