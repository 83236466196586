import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { AddBtn, Loader, LoaderFull, PaginationThree } from "../../common";
import moment from "moment";
import axios from "axios";
import styles from "../../../styles/tailwind/List.module.css";
import { getPage, getParamValue } from "../../../utils";
import { Avatar, Button, Empty, Form, Switch, Tag } from "antd";
import classNames from "classnames";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { Link } from "react-router-dom";
import { responseNotification } from "../../../utils/notify";

const ShopPackagesList = (): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const route = useParams();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [packageData, setPackageData] = useState<any>({
    loading: false,
    data: null,
  });

  const getPackages = useCallback(async () => {
    setPackageData({ loading: true, data: null });
    const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUrl}/package/my-packages?shopId=${(route as any)?.shopId}` +
          (page
            ? `&page=${
                page == packageData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPackageData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setPackageData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [limit, page]);

  const onStatusChange = async (id: any, val: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/package/update`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getPackages();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  
  useEffect(() => {
    getPackages();
  }, [getPackages]);
  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Package List"
        subTitle="Package List"
        extra={<></>}
      >
        <>
          <BreadCrumb
            title="Package List"
            subTitle={`${
              packageData?.data?.totalElements ||
              packageData?.data?.deliveryPackages?.length ||
              0
            } Package(s)`}
            extra={[
              <AddBtn
                onClick={() => {
                  navigate(`/shops/${(route as any)?.shopId}/package-add`);
                }}
              />,
            ]}
          />
        </>

        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    {confirmLoading && <LoaderFull />}
                    {packageData?.loading ? (
                      <Loader />
                    ) : (
                      <table className={styles.mainTable}>
                        <thead className="bg-white border-b">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Type</th>
                            <th scope="col">Package Type</th>
                            <th scope="col">Who preps units</th>
                            <th scope="col">Who Labels units</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                            <th scope="col">Created Info</th>
                            <th scope="col">Updated Info</th>
                          </tr>
                        </thead>

                        <tbody>
                          {packageData?.data?.deliveryPackages?.length ? (
                            packageData?.data?.deliveryPackages?.map(
                              (deliveryPackage: any, index: any) => (
                                <tr
                                  className="border-t hover:bg-gray-100 cursor-pointer"
                                  key={index}
                                >
                                  <td
                                    onClick={() =>
                                      navigate(`/shops/${deliveryPackage?.id}/details`)
                                    }
                                    className="flex gap-2 items-center"
                                  >
                                    <span className="font-medium text-gray-500 ml-2 flex flex-col">
                                      {deliveryPackage?.name}
                                    </span>
                                  </td>

                                  <td>
                                  <span className="font-medium text-gray-500 ml-2 flex flex-col">
                                      {deliveryPackage?.packageUnitType?.caseUnits===true ? 'Case':deliveryPackage?.packageUnitType?.groupUnits===true ? 'Group':deliveryPackage?.packageUnitType?.individualUnits===true ? 'Individual':'' }
                                    </span>
                                  </td>
                                  <td>
                                  <span className="font-medium text-gray-500 ml-2 flex flex-col">
                                  {deliveryPackage?.packageType?.box===true ? 'Box':deliveryPackage?.packageType?.envelope===true ? 'Envelope':deliveryPackage?.packageType?.softPackageOrSatchel===true ? 'Soft Package Or Satchel':'' }
                                    </span>
                                  </td>
                                  <td>
                                  <span className="font-medium text-gray-500 ml-2 flex flex-col">
                                      {deliveryPackage?.whoPrepsUnits}
                                    </span>
                                  </td>
                                  <td>
                                  <span className="font-medium text-gray-500 ml-2 flex flex-col">
                                      {deliveryPackage?.whoLabelsUnits}
                                    </span>
                                  </td>
                                  <td>
                                  <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={deliveryPackage?.isActive}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onStatusChange(deliveryPackage?.id, val);
                                  }}
                                />
                                  </td>
                                  <td>  
                                  <Link
                                          title="Edit Package"
                                          to={`/shops/${(route as any)?.shopId}/package/${deliveryPackage?.id}/edit`}
                                          target={
                                            type == "JC_FOOD"
                                              ? "_self"
                                              : "_blank"
                                          }
                                          className="py-2 px-4 rounded-full leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                        >
                                          <EditOutlined />
                                        </Link>
                                  </td>
                                  <td>
                                  <small className="font-bold uppercase text-gray-500">
                                    {deliveryPackage?.createdBy?.name ||
                                      deliveryPackage?.createdBy?.mobileNumber?.replace(
                                        "+88",
                                        ""
                                      )}
                                    {" - "}
                                  </small>
                                  <small
                                    className={`font-capitalize ${
                                      moment().diff(
                                        deliveryPackage?.createdAt,
                                        "minutes"
                                      ) >= 60
                                        ? "text-red-600"
                                        : "text-gray-800"
                                    }`}
                                  >
                                    {moment(deliveryPackage?.createdAt).fromNow()}
                                  </small>
                                  <br />
                                  <span className="name">
                                    {moment(deliveryPackage?.createdAt).format("lll")}
                                  </span>
                                  </td>
                                  <td>
                                  <small className="font-bold uppercase text-gray-500">
                                    {deliveryPackage?.updatedBy?.name ||
                                      deliveryPackage?.updatedBy?.mobileNumber?.replace(
                                        "+88",
                                        ""
                                      )}
                                    {" - "}
                                  </small>
                                  <small
                                    className={`font-capitalize ${
                                      moment().diff(
                                        deliveryPackage?.updatedAt,
                                        "minutes"
                                      ) >= 60
                                        ? "text-red-600"
                                        : "text-gray-800"
                                    }`}
                                  >
                                    {moment(deliveryPackage?.updatedAt).fromNow()}
                                  </small>
                                  <br />
                                  <span className="name">
                                    {moment(deliveryPackage?.updatedAt).format("lll")}
                                  </span>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td>
                                <Empty />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
              <PaginationThree
                {...packageData?.data}
                limit={limit}
                page={getPage(loc.search)}
              />
            </div>
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopPackagesList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopPackagesList;
