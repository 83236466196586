import React from "react";
import BulkUploadSubUrbList from "../../components/SubUrb/SubUrbBulkUpload";
import MainLayout from "../../components/Layouts";

const BulkUploadSubUrbPage = () => {
  return (
    <MainLayout>
      <BulkUploadSubUrbList />
    </MainLayout>
  );
};

export default BulkUploadSubUrbPage;
