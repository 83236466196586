import {
  BarcodeOutlined,
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  Steps,
  Select,
  Empty,
  Avatar,
  Spin,
  Radio,
  RadioChangeEvent,
  Tabs,
} from "antd";
import { Image } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { responseNotification } from "../../../utils/notify";
import BreadCrumb from "../../Layouts/Breadcrumb";
import OrderProductList from "../Details/products";
import styles from "../../../styles/tailwind/List.module.css";
import { Loader } from "../../common";
import TabPane from "antd/es/tabs/TabPane";
const { Step } = Steps;
const OrderCreate = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const serviceType = initialState?.type;
  const [promoCode, setPromoCode] = useState<any>(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const router = useLocation();
  const [userPromoDiscount, setUserPromoDiscount] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [countryId, setCountryId] = useState<any>("");
  const [stateId, setStateId] = useState<any>();
  const [cityId, setCityId] = useState<any>();
  const [subUrbId, setSubUrbId] = useState<any>();
  const [orderAreaId, setOrderAreaId] = useState<any>();
  const [deliveryChargeInitial, setDeliveryChargeInitial] = useState<any>(0);
  const [promoDiscount, setPromoDiscount] = useState<any>(0);
  const [promoDiscountCode, setPromoDiscountCode] = useState<any>(0);
  const [deliveryCharge, setDeliveryCharge] = useState<any>(0);
  const [shopViewAllDeliveryCharge, setShopViewAllDeliveryCharge] =
    useState<any>("");
  const [scheduleDelivery, setScheduleDelivery] = useState(false);
  const [shopAllDeliveryMethod, setShopAllDeliveryMethod] = useState<any>("");
  const [deliveryChargeResponse, setDeliveryChargeResponse] = useState<any>();
  const [deliveryMethodChekedFromRequest, setDeliveryMethodChekedFromRequest] =
    useState<any>([]);
  const [deliveryMethodShopId, setDeliveryMethodShopId] = useState<any>("");
  const [deliveryMethods, setDeliveryMethods] = useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [handelPromo, setHandelPromo] = useState("all");
  const [type, setType] = useState("");
  const handelModal = (type) => {
    setType(type);
    setShowModal(true);
  };
  const [key, setKey] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const groupedMap = useMemo(
    () =>
      selectedProducts?.reduce(
        (entryMap, e) =>
          entryMap.set(e.shop?.id, {
            shop: e.shop,
            chargeByDeliveryMethods:
              deliveryChargeResponse?.deliveryCharges.filter(
                (deliveryCharge) => deliveryCharge?.shopId === e.shop?.id
              )?.[0]?.chargeByDeliveryMethods,
            products: [...(entryMap.get(e.shop?.id)?.products || []), e],
          }),

        new Map()
      ),
    [router.pathname, selectedProducts, deliveryChargeResponse]
  );
  const [shopShippingMethod, setShopShippingMethod] = useState<any>("");
  const [shipShippingCharge, setShipShippingCharge] = useState<any>("");
  const [shopShippingMessage, setShopShippingMessage] = useState<any>("");
  const [shopShippingDate, setShopShippingDate] = useState<any>("");
  const [firstStepData, setFirstStepData] = useState<any>();
  const [userHub, setUserHub] = useState<any>();
  const [hubName, setHubName] = useState<any>();
  const [areaName, setAreaName] = useState<any>();
  const [mobileNumber, setMobileNumber] = useState("");
  const [manualOrderType, setManualOrderType] = useState(1);
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState({
    loading: false,
    list: [],
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [areaOptions, setAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [orderAreaOptions, setOrderAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [subUrbOptions, setSubUrbOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [usersOptions, setUsersOptions] = useState<any>({
    loading: false,
    list: null,
  });

  const [locationOptions, setLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [currentLocation, setCurrentLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  function myFunction(
    shopId: any,
    methodDetails: any,
    methodName: any,
    deliveryCharge: any
  ) {
    if (methodName === "SCHEDULE_DELIVERY" && methodDetails === null) {
    } else {
      // addDeliveryMethods(
      //   {shopId: shopId, schedule: methodDetails, methodName: methodName}
      // );

      setDeliveryMethods([
        ...deliveryMethods,
        {
          shopId: shopId,
          schedule: methodDetails,
          methodName: methodName,
          deliveryCharge: deliveryCharge,
        },
      ]);
    }
  }
  const onSubmitFirst = (data: any) => {
    setFirstStepData(data);
    if (type === "JC_MART" && userHub) {
      if (userHub) {
        next();
      } else {
        responseNotification(
          "No hub found in this area. Search again...",
          "error"
        );
      }
    } else {
      next();
    }
  };
  const getCountryOptions = useCallback(async (countryName?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (countryName ? `&key=${countryName}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);
  const getStateOptions = useCallback(
    async (key: any) => {
      setStateOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?page=0&limit=20` +
        (countryId ? `&countryId=${countryId}` : ``) +
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/states${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStateOptions({
            loading: false,
            list: res.data?.states?.map((state: any) => ({
              label: state.name,
              value: state.id,
            })),
          });
        })
        .catch((err) => {
          setStateOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [countryId, key]
  );
  const getCityOptions = useCallback(
    async (key: any) => {
      setCityOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?page=0&limit=20` +
        (stateId ? `&stateId=${stateId}` : ``) +
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/cities${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCityOptions({
            loading: false,
            list: res.data?.cities?.map((city: any) => ({
              label: city.name,
              value: city.id,
            })),
          });
        })
        .catch((err) => {
          setCityOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [stateId, key]
  );
  const getAreaOptions = useCallback(
    async (key: any) => {
      setOrderAreaOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/area/all?cityId=${cityId}&page=0&limit=20`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setOrderAreaOptions({
            loading: false,
            list: res.data?.areas?.map((area: any) => ({
              label: area.name,
              value: area.id,
            })),
          });
        })
        .catch((err) => {
          setOrderAreaOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [cityId]
  );
  const getSubUrbOptions = useCallback(
    async (key: any) => {
      setSubUrbOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/suburb/all?areaId=${orderAreaId}&page=0&limit=20`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setSubUrbOptions({
            loading: false,
            list: res.data?.suburbs?.map((suburb: any) => ({
              label: suburb.name,
              value: suburb.id,
            })),
          });
        })
        .catch((err) => {
          setSubUrbOptions({ loading: false, list: [] });
          console.error("suburbs: Error", err);
        });
    },
    [orderAreaId]
  );
  const [value, setValue] = useState(1);
  const onChange5555 = (e: RadioChangeEvent) => {
    alert("okey");
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const CheckDeliveryCharge = async () => {
    const readyData = {
      products: selectedProducts?.map((product, productIndex) => ({
        productId: product.id,
        quantity: product.quantity,
        variationId: product.variation.variationId,
      })),
    };
    fetch(
      `${process.env.REACT_APP_ORDER_API}/delivery-charge/all?countryId=${countryId}&stateId=${stateId}&cityId=${cityId}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        }),
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?.statusCode === 200) {
          setDeliveryChargeResponse(res);
          var countTotalDeliveryCharge = 0;
          for (
            let shopDeliveryCharge = 0;
            shopDeliveryCharge < res?.deliveryCharges.length;
            shopDeliveryCharge++
          ) {
            countTotalDeliveryCharge +=
              res?.deliveryCharges[shopDeliveryCharge]
                .chargeByDeliveryMethods[0]?.deliveryCharge;

            const shopWiseDeliveryChange = {
              shopId: res?.deliveryCharges[shopDeliveryCharge].shopId,
              charge:
                res?.deliveryCharges[shopDeliveryCharge]
                  .chargeByDeliveryMethods[0]?.deliveryCharge,
            };
          }
          setDeliveryChargeInitial(countTotalDeliveryCharge);
          setDeliveryCharge(
            res?.deliveryCharges[0]?.chargeByDeliveryMethods[0]?.deliveryCharge
          );
        } else {
          if (res?.statusCode === 401 || !token) {
            //setToken('')
            //setUserData(null)
            //openModal()
            // setModalView('LOGIN_VIEW')
          } else {
            // addToast(
            //   (res as any)?.message || 'Something went wrong. Try again',
            //   {
            //     appearance: 'error',
            //   }
            // )
          }
          //removePromo()
        }
      });
  };
  const promoCodeVerify = async () => {
    const readyData = {
      products: selectedProducts?.map((product, productIndex) => ({
        productId: product.id,
        quantity: product.quantity,
        variationId: product.variation.variationId,
      })),
      type: serviceType,
      promoCode: promoCode || null,
    };
    fetch(`${process.env.REACT_APP_ORDER_API}/promo/v3`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      }),
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.statusCode === 200) {
          setUserPromoDiscount(res);
          setPromoDiscount(res?.discountAmount);
          setPromoDiscountCode(res?.promo?.promoCode);
        } else {
          if (res?.statusCode === 401 || !token) {
            //setToken('')
            //setUserData(null)
            //openModal()
            // setModalView('LOGIN_VIEW')
          } else {
            // addToast(
            //   (res as any)?.message || 'Something went wrong. Try again',
            //   {
            //     appearance: 'error',
            //   }
            // )
          }

          //removePromo()
        }
      });
  };
  useEffect(() => {
    if (promoCode) {
      promoCodeVerify();
    }
  }, [promoCode]);
  useEffect(() => {
    if (selectedProducts) {
      CheckDeliveryCharge();
    }
  }, [selectedProducts, countryId, stateId, cityId]);
  useEffect(() => {
    if (type === "JC_MART") {
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      fetch(
        `${encodedUri}/hub/nearest?type=${
          firstStepData?.type || type
        }&latitude=${currentLocation.lat}&longitude=${currentLocation.lng}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setUserHub(res?.hub?.id);
          setHubName(res?.hub?.name);
        })
        .catch((e) => {
          console.error("Error: Fetch Hub - ", e);
        });
    }
  }, [currentLocation.lat, currentLocation.lng, type]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      products: selectedProducts?.map((product: any) => ({
        productId: product.id,
        quantity: product.quantity * 1 || 1,
        variationId: product?.variation?.variationId,
      })),
      customerMobileNumber: `+88${firstStepData.customerMobileNumber?.replace(
        "+88",
        ""
      )}`,
      promoCode: promoCode || null,
      countryId: countryId,
      stateId: stateId,
      cityId: cityId,
      areaId: orderAreaId,
      suburbId: subUrbId,
      orderNote: firstStepData.orderNote,
      shippingAddress: firstStepData.shippingAddress + ", " + areaName,
      deliveryMethods: deliveryMethods?.map(
        (deliveryMethod: any, deliveryIndex: any) => ({
          methodName: deliveryMethod.methodName,
          schedule: {
            charge: deliveryMethod?.schedule?.charge || null,
            scheduleDeliveryTimeFrom:
              deliveryMethod?.schedule?.scheduleDeliveryTimeFrom || null,
            scheduleDeliveryTimeTo:
              deliveryMethod?.schedule?.scheduleDeliveryTimeTo || null,
          },
          scheduleDate: deliveryMethod.scheduleDate,
          scheduleDayName: deliveryMethod.scheduleDayName,
          shopId: deliveryMethod.shopId,
        })
      ),
      shippingLocation: {
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
      },
      type: serviceType,
    };
    await fetch(`${process.env.REACT_APP_ORDER_API}/admin/order/new-order`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...readyData,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Order Places Successfully", "success");
          form.resetFields();
          navigate(-1);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  const getUsersOptions = useCallback(async (mobileNumber: string) => {
    setUsersOptions({ loading: true, list: null });
    const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
    axios
      .get(
        `${encodedUri}/admin/user?` +
          `page=${0}` +
          `&limit=${20}` +
          (mobileNumber
            ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
            : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUsersOptions({
          loading: false,
          list: res.data?.users?.map((user: any) => ({
            label: user.fullName,
            value: user.mobileNumber.replace("+88", ""),
          })),
        });
      })
      .catch((err) => {
        setUsersOptions({ loading: false, list: [] });
        console.error("Users: Error", err);
      });
  }, []);

  const getLocationOptions = useCallback(async (key?: string) => {
    setLocationOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_MAP_API}`;
    const res = await axios.get(
      `${encodedUri}/autocomplete` + (key ? `?key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLocationOptions({
      loading: false,
      list: res?.data?.places,
    });
  }, []);

  const getArea = useCallback(async (key?: string) => {
    setAreaOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/area` + (key ? `?name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAreaOptions({
      loading: false,
      list: res?.data?.areas?.map((area: { name: any }) => {
        return {
          value: area?.name,
          label: area?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getArea();
  }, [getArea]);
  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "locations") getLocationOptions(value);
        if (field === "user") getUsersOptions(value);
        if (field === "area") getArea(value);
        if (field === "country") getCountryOptions(value);
        if (field === "state") getStateOptions(value);
        if (field === "city") getCityOptions(value);
        if (field === "areaes") getAreaOptions(value);
        if (field === "suburb") getSubUrbOptions(value);
      }
    };
    return debounce(loadOptions, 1200);
  }, [
    getLocationOptions,
    getUsersOptions,
    getArea,
    getCountryOptions,
    getStateOptions,
    getCityOptions,
    getAreaOptions,
    getSubUrbOptions,
  ]);
  useEffect(() => {
    getCountryOptions("");
    getStateOptions("");
    getCityOptions("");
    getAreaOptions("");
    getSubUrbOptions("");
  }, [
    getCountryOptions,
    getStateOptions,
    getCityOptions,
    getAreaOptions,
    getSubUrbOptions,
  ]);
  const onChangeManualOrderType = (e: RadioChangeEvent) => {
    setManualOrderType(e.target.value);
  };
  const hasProduct = (id: string) =>
    selectedProducts?.find((item: any) => item?.id === id);
  const steps = [
    {
      title: "Customers Info",
      content: (
        <Card size="default">
          <p>Create Manual Order</p>
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            layout="vertical"
            onFinish={onSubmitFirst}
            form={form} // like ref
          >
            <Radio.Group
              onChange={onChangeManualOrderType}
              value={manualOrderType}
            >
              <Radio value={1}>Inhouse Manual Order</Radio>
              <Radio value={2}>Out site Manual Order</Radio>
            </Radio.Group>
            <div className="grid grid-cols-2 gap-4">
              <Form.Item label="Full Name" name="fullName">
                <Input size="large" placeholder="Enter full name..." />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Customer Mobile Number"
                name="customerMobileNumber"
                rules={[
                  {
                    required: false,
                    message: "Mobile Number is required!",
                  },
                  {
                    validator: async (_, names) => {
                      if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                        return Promise.reject(
                          new Error("Enter valid mobile number")
                        );
                      }
                    },
                  },
                ]}
                initialValue={"01"}
              >
                <Select
                  allowClear
                  autoClearSearchValue={false}
                  showSearch
                  placeholder="Search Area"
                  optionFilterProp="children"
                  onSearch={(e) => handleSearch(e, "user")}
                  onSelect={(val: string) => setMobileNumber(val?.toString())}
                  filterOption={() => {
                    return true;
                  }}
                  loading={usersOptions.loading}
                  options={usersOptions?.list}
                  notFoundContent={
                    usersOptions?.loading ? <Spin size="small" /> : null
                  }
                ></Select>
              </Form.Item>
              <Form.Item
                hasFeedback
                label={`Country`}
                rules={[
                  {
                    required: false,
                    message: "Region is required!",
                  },
                ]}
                name="businessCountry"
              >
                <Select
                  showSearch
                  placeholder="Select Country"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch?.(val, "country");
                  }}
                  onChange={(val) => setCountryId(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={countryOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item
                name="state_search"
                label="State"
                rules={[
                  {
                    required: false,
                    message: "State is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by State`}
                  optionFilterProp="children"
                  onChange={(val) => setStateId(val)}
                  onSearch={(e) => handleSearch(e, "state")}
                  filterOption={() => {
                    return true;
                  }}
                  options={stateOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item
                hasFeedback
                label={`City`}
                rules={[
                  {
                    required: false,
                    message: "City is required!",
                  },
                ]}
                name="businessCity"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select City"
                  optionFilterProp="children"
                  onChange={(val) => setCityId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "city");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={cityOptions?.list}
                ></Select>
              </Form.Item>

              <Form.Item
                hasFeedback
                label={`Area`}
                rules={[
                  {
                    required: false,
                    message: "Area is required!",
                  },
                ]}
                name="orderArea"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Area"
                  optionFilterProp="children"
                  onChange={(val) => setOrderAreaId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "areaes");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={orderAreaOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item
                hasFeedback
                label={`Sub-Urb`}
                rules={[
                  {
                    required: false,
                    message: "Sub-Urb is required!",
                  },
                ]}
                name="subUrb"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Sub-Urb"
                  optionFilterProp="children"
                  onChange={(val) => setSubUrbId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "suburb");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={subUrbOptions?.list}
                ></Select>
              </Form.Item>
              
              <Form.Item
                label="Full Shipping Address"
                name="shippingAddress"
                rules={[
                  {
                    required: false,
                    message: "Full Shipping Address is required!",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Full Shipping Address" />
              </Form.Item>
              <Form.Item label="Note" name="orderNote">
                <Input size="large" placeholder="Enter note..." />
              </Form.Item>
            </div>
            {hubName && <h4>Hub: {hubName || "No Hub Found!"}</h4>}
          </Form>
        </Card>
      ),
    },
    {
      title: "Select Product",
      content: (
        <OrderProductList
          selectedProducts={selectedProducts}
          setSelectedProducts={async (products: any) => {
            setSelectedProducts(products);
          }}
          hubId={userHub}
          orderType={firstStepData?.type}
        />
      ),
    },
    {
      title: "Review & Place Order",
      content: (
        <div>
          <div
            className="order_details_area"
            style={{ maxHeight: `calc(100vh - 200px)` }}
          >
            <Row gutter={10} className="mt-0">
              <Col span={24}>
                <Card
                  title={<h4 className="mb-0">Review Order</h4>}
                  className="h-100"
                  size="small"
                >
                  <div className="product-information-tab-menu">
                    <Row gutter={20}>
                      <Col span={12}>
                        <h3 className="font-semibold text-gray-600 text-lg mb-2">
                          Customer Info
                        </h3>

                        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                          {firstStepData?.fullName && (
                            <div className="">
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Customer Name:
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {firstStepData?.fullName}
                              </dd>
                            </div>
                          )}

                          <div className="">
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Contact Number:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {firstStepData?.customerMobileNumber?.split(
                                "+88"
                              )}
                            </dd>
                          </div>

                          {firstStepData?.fullName && (
                            <div className="">
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Type:
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {type?.split("_").join(" ")}
                              </dd>
                            </div>
                          )}
                        </dl>
                      </Col>
                      <Col span={12}>
                        <h3 className="font-semibold text-gray-600 text-lg mb-2">
                          Delivery Address
                        </h3>
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                          {firstStepData?.shippingAddress && (
                            <div className="">
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Shipping Address:
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {firstStepData?.shippingAddress}
                              </dd>
                            </div>
                          )}
                        </dl>
                      </Col>
                    </Row>

                    <Form
                      name="control-hooks"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className="ant-form ant-form-vertical"
                      onFinish={onSubmit}
                      initialValues={
                        {
                          // ...order?.data,
                          // type: type || singleProductInfo?.data?.shop?.type,
                        }
                      }
                      form={form}
                      layout="vertical"
                      autoComplete="off"
                    >
                      <Row>
                        <Col span={24} className="mt-6">
                          <h3 className="font-semibold text-lg text-gray-700">
                            Selected Items
                          </h3>
                          {groupedMap && selectedProducts.length
                            ? [...groupedMap?.values()].map(
                                (item, i) => (
                                  (
                                    <div key={i} style={{ maxWidth: "100%" }}>
                                      <div className="flex-1 bg-accent-0">
                                        <div className="flex justify-between p-2 md:p-4 sm:p-4 border-b">
                                          <div>
                                            <div className="flex items-center gap-3">
                                              <div className="flex gap-3 items-center">
                                                <div className="w-16 h-16 rounded-full bg-accent-2 overflow-hidden shadow-md border border-accent-0">
                                                  <Avatar
                                                    size={45}
                                                    src={item?.shop?.logo}
                                                    shape="square"
                                                  />
                                                </div>

                                                <div className="flex flex-col">
                                                  <Link
                                                    href={`shops/${item?.shop?.slug}`}
                                                  >
                                                    <span className="text-lg text-accent-6 font-bold">
                                                      {item?.shop?.name}
                                                    </span>
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                            <div>Delivery Option</div>
                                          </div>
                                          <div className="flex flex-col text-right">
                                            <span
                                              className="text-sm"
                                              onClick={() => {
                                                handelModal("delivery-method"),
                                                  setDeliveryMethodShopId(
                                                    item?.shop?.id
                                                  ),
                                                  setShopViewAllDeliveryCharge(
                                                    item?.chargeByDeliveryMethods
                                                  );
                                              }}
                                            >
                                              <div className="flex flex-row gap-1 mt-8">
                                                <div>View all options</div>
                                                <div className="mt-0.5">
                                                  <Image
                                                    style={{ marginTop: 6 }}
                                                    width={18}
                                                    height={18}
                                                    src="/cartArrow.png"
                                                    alt="Logo"
                                                  />
                                                </div>
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="p-2 rounded-lg border border-gray-200 mt-5 mx-4">
                                          <div className="flex flex-row gap-1">
                                            <div className="mt-1">
                                              <Image
                                                width={18}
                                                height={18}
                                                src="/cartDelivery.png"
                                                alt="Logo"
                                              />
                                            </div>
                                            <div>
                                              Shipping Fee:
                                              {shopShippingMethod?.replace(
                                                "_",
                                                " "
                                              ) ||
                                                item.chargeByDeliveryMethods?.[0]?.methodName?.replace(
                                                  "_",
                                                  " "
                                                )}
                                              -BDT
                                              {item.chargeByDeliveryMethods?.[0]
                                                ?.deliveryCharge ||
                                                shipShippingCharge}
                                            </div>
                                          </div>
                                          <div className="flex flex-row gap-1">
                                            <div className="mt-1">
                                              <Image
                                                width={18}
                                                height={18}
                                                src="/cartShoppingBag.png"
                                                alt="Logo"
                                              />
                                            </div>
                                            <div className="text-blue-400">
                                              {item.chargeByDeliveryMethods?.[0]
                                                ?.message ||
                                                shopShippingMessage}
                                            </div>
                                          </div>
                                          <div>
                                            Estimated Delivery Time:{" "}
                                            {item.chargeByDeliveryMethods?.[0]
                                              ?.deliveryTime ||
                                              shopShippingDate}
                                          </div>
                                        </div>
                                        {/* product list show */}
                                        <table className={styles.mainTable}>
                                          <thead>
                                            <tr>
                                              <th scope="col">Product</th>
                                              <th scope="col">Barcode</th>
                                              <th scope="col">TP</th>
                                              <th scope="col">Price</th>
                                              <th scope="col">Quantity</th>
                                              <th scope="col">Total</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {item?.products?.length ? (
                                              item?.products?.map(
                                                (product: any, index: any) => (
                                                  <tr
                                                    className="border-t hover:bg-gray-100"
                                                    key={index}
                                                  >
                                                    <td className="flex items-center gap-2">
                                                      <Avatar
                                                        size={45}
                                                        src={
                                                          product?.productImage
                                                        }
                                                        shape="square"
                                                      />

                                                      <div className="flex flex-col">
                                                        <span className="font-medium text-gray-500">
                                                          {product?.name?.slice(
                                                            0,
                                                            50
                                                          )}
                                                        </span>
                                                        {product?.barCode && (
                                                          <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                                            <BarcodeOutlined />{" "}
                                                            {product?.barCode}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </td>

                                                    <td>
                                                      <small className="text-center">
                                                        {
                                                          product?.variation
                                                            ?.variationName
                                                        }
                                                        <br />
                                                        {product?.variation
                                                          ?.barCode ? (
                                                          <>
                                                            {" "}
                                                            <BarcodeOutlined />{" "}
                                                            {
                                                              product?.variation
                                                                ?.barCode
                                                            }
                                                          </>
                                                        ) : undefined}
                                                      </small>
                                                    </td>
                                                    <td>
                                                      <span>
                                                        &#2547;
                                                        {
                                                          product?.variation
                                                            ?.price?.tp
                                                        }
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      <small className="name">
                                                        &#2547;
                                                        {
                                                          product?.variation
                                                            ?.price?.mrp
                                                        }
                                                        {product?.variation
                                                          ?.price
                                                          ?.discountedPrice !==
                                                        product?.variation
                                                          ?.price?.mrp ? (
                                                          <>
                                                            <span className="text-danger px-1">
                                                              {product
                                                                ?.variation
                                                                ?.productDiscount
                                                                ?.flat ? (
                                                                <>
                                                                  {" - "}
                                                                  <del>
                                                                    &#2547;
                                                                    {
                                                                      product
                                                                        ?.variation
                                                                        ?.productDiscount
                                                                        ?.flat
                                                                    }
                                                                  </del>
                                                                </>
                                                              ) : undefined}
                                                              {product
                                                                ?.variation
                                                                ?.productDiscount
                                                                ?.percentage ? (
                                                                <>
                                                                  -
                                                                  <del>
                                                                    {
                                                                      product
                                                                        ?.variation
                                                                        ?.productDiscount
                                                                        ?.percentage
                                                                    }
                                                                    %
                                                                  </del>
                                                                </>
                                                              ) : undefined}
                                                            </span>
                                                            {product?.variation
                                                              ?.price
                                                              ?.discountedPrice ? (
                                                              <span className=" px-1">
                                                                {"= "}
                                                                &#2547;
                                                                {
                                                                  product
                                                                    ?.variation
                                                                    ?.price
                                                                    ?.discountedPrice
                                                                }
                                                              </span>
                                                            ) : (
                                                              <span className="px-1">
                                                                {"= "}
                                                                &#2547;
                                                                {
                                                                  product
                                                                    ?.variation
                                                                    ?.price?.mrp
                                                                }
                                                              </span>
                                                            )}
                                                          </>
                                                        ) : undefined}
                                                      </small>
                                                    </td>
                                                    <td>
                                                      <span className="">
                                                        {hasProduct(
                                                          product.id
                                                        ) && (
                                                          <span className="order_input_area">
                                                            <div className="flex items-center">
                                                              <Form.Item
                                                                hasFeedback
                                                                name="productId"
                                                                initialValue={
                                                                  product?.id
                                                                }
                                                                hidden
                                                              >
                                                                <Input
                                                                  type="hidden"
                                                                  style={{
                                                                    minWidth: 50,
                                                                  }}
                                                                  disabled={
                                                                    product?.quantity >=
                                                                    product
                                                                      ?.variation
                                                                      ?.stock
                                                                  }
                                                                />
                                                              </Form.Item>
                                                              <Button
                                                                onClick={() => {
                                                                  setSelectedProducts(
                                                                    (
                                                                      prevProducts: any
                                                                    ) => {
                                                                      const arr =
                                                                        Array.from(
                                                                          prevProducts
                                                                        );
                                                                      const newArr =
                                                                        arr.map(
                                                                          (
                                                                            item: any
                                                                          ) => {
                                                                            if (
                                                                              item?.id ===
                                                                              product?.id
                                                                            ) {
                                                                              if (
                                                                                item &&
                                                                                (
                                                                                  item as any
                                                                                )
                                                                                  .quantity >
                                                                                  1
                                                                              ) {
                                                                                return {
                                                                                  ...item,
                                                                                  quantity:
                                                                                    ((
                                                                                      item as any
                                                                                    )
                                                                                      .quantity ||
                                                                                      0) -
                                                                                    1,
                                                                                };
                                                                              } else {
                                                                                return item;
                                                                              }
                                                                            }
                                                                            return item;
                                                                          }
                                                                        );

                                                                      return newArr;
                                                                    }
                                                                  );
                                                                }}
                                                                disabled={
                                                                  product?.quantity <
                                                                  2
                                                                }
                                                                size="small"
                                                              >
                                                                <MinusOutlined />
                                                              </Button>

                                                              <Input
                                                                style={{
                                                                  margin:
                                                                    "0px !important",
                                                                  width: 70,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                                size="small"
                                                                name="quantity"
                                                                type="number"
                                                                value={
                                                                  hasProduct(
                                                                    product.id
                                                                  )?.quantity
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const qty =
                                                                    parseInt(
                                                                      e.target
                                                                        .value
                                                                    ) || 0;
                                                                  setSelectedProducts(
                                                                    (
                                                                      prevProducts: any
                                                                    ) => {
                                                                      const arr =
                                                                        Array.from(
                                                                          prevProducts
                                                                        );
                                                                      const newArr =
                                                                        arr.map(
                                                                          (
                                                                            item: any
                                                                          ) => {
                                                                            if (
                                                                              item?.id ===
                                                                              product?.id
                                                                            ) {
                                                                              if (
                                                                                qty >
                                                                                0
                                                                              ) {
                                                                                return {
                                                                                  ...item,
                                                                                  quantity:
                                                                                    qty,
                                                                                };
                                                                              } else {
                                                                                return item;
                                                                              }
                                                                            }
                                                                            return item;
                                                                          }
                                                                        );

                                                                      return newArr;
                                                                    }
                                                                  );
                                                                }}
                                                                min={1}
                                                                minLength={1}
                                                              />

                                                              <Button
                                                                size="small"
                                                                onClick={() => {
                                                                  setSelectedProducts(
                                                                    (
                                                                      prevProducts: any
                                                                    ) => {
                                                                      const arr =
                                                                        Array.from(
                                                                          prevProducts
                                                                        );
                                                                      const newArr =
                                                                        arr.map(
                                                                          (
                                                                            item: any
                                                                          ) => {
                                                                            if (
                                                                              item?.id ===
                                                                              product?.id
                                                                            ) {
                                                                              if (
                                                                                item &&
                                                                                (
                                                                                  item as any
                                                                                )
                                                                                  .quantity >
                                                                                  0
                                                                              ) {
                                                                                return {
                                                                                  ...item,
                                                                                  quantity:
                                                                                    ((
                                                                                      item as any
                                                                                    )
                                                                                      .quantity ||
                                                                                      0) +
                                                                                    1,
                                                                                };
                                                                              } else {
                                                                                return item;
                                                                              }
                                                                            }
                                                                            return item;
                                                                          }
                                                                        );

                                                                      return newArr;
                                                                    }
                                                                  );
                                                                }}
                                                                disabled={
                                                                  product?.quantity >=
                                                                  product
                                                                    ?.variation
                                                                    ?.stock
                                                                }
                                                              >
                                                                <PlusOutlined />
                                                              </Button>
                                                              <Button
                                                                type="dashed"
                                                                size="small"
                                                                shape="circle"
                                                                danger
                                                                className="ml-2"
                                                                onClick={async () => {
                                                                  await setSelectedProducts(
                                                                    (
                                                                      prevProducts: any
                                                                    ) => {
                                                                      const arr =
                                                                        Array.from(
                                                                          prevProducts
                                                                        );
                                                                      const newArr =
                                                                        arr.filter(
                                                                          (
                                                                            item: any
                                                                          ) =>
                                                                            item?.id !==
                                                                            product?.id
                                                                        );
                                                                      return newArr;
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <CloseOutlined />
                                                              </Button>
                                                            </div>
                                                          </span>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span className="text-right font-semibold text-gray-600">
                                                        {product?.variation
                                                          ?.price
                                                          ?.discountedPrice ? (
                                                          <span className="name">
                                                            {product?.variation
                                                              ?.price?.mrp *
                                                              product?.quantity}
                                                            {""}&#2547;
                                                          </span>
                                                        ) : (
                                                          <span className="name">
                                                            {product?.variation
                                                              ?.price?.mrp *
                                                              product?.quantity}{" "}
                                                            &#2547;
                                                          </span>
                                                        )}
                                                      </span>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td>
                                                  <Empty />
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                        {/* product list show end */}
                                      </div>
                                    </div>
                                  )
                                )
                              )
                            : null}
                          <div
                            className="content-body"
                            style={{
                              maxHeight: "60vh",
                              height: "60vh",
                              overflow: "scroll",
                            }}
                          >
                            <div>
                              <Form.Item
                                label="Apply Promo Code"
                                name="applyPromoCode"
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Apply Promo Code..."
                                  onBlur={(e) => setPromoCode(e.target.value)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row justify="end" align="top">
                        <Col span={9} className="mb-5 ml-auto max-w-xs">
                          <div className="flex justify-between py-1">
                            <span className="font-semibold">Sub Total:</span>
                            <span className="info-desc text-right">
                              &#2547;
                              {selectedProducts?.reduce((a, b) => {
                                return (
                                  a +
                                  b?.quantity *
                                    (b?.variation?.price?.mrp ||
                                      b?.variation?.price?.mrp)
                                );
                              }, 0)}
                            </span>
                          </div>
                          <div className="flex justify-between py-1">
                            <span className="font-semibold">
                              Delivery Charge :
                            </span>
                            <span className="info-desc text-right">
                              &#2547;{deliveryChargeInitial}
                            </span>
                          </div>
                          <div className="flex justify-between py-1">
                            <span className="font-semibold">
                              Promo Code Discount ({promoDiscountCode})
                            </span>
                            <span className="info-desc text-right">
                              - &#2547;{promoDiscount}
                            </span>
                          </div>
                          <div className="flex justify-between py-1">
                            <span className="font-semibold">Discount: </span>
                            <span className="info-desc text-right">
                              &#2547;
                              {selectedProducts?.reduce((a, b) => {
                                return (
                                  a +
                                  b?.quantity *
                                    (b?.variation?.price?.mrp -
                                      (b?.variation?.price?.discountedPrice ||
                                        b?.variation?.price
                                          ?.sellerDiscountedPrice ||
                                        0))
                                );
                              }, 0)}
                            </span>
                          </div>
                          <div className="flex justify-between py-1">
                            <span className="font-semibold">Total:</span>
                            <span className="info-desc text-right">
                              &#2547;
                              {
                                selectedProducts?.reduce((a, b) => {
                                  return (
                                    a +
                                    b?.quantity *
                                      (b?.variation?.price?.discountedPrice ||
                                        b?.variation?.price?.mrp)
                                  );
                                }, 0) +
                                  deliveryChargeInitial -
                                  promoDiscount
                                // + firstStepData?.deliveryCharge +
                                //   firstStepData?.vat
                              }
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      <BreadCrumb title="Create Order" />
      <div className="mt-4">
        <Row gutter={10}>
          <Col span={24}>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content mt-3">{steps[current].content}</div>
            <div className="steps-action_ mt-auto">
              {current > 0 && (
                <Button size="large" className="mr-3" onClick={() => prev()}>
                  Back
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  size="large"
                  className="bg-green-500"
                  type="primary"
                  onClick={() => {
                    if (current === 0) {
                      form.validateFields();
                      form.submit();
                    } else {
                      next();
                    }
                  }}
                  disabled={
                    (current === 1 && selectedProducts?.length === 0) ||
                    (type === "JC_MART" && !userHub)
                  }
                >
                  <span className="px-6">Next 11</span>
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type="primary" danger size="large" onClick={onSubmit}>
                  Place Order
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {type == "delivery-method" ? (
                  <>
                    {scheduleDelivery ? (
                      <div className="mx-5 w-96">
                        <div className="flex flex-row justify-between my-4">
                          <div
                            onClick={() => setScheduleDelivery(false)}
                            className="cursor-pointer font-bold flex flex-row"
                          >
                            <Image
                              width={25}
                              height={16}
                              src="/left.png"
                              alt="Logo"
                            />
                          </div>
                          <div className="font-bold text-lg">
                            Delivery Method
                          </div>
                          <div
                            onClick={() => setShowModal(false)}
                            className="cursor-pointer"
                          >
                            X
                          </div>
                        </div>
                        <Tabs defaultActiveKey="0">
                          {shopAllDeliveryMethod?.map(
                            (deliveryMethod: any, deliveryMethodIndex: any) => (
                              <TabPane
                                tab={deliveryMethod?.businessDayName}
                                key={deliveryMethodIndex}
                              >
                                <Radio.Group onChange={onChange5555}>
                                  {deliveryMethod?.chargeBySchedules?.map(
                                    (
                                      deliverySchedule: any,
                                      deliveryScheduleIndex: any
                                    ) => (
                                      <Radio
                                        value={deliverySchedule?.charge}
                                        key={deliveryScheduleIndex}
                                        checked={
                                          deliveryScheduleIndex ==
                                          deliveryMethodChekedFromRequest
                                        }
                                        onClick={() =>
                                          myFunction(
                                            deliveryMethodShopId,
                                            deliverySchedule,
                                            "SCHEDULE_DELIVERY",
                                            null
                                          )
                                        }
                                        className="flex flex-row justify-between"
                                      >
                                        <div className="flex flex-row gap-x-2">
                                          <div className="float-left">
                                            {
                                              deliverySchedule?.scheduleDeliveryTimeFrom
                                            }{" "}
                                            -{" "}
                                            {
                                              deliverySchedule?.scheduleDeliveryTimeTo
                                            }
                                          </div>
                                          <div className="float-right">
                                            {" "}
                                            BDT {deliverySchedule?.charge}
                                          </div>
                                        </div>
                                      </Radio>
                                    )
                                  )}
                                </Radio.Group>
                              </TabPane>
                            )
                          )}
                        </Tabs>
                        <div className="my-5 bg-accent text-white rounded-lg py-2 text-center">
                          Submit
                        </div>
                      </div>
                    ) : (
                      <div className="mx-5 w-96">
                        <div className="flex flex-row justify-between my-4">
                          <div className="font-bold text-lg">
                            Delivery Method
                          </div>
                          <div
                            onClick={() => setShowModal(false)}
                            className="cursor-pointer"
                          >
                            X
                          </div>
                        </div>
                        <div className="h-0.5 my-2 bg-gray-200"></div>
                        <div className="mt-2">
                          {shopViewAllDeliveryCharge?.map(
                            (
                              shopDeliveryMethod: any,
                              shopDeliveryMethodIndex: any
                            ) => (
                              <div
                                className="flex flex-row rounded-lg border border-gray-300 justify-between mt-2"
                                onClick={() =>
                                  myFunction(
                                    deliveryMethodShopId,
                                    null,
                                    shopDeliveryMethod?.methodName,
                                    shopDeliveryMethod?.deliveryCharge
                                  )
                                }
                              >
                                <div className="p-2">
                                  <div className="font-bold">
                                    {shopDeliveryMethod?.methodName?.replace(
                                      "_",
                                      " "
                                    )}
                                  </div>
                                  <div>{shopDeliveryMethod?.deliveryTime}</div>
                                </div>
                                {shopDeliveryMethod?.methodName ===
                                "SCHEDULE_DELIVERY" ? (
                                  <div
                                    className="pt-4 pr-4"
                                    key={shopDeliveryMethodIndex}
                                    onClick={() => {
                                      setScheduleDelivery(true),
                                        setShopAllDeliveryMethod(
                                          shopDeliveryMethod?.schedules
                                        );
                                    }}
                                  >
                                    <Image
                                      width={16}
                                      height={16}
                                      src="/arrow_black.png"
                                      alt="Logo"
                                    />
                                  </div>
                                ) : (
                                  <div className="pt-4 pr-4">
                                    BDT {shopDeliveryMethod?.deliveryCharge}
                                  </div>
                                )}
                              </div>
                            )
                          )}
                          <div className="flex flex-row justify-between w-52 my-4">
                            <div className="border border-gray-300 rounded-lg bg-white px-6 py-2">
                              cancel
                            </div>
                            <div className="border border-gray-300 rounded-lg bg-accent text-white px-6 py-2">
                              Confirm
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {type == "add-bags" ? (
                  <div className="mx-5 w-auto">
                    <div className="flex flex-row justify-between my-5">
                      <div className="font-bold text-lg">
                        Add Bags & Package
                      </div>
                      <div
                        onClick={() => setShowModal(false)}
                        className="cursor-pointer"
                      >
                        X
                      </div>
                    </div>

                    <div></div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </React.Fragment>
  );
};
OrderCreate.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default OrderCreate;
