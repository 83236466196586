import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopShippingPackageList from "../../../components/Shop/ShippingPackage/index";

const ShopShippingPackagePage = () => {
  return (
    <MainLayout>
      <ShopShippingPackageList />
    </MainLayout>
  );
};

export default ShopShippingPackagePage;
