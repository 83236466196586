import React from "react";
import AreaBulkUploadList from "../../components/Area/AreaBulkUpload";
import MainLayout from "../../components/Layouts";

const AreaBulkUploadPage = () => {
  return (
    <MainLayout>
      <AreaBulkUploadList />
    </MainLayout>
  );
};

export default AreaBulkUploadPage;
