import React from "react";
import StateBulkUploadList from "../../components/State/StateBulkUpload";
import MainLayout from "../../components/Layouts";

const StateBulkUploadPage = () => {
  return (
    <MainLayout>
      <StateBulkUploadList />
    </MainLayout>
  );
};

export default StateBulkUploadPage;
