import React from "react";
import CityBulkUploadList from "../../components/City/CityBulkUpload";
import MainLayout from "../../components/Layouts";

const CityBulkUploadPage = () => {
  return (
    <MainLayout>
      <CityBulkUploadList />
    </MainLayout>
  );
};

export default CityBulkUploadPage;
