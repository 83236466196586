import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Drawer,
  Empty,
  Form,
  Modal,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import { isAllowedService } from "../../utils/services";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleBanner from "./Details";
import AddBanner from "./AddTag";
import moment from "moment";
import React from "react";
import TagShopList from "./TagShopList";
import { debounce } from "lodash";

const BannerList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedTag, setSelectedTag] = useState(undefined);
  const [selectedTagForEdit, setSelectedTagForEdit] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shopId, setShopId] = useState<any>();
  const [selectedTagId, setSelectedTagId] = useState<any>(undefined);
  const [selectedShopForAssign, setSelectedShopForAssign] =
    useState<any>(undefined);
  const [tagData, setTagData] = useState<any>({
    loading: false,
    data: null,
  });
  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
    setIsModalVisible(false);
  };
  const getTags = useCallback(async () => {
    setTagData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(`${encodedUri}/tag?type=${type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTagData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTagData({ loading: false, data: [] });
        console.error("Tags: Error", err);
      });
  }, []);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=100` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: { name: any; id: any }) => ({
              label: shop.name,
              value: shop.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );
  useEffect(() => {
    getShopOptions("");
  }, [getShopOptions]);
  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/tag`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getTags();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      shops: data.shopId.map((shop: any, index: string) => ({
        shopId: shop,
        displayOrder: 0,
      })),
      tagId: selectedShopForAssign?.id,
    };

    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/tag-shop/add-multi-shop-by-tag`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Shop Assigned Successfully", "success");
          info(res?.tagMultiShopAddResponses);
          onClose();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const onTagRemove = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/tag?tagId=${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tagId: id,
            deleted: true,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Tag Remove Successfully", "success");
            getTags();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "shop") getShopOptions(value);
      }
    };

    return debounce(loadOptions, 500);
  }, [getShopOptions]);

  const onClose = () => {
    form.resetFields();
    getTags();
    setVisible(undefined);
    setSelectedTag(undefined);
    setSelectedTagForEdit(undefined);
    setSelectedShopForAssign(undefined);
    setSelectedTagId(undefined);
  };

  const handleOk = () => {
    setSelectedShopForAssign(false);
  };

  function info(alreadyExistsData: any) {
    Modal.info({
      title: "Assign Shop Info",
      content: (
        <>
          {alreadyExistsData?.map((data, i) => (
            <div key={i} className="mb-3">
              <p className="text-gray-800 font-semibold">
                Added {data?.newAdded} shop(s) and already exists{" "}
                {data?.alreadyExists} shop(s) in tag -{" "}
                <span className="font-bold text-red-900">{data?.tagName}</span>
              </p>
              <ol className="text-gray-500">
                {data?.alreadyExistsShopName?.map((shop, index) => (
                  <li key={index}>
                    {index + 1}. {shop}
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </>
      ),
      width: 600,
      onOk() {},
      okText: "GOT IT",
      okButtonProps: { danger: true },
    });
  }

  return (
    <>
      <BreadCrumb
        title="Tag List"
        subTitle={`${
          tagData?.data?.totalElements || tagData?.data?.tags?.length || 0
        } Tag(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {tagData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Assign Shops</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {tagData?.data?.tags?.length ? (
                      tagData?.data?.tags?.map((tag: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() => setSelectedTag(tag)}
                        >
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {tag?.name}
                            </span>
                          </td>
                          <td>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              defaultChecked={tag?.isActive}
                              onChange={(val, e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onStatusChange(tag?.id, val);
                              }}
                              loading={
                                confirmLoading && tag?.id === confirmLoading
                              }
                            />
                          </td>
                          <td>
                            <small
                              className={`font-capitalize ${
                                moment().diff(tag?.updatedAt, "minutes") >= 60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(tag?.updatedAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(tag?.updatedAt).format("lll")}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {tag?.updatedBy?.name ||
                                tag?.updatedBy?.mobileNumber}
                            </span>
                          </td>
                          <td>
                            <Button
                              shape="round"
                              type="primary"
                              danger
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSelectedShopForAssign(tag);
                              }}
                            >
                              Assign Shop
                            </Button>
                          </td>
                          <td>
                            <div className="flex gap-2">
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={() => setSelectedTagForEdit(tag)}
                              >
                                <EditOutlined />
                              </Button>
                              <Button
                                title="Shop List"
                                shape="round"
                                type="primary"
                                danger
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSelectedTagId(tag?.id);
                                }}
                              >
                                <UnorderedListOutlined />
                              </Button>
                              {isAllowedService(`Tags Delete`) && (
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={tag?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onTagRemove(tag?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(tag?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...tagData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        title="Tag Shops"
        onCancel={onClose}
        open={selectedTagId}
        width={700}
        centered
        footer={false}
      >
        <TagShopList onCloseMethod={onClose} visibleData={selectedTagId} />
      </Modal>

      <Modal
        title="Assign Shop"
        onCancel={onClose}
        open={selectedShopForAssign}
        onOk={handleOk}
        width={700}
        centered
        footer={false}
      >
        <div>
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            onFinish={onSubmit}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              hasFeedback
              label="Select Shops"
              name="shopId"
              rules={[
                {
                  required: true,
                  message: "Pleasse Select Minimum One Shop!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Select Shop"
                optionFilterProp="children"
                onChange={(val) => setShopId(val)}
                onSearch={(e) => handleSearch(e, "shop")}
                filterOption={(input, option) => {
                  return true;
                }}
                options={shopOptions?.list}
                loading={shopOptions?.loading}
                mode="multiple"
              ></Select>
            </Form.Item>
            <div className="buttons-container mt-3">
              <Button
                type="primary"
                htmlType="submit"
                className="add-submit-btn text-center mr-2"
              >
                Submit
              </Button>
              <Button
                type="ghost"
                htmlType="button"
                onClick={() => form?.resetFields()}
                className="reset-submit-btn text-center mr-2"
              >
                Reset
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Drawer
        title={
          visible ? "Add Tag" : selectedTagForEdit ? "Edit Tag" : "Tag Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedTag || selectedTagForEdit}
        width={450}
      >
        {visible || selectedTagForEdit ? (
          <AddBanner onCloseMethod={onClose} visibleData={selectedTagForEdit} />
        ) : (
          <SingleBanner BannerDetails={selectedTag} />
        )}
      </Drawer>
    </>
  );
};

export default BannerList;
function setLoading(arg0: boolean) {
  throw new Error("Function not implemented.");
}
