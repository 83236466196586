import React from "react";
import MainLayout from "../../../components/Layouts";
import CorporateTopUpApprovedList from "../../../components/Corporate/TopUp/RequestedTopupList";

const CorporateTopUpApprovedPage = () => {
  return (
    <MainLayout>
      <CorporateTopUpApprovedList />
    </MainLayout>
  );
};

export default CorporateTopUpApprovedPage;
