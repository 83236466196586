import React from "react";
import VehicleTypeList from "../../../components/Vehicles/Types/index";
import MainLayout from "../../../components/Layouts";

const VehicleTypeListPage = () => {
  return (
    <MainLayout>
      <VehicleTypeList />
    </MainLayout>
  );
};

export default VehicleTypeListPage;
