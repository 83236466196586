import React from "react";
import { ReactElement } from "react";
import DriverVehicleInfoList from "../../../components/Delivery/DeliveryHero/VehicleInfo";
import MainLayout from "../../../components/Layouts";

const DriverVehicleInfoPage = (): ReactElement => {
  return (
    <MainLayout>
      <DriverVehicleInfoList />
    </MainLayout>
  );
};

export default DriverVehicleInfoPage;
