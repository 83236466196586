import {
  AutoComplete,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  TimePicker,
  Tooltip,
} from "antd";

import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getParamValue, priceCalculationType, ShopStatus } from "../../utils";
import { responseNotification } from "../../utils/notify";
import { useLocation, useNavigate, useParams } from "react-router";
import ShopLayout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { SET_SHOP_INFO } from "../../redux/auth/authType";
import React from "react";
import moment from "moment";
import { ImageUploader, MapBox, SubmitResetBtn } from "../common";
import { regions } from "../common/Region";
const { TextArea } = Input;
import dayjs from "dayjs";

const AddShop = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef<any>(0);
  const route = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [generatedBankImgUrl, setGeneratedBankImgUrl] = useState<string>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [getBankType, setGetBankType] = useState("BANKING");
  const [isDefault, setIsDefault] = useState(false);
  const [priceCalcType, setPriceCalcType] = useState("SHOP_BASED_COMMISSION");
  const [selectedStartTime, setSelectedStartTime] = useState("00:00");
  const [selectedCloseTime, setSelectedCloseTime] = useState("00:00");
  const [startAt, setStartAt] = useState<any>("00:00");
  const [endAt, setEndAt] = useState<any>("00:00");
  const [businessType, setBusinessType] = useState<any>();
  const [serviceId, setServiceId] = useState<any>();
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [pickUp, setPickUp] = useState(false);
  const [dineIn, setDineIn] = useState(false);
  const [commissionType, setCommissionType] = useState("PERCENTAGE");
  const [discountType, setDiscountType] = useState<any>(null);
  const [subsidyUpdateType, setSubsidyUpdateType] = useState(null);
  const [limit, setLimit] = useState(16);
  const [key, setKey] = useState("");
  const [companyId, setCompanyId] = useState<any>();
  const [countryId, setCountryId] = useState<any>("");
  const [stateId, setStateId] = useState<any>();
  const [cityId, setCityId] = useState<any>();
  const [areaId, setAreaId] = useState<any>();
  const [subUrbId, setSubUrbId] = useState<any>();

  const [operationType, setOperationType] = useState<any>("INDIVIDUAL");
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [areaOptions, setAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [subUrbOptions, setSubUrbOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [merchantId, setMerchantId] = useState<any>();
  const [expireDate, setExpireDate] = useState<DatePickerProps["value"]>();

  const [shopData, setShopData] = useState<any>({ loading: false, data: null });

  const [merchantOptions, setMerchantOptions] = useState({
    loading: false,
    list: [],
  });
  const [serviceOptions, setServiceOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [companyOptions, setCompanyOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [singleMerchanrInfo, setSingleMerchantInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [businessTypeOptions, setBusinessTypeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [logoUrl, setLogoUrl] = useState<string>();
  const [bannerUrl, setBannerUrl] = useState<string>();
  const [bankStartUpload, setBankStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [geoAddress, setGeoAddress] = useState<any>({
    loading: false,
    data: null,
  });

  const [locationOptions, setLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [locationListOptions, setLocationListOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [currentLocation, setCurrentLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });
  // **************************************

  // **************************************
  const getShopLocationOptions = useCallback(async (key?: any) => {
    setLocationOptions({ loading: true, list: [] });
    const res = await axios.get(
      `https://map.ejachai.com/api/v1/autocomplete` +
        (key ? `?key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setLocationOptions({
      loading: false,
      list: res?.data?.places,
    });

    setLocationListOptions({
      loading: false,
      list: res?.data?.places?.map((place: any) => {
        return {
          value: place?.address,
          label: place?.address,
        };
      }),
    });
  }, []);

  const getCountryOptions = useCallback(async (countryName?: string) => {
    setCountryOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (countryName ? `&key=${countryName}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);
  const getStateOptions = useCallback(
    async (key?: string) => {
      setStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${countryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [countryId]
  );
  const getCityOptions = useCallback(
    async (key?: string) => {
      setCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${stateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [stateId]
  );
  const getAreaOptions = useCallback(
    async (key: any) => {
      setAreaOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/area/all?cityId=${cityId}&page=0&limit=20`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setAreaOptions({
            loading: false,
            list: res.data?.areas?.map((area: any) => ({
              label: area.name,
              value: area.id,
            })),
          });
        })
        .catch((err) => {
          setAreaOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [cityId]
  );
  const getSubUrbOptions = useCallback(
    async (key: any) => {
      setSubUrbOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/suburb/all?areaId=${areaId}&page=0&limit=20`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setSubUrbOptions({
            loading: false,
            list: res.data?.suburbs?.map((suburb: any) => ({
              label: suburb.name,
              value: suburb.id,
            })),
          });
        })
        .catch((err) => {
          setSubUrbOptions({ loading: false, list: [] });
          console.error("suburbs: Error", err);
        });
    },
    [areaId]
  );
  const getBusinessTypeOptions = useCallback(async (key?: string) => {
    setBusinessTypeOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/service-type-list?page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBusinessTypeOptions({
      loading: false,
      list: res?.data?.businessTypes?.map((business: any) => {
        return {
          value: business?.id,
          label: business?.name,
        };
      }),
    });
  }, []);

  const businessServiceOption = useCallback(
    async (key?: string) => {
      setServiceOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/details-business-type?id=${businessType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setServiceOptions({
        loading: false,
        list: res?.data?.businessType?.serviceTypes?.map((service) => {
          return {
            value: service?.value,
            label: service?.name,
          };
        }),
      });
    },
    [businessType]
  );
  const companyOption = useCallback(async (key?: string) => {
    setCompanyOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/company-list?page=0&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCompanyOptions({
      loading: false,
      list: res?.data?.companies?.map((company) => {
        return {
          value: company?.id,
          label: company?.name,
        };
      }),
    });
  }, []);

  const fetchMerchantDetails = useCallback((getMerchantId: any) => {
    if (getMerchantId) {
      try {
        setSingleMerchantInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/admin/merchant/merchant/details?merchantId=${getMerchantId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleMerchantInfo({
                loading: false,
                data: data?.data?.merchant,
              });
            } else {
              setSingleMerchantInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleMerchantInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleMerchantInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [form, startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    if (logoUrl || bannerUrl) {
      setLoading(true);

      const readyData = data && {
        address: key || data.address,
        area: data.area,
        bankInfo: {
          accountName: data.accountName,
          accountNumber: data.accountNumber,
          bankName: data.bankName,
          branchName: data.branchName,
          routingNumber: data.routingNumber,
          swiftCode: data.swiftCode,
        },
        basePriceCalculationType: priceCalcType,
        businessTypeId: businessType,
        type: serviceId,
        businessOperationType: operationType,
        companyId: companyId,
        companyName: data.companyName,
        banner: bannerUrl || data.banner,
        baseDeliveryCharge: data.baseDeliveryCharge * 1,
        contactNumber: `+88${data?.contactNumber?.replace("+88", "")}`,
        countryId: countryId || null,
        stateId: stateId || null,
        cityId: cityId || null,
        description: data.description,
        displayOrder: parseInt(data.displayOrder),
        email: data.email,
        hubId: data.hubId,
        isFreeDelivery: freeDelivery || false,
        dineIn: dineIn || false,
        pickUp: pickUp || false,
        isVerified: data.isVerified || false,
        numberOfFollowers: data.numberOfFollowers,
        keywords: data.keywords,
        promo: data.promo,
        promoDescription: data.promoDescription,
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        vat: data.vat,
        location: {
          latitude: data.latitude * 1,
          longitude: data.longitude * 1,
        },
        flashDiscount: {
          discountAmount: data.discountAmount,
          discountType: data.discountType,
          expireDate: expireDate || data.expireDate,
        },
        logo: logoUrl || data.logo,
        merchantId: data.merchantId,
        minimumAmountForFreeDelivery: freeDelivery
          ? null
          : parseInt(data.minimumAmountForFreeDelivery),
        mobileNumber: `+88${data?.mobileNumber?.replace("+88", "")}`,
        name: data.name,
        prepareTime: data.prepareTime * 1,
        status: data.status,
        startAt: selectedStartTime || data.selectedStartTime,
        endAt: selectedCloseTime || data.selectedCloseTime,
        productSubsidyUpdateType: data.productSubsidyUpdateType,
        commissionType: data.commissionType,
        commissionAmount: parseFloat(data.commissionAmount),
        subsidy: parseFloat(data.subsidy),
      };

      if (shopData.data) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: !shopData?.data?.hub?.id && shopData?.data?.id,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Shop Updated Successfully", "success");
              form.resetFields();
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/create-shop-from-admin`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...readyData, isActive: false }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Shop Create Successfully", "success");
              form.resetFields();
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Shop Logo & Banner required`, "error");
    }
  };

  const getMerchantOptions = useCallback(
    async (mobileNumber?: string) => {
      setMerchantOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      return axios
        .get(
          `${encodedUri}/admin/merchant/all-super-merchant` +
            `?page=${page || 0}` +
            (limit ? `&limit=${limit || 16}` : ``) +
            (mobileNumber ? `&mobileNumber=${mobileNumber}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setMerchantOptions({
            loading: false,
            list: res.data?.superMerchant?.map((merchant: any) => ({
              label: `${
                merchant?.fullName?.toUpperCase() ||
                merchant?.merchantPersonalDetail?.name?.toUpperCase()
              } - ${merchant?.mobileNumber?.replace("+88", "")}`,
              value: merchant?.id,
            })),
          });
        })
        .catch((err) => {
          setMerchantOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page]
  );

  const fetchGeoAddress = useCallback(() => {
    try {
      setGeoAddress({ loading: true, data: null });
      axios
        .get(
          `${process.env.REACT_APP_MAP_API}/geocode?latitude=${currentLocation?.lat}&longitude=${currentLocation?.lng}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setGeoAddress({
              loading: false,
              data: data?.data?.place,
            });
          } else {
            setGeoAddress({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setGeoAddress({ loading: false, data: null });
        });
    } catch (error) {
      setGeoAddress({ loading: false, data: null });
    }
  }, [currentLocation?.lat, currentLocation?.lng]);

  const fetchShopDetails = useCallback((getShopId: any) => {
    if (getShopId) {
      try {
        setShopData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/shop/details?shopId=${getShopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              const obj: any = {};
              Object.keys(data?.data?.shop).map((key) => {
                if (
                  key === "createdBy" ||
                  key === "updatedBy" ||
                  key === "hub" ||
                  key === "merchant"
                )
                  return;
                const item = data.data.shop?.[key];

                if (item && typeof item === "object") {
                  if (key === "location") {
                    obj["latitude"] = item?.y;
                    obj["longitude"] = item?.x;
                  } else {
                    Object.keys(item).map((key) => {
                      if (key !== "type") {
                        const itemNested = item?.[key];
                        obj[key] = itemNested;
                      }
                    });
                  }
                }
                obj[key] = item;
              });

              setShopData({
                loading: false,
                data: obj,
              });
              setLogoUrl(data?.data?.shop?.logo);
              setBannerUrl(data?.data?.shop?.banner);
              setBusinessType(data?.data?.shop?.businessTypeId);
              setServiceId(data?.data?.shop?.type);
              setCountryId(data?.data?.shop?.countryId);
              setPriceCalcType(data?.data?.shop?.basePriceCalculationType);
              setDiscountType(data?.data?.shop?.flashDiscount?.discountType);
              setSelectedStartTime(data?.data?.shop?.startAt || "00:00");
              setSelectedCloseTime(data?.data?.shop?.endAt || "00:00");

              dispatch({
                type: SET_SHOP_INFO,
                payload: {
                  shopInfo: {
                    id: data?.data?.shop?.id,
                    name: data?.data?.shop?.name,
                    type: data?.data?.shop?.type,
                    mobileNumber: data?.data?.shop?.mobileNumber,
                    logo: data?.data?.shop?.logo,
                    banner: data?.data?.shop?.banner,
                    rating: data?.data?.shop?.rating,
                    status: data?.data?.shop?.status,
                    hubId: data?.data?.shop?.hub?.id,
                    merchantId: data?.data?.shop?.merchantId,
                    deleted: data?.data?.shop?.deleted,
                  },
                },
              });
            } else {
              setShopData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })

          .catch((err) => {
            setShopData({ loading: false, data: null });
          });
      } catch (error) {
        setShopData({ loading: false, data: null });
      }
    }
  }, []);

  const handleSearch = useMemo<any>(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef!.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "merchant") getMerchantOptions(value);
        if (field === "country") getCountryOptions(value);
        if (field === "state") getStateOptions(value);
        if (field === "city") getCityOptions(value);
        if (field === "area") getAreaOptions(value);
        if (field === "suburb") getSubUrbOptions(value);
        if (field === "locations") getShopLocationOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getShopLocationOptions,
    getCountryOptions,
    getStateOptions,
    getCityOptions,
    getAreaOptions,
    getSubUrbOptions,
    getMerchantOptions,
  ]);

  useEffect(() => {
    fetchShopDetails((route as any)?.shopId);
  }, [fetchShopDetails]);

  useEffect(() => {
    getCountryOptions("");
    getStateOptions();
    getCityOptions();
    getAreaOptions("");
    getSubUrbOptions("");
  }, [
    getCountryOptions,
    getStateOptions,
    getCityOptions,
    getAreaOptions,
    getSubUrbOptions,
  ]);
  useEffect(() => {
    fetchMerchantDetails(merchantId);
  }, [fetchMerchantDetails, merchantId]);

  useEffect(() => {
    getShopLocationOptions();
  }, [getShopLocationOptions]);

  useEffect(() => {
    getMerchantOptions();
    getBusinessTypeOptions();
    businessServiceOption();
    companyOption();
  }, [
    getMerchantOptions,
    getBusinessTypeOptions,
    businessServiceOption,
    companyOption,
  ]);

  useEffect(() => {
    if (shopData.data) {
      form.resetFields(Object.keys(shopData.data as any));
      form.resetFields();
      setLogoUrl(shopData?.data?.logo);
      setBannerUrl(shopData?.data?.banner);
      setBusinessType(shopData?.data?.businessTypeId);
      setPriceCalcType(shopData?.data?.basePriceCalculationType);
      setServiceId(shopData?.data?.type);
      setCountryId(shopData?.data?.countryId);
      setStateId(shopData?.data?.stateId);
      setCityId(shopData?.data?.cityId);
      setCurrentLocation({
        lat: shopData?.data?.location?.y,
        lng: shopData?.data?.location?.x,
      });
      setBusinessTypeOptions({
        loading: false,
        list: [
          {
            value: shopData?.data?.business?.id,
            label: shopData?.data?.business?.name,
          },
          ...(businessTypeOptions.list?.filter(
            (item) => item.value !== shopData?.data?.business?.id
          ) || []),
        ],
      });
      setServiceOptions({
        loading: false,
        list: [
          {
            value: shopData?.data?.service?.service,
            label: shopData?.data?.service?.name,
          },
          ...(serviceOptions.list?.filter(
            (item) => item.value !== shopData?.data?.service?.service
          ) || []),
        ],
      });
      // setCountryOptions({
      //   loading: false,
      //   list: [
      //     {
      //       value: shopData?.data?.country?.id,
      //       label: shopData?.data?.country?.name,
      //     },
      //     ...(countryOptions.list?.filter(
      //       (item) => item.value !== shopData?.data?.country?.id
      //     ) || []),
      //   ],
      // });
      // setStateOptions({
      //   loading: false,
      //   list: [
      //     {
      //       value: shopData?.data?.state?.id,
      //       label: shopData?.data?.state?.name,
      //     },
      //     ...(stateOptions.list?.filter(
      //       (item) => item.value !== shopData?.data?.state?.id
      //     ) || []),
      //   ],
      // });
      // setCityOptions({
      //   loading: false,
      //   list: [
      //     {
      //       value: shopData?.data?.city?.id,
      //       label: shopData?.data?.city?.name,
      //     },
      //     ...(cityOptions.list?.filter(
      //       (item) => item.value !== shopData?.data?.city?.id
      //     ) || []),
      //   ],
      // });
    }
  }, [form, shopData]);

  useEffect(() => {
    if (
      shopData?.data &&
      currentLocation.lat !== shopData?.data?.location?.y &&
      currentLocation.lng !== shopData?.data?.location?.x
    ) {
      fetchGeoAddress();
    }
  }, [fetchGeoAddress, currentLocation]);

  const resetData = () => {
    // form?.resetFields();
    setLogoUrl(undefined);
    setBannerUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  useEffect(() => {
    form.resetFields(["latitude", "longitude", "address"]);
  }, [currentLocation]);

  useEffect(() => {
    form.resetFields(["address"]);
  }, [geoAddress]);

  useEffect(() => {
    dispatch({
      type: SET_SHOP_INFO,
      payload: {
        shopInfo: null,
      },
    });
  }, []);

  const OPTIONS = [
    {
      label: "15 Min",
      value: 15,
    },
    {
      label: "30 Min",
      value: 30,
    },
    {
      label: "45 Min",
      value: 45,
    },
    {
      label: "60 Min",
      value: 60,
    },
  ];

  return (
    <ShopLayout
      shopId={(route as any)?.shopId as string}
      title={shopData.data ? `Shop Edit` : `Shop Add`}
      subTitle={shopData.data ? `Edit Shop` : `Create Shop`}
      extra={
        <>
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading"
            }
            loading={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading"
            }
          />
        </>
      }
    >
      <div className="content-body rounded-2xl">
        <div className="">
          {" "}
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
            initialValues={{
              ...shopData.data,

              contactNumber: shopData.data?.contactNumber?.replace("+88", ""),
              mobileNumber: merchantId
                ? singleMerchanrInfo?.data?.mobileNumber?.replace("+88", "")
                : shopData.data?.mobileNumber?.replace("+88", ""),
              email: merchantId
                ? singleMerchanrInfo?.data?.email
                : shopData.data?.email,
              address: geoAddress?.data?.address || shopData.data?.address,
              latitude: currentLocation?.lat,
              longitude: currentLocation?.lng,
              expireDate: dayjs(shopData.data?.flashDiscount?.expireDate),
            }}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
          >
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
              <div className="grid grid-cols-2 gap-x-6">
                <div>
                  <Form.Item
                    hasFeedback
                    label="Shop Name"
                    rules={[
                      {
                        required: true,
                        message: "Shop Name is Required!",
                      },
                    ]}
                    name="name"
                  >
                    <Input id="name" type="text" placeholder="Enter Name" />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label={`Merchant`}
                    rules={[
                      {
                        required: true,
                        message: "Status is required!",
                      },
                    ]}
                    name="merchantId"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Merchant"
                      optionFilterProp="children"
                      onSearch={(val) => {
                        handleSearch?.(val, "merchant");
                      }}
                      onChange={(e) => setMerchantId(e)}
                      filterOption={() => {
                        return true;
                      }}
                      options={merchantOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Email"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Email is required!",
                      // },
                      {
                        validator: async (_, email) => {
                          if (
                            email &&
                            !String(email)
                              .toLowerCase()
                              ?.match(
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                              )
                          ) {
                            return Promise.reject(
                              new Error("Enter valid email")
                            );
                          }
                        },
                      },
                    ]}
                    name="email"
                  >
                    <Input id="email" type="email" placeholder="Enter Email" />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Mobile Number (Login)"
                    rules={[
                      {
                        required: true,
                        message: "Mobile Number is required!",
                      },
                      {
                        validator: async (_, names) => {
                          if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                            return Promise.reject(
                              new Error("Enter valid mobile number")
                            );
                          }
                        },
                      },
                    ]}
                    name="mobileNumber"
                    initialValue={"01"}
                  >
                    <Input
                      disabled={shopData?.data || false}
                      id="mobileNumber"
                      type="contact"
                      addonBefore={"+88"}
                      placeholder="Enter Mobile Number"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Contact Number"
                    rules={[
                      {
                        required: true,
                        message: "Contact Number is required!",
                      },
                      {
                        validator: async (_, names) => {
                          if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                            return Promise.reject(
                              new Error("Enter valid contact number")
                            );
                          }
                        },
                      },
                    ]}
                    name="contactNumber"
                    initialValue={"01"}
                  >
                    <Input
                      id="contact"
                      type="contact"
                      addonBefore={"+88"}
                      placeholder="Enter Contact Number"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Activity Status"
                    rules={[
                      {
                        required: true,
                        message: "Status is required!",
                      },
                    ]}
                    name="status"
                  >
                    <Select
                      placeholder="Select Status"
                      optionFilterProp="children"
                      allowClear
                      status={
                        shopData?.status?.toLowerCase() === "Close"
                          ? "error"
                          : shopData?.status?.toLowerCase() === "Busy"
                          ? "warning"
                          : "warning"
                      }
                    >
                      {Object.values(ShopStatus)?.map((status, i) => (
                        <Select.Option value={status} key={i}>
                          {status?.replace("_", " ")}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                {type == "JC_FOOD" && (
                  <>
                    <Form.Item
                      hasFeedback
                      label={`Opening Time (${selectedStartTime})`}
                    >
                      <TimePicker
                        format="HH:mm"
                        //showNow={false}
                        value={moment(selectedStartTime, "HH:mm")}
                        suffixIcon={<DownOutlined />}
                        onSelect={(value) => {
                          const startTimeString = moment(value as any).format(
                            "HH:mm"
                          );
                          setSelectedStartTime(startTimeString);
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      hasFeedback
                      label={`Closing Time (${selectedCloseTime})`}
                    >
                      <TimePicker
                        format="HH:mm"
                        //showNow={false}
                        value={moment(selectedCloseTime, "HH:mm")}
                        suffixIcon={<DownOutlined />}
                        onSelect={(value) => {
                          const timeCloseString = moment(value).format("HH:mm");
                          setSelectedCloseTime(timeCloseString);
                        }}
                      />
                      {/* <TimePicker
                    placeholder="Closing Time"
                    onChange={(val: any) =>
                      setEndAt(moment(val).format("HH:mm"))
                    }
                    defaultValue={endAt}
                    format="HH:mm"
                    className="min-w-full"
                    // disabledHours={() => startAt}
                    value={moment(`2022-10-10 ${endAt || "00:00"}`)}
                  /> */}
                    </Form.Item>
                  </>
                )}
                <div className="">
                  <Form.Item
                    hasFeedback
                    label="Business Type"
                    rules={[
                      {
                        required: true,
                        message: "Business Type is required!",
                      },
                    ]}
                    name="businessTypeId"
                    initialValue={businessType}
                  >
                    <Select
                      showSearch
                      placeholder="Select Business Type"
                      optionFilterProp="children"
                      onSearch={(val) => {
                        handleSearch(val, "businessType");
                      }}
                      defaultValue={businessType}
                      onChange={(val) => setBusinessType(val)}
                      filterOption={() => {
                        return true;
                      }}
                      options={businessTypeOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="">
                  <Form.Item
                    hasFeedback
                    label="Service Type"
                    rules={[
                      {
                        required: true,
                        message: "Service Type is required!",
                      },
                    ]}
                    name="shopId"
                  >
                    <Select
                      showSearch
                      placeholder="Select Service Type"
                      optionFilterProp="children"
                      onSearch={(val) => {
                        handleSearch(val, "service");
                      }}
                      onChange={(val) => setServiceId(val)}
                      filterOption={() => {
                        return true;
                      }}
                      options={serviceOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div className="">
                  <Form.Item
                    hasFeedback
                    label="Business Operation Type"
                    rules={[
                      {
                        required: true,
                        message: "Business Operation Type is required!",
                      },
                    ]}
                    name="businessOperationType"
                  >
                    <Select
                      onChange={(val) => setOperationType(val)}
                      options={[
                        {
                          value: "INDIVIDUAL",
                          label: "None, I am an Indusial (As a Person)",
                        },
                        {
                          value: "COMPANY",
                          label: "As a Company",
                        },
                      ]}
                      placeholder="Enter Operation Type"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Price Calculation Type"
                    name="basePriceCalculationType"
                    rules={[
                      {
                        required: true,
                        message: "Price Calculation Type is Required!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Price Calculation Type"
                      onChange={(val) => setPriceCalcType(val)}
                    >
                      {Object.values(priceCalculationType)?.map((type, i) => (
                        <Option value={type} key={i}>
                          {type?.split("_").join(" ")}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                {operationType === "COMPANY" && (
                  <>
                    {companyOptions?.length > 0 && (
                      <>
                        <div className="">
                          <Form.Item
                            hasFeedback
                            label="Company Name"
                            rules={[
                              {
                                required: false,
                                message: "Company name is required!",
                              },
                            ]}
                            name="companyId"
                          >
                            <Select
                              showSearch
                              placeholder="Select Company name"
                              optionFilterProp="children"
                              onSearch={(val) => {
                                handleSearch(val, "company");
                              }}
                              onChange={(val) => setCompanyId(val)}
                              filterOption={() => {
                                return true;
                              }}
                              options={companyOptions?.list}
                            ></Select>
                          </Form.Item>
                        </div>
                        <Form.Item>
                          <Checkbox.Group>
                            <Row>
                              <Checkbox
                                value="newComapny"
                                checked={isChecked}
                                onChange={handleOnChange}
                              >
                                Add New Company
                              </Checkbox>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                        {isChecked && (
                          <div className="">
                            <Form.Item
                              hasFeedback
                              label="New Company Name"
                              name="companyName"
                            >
                              <Input
                                id="companyName"
                                type="text"
                                placeholder="Enter Company Name"
                              />
                            </Form.Item>
                          </div>
                        )}
                      </>
                    )}

                    <div className="">
                      <Form.Item
                        hasFeedback
                        label="New Company Name"
                        name="companyName"
                      >
                        <Input
                          id="companyName"
                          type="text"
                          placeholder="Enter Company Name"
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
                <div>
                  <Form.Item
                    hasFeedback
                    label="Commission Type"
                    rules={[
                      {
                        required: false,
                        message: "Commission Type is Required!",
                      },
                    ]}
                    name="commissionType"
                    initialValue={commissionType}
                  >
                    <Select
                      options={[
                        { value: null, label: "NONE" },
                        { value: "PERCENTAGE", label: "PERCENTAGE" },
                        { value: "FLAT", label: "FLAT" },
                      ]}
                      placeholder="Enter Commission Type"
                      onChange={(val) => setCommissionType(val)}
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Commission Amount"
                    rules={[
                      {
                        required: false,
                        message: "Commission Amount is Required!",
                      },
                    ]}
                    name="commissionAmount"
                  >
                    <Input
                      disabled={!commissionType}
                      id="commissionAmount"
                      type="number"
                      min={0}
                      placeholder="Enter Commission Amount"
                    />
                  </Form.Item>
                </div>

                {shopData?.data && (
                  <div>
                    <Form.Item
                      hasFeedback
                      label="Product Subsidy Update Type"
                      name="productSubsidyUpdateType"
                      initialValue={null}
                    >
                      <Select
                        onChange={(val) => setSubsidyUpdateType(val)}
                        options={[
                          { value: null, label: "NONE" },
                          { value: "UPDATE_ALL", label: "UPDATE ALL" },
                          { value: "EXCLUDE_CUSTOM", label: "EXCLUDE CUSTOM" },
                        ]}
                        placeholder="Select Product Subsidy Update Type"
                      />
                    </Form.Item>
                  </div>
                )}

                <div className="">
                  <Form.Item hasFeedback label="Subsidy" name="subsidy">
                    <Input
                      id="subsidy"
                      type="number"
                      min={0}
                      placeholder="Enter Subsidy"
                      disabled={shopData?.data && !subsidyUpdateType}
                    />
                  </Form.Item>
                </div>

                {type === "JC_FOOD" && (
                  <>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Flash Discount Type"
                        name="discountType"
                        initialValue={discountType}
                      >
                        <Select
                          options={[
                            { value: null, label: "NONE" },
                            { value: "PERCENTAGE", label: "PERCENTAGE" },
                            { value: "FLAT", label: "FLAT" },
                          ]}
                          placeholder="Enter Flash Discount Type"
                          onChange={(val) => setDiscountType(val)}
                        />
                      </Form.Item>
                    </div>
                    {discountType && (
                      <>
                        <div className="">
                          <Form.Item
                            hasFeedback
                            label="Flash Discount Amount"
                            name="discountAmount"
                          >
                            <Input
                              id="discountAmount"
                              type="number"
                              min={0}
                              placeholder="Enter Flash Dicount Amount"
                            />
                          </Form.Item>
                        </div>

                        <div>
                          <Form.Item
                            hasFeedback
                            label="Flash Discount Expire Date"
                            name="expireDate"
                          >
                            <DatePicker
                              showTime
                              id="startFrom"
                              placeholder="Enter Starting Date"
                              onChange={(val: DatePickerProps["value"]) =>
                                setExpireDate(val)
                              }
                              style={{ minWidth: "100%" }}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </>
                )}

                <div>
                  <Form.Item
                    hasFeedback
                    label="Prepare Time"
                    rules={[
                      {
                        required: false,
                        message: "Prepare Time is Required!",
                      },
                      {
                        validator: async (_, name) => {
                          if (name && !(parseFloat(name) > 0)) {
                            return Promise.reject(
                              new Error("Invalid Delivery Time")
                            );
                          }
                        },
                      },
                    ]}
                    name="prepareTime"
                  >
                    <Select
                      options={OPTIONS}
                      placeholder="Enter Prepare Type"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Display Position"
                    rules={[
                      {
                        required: false,
                        message: "Display shop position  is required!",
                      },
                    ]}
                    name="displayOrder"
                  >
                    <Input
                      id="displayOrder"
                      type="number"
                      min={0}
                      placeholder="Enter Display Position"
                    />
                  </Form.Item>
                </div>
                {type === "JC_FOOD" && (
                  <>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Free Delivery?"
                        name="isFreeDelivery"
                        style={{ minWidth: "100%" }}
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked={shopData?.data?.isFreeDelivery}
                          onChange={setFreeDelivery}
                        />
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        hasFeedback
                        label="Dine In?"
                        name="dineIn"
                        style={{ minWidth: "100%" }}
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked={shopData?.data?.dineIn}
                          onChange={setDineIn}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
                <div>
                  <Form.Item
                    hasFeedback
                    label="Pick Up?"
                    name="pickUp"
                    style={{ minWidth: "100%" }}
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={shopData?.data?.pickUp}
                      onChange={setPickUp}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Base Delivery Charge"
                    rules={[
                      {
                        required: true,
                        message: "Delivery Charge is Required!",
                      },
                      {
                        validator: async (_, name) => {
                          if (name && !(parseFloat(name) > 0)) {
                            return Promise.reject(
                              new Error("Invalid Delivery Charge")
                            );
                          }
                        },
                      },
                    ]}
                    name="baseDeliveryCharge"
                  >
                    <Input
                      id="baseDeliveryCharge"
                      type="text"
                      placeholder="Enter Delivery Charge"
                    />
                  </Form.Item>
                </div>

                {!freeDelivery && (
                  <div>
                    <>
                      <Form.Item
                        hasFeedback
                        label="Minimum Amount For Free Delivery"
                        name="minimumAmountForFreeDelivery"
                      >
                        <Input
                          id="minimumAmountForFreeDelivery"
                          type="text"
                          placeholder="Enter Minimum Amount"
                        />
                      </Form.Item>
                    </>
                  </div>
                )}
                {type === "JC_FOOD" && (
                  <>
                    <div>
                      <Form.Item hasFeedback label="Promo Code" name="promo">
                        <Input id="promo" placeholder="Enter shop promo code" />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Promo Description"
                        name="promoDescription"
                      >
                        <Input
                          id="promoDescription"
                          placeholder="Enter Shop promo description"
                        />
                      </Form.Item>
                    </div>
                  </>
                )}

                <div>
                  <Form.Item hasFeedback label="Keywords" name="keywords">
                    <Input id="keywords" placeholder="Enter Shop keywords" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Number Of Followers"
                    name="numberOfFollowers"
                  >
                    <Input
                      id="numberOfFollowers"
                      placeholder="Enter Shop number of followers"
                    />
                  </Form.Item>
                </div>
                <div className="">
                  <Form.Item hasFeedback label="Meta Title" name="metaTitle">
                    <Input
                      id="metaTitle"
                      type="text"
                      placeholder="Enter Meta Title"
                    />
                  </Form.Item>
                </div>
                <div className="">
                  <Form.Item
                    hasFeedback
                    label="Meta Description"
                    name="metaDescription"
                  >
                    <Input.TextArea
                      id="metaDescription"
                      placeholder="Enter Meta Description"
                      rows={3}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item hasFeedback label="VAT" name="vat">
                    <Input id="vat" placeholder="Enter Shop vat" />
                  </Form.Item>
                </div>
                <div className="col-span-2">
                  <Form.Item hasFeedback label="Description" name="description">
                    <TextArea
                      id="description"
                      placeholder="Enter Shop Description"
                      rows={4}
                    />
                  </Form.Item>
                </div>

                {/* <h4 className="title" style={{ maxWidth: "99%" }}>
                Bank Information:
              </h4>
              <Form.Item
                hasFeedback
                label="Account Name"
                rules={[
                  {
                    required: true,
                    message: "Account Name is Required!",
                  },
                ]}
                name="accountName"
              >
                <Input
                  id="accountName"
                  type="text"
                  placeholder="Enter Account Name"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Account Number"
                rules={[
                  {
                    required: true,
                    message: "Account Number is required!",
                  },
                ]}
                name="accountNumber"
              >
                <Input
                  id="accountNumber"
                  type="text"
                  placeholder="Enter Account Number"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Bank Name"
                rules={[
                  {
                    required: true,
                    message: "Bank Name is Required!",
                  },
                ]}
                name="bankName"
              >
                <Input
                  id="bankName"
                  type="text"
                  placeholder="Enter Bank Name"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Branch Name"
                rules={[
                  {
                    required: true,
                    message: "Branch Name is Required!",
                  },
                ]}
                name="branchName"
              >
                <Input
                  id="branchName"
                  type="text"
                  placeholder="Enter Branch Name"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Routing Number"
                rules={[
                  {
                    required: true,
                    message: "Routing Number is Required!",
                  },
                ]}
                name="routingNumber"
              >
                <Input
                  id="routingNumber"
                  type="text"
                  placeholder="Enter Routing Number"
                />
              </Form.Item>

              <Form.Item hasFeedback label="Swift Code" name="swiftCode">
                <Input
                  id="swiftCode"
                  type="text"
                  placeholder="Enter Swift Code"
                />
              </Form.Item> */}

                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
              <h3 className="mb-4 text-xl font-bold">Bank Info</h3>
              <div className="grid grid-cols-2 gap-x-6">
                <div>
                  <Radio.Group
                    onChange={(e) => setGetBankType(e.target.value)}
                    defaultValue={getBankType}
                  >
                    <Radio value={"BANKING"} checked>
                      BANKING
                    </Radio>
                    <Radio value="MOBILE_BANKING">MOBILE BANKING</Radio>
                  </Radio.Group>
                </div>

                <div>
                  <Form.Item hasFeedback label="Bank Name" name="bankName">
                    <Input
                      id="bankName"
                      type="text"
                      placeholder="Enter bank name"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="accountName"
                    hasFeedback
                    label="Account Name"
                    rules={[
                      {
                        required: true,
                        message: "Account Name is Required!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      id="accountName"
                      className="input-box"
                      placeholder="Enter account name"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Account Number"
                    name="accountNumber"
                  >
                    <Input
                      id="accountNumber"
                      type="text"
                      placeholder="Enter account number"
                    />
                  </Form.Item>
                </div>

                {getBankType === "BANKING" && (
                  <>
                    <Form.Item
                      name="branchName"
                      hasFeedback
                      label="Branch Name"
                      rules={[
                        {
                          required: true,
                          message: "Branch Name is Required!",
                        },
                      ]}
                    >
                      <Input
                        id="branchName"
                        type="text"
                        className="input-box"
                        placeholder="Enter branch name"
                      />
                    </Form.Item>

                    <Form.Item
                      name="routingNumber"
                      hasFeedback
                      label="Routing Number"
                      rules={[
                        {
                          required: true,
                          message: "Routing Number is Required!",
                        },
                      ]}
                    >
                      <Input
                        id="routingNumber"
                        type="text"
                        className="input-box"
                        placeholder="Enter routing number"
                      />
                    </Form.Item>
                    <Form.Item hasFeedback label="Swift Code" name="swiftCode">
                      <Input
                        id="swiftCode"
                        type="text"
                        placeholder="Enter swift code"
                      />
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  hasFeedback
                  label="IS Defaul?"
                  name="isDefault"
                  style={{ minWidth: "100%" }}
                >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={shopData?.isDefault}
                    onChange={setIsDefault}
                  />
                </Form.Item>
                <div className="mr-4">
                  <Divider orientation="left">
                    {getBankType === "BANKING" && <>Upload Bank Cheque Copy</>}
                    {getBankType === "MOBILE_BANKING" && <>Logo</>}

                    <Tooltip
                      placement="bottom"
                      title={
                        "Upload Bank Cheque Copy should be square. We prefer height 200px and width 200px (Ratio 1)"
                      }
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>
                  <ImageUploader
                    imgUrl={generatedBankImgUrl || ""}
                    startUpload={bankStartUpload}
                    setStartUpload={(val: any) => {
                      setBankStartUpload(val);
                    }}
                    setGeneratedImgUrl={(url: any) =>
                      setGeneratedBankImgUrl(url)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
              <h3 className="mb-4 text-xl font-bold">Address</h3>
              <div className="grid grid-cols-2 gap-x-6">
                <div>
                  <Form.Item hasFeedback label="Area" name="area">
                    <Input
                      id="area"
                      type="text"
                      placeholder="Enter shops area"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Country"
                    rules={[
                      {
                        required: true,
                        message: "Country Name is Required!",
                      },
                    ]}
                    name="country"
                    // initialValue="BD"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      defaultValue={countryId}
                      onChange={(val) => setCountryId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "country");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={countryOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label={`State/Province`}
                    rules={[
                      {
                        required: false,
                        message: "State is required!",
                      },
                    ]}
                    name="state"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      defaultValue={stateId}
                      onChange={(val) => setStateId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "state");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={stateOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label={`City`}
                    rules={[
                      {
                        required: false,
                        message: "City is required!",
                      },
                    ]}
                    name="city"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select City"
                      optionFilterProp="children"
                      defaultValue={cityId}
                      onChange={(val) => setCityId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "city");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={cityOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label={`Area`}
                    rules={[
                      {
                        required: false,
                        message: "Area is required!",
                      },
                    ]}
                    name="orderArea"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Area"
                      optionFilterProp="children"
                      onChange={(val) => setAreaId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "area");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={areaOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label={`Sub-Urb`}
                    rules={[
                      {
                        required: false,
                        message: "Sub-Urb is required!",
                      },
                    ]}
                    name="subUrb"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Sub-Urb"
                      optionFilterProp="children"
                      onChange={(val) => setSubUrbId(val)}
                      onSearch={(val) => {
                        handleSearch?.(val, "suburb");
                      }}
                      filterOption={() => {
                        return true;
                      }}
                      options={subUrbOptions?.list}
                    ></Select>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Address is required!",
                      },
                    ]}
                    initialValue={key}
                  >
                    <AutoComplete
                      //autoClearSearchValue={false}
                      // allowClear
                      showSearch
                      onSearch={(val) => {
                        handleSearch?.(val, "locations");
                        setKey(val);
                      }}
                      onSelect={(val: any) => {
                        const matchData = locationOptions?.list?.find(
                          (place: any) => val === place.address
                        );
                        setKey(val);
                        setCurrentLocation({
                          lat: matchData?.location?.latitude,
                          lng: matchData?.location?.longitude,
                        });
                      }}
                      options={locationListOptions?.list}
                      notFoundContent={
                        locationListOptions?.loading ? (
                          <Spin size="small" />
                        ) : null
                      }
                      //loading={locationListOptions.loading}
                      placeholder="Search Address"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Latitude"
                    name="latitude"
                    // initialValue={currentLocation?.lat}
                  >
                    <Input id="latitude" type="text" placeholder="23.86450" />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Longitude"
                    name="longitude"
                    // initialValue={currentLocation?.lng}
                  >
                    <Input id="longitude" type="text" placeholder="90.53680" />
                  </Form.Item>
                </div>

                <div className="col-span-2">
                  <MapBox
                    draggable={true}
                    coordinate={currentLocation}
                    setCoordinator={(val: any) => setCurrentLocation(val)}
                  />
                </div>
              </div>
            </div>

            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
              <div className="flow-root">
                <h3 className="text-xl font-bold">Extra Options</h3>
                <div className="grid grid-cols-3 gap-x-6 mt-6">
                  {type == "JC_FOOD" && (
                    <>
                      <div>
                        <Form.Item hasFeedback label="Dine In?" name="dineIn">
                          <Switch
                            checkedChildren="Allow"
                            unCheckedChildren="Not Allow"
                            defaultChecked={shopData?.data?.dineIn}
                            // onChange={setFreeDelivery}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item hasFeedback label="PickUp?" name="pickUp">
                          <Switch
                            checkedChildren="Allow"
                            unCheckedChildren="Not Allow"
                            defaultChecked={shopData?.data?.pickUp}
                            // onChange={setFreeDelivery}
                          />
                        </Form.Item>
                      </div>
                    </>
                  )}
                  <div>
                    <Form.Item
                      hasFeedback
                      label="isVerified?"
                      name="isVerified"
                    >
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked={shopData?.data?.isVerified}
                        // onChange={setFreeDelivery}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4 xl:mb-0">
              <div className="flow-root">
                <h3 className="text-xl font-bold">Images</h3>

                <div className="grid grid-cols-2 mt-2">
                  <div className="mr-4">
                    <Divider orientation="left">
                      Logo
                      <Tooltip
                        placement="bottom"
                        title={
                          "Shop Logo should be square. We prefer height 200px and width 200px (Ratio 1)"
                        }
                      >
                        <QuestionCircleOutlined className="px-2 -mt-2" />
                      </Tooltip>
                    </Divider>
                    <ImageUploader
                      imgUrl={logoUrl || ""}
                      startUpload={startUpload}
                      setStartUpload={(val: any) => {
                        setStartUpload(val);
                      }}
                      setGeneratedImgUrl={(url: any) => {
                        setLogoUrl(url);
                        dispatch({
                          type: SET_SHOP_INFO,
                          payload: {
                            shopInfo: {
                              logo: url,
                              // banner: data?.data?.shop?.banner,
                            },
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="">
                    <Divider orientation="left">
                      Banner
                      <Tooltip
                        placement="bottom"
                        title={
                          "Shop Banner image should 1200px/500px (Ratio 2.4)"
                        }
                      >
                        <QuestionCircleOutlined className="px-2 -mt-2" />
                      </Tooltip>
                    </Divider>

                    <ImageUploader
                      imgUrl={bannerUrl || ""}
                      startUpload={startUpload}
                      setStartUpload={(val: any) => setStartUpload2(val)}
                      setGeneratedImgUrl={(url: any) => setBannerUrl(url)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </ShopLayout>
  );
};

export default AddShop;
