import { Divider, Form, Input, Select } from "antd";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router";
import ImageUploader from "../../common/ImageUploader";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { useSelector } from "react-redux";
import React from "react";
import SubmitResetBtn from "../../common/SubmitBtn";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../../utils/editor-config";

const AddPage = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);

  const editor = useRef(null);
  const navigate = useNavigate();
  const [convertedText, setConvertedText] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [generatedBannerUrl, setGeneratedBannerUrl] = useState<string>();
  const route = useParams();

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [singlePageInfo, setSinglePageInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [categoryOptions, setCategoryOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const fetchPageDetails = useCallback((pageSlug: any) => {
    if (pageSlug) {
      try {
        setSinglePageInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/web-content/category/details?slug=${pageSlug}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSinglePageInfo({
                loading: false,
                data: data?.data?.category,
              });
              setConvertedText(data?.data?.category?.description || "");
              setGeneratedBannerUrl(data?.data?.category?.image);
              setGeneratedImgUrl(data?.data?.category?.bannerImage);
            } else {
              setSinglePageInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSinglePageInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSinglePageInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    // if (generatedImgUrl || generatedBannerUrl) {
    setLoading(true);

    const readyData = data && {
      title: data.title,
      position: data.position,
      contentType: data.contentType,
      description: convertedText || "<p></p>",
      shortDescription: data.shortDescription,
      keyword: data.keyword,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
      displayOrder: parseFloat(data.displayOrder),
      type: type,
      image: generatedImgUrl ? generatedImgUrl : data?.image,
      banner: generatedBannerUrl ? generatedBannerUrl : data?.bannerImage,
    };

    if (singlePageInfo?.data) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/web-category`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: singlePageInfo?.data?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Categories Updated Successfully", "success");
            navigate(-1);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/web-category`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Categories Create Successfully", "success");
            navigate(-1);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
    // } else {
    //   responseNotification(`Image required`, "error");
    // }
  };

  // **********************************
  // Search Options
  // **********************************

  const getCategoryOptions = useCallback(
    async (key) => {
      setCategoryOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/web-category/category?type=${type}&contentType=BLOG`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCategoryOptions({
        loading: false,
        list: res?.data?.categories?.map(
          (category: { id: any; title: any }) => {
            return {
              value: category?.id,
              label: category?.title,
            };
          }
        ),
      });
    },
    [type]
  );

  // **********************************
  // Search Options
  // **********************************

  useEffect(() => {
    getCategoryOptions(``);
  }, [getCategoryOptions]);

  useEffect(() => {
    fetchPageDetails((route as any)?.slug);
  }, [fetchPageDetails, route]);

  useEffect(() => {
    if (singlePageInfo?.data) {
      // form.resetFields(Object.keys(singlePageInfo?.data));
      form.resetFields();
      setGeneratedBannerUrl(singlePageInfo?.data?.bannerImage);
      setGeneratedImgUrl(singlePageInfo?.data?.image);
    }
  }, [form, singlePageInfo?.data]);

  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(singlePageInfo?.data?.image);
    setGeneratedBannerUrl(singlePageInfo?.data?.bannerImage);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <>
      <BreadCrumb title={singlePageInfo?.data ? "Edit Page" : "Add Page"} />

      <div className="content-body rounded-2xl">
        <div className="">
          <Form
            name="control-hooks"
            form={form} // like ref
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
            initialValues={{
              ...singlePageInfo?.data,
            }}
            layout="vertical"
            autoComplete="off"
          >
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 grid grid-cols-2 gap-x-4">
              {/* <h3 className="mb-4 text-xl font-bold col-span-2 mb-10 border-b pb-2 sticky top-0 bg-white z-30">
                Content Info
              </h3> */}

              <div>
                <Form.Item
                  hasFeedback
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Title is Required!",
                    },
                  ]}
                  name="title"
                >
                  <Input
                    id="title"
                    type="text"
                    placeholder="Enter Category Title"
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  hasFeedback
                  label="Display Order"
                  name="displayOrder"
                >
                  <Input
                    type="number"
                    id="displayOrder"
                    placeholder="Enter Display Order"
                    min="0"
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  hasFeedback
                  label="Content Type"
                  rules={[
                    {
                      required: true,
                      message: "Content Type is Required!",
                    },
                  ]}
                  name="contentType"
                  initialValue={"ARTICLE"}
                >
                  <Select
                    options={[
                      { value: "ARTICLE", label: "SINGLE ARTICLE" },
                      { value: "BLOG", label: "BLOG" },
                    ]}
                    id="contentType"
                    placeholder="Select Content Type"
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  hasFeedback
                  label="Position"
                  rules={[
                    {
                      required: false,
                      message: "Position is Required!",
                    },
                  ]}
                  name="position"
                >
                  <Select
                    options={[
                      { value: "INFORMATION", label: "INFORMATION" },
                      {
                        value: "CUSTOMER_SERVICE",
                        label: "CUSTOMER SERVICE",
                      },
                      {
                        value: "OTHERS",
                        label: "OTHERS",
                      },
                    ]}
                    id="position"
                    placeholder="Select Position"
                  />
                </Form.Item>
              </div>

              <div className="col-span-2 mb-8">
                <Form.Item
                  hasFeedback
                  label="Short Description"
                  rules={[
                    {
                      required: false,
                      message: "Short Description is Required!",
                    },
                  ]}
                  name="shortDescription"
                >
                  <Input.TextArea
                    rows={3}
                    id="shortDescription"
                    placeholder="Enter Page Short Description"
                  />
                </Form.Item>
              </div>

              <div className="col-span-2 mb-8">
                <h4 className="font-semibold mb-1">Content Details</h4>
                <JoditEditor
                  ref={editor}
                  value={convertedText}
                  config={jodiConfig as any}
                  // tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                />
              </div>

              <h3 className="mb-4 text-xl font-bold col-span-2">SEO</h3>

              <div className="col-span-2">
                <Form.Item hasFeedback label="Meta Title" name="metaTitle">
                  <Input
                    id="metaTitle"
                    type="text"
                    placeholder="Enter Meta Title"
                  />
                </Form.Item>
              </div>
              <div className="">
                <Form.Item hasFeedback label="Keywords" name="keyword">
                  <Input.TextArea
                    id="keyword"
                    placeholder="Enter Product Keywords"
                    rows={3}
                  />
                </Form.Item>
              </div>

              <div className="">
                <Form.Item
                  hasFeedback
                  label="Meta Description"
                  name="metaDescription"
                >
                  <Input.TextArea
                    id="metaDescription"
                    placeholder="Enter Meta Description"
                    rows={3}
                  />
                </Form.Item>
              </div>

              <div className="flex gap-12">
                <div className="">
                  <Divider orientation="left">Content Image</Divider>
                  <ImageUploader
                    imgUrl={generatedImgUrl || ""}
                    startUpload={startUpload2}
                    setStartUpload={(val: any) => setStartUpload2(val)}
                    setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
                  />
                </div>
                <div className="">
                  <Divider orientation="left">Banner Image</Divider>
                  <ImageUploader
                    // crop={1}
                    imgUrl={generatedBannerUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => setStartUpload(val)}
                    setGeneratedImgUrl={(url: any) => {
                      setGeneratedBannerUrl(url);
                    }}
                  />
                </div>
              </div>

              <div className="col-span-2 my-6">
                {" "}
                <SubmitResetBtn
                  onClickSubmit={(e) => {
                    e.preventDefault();
                    setStartUpload("Uploading");
                    setStartUpload2("Uploading");
                  }}
                  onClickReset={resetData}
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddPage;
