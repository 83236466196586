import React, { useState, useCallback, useEffect } from "react";
import { Button, Form, Input, Select } from "antd";
import {
  GoogleMap,
  useJsApiLoader,
  DrawingManager,
  Polygon,
  Marker,
} from "@react-google-maps/api";
import axios from "axios";
import { useSelector } from "react-redux";
import { SubmitResetBtn } from "../common";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import BreadCrumb from "../Layouts/Breadcrumb";
import { authenticateToken } from "../../utils/auth";
import {
  getParamValue,
  checkStatesAndPushToHistory,
  jcTypeArray,
} from "../../utils/index";
import ShopMarker from "../../images/icon/ShopMarker.svg";
import PreviewBtn from "../common/PreviewBtn";

export default function AreaMap() {
  const navigate = useNavigate();
  const [draw, setDraw] = useState(false);
  const [sendPoints, setSendPoints] = useState();
  const router = useLocation();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { type } = useSelector((state) => state?.authReducer);
  //const [type, setType] = useState("JC_GROCERY");
  const [neighborhoodData, setNeighborhoodData] = useState({
    loading: false,
    data: null,
  });
  const [allShops, setAllShops] = useState({
    loading: false,
    data: null,
  });

  const APIKey = "AIzaSyDYp1te-bQEhWE9P9yehRE3biB7LpSEh4U";
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: APIKey,
    libraries: ["drawing"],
  });
  const center = {
    lat: 23.773414356724892,
    lng: 90.41340705259854,
  };
  const containerStyle = {
    width: "100%",
    height: "70vh",
  };
  const drawingOnLoad = (drawingManager) => {
    console.log(drawingManager);
  };
  const onPolygonComplete = (polygon) => {
    console.log(polygon);
  };
  setTimeout(() => {
    if (isLoaded === true) {
      setDraw(true);
    }
  }, 1000);

  const getNeighborhood = useCallback(async () => {
    let pageReseted = false;
    setNeighborhoodData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/neighborhood/all` +
          (type ? `?type=${type}` : ``) +
          (page ? `&page=${pageReseted ? 0 : page || 0}` : ``) +
          (limit ? `&limit=${limit}` : ``) +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setNeighborhoodData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setNeighborhoodData({ loading: false, data: [] });
      });
  }, [limit, page, type]);

  const getAllShopList = useCallback(async () => {
    let pageReseted = false;
    setAllShops({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/shop` +
          (type ? `?type=${type}` : ``) +
          (page ? `&page=${pageReseted ? 0 : page || 0}` : ``) +
          (limit ? `&limit=${limit}` : ``) +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setAllShops({ loading: false, data: res.data });
      })
      .catch((err) => {
        setAllShops({ loading: false, data: [] });
      });
  }, [limit, page, type]);

  useEffect(() => {
    getNeighborhood();
  }, [getNeighborhood]);
  useEffect(() => {
    getAllShopList();
  }, [getAllShopList]);

  function getPaths(polygon) {
    var polygonBounds = polygon.getPath();
    var bounds = [];
    for (var i = 0; i < polygonBounds.length; i++) {
      var point = {
        latitude: polygonBounds.getAt(i).lat(),
        longitude: polygonBounds.getAt(i).lng(),
      };
      bounds.push(point);
    }
    setSendPoints(bounds);
  }
  const resetData = () => {
    setType("");
  };

  const onSubmit = async (data) => {
    const readyData = data && {
      name: data.name,
      points: sendPoints,
      type: type,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/neighborhood`,
        JSON.stringify(readyData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        navigate("/neighborhood");
      }
    } catch (err) {
      if (!err?.response) {
        console.log("Error", err);
      }
    }
  };

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <BreadCrumb
          title={`Add Neighborhood`}
          extra={[<PreviewBtn onClick={() => navigate(`/neighborhood`)} />]}
        />
        <div className="bg-white p-3">
          <div className="details-area" style={{ minHeight: 650 }}>
            <Form
              name="control-hooks"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="ant-form ant-form-vertical"
              onFinish={onSubmit}
              form={form} // like ref
              layout="vertical"
            >
              <div className="mb-4">
                <div>
                  <Form.Item
                    hasFeedback
                    label="Neighborhood Name"
                    rules={[
                      {
                        required: true,
                        message: "Neighborhood Name is Required!",
                      },
                    ]}
                    name="name"
                  >
                    <Input
                      id="name"
                      type="text"
                      placeholder="Enter Neighborhood Name"
                    />
                  </Form.Item>
                </div>

                {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={12}
                    onClick={(e) => console.log(e)}
                  >
                    {draw && (
                      <DrawingManager
                        onLoad={drawingOnLoad}
                        onPolygonComplete={(value) => getPaths(value)}
                        options={{
                          drawingControl: true,
                          drawingControlOptions: {
                            drawingModes: [
                              // window.google.maps.drawing.OverlayType.CIRCLE,
                              window.google.maps.drawing.OverlayType.POLYGON,
                              window.google.maps.drawing.OverlayType.POLYLINE,
                              window.google.maps.drawing.OverlayType.RECTANGLE,
                            ],
                          },
                        }}
                      />
                    )}

                    {neighborhoodData?.data?.neighborhoods?.map(
                      (neighborhood) => (
                        <Polygon
                          path={neighborhood?.polygon?.points?.map((point) => ({
                            lat: point?.y,
                            lng: point?.x,
                          }))}
                          options={{
                            strokeColor: "red",
                            strokeOpacity: 0.75,
                            strokeWeight: 2,
                            fillColor: "rgb(185 153 255 / 44%)",
                            fillOpacity: 0.6,
                            icons: [
                              {
                                offset: "0",
                                repeat: "20px",
                              },
                            ],
                          }}
                        />
                      )
                    )}

                    {allShops?.data?.shops?.map((shop) => (
                      <Marker
                        icon={ShopMarker}
                        title={shop?.name}
                        position={{
                          lat: shop?.location?.y,
                          lng: shop?.location?.x,
                        }}
                      ></Marker>
                    ))}
                  </GoogleMap>
                )}
              </div>
              <SubmitResetBtn loading={loading} disabled={!sendPoints} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
