import React from "react";
import VehicleBrandModelList from "../../../components/Vehicles/Brand/brandModelList";
import MainLayout from "../../../components/Layouts";

const VehicleBrantModelListPage = () => {
  return (
    <MainLayout>
      <VehicleBrandModelList />
    </MainLayout>
  );
};

export default VehicleBrantModelListPage;
