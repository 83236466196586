import React, { useRef, useState } from "react";
import cn from "classnames";
import Img from "../../images/empty.jpeg";
import styles from "../../styles/tailwind/List.module.css";
import BreadCrumb from "../Layouts/Breadcrumb";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCableCar,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  CloseOutlined,
  DownloadOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import SubmitResetBtn from "../common/SubmitBtn";
import JoditEditor from "jodit-react";
import { responseNotification } from "../../utils/notify";
//import { jodiConfig } from "../../utils/editor-config";

interface EmptyProps {
  height?: string;
  data?: string;
}
const gridStyle: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const contendStyle: React.CSSProperties = {
  backgroundColor: "#F8F8F8",
};
const gridStyle1: React.CSSProperties = {
  width: "60%",
  textAlign: "center",
};
const gridStyle2: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const totalData = [
  "Small Package 10 x 10 x 5 cm, 1 kg",
  "Medium Package 12 x 12 x 12 cm, 3 kg",
  "Large Package 20 x 20 x 20 cm, 10 kg",
];
const AddZone = ({ height = "100%", data }: EmptyProps) => {
  const editor = useRef(null);
  const [form] = Form.useForm();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const { shopInfo } = useSelector((state) => (state as any)?.authReducer);
  
  const [size, setSize] = useState<SizeType>("large");
  const [convertedText, setConvertedText] = useState("");
  
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState<boolean>(false);
  const [variationImageUrl, setVariationImageUrl] = useState<string[]>([]);
  const [shippingDeliveryPackeging, setShippingDeliveryPackeging] =
    useState<any>();
  const [teacherData, setTeacherData] = useState<any>({
    loading: false,
    data: null,
  });
  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      shippingZone: data?.countryZone?.map(
        (zone: any, experienceId: string) => ({
          countryZoneId: zone.countryZoneId,
          city: zone.city,
          addShippingRules: zone.addShippingRules,
          stateOrProvince: zone.stateOrProvince,
        })
      ),
      shopId: '6464b72bdac5a34cc3355622',
    };

    if (teacherData.data) {
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/delivery-configuration/add-shipping-zone`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: teacherData?.data?.id,
            deleted: false,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Shipping Updated Successfully", "success");
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch( 
        `${process.env.REACT_APP_ORDER_API}/delivery-configuration/add-shipping-zone`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Shipping Place Create Successfully",
              "success"
            );
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  return (
    <>
      <BreadCrumb title={`Referral & Share`} extra={false} />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8 ">
            <div className="content-body">
              <>
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className={styles.formStyles}
                  onFinish={onSubmit}
                  form={form} // like ref
                  layout="horizontal"
                  initialValues={{
                    ...teacherData?.data,
                  }}
                >
                  <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                    <h3 className="mb-4 text-xl font-bold">
                      Country Zone Name
                    </h3>

                    <Form.List
                      name="countryZone"
                      rules={[
                        {
                          validator: async (_, names) => {
                            if (!names || names.length < 1) {
                              return Promise.reject(
                                new Error(
                                  "At Least 1 Country Zone List Required!"
                                )
                              );
                            }
                          },
                        },
                      ]}
                      initialValue={[{}]}
                    >
                      {(countryZoneFields, { add, remove }, { errors }) => (
                        <>
                          {countryZoneFields.map(
                            ({
                              experienceKey,
                              name,
                              experienceFieldKey,
                              ...experienceRestField
                            }: any) => (
                              <Card
                                className="mb-2"
                                size="small"
                                key={experienceFieldKey}
                              >
                                <Space
                                  wrap
                                  align="center"
                                  className="float-left w-full"
                                >
                                  <div className="float-left w-full">
                                    <div className="float-left w-full">
                                      <div className="float-left w-1/4">
                                        <div className="float-left w-11/12">
                                          <Form.Item
                                            {...experienceRestField}
                                            name={[name, "countryZoneId"]}
                                            fieldKey={[
                                              experienceFieldKey,
                                              "countryZoneId",
                                            ]}
                                            hasFeedback
                                            label="Country/Region"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.countryZone?.[name]
                                                ?.countryZoneId
                                            }
                                          >
                                            <Input placeholder="Enter Country/Region" />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="float-left w-1/4">
                                        <div className="float-left w-11/12">
                                          <Form.Item
                                            hasFeedback
                                            label="City"
                                            rules={[
                                              {
                                                required: false,
                                                message:
                                                  "Your processing Time is Required!",
                                              },
                                            ]}
                                            name={[name, "city"]}
                                          >
                                            <Select
                                              options={[
                                                {
                                                  value: "DISCOUNT",
                                                  label: "DISCOUNT",
                                                },
                                                {
                                                  value: "BOGO",
                                                  label: "BUY ONE GET ONE",
                                                },
                                                {
                                                  value: "GIFT_ITEMS",
                                                  label: "GIFT ITEMS",
                                                },
                                                {
                                                  value: "GROUP_BUY",
                                                  label: "GROUP BUY",
                                                },
                                                {
                                                  value: "PURCHASE_VOUCHER",
                                                  label: "PURCHASE VOUCHER",
                                                },
                                                {
                                                  value: "FREE_DELIVERY",
                                                  label: "FREE DELIVERY",
                                                },
                                              ]}
                                              placeholder="Enter Campaign Type"
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="float-left w-1/4">
                                        <div className="float-left w-11/12">
                                          <div></div>
                                          <Form.Item
                                            hasFeedback
                                            label="State/Province"
                                            rules={[
                                              {
                                                required: false,
                                                message:
                                                  "Your processing Time is Required!",
                                              },
                                            ]}
                                            name={[name, "stateOrProvince"]}
                                          >
                                            <Select
                                              options={[
                                                {
                                                  value: "DISCOUNT",
                                                  label: "DISCOUNT",
                                                },
                                                {
                                                  value: "BOGO",
                                                  label: "BUY ONE GET ONE",
                                                },
                                                {
                                                  value: "GIFT_ITEMS",
                                                  label: "GIFT ITEMS",
                                                },
                                                {
                                                  value: "GROUP_BUY",
                                                  label: "GROUP BUY",
                                                },
                                                {
                                                  value: "PURCHASE_VOUCHER",
                                                  label: "PURCHASE VOUCHER",
                                                },
                                                {
                                                  value: "FREE_DELIVERY",
                                                  label: "FREE DELIVERY",
                                                },
                                              ]}
                                              placeholder="Enter Campaign Type"
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="float-left w-full">
                                        <div className="float-left w-1/5 p-2">
                                          <div>
                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                              Taxes
                                            </label>
                                            <div className="relative rounded-md shadow-sm">
                                              <Input
                                                type="text"
                                                name="price"
                                                id="price"
                                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="0.00"
                                              />
                                              <div className="absolute inset-y-0 right-0 flex items-center">
                                                <label className="sr-only">
                                                  Currency
                                                </label>
                                                <select
                                                  id="currency"
                                                  name="currency"
                                                  className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                                >
                                                  <option>%</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="float-left w-1/5 p-2">
                                          <div>
                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                              VAT
                                            </label>
                                            <div className="relative rounded-md shadow-sm">
                                              <Input
                                                type="text"
                                                name="price"
                                                id="price"
                                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="0.00"
                                              />
                                              <div className="absolute inset-y-0 right-0 flex items-center">
                                                <label className="sr-only">
                                                  Currency
                                                </label>
                                                <select
                                                  id="currency"
                                                  name="currency"
                                                  className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                                >
                                                  <option>%</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="float-left w-1/5 p-2">
                                          <div>
                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                              GST
                                            </label>
                                            <div className="relative rounded-md shadow-sm">
                                              <Input
                                                type="text"
                                                name="price"
                                                id="price"
                                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="0.00"
                                              />
                                              <div className="absolute inset-y-0 right-0 flex items-center">
                                                <label className="sr-only">
                                                  Currency
                                                </label>
                                                <select
                                                  id="currency"
                                                  name="currency"
                                                  className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                                >
                                                  <option>%</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="float-left w-1/5 p-2">
                                          <div>
                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                              MSC
                                            </label>
                                            <div className="relative rounded-md shadow-sm">
                                              <Input
                                                type="text"
                                                name="price"
                                                id="price"
                                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="0.00"
                                              />
                                              <div className="absolute inset-y-0 right-0 flex items-center">
                                                <label className="sr-only">
                                                  Currency
                                                </label>
                                                <select
                                                  id="currency"
                                                  name="currency"
                                                  className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                                >
                                                  <option>%</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="float-left w-1/5">
                                          <Button
                                            type="primary"
                                            size={size}
                                            className="rounded-md mt-7 text-black"
                                            style={{
                                              backgroundColor: "#DA6900",
                                            }}
                                          >
                                            Add
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="float-left w-1/4"></div>
                                  </div>

                                  <div className="float-left col-span-2 mb-8">
                                    <h4 className="font-semibold mb-1">
                                      Add Shipping Rules
                                    </h4>
                                    <JoditEditor
                                      ref={editor}
                                      value={convertedText}
                                      //config={jodiConfig as any}
                                      // tabIndex={1} // tabIndex of textarea
                                      onBlur={(newContent) =>
                                        setConvertedText(newContent)
                                      } // preferred to use only this option to update the content for performance reasons
                                      onChange={(newContent) => {}}
                                    />
                                  </div>
                                </Space>

                                <Button
                                  type="primary"
                                  danger
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    bottom: 0,
                                  }}
                                  onClick={() => {
                                    remove(name);
                                    setVariationImageUrl((prev) => {
                                      const newArr = prev.filter(
                                        (_, i) => i !== name
                                      );
                                      return newArr;
                                    });
                                  }}
                                >
                                  <CloseOutlined />
                                </Button>
                              </Card>
                            )
                          )}

                          <Form.Item
                            style={{
                              width: "100%",
                              flex: "1 1 100%",
                              marginBottom: 5,
                            }}
                          >
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                                setVariationImageUrl((prev) => {
                                  const newArr = Array.from(prev);
                                  newArr.push("");
                                  return newArr;
                                });
                              }}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Experience List
                            </Button>
                          </Form.Item>

                          <Form.ErrorList errors={errors} />
                        </>
                      )}
                    </Form.List>
                  </div>
                  <div className="w-full mx-8 py-0">
                    <div className="float-left w-11/12 text-right">
                      <SubmitResetBtn
                        onClickReset={() => {
                          form?.resetFields();
                        }}
                        disabled={loading || !!error}
                        loading={loading}
                      />
                    </div>
                  </div>
                </Form>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddZone;
