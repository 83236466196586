import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getParamValue } from "../../../utils/index";
import { responseNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";
import styles from "../../../styles/tailwind/List.module.css";

const DriverDeliveryCharge = ({ visibleData, onCloseMethod, submit }: any) => {
  const { driverInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [singleDriverInfo, setSingleDriverInfo] = useState<any>(undefined);

  useEffect(() => {
    if (submit) {
      form.submit();
    }
  }, [submit]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      deliveryCharge: data.deliveryCharge,
    };

    await fetch(
      `${process.env.REACT_APP_RIDER_API}/admin/driver/set-delivery-charge?driverId=${driverInfo?.driverOwnId}&deliveryCharge=${data.deliveryCharge}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Delivery Charge Updated Successfully",
            "success"
          );
          form.resetFields();
          if (onCloseMethod) {
            onCloseMethod();
          }
          navigate(`/delivery-shipping/drivers/${driverInfo?.driverId}/details`);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    if (visibleData) {
      setSingleDriverInfo(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (singleDriverInfo) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [singleDriverInfo, form, visibleData]);

  return (
    <Form
      name="control-hooks"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      className={styles.formStyles}
      onFinish={onSubmit}
      form={form} // like ref
      layout="vertical"
      initialValues={{
        ...driverInfo,
      }}
    >
      <Form.Item
        hasFeedback
        label="Delivery Charge"
        rules={[
          {
            required: true,
            message: "Delivery Charge is Required!",
          },
        ]}
        name="deliveryCharge"
      >
        <Input
          id="deliveryCharge"
          type="number"
          min={0}
          placeholder="Enter Delivery Charge"
        />
      </Form.Item>
      {/* <div className="buttons-container">
        <Button
          disabled={loading || !!error}
          loading={(loading ? "loading" : undefined) as any}
          type="primary"
          htmlType="submit"
          className="add-submit-btn text-center mr-2"
        >
          Submit
        </Button>

        <Button
          type="ghost"
          htmlType="button"
          onClick={() => {
            form?.resetFields();
          }}
          className="reset-submit-btn text-center mr-2"
        >
          Reset
        </Button>
      </div> */}
    </Form>
  );
};

export default DriverDeliveryCharge;
