import React from "react";
import ContentCategoriesList from "../../../components/Content/Page";
import MainLayout from "../../../components/Layouts";

const ContentCategoryPage = () => {
  return (
    <MainLayout>
      <ContentCategoriesList />
    </MainLayout>
  );
};

export default ContentCategoryPage;
