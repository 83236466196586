import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import { Button, Drawer, Empty, Form, Select, Switch } from "antd";
import { responseNotification } from "../../../utils/notify";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../../utils";
import { useLocation } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SinglePromo from "./Details";
import { debounce } from "lodash";
import AddPromoCode from "./AddPromoCode";
import moment from "moment";
import { AddBtn, Loader, Pagination } from "../../common";
import { Link } from "react-router-dom";

const PromoCodeList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedPromo, setSelectedPromo] = useState(undefined);
  const [selectedPromoForEdit, setSelectedPromoForEdit] = useState(undefined);
  const [promoId, setPromoId] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [promoOptions, setPromoOptions] = useState({
    loading: false,
    list: [],
  });
  const [promoData, setPromoData] = useState<any>({
    loading: false,
    data: null,
  });

  const getPromoCode = useCallback(async () => {
    setPromoData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/promo/all/V2?type=${type}&page=${
          page || 0
        }&limit=${limit || 16}` +
          (key ? `&promo=${key}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPromoData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setPromoData({ loading: false, data: [] });
        console.error("Promo: Error", err);
      });
  }, [key, limit, page, isActive, type]);

  const getPromoOptions = useCallback(
    async (key?: string) => {
      setPromoOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/promo/all/V2?page=0&limit=20` +
          (key ? `&promo=${key}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPromoOptions({
        loading: false,
        list: res.data?.promos?.map((promo: { promoCode: any }) => ({
          label: promo.promoCode,
          value: promo.promoCode,
        })),
      });
    },
    [type]
  );

  useEffect(() => {
    getPromoCode();
  }, [getPromoCode]);

  const onClose = () => {
    getPromoCode();
    setVisible(undefined);
    setSelectedPromo(undefined);
    setSelectedPromoForEdit(undefined);
  };

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/admin/promo`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          promoCodeId: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getPromoCode();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "promo") getPromoOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getPromoOptions]);

  const reseAllFieldData = () => {
    setPromoId("");
    setKey("");
    setIsActive("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getPromoOptions("");
    }
  }, [showSearch]);

  return (
    <>
      <BreadCrumb
        title={`Promo Code List`}
        subTitle={`${
          promoData?.data?.totalElements ||
          promoData?.data?.categories?.length ||
          0
        } Promo(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="promo_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Promo"
                optionFilterProp="children"
                onChange={(val) => setKey(val)}
                onSearch={(e) => handleSearch(e, "promo")}
                filterOption={() => {
                  return true;
                }}
                options={promoOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="isActive_search" initialValue={isActive}>
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {promoData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Promo Title</th>
                      <th scope="col">Promo Code</th>
                      <th scope="col">Message</th>
                      {/* <th scope="col">Total Uses</th> */}
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {promoData?.data?.promos?.length ? (
                      promoData?.data?.promos?.map((promo: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() => setSelectedPromo(promo?.id)}
                        >
                            <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {promo?.title}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {promo?.promoCode}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500">
                              {promo?.message}
                            </span>
                          </td>
                          {/* <td>
                            <Link
                              to={`/promotion/promo-code/${promo?.promoCode}/users`}
                            >
                              <span className="font-medium text-gray-500">
                                {promo?.totalUses}
                              </span>
                            </Link>
                          </td> */}
                          <td>
                            <span className="font-medium text-gray-500">
                              {moment(promo?.startFrom).format("ll")}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500">
                              {moment(promo?.endAt).format("ll")}
                            </span>
                          </td>
                          <td>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              defaultChecked={promo?.isActive}
                              onChange={(val, e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onStatusChange(promo?.id, val);
                              }}
                              loading={
                                confirmLoading && promo?.id === confirmLoading
                              }
                            />
                          </td>

                          <td>
                            <Button
                              shape="round"
                              type="dashed"
                              className="bg-white"
                              onClick={() => setSelectedPromoForEdit(promo)}
                            >
                              <EditOutlined />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...promoData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Promo"
            : selectedPromoForEdit
            ? "Edit Promo"
            : "Promo Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedPromo || selectedPromoForEdit}
        width={selectedPromoForEdit ? 600 : 500}
      >
        {visible || selectedPromoForEdit ? (
          <AddPromoCode
            onCloseMethod={onClose}
            visibleData={selectedPromoForEdit}
          />
        ) : (
          <SinglePromo promoId={selectedPromo} />
        )}
      </Drawer>
    </>
  );
};

export default PromoCodeList;
