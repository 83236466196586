import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import UserManagementList from "../../components/Administration";
import React from "react";
const UserManagementPage = (): ReactElement => {
  return (
    <MainLayout>
      <UserManagementList />
    </MainLayout>
  );
};

export default UserManagementPage;
