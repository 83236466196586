import {
    Button,
    DatePicker,
    Divider,
    Drawer,
    Empty,
    Form,
    Input,
    Modal,
    Select,
  } from "antd";
  import {
    CommentOutlined,
    DownloadOutlined,
    SearchOutlined,
  } from "@ant-design/icons";
  import React, { useCallback, useEffect, useRef, useState } from "react";
  import { useLocation, useNavigate } from "react-router";
  import axios from "axios";
  import moment from "moment";
  import { getPage, getParamValue } from "../../utils";
  import { debounce } from "lodash";
  import Loader from "../common/Loader";
  import LoaderFull from "../common/LoaderFull";
  import Pagination from "../common/Pagination/Pagination";
  import styles from "../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import { SubmitResetBtn } from "../common";
  import { useParams } from "react-router";
  import { responseNotification } from "../../utils/notify";
  import SingleDeposit from "./Details";
  const { RangePicker } = DatePicker;
  
  const CompanyBlanceDepositList = ({
    inCorporate = false,
    getComanyId,
  }: {
    inCorporate?: boolean;
    getComanyId?: string;
  }) => {
    const { token } = useSelector((state) => (state as any)?.authReducer);
    const [form] = Form.useForm();
    const router = useLocation();
    const [showSearch, setShowSearch] = useState(true);
    const [loading, setLoading] = useState<any>(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const navigate = useNavigate();
    const route = useParams();
    const [companyId, setCompanyId] = useState<any>(route?.corporateId);
    const loc = useLocation();
    const page = getParamValue(loc.search, "page");
    const [limit, setLimit] = useState(16);
    const [key, setKey] = useState(getParamValue(router.search, "key"));
    const [visible, setVisible] = useState<any>(undefined);
    const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
    const [selectedDeposit, setSelectedDeposit] = useState(undefined);
    const [selectedDepositForEdit, setSelectedDepositForEdit] = useState(undefined);
    const showPopconfirm = (id: any) => {
        setVisiblePopconfirm(id);
      };
    const [corporateOptions, setCorporateOptions] = useState<any>({
      loading: false,
      list: [],
    });
    const [blanceDepositData, setBlanceDepositData] = useState<any>({
      loading: false,
      data: null,
    });
    const [selectedBlanceApproved, setSelectedBlanceApproved] =
      useState<any>(undefined);
  
    const getCorporateOptions = useCallback(async (key?: string) => {
      setCorporateOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      const res = await axios.get(
        `${encodedUri}/company/all?` +
          (page ? `&page=${page}` : ``) +
          (limit ? `&limit=${limit}` : ``) +
          +(key ? `&name=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setCorporateOptions({
        loading: false,
        list: res?.data?.companies?.map((company: any) => ({
          label: company.name,
          value: company.id,
        })),
      });
    }, []);
  
    const getCompanyBlanceDepositData = useCallback(async () => {
      setBlanceDepositData({ loading: true, data: null });
      const encodedUri = `${process.env.REACT_APP_PAY_API}`;
      axios
        .get(
          `${encodedUri}/admin/deposit-order?page=0&limit=20`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBlanceDepositData({ loading: false, data: res.data });
          setLoading(false);
        })
        .catch((err) => {
          setBlanceDepositData({ loading: false, data: null });
          console.error("Promo: Error", err);
        });
    }, []);
  
    useEffect(() => {
        getCompanyBlanceDepositData();
    }, [getCompanyBlanceDepositData]);
  
    const reseAllFieldData = () => {
      form.resetFields();
    };
  
    const onSubmit = async (data: any) => {
      setLoading(true);
      const readyData = data && {
        amount: selectedBlanceApproved?.amount,
        orderId: selectedBlanceApproved?.orderId,
        paymentRefId: selectedBlanceApproved?.paymentId,
        note: data.note,
      };
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/admin/payment/approve-all-manual-payment`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
  
          if (res.statusCode === 200) {
            responseNotification("Approved Successfully", "success");
            onClose();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    };
  
    const handleSearch = React.useMemo(() => {
      const loadOptions = (value: string, field: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
  
        if (fetchId !== fetchRef.current) {
          return;
        }
        if (value) {
          if (field === "corporate") getCorporateOptions(value);
        }
      };
  
      return debounce(loadOptions, 800);
    }, [getCorporateOptions]);
    useEffect(() => {
      if (showSearch) {
        getCorporateOptions("");
      }
    }, [showSearch]);
  
    const fetchRef = useRef(0);
    const onClose = () => {
      form.resetFields();
      setVisible(undefined);
      setSelectedDeposit(undefined);
      setSelectedDepositForEdit(undefined);
      getCompanyBlanceDepositData();
      setSelectedBlanceApproved(undefined);
    };
  
    const handleOk = () => {
      setSelectedBlanceApproved(false);
    };
    const handleCancel = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setVisiblePopconfirm(undefined);
    };
    return (
      <>
        <BreadCrumb
          title="Company Balance Deposit List"
          extra={[
            <Button
              type="dashed"
              shape="circle"
              onClick={() => setShowSearch(!showSearch)}
              key={1}
            >
              <SearchOutlined />
            </Button>,
          ]}
        />
  
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {blanceDepositData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Ref ID</th>
                        <th scope="col">Deposited Amount</th>
                        <th scope="col">Due Amount</th>
                        <th scope="col">Paid Amount</th>
                        <th scope="col">Payment Method</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {blanceDepositData?.data?.depositOrders
                        ?.length ? (
                        blanceDepositData?.data?.depositOrders?.map(
                          (payment: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                              onClick={() => setSelectedDeposit(payment)}
                            >
                              <td>
                                <span className="font-medium">
                                  {payment?.paymentId}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium">
                                  {payment?.amount}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium">
                                  {payment?.dueAmount}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium">
                                  {payment?.paidAmount}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium">
                                  {payment?.paymentMethod}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium">
                                  {payment?.paymentStatus}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium">
                                  {payment?.status}
                                </span>
                              </td>
                              <td>
                                {payment?.paymentId && payment?.paymentStatus === "UNPAID" ? (
                                  <Button
                                    shape="round"
                                    type="primary"
                                    danger
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setSelectedBlanceApproved(payment);
                                    }}
                                  >
                                    Approved
                                  </Button>
                                ) : (
                                  <span className="border p-1 px-5 rounded-2xl bg-white">
                                    {payment?.status}
                                  </span>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              <Modal
                title="Approved Blance"
                onCancel={onClose}
                open={selectedBlanceApproved}
                onOk={handleOk}
                width={700}
                centered
                footer={false}
              >
                <div>
                  <Form
                    name="control-hooks"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="ant-form ant-form-vertical"
                    onFinish={onSubmit}
                    form={form} // like ref
                    layout="vertical"
                    autoComplete="off"
                  >
                    <Form.Item
                      hasFeedback
                      label="Note"
                      name="note"
                      rules={[
                        {
                          required: true,
                          message: "Note is Required!",
                        },
                      ]}
                    >
                      <Input id="note" type="text" placeholder="Enter Note" />
                    </Form.Item>
                    <div className="buttons-container mt-3">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="add-submit-btn text-center mr-2"
                      >
                        Submit
                      </Button>
                      <Button
                        type="ghost"
                        htmlType="button"
                        onClick={() => form?.resetFields()}
                        className="reset-submit-btn text-center mr-2"
                      >
                        Reset
                      </Button>
                    </div>
                  </Form>
                </div>
              </Modal>
            </div>
          </div>
        </div>


        <Drawer
        title={
          visible
            ? "Add Deposit"
            : selectedDepositForEdit
            ? "Edit Deposit"
            : "Deposit Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedDeposit }
        width={450}
      >
        {visible  ? (
        ''
        ) : (
          <SingleDeposit DepositDetails={selectedDeposit} />
        )}
      </Drawer>

      </>
    );
  }; 
  
  export default CompanyBlanceDepositList;
  