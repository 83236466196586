import React from "react";
import MainLayout from "../../../components/Layouts";
import MyShippingZoneList from "../../../components/Shop/ShippingProfile/MyShippingZone";

const MyShippingZonePage = () => {
  return (
    <MainLayout>
      <MyShippingZoneList />
    </MainLayout>
  );
};

export default MyShippingZonePage;
