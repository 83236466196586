import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Empty, Form, Popconfirm, Select, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import MethodDetails from "./Details";
import React from "react";
import AddMethod from "./AddMethod";
import { AddBtn, Loader, Pagination } from "../common";

const PaymentMethodList = (): ReactElement => {
  const [status, setStatus] = useState("");
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [limit, setLimit] = useState(16);
  const [searchKey, setSearchKey] = useState("");
  const [visible, setVisible] = useState<any>(undefined);
  const [showConfirm, setShowConfirm] = useState<any>(undefined);
  const [showSearch, setShowSearch] = useState(true);
  const [type, setType] = useState(initialState?.type);
  const [methodData, setMethodData] = useState<any>({
    loading: false,
    data: null,
  });
  const [selectedMethodForEdit, setSelectedMethodForEdit] =
    useState<any>(undefined);

  const [methodOptions, setMethodOptions] = useState({
    loading: false,
    list: null,
  });
  const [selectedMethod, setSelectedMethod] = useState<any>(undefined);

  const [confirmLoading, setConfirmLoading] = useState<any>(false);

  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");

  const reseAllFieldData = () => {
    setStatus("");
    setSearchKey("");
    setType("");
    form.resetFields();
  };

  // const handleChangeStatus = (value: string) => {
  //   setStatus(value);
  // };

  const getMethod = useCallback(async () => {
    setMethodData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_PAY_API}`;
    axios
      .get(
        `${encodedUri}/payment-method/all?page=${page || 0}&limit=${
          limit || 16
        }` +
          (type ? `&type=${type}` : ``) +
          (status ? `&status=${status}` : ``) +
          (searchKey ? `&key=${searchKey}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setMethodData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setMethodData({ loading: false, data: [] });
        console.error("Method: Error", err);
      });
  }, [limit, status, page, type, searchKey]);

  const showPopconfirm = (id) => {
    setShowConfirm(id);
  };

  const handleCancel = () => {
    setVisible(undefined);
  };

  //
  const onDelete = async (id) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/payment-method/deleted`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodId: id,
            deleted: true,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Method Deleted Successfully", "success");
            getMethod();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onStatusChange = async (id: any, val: boolean) => {
    setConfirmLoading(id);
    if (id) {
      await fetch(`${process.env.REACT_APP_PAY_API}/payment-method`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConfirmLoading(false);
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getMethod();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  //
  useEffect(() => {
    getMethod();
  }, [getMethod]);

  useEffect(() => {
    if (visible) {
      setSelectedMethod(undefined);
      setSelectedMethodForEdit(undefined);
    }
  }, [selectedMethod, selectedMethodForEdit, visible]);

  useEffect(() => {
    setSelectedMethod(undefined);
    setSelectedMethodForEdit(undefined);
  }, [visible]);

  const onClose = () => {
    setSelectedMethod(undefined);
    setSelectedMethodForEdit(undefined);
    getMethod();
    setVisible(undefined);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  return (
    <>
      <BreadCrumb
        title="Payment Methods"
        subTitle={`${
          methodData?.data?.totalElements ||
          methodData?.data?.methods?.length ||
          0
        } Payment Method(s)`}
        extra={[<AddBtn onClick={showDrawer} />]}
      />
      <Drawer
        destroyOnClose={true}
        title="Add Method"
        width={500}
        onClose={onClose}
        open={!!visible}
      >
        <AddMethod onCloseMethod={onClose} />
      </Drawer>
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} >
            <Form.Item initialValue={type}>
              <Select
                placeholder="Shop Type"
                onChange={(val) => setType(val)}
                value={type}
                style={{ width: 250 }}
              >
                {Object.values(jcTypeArray)?.map((type, i) => (
                  <Option value={type} key={i}>
                    {type.replace("_", " ")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {methodData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Title</th>
                      <th scope="col">Type</th>
                      <th scope="col">Description</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {methodData?.data?.methods?.length ? (
                      methodData?.data?.methods?.map(
                        (method: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedMethod(method)}
                          >
                            <td>
                              <Avatar
                                shape="square"
                                size={45}
                                src={method?.logo}
                                className="mr-3"
                              />
                              <span className="font-medium text-gray-500 ml-2">
                                {method?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {method?.title || "..."}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {method?.type?.replace("_", " ")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {method?.description}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <button
                                  className="btn btn-sm btn-warning mx-1"
                                  onClick={() =>
                                    setSelectedMethodForEdit(method)
                                  }
                                >
                                  <EditOutlined />
                                </button>

                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={!method?.deleted}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onStatusChange(method?.id, val);
                                  }}
                                  className="mr-1"
                                  loading={
                                    confirmLoading &&
                                    method?.id === confirmLoading
                                  }
                                  // disabled={method?.deleted}
                                />

                                {/* <Popconfirm
                                  placement="left"
                                  title="Are you sure to delete?"
                                  visible={method?.id === showConfirm}
                                  onConfirm={() => onDelete(method?.id)}
                                  okButtonProps={{ loading: confirmLoading }}
                                  onCancel={handleCancel}
                                >
                                  <button
                                    className="btn btn-sm btn-danger mx-1"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(method?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </button>
                                </Popconfirm> */}
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...methodData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={selectedMethodForEdit ? "Edit Method" : "Method Details"}
        width={500}
        onClose={onClose}
        open={selectedMethod || selectedMethodForEdit}
      >
        {selectedMethodForEdit ? (
          <AddMethod
            visibleData={selectedMethodForEdit}
            onCloseMethod={onClose}
          />
        ) : (
          <MethodDetails MethodDetails={selectedMethod} />
        )}
      </Drawer>
    </>
  );
};

export default PaymentMethodList;
