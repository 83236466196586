import { Avatar, Button, Layout, Modal, Rate } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import PropTypes from "prop-types";
import React, { ReactElement, useState } from "react";
import Sidebar from "./sidebar";
import { useSelector } from "react-redux";
import { getImgUrl } from "../../../../utils";
import { Link, useNavigate } from "react-router-dom";
import { EditFilled } from "@ant-design/icons";
import DriverDeliveryCharge from "../DeliveryCharge";
const { Content } = Layout;

const DriverLayout = ({
  children,
  driverId,
  title,
  extra,
}: {
  children?: ReactElement;
  driverId: string;
  title: string;
  extra?: any;
}): ReactElement => {
  const navigate = useNavigate();
  const { driverInfo } = useSelector((state) => (state as any)?.authReducer);
  const [visible, setVisible] = useState<boolean>(false);
  const [submitBtn, setSubmitBtn] = useState<boolean>(false);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <main>
          <div className="grid grid-cols-2 px-2 pt-4 xl:grid-cols-3 xl:gap-6">
            <div className="col-span-full">
              <PageHeader
                className="site-page-header px-0 py-0"
                onBack={() => navigate(-1)}
                title={
                  <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                    {title}
                  </h1>
                }
                extra={[extra]}
              />
              <nav className="flex mb-0" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2">
                  <li className="inline-flex items-center">
                    <Link
                      to="#"
                      className="inline-flex items-center text-gray-700 hover:text-gray-900"
                    >
                      Delivery
                    </Link>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <svg
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <Link
                        to="/delivery-shipping/drivers"
                        className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2"
                      >
                        Driver
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <svg
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span
                        className="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                        aria-current="page"
                      >
                        {title}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="col-span-full xl:col-auto grid grid-cols-2 xl:grid-cols-1 gap-6 mb-6 xl:mb-0">
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
                <div className="sm:flex xl:block sm:space-x-4 xl:space-x-0">
                  <Avatar
                    size={100}
                    src={getImgUrl(driverInfo?.image)}
                    className="mb-5 w-20 h-20 rounded-2xl shadow-lg shadow-gray-300"
                  />
                  <div>
                    <h2 className="text-xl font-bold">
                      {driverInfo?.name?.toUpperCase()}
                    </h2>
                    <ul className="mt-2 space-y-1">
                      <li className="flex items-center text-sm font-normal text-gray-500">
                        <svg
                          className="mr-2 w-4 h-4 text-gray-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                            clipRule="evenodd"
                          ></path>
                          <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                        </svg>
                        &#2547;
                        {parseInt(driverInfo?.balance) || 0}
                      </li>
                      <li className="flex items-center text-sm font-normal text-gray-500">
                        <svg
                          className="mr-2 w-4 h-4 text-gray-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        {driverInfo?.city || "Dhaka, Bangladesh."}
                      </li>
                    </ul>
                    <Rate
                      value={driverInfo?.rating || 1}
                      className="mt-1"
                      disabled
                    />
                    <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                      {driverInfo?.numberOfRating || 0}
                    </span>
                  </div>
                </div>
                <div className="mb-4 sm:flex xl:block">
                  <div className="sm:flex-1">
                    <address className="text-sm not-italic font-normal text-gray-500">
                      <div className="mt-4">Phone number</div>
                      <div className="mb-2 text-sm font-medium text-gray-900">
                        {driverInfo?.mobileNumber?.split("+88")}
                      </div>
                    </address>
                  </div>
                </div>
                <div className="mb-4 sm:flex xl:block">
                  <div className="sm:flex-1">
                    <address className="text-sm not-italic font-normal text-gray-500">
                      <div className="mt-4 flex justify-between items-center">
                        <span>Delivery Charge</span>
                        <Button onClick={() => setVisible(true)} type="text">
                          <EditFilled />
                        </Button>
                      </div>
                      <div className="mb-2 text-sm font-medium text-gray-900">
                        &#2547;{driverInfo?.deliveryCharge}
                      </div>
                    </address>
                  </div>
                </div>
              </div>

              <Sidebar driverId={driverId} />
            </div>
            <div className="col-span-2">
              <Layout>
                <Content className="main-content-layout">{children}</Content>
              </Layout>{" "}
            </div>
          </div>
        </main>
      </Layout>

      <Modal
        title="Update Delivery Charge"
        open={visible}
        onCancel={onClose}
        okType={"danger"}
        okText="Update"
        onOk={() => setSubmitBtn(true)}
      >
        <DriverDeliveryCharge submit={submitBtn} onCloseMethod={onClose} />
      </Modal>
    </React.Fragment>
  );
};

DriverLayout.propType = {
  children: PropTypes.element,
};

export default DriverLayout;
