import {
  Avatar,
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Switch,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DriverStatus, getPage, getParamValue } from "../../../utils/index";
import Empty from "../../common/Empty";
import moment from "moment";
import { responseNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";
import DriverLayout from "./Layout";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination/Pagination";
import styles from "../../../styles/tailwind/List.module.css";
import AddVehicle from "../DeliveryHero/EditVehicle";
import SingleVehicle from "../DeliveryHero/VehicleDetails";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { confirm } = Modal;
const DriverVehicleInfoList = () => {
  const { driverInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [limit, setLimit] = useState(16);
  const [deliveryManTypeId, setDeliveryManTypeId] = useState<any>(``);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(undefined);
  const [selectedBannerForEdit, setSelectedBannerForEdit] = useState(undefined);
  const [vehiclesData, setVehiclesData] = useState<any>({
    loading: false,
    data: null,
  });

  const [selectedUser, setSelectedUser] = useState(undefined);
  const route = useParams();

  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");

  const getVehicleInformations = useCallback(async () => {
    setVehiclesData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/vehicle/all-vehicle` +
          `?driverId=${(route as any)?.id || ``}` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setVehiclesData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setVehiclesData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [route, page, limit]);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/vehicle`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getVehicleInformations();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  function showConfirm(status: any) {
    confirm({
      title: "Do you Want to change Status?",
      icon: <ExclamationCircleOutlined />,
      content:
        status === "VERIFIED" ? (
          <Radio.Group
            onChange={(e) => {
              setDeliveryManTypeId(e.target.value);
            }}
            value={deliveryManTypeId}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={12}>
                <Radio value={1}>2021</Radio>
              </Col>
              <Col span={12}>
                <Radio value={2}>2022</Radio>
              </Col>
            </Row>
          </Radio.Group>
        ) : // <Radio.Group
        //   name="Type"
        //   options={dmTypeData?.list}
        //   value={1}
        //   onChange={(e: any) => setDmType(e.target.value)}
        // />
        undefined,
      onOk() {
        onStatusChange(status, deliveryManTypeId);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
      okButtonProps: {
        danger: true,
        disabled: status === "VERIFIED" && !!deliveryManTypeId,
      },
    });
  }

  const onClose = () => {
    getVehicleInformations();
    setSelectedBanner(undefined);
    setSelectedBannerForEdit(undefined);
  };

  useEffect(() => {
    getVehicleInformations();
  }, [getVehicleInformations]);

  return (
    <React.Fragment>
      <DriverLayout
        driverId={(route as any)?.id as string}
        title="Vehicle Information"
        extra={
          <Dropdown.Button
            className="bg-white"
            overlay={
              <Menu>
                {Object?.values(DriverStatus)?.map((status, i) => (
                  <Menu.Item
                    key={status}
                    onClick={(val) => {
                      showConfirm(val?.key);
                    }}
                  >
                    {status?.split("_").join(" ") || "Status"}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            {driverInfo?.status?.split("_").join(" ") || "Status"}
          </Dropdown.Button>
        }
      >
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <table className={styles.mainTable}>
                <thead className="bg-white border-b">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created Info</th>
                    <th scope="col">Updated Info</th>
                  </tr>
                </thead>
                {vehiclesData?.loading ? (
                  <Loader />
                ) : (
                  <tbody>
                    {vehiclesData?.data?.vehicleResponses?.length ? (
                      vehiclesData?.data?.vehicleResponses?.map(
                        (vehicle: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedBanner(vehicle)}
                          >
                            <td>
                              {vehicle?.vehicleImages === null ? (
                                " "
                              ) : (
                                <Avatar
                                  size={40}
                                  src={vehicle?.vehicleImages[0]}
                                />
                              )}
                              <span className="font-medium text-gray-500 ml-2">
                                {vehicle?.brandName} {vehicle?.brandModelName}{" "}
                                {vehicle?.year}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {moment(vehicle?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={vehicle?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(vehicle?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  vehicle?.id === confirmLoading
                                }
                              />
                            </td>
                          
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {vehicle?.createdBy?.name ||
                                  vehicle?.createdBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    vehicle?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(vehicle?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(vehicle?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {vehicle?.updatedBy?.name ||
                                  vehicle?.updatedBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    vehicle?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(vehicle?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(vehicle?.updatedAt).format("lll")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
              <Pagination
                {...vehiclesData?.data}
                limit={limit}
                page={getPage(loc.search)}
              />
            </div>
            <Drawer
              title={
                visible
                  ? "Add Vehicle"
                  : selectedBannerForEdit
                  ? "Edit Vehicle"
                  : "Vehicle Details"
              }
              placement="right"
              onClose={onClose}
              open={visible || selectedBanner || selectedBannerForEdit}
              width={450}
            >
              {visible || selectedBannerForEdit ? (
                <AddVehicle
                  onCloseMethod={onClose}
                  visibleData={selectedBannerForEdit}
                />
              ) : (
                <SingleVehicle VehicleDetails={selectedBanner} />
              )}
            </Drawer>
          </div>
        </div>
      </DriverLayout>
    </React.Fragment>
  );
};

export default DriverVehicleInfoList;
