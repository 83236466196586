import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Drawer, Empty, Switch } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { responseNotification } from "../../../utils/notify";
import { Loader, AddBtn } from "../../common";
import styles from "../../../styles/tailwind/List.module.css";
import AddService from "./AddGroup";
import SingleService from "./Details";
import { useSelector } from "react-redux";
import React from "react";

const ServiceGroupList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [showSearch, setShowSearch] = useState(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [selectedGroupForEdit, setSelectedGroupForEdit] = useState(undefined);

  const [groupData, setGroupData] = useState<any>({
    loading: false,
    data: null,
  });

  const getServiceGroup = useCallback(async () => {
    setGroupData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_AUTH_API}`;
    axios
      .get(`${encodedUrl}/merchant-group/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setGroupData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setGroupData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, []);

  //
  const onStatusChange = async (id: any, val: boolean) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/merchant-group`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getServiceGroup();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getServiceGroup();
  }, [getServiceGroup]);

  const onClose = () => {
    getServiceGroup(); 
    setVisible(undefined);
    setSelectedGroup(undefined);
    setSelectedGroupForEdit(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Membership Level"
        subTitle={`${
          groupData?.data?.totalElements || groupData?.data?.groups?.length || 0
        } Membership(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className={showSearch ? `content-body` : `content-body`}>
              {groupData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Membership</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {groupData?.data?.merchantGroups?.length ? (
                      groupData?.data?.merchantGroups?.map((group: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() => setSelectedGroup(group)}
                        >
                          <td>{group?.name}</td>
                          <td>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              defaultChecked={group?.isActive}
                              onChange={(val, e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onStatusChange(group?.id, val);
                              }}
                            />
                          </td>
                          <td>
                            <Button
                              shape="round"
                              type="dashed"
                              className="bg-white"
                              onClick={() => setSelectedGroupForEdit(group)}
                            >
                              <EditOutlined />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title={
          visible
            ? "Add Membership Level"
            : selectedGroupForEdit
            ? "Edit Membership Level"
            : "Membership Level Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedGroup || selectedGroupForEdit}
        width={550}
      >
        {visible || selectedGroupForEdit ? (
          <AddService
            onCloseMethod={onClose}
            visibleData={selectedGroupForEdit}
          />
        ) : (
          <SingleService GroupDetails={selectedGroup} />
        )}
      </Drawer>
    </>
  );
};

export default ServiceGroupList;
