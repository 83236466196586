import {
  Rate,
  Avatar,
  Layout,
  Button,
  Tabs,
  Empty,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Steps,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router";
import { getImgUrl, ProductStatus } from "../../../utils";
import { Link } from "react-router-dom";
import {
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import cn from "classnames";
import moment from "moment";
import { useSelector } from "react-redux";
import AddAddon from "../Addon/AddAddon";
const { Content } = Layout;
const { TabPane } = Tabs;
const { Step } = Steps;
const { confirm } = Modal;

const SingleProduct = ({
  inShop = false,
}: {
  inShop: boolean;
}): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);

  const navigate = useNavigate();
  const route = useParams();
  const [showForm, setShowForm] = useState(false);
  const [selectedAddonForEdit, setSelectedAddonForEdit] = useState(false);
  const [singleProductInfo, setSingleProductInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchProductDetails = useCallback((getProductSlug: any) => {
    if (getProductSlug) {
      try {
        setSingleProductInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/product/details?slug=${getProductSlug}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleProductInfo({
                loading: false,
                data: data?.data?.product,
              });
            } else {
              setSingleProductInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleProductInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleProductInfo({ loading: false, data: null });
      }
    }
  }, []);

  const onStatusChange = async (status: any) => {
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/product/approve-product`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productIds: [product?.id],
          status: status,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          fetchProductDetails((route as any)?.slug);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(status: any) {
    confirm({
      title: "Do you Want to change Status?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onStatusChange(status);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
    });
  }

  useEffect(() => {
    fetchProductDetails((route as any)?.slug);
  }, [fetchProductDetails, route]);

  useEffect(() => {
    if (selectedAddonForEdit) {
      setShowForm(true);
    }
  }, [selectedAddonForEdit]);

  const onclose = () => {
    fetchProductDetails((route as any)?.slug);
    setSelectedAddonForEdit(undefined);
    setShowForm(false);
  };
  const product = singleProductInfo?.data;

  return (
    <React.Fragment>
      <main>
        <div
          className={cn("grid grid-cols-3 xl:grid-cols-3 gap-6 xl:gap-6", {
            ["grid grid-cols-1 xl:grid-cols-1 xl:gap-0 xl:gap-y-6"]: inShop,
          })}
        >
          {/* HEADER AREA */}
          <div className={cn("col-span-3", { ["col-span-2"]: inShop })}>
            <PageHeader
              className="site-page-header px-0 py-0"
              onBack={() => navigate(-1)}
              title={
                <h1 className="text-xl font-medium text-gray-900 sm:text-2xl">
                  Product Details
                </h1>
              }
              extra={
                <>
                  <Button
                    type="dashed"
                    shape="round"
                    className="bg-white"
                    onClick={() => navigate(`/products/${product?.slug}/edit`)}
                  >
                    <EditOutlined />
                  </Button>
                  <Dropdown.Button
                    className="bg-white"
                    overlay={
                      <Menu>
                        {Object?.values(ProductStatus)?.map((status) => (
                          <Menu.Item
                            key={status}
                            onClick={(val) => {
                              showConfirm(val?.key);
                            }}
                          >
                            {status?.split("_").join(" ") || "Status"}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    {product?.status?.split("_").join(" ") || "Status"}
                  </Dropdown.Button>
                </>
              }
            />
            <nav className="flex mb-0" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-2">
                <li className="inline-flex items-center">
                  <Link
                    to="#"
                    className="inline-flex items-center text-gray-700 hover:text-gray-900"
                  >
                    Product
                  </Link>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span
                      className="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                      aria-current="page"
                    >
                      Details
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
          </div>

          {/* SIDEBAR ARE */}
          <div
            className={cn(
              "col-span-full xl:col-auto grid gap-6 grid-cols-2 xl:grid-cols-1",
              {
                ["grid-cols-1 xl:grid-cols-1 xl:col-span-3"]: inShop,
              }
            )}
          >
            {/* THUMBNAIL AREA */}
            <div
              className={cn(
                "bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 col-span-1",
                { ["col-span-1 xl:col-span-1 mb-0"]: inShop }
              )}
            >
              <div className="xl:block sm:space-x-4 xl:space-x-0">
                <img
                  src={getImgUrl(product?.productImage)}
                  className={cn(
                    "mb-5 rounded-2xl _shadow-lg shadow-gray-300 max-h-96 mx-auto object-cover",
                    { ["max-h-60"]: inShop }
                  )}
                />

                <div>
                  <h2 className="text-md font-medium">
                    {product?.name?.toUpperCase()}
                  </h2>

                  <Rate
                    value={product?.rating || 1}
                    className="mt-1"
                    disabled
                  />
                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                    {product?.numberOfRating || 0}
                  </span>
                  <ul className="mt-2 space-y-1">
                    <li className="flex items-center text-sm font-normal text-gray-500">
                      <span className="font-semibold mr-3">Total Visitor:</span>{" "}
                      <span className="text-xl">{product?.visitors || 0}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* IMAGES AREA */}
            {!!product?.images?.length && (
              <div
                className={cn(
                  "bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 col-span-1",
                  { ["xl:col-span-1  col-span-1"]: inShop }
                )}
              >
                <div className="flow-root">
                  <h3 className="text-xl font-bold mb-4">Images</h3>

                  <div className="flex flex-wrap gap-4">
                    {product?.images?.map((image: any, index: string) => (
                      <div key={index}>
                        <Avatar
                          src={image}
                          size={97}
                          shape="square"
                          className="rounded-lg shadow-md"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* CONTENT AREA */}
          <div
            className={cn("col-span-3 xl:col-span-2", {
              ["xl:col-span-3"]: inShop,
            })}
          >
            <Layout>
              <Content className="main-content-layout">
                <div className="content-body_ rounded-2xl">
                  <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
                    <Tabs tabPosition="top">
                      <TabPane tab="GENERAL INFORMATION" key="General">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          <div className="col-span-1">
                            <dt className="text-sm font-medium text-gray-900 mb-1">
                              Product Name
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.name?.trim()}
                            </dd>
                          </div>
                          <div className="">
                            <dt className="text-sm font-medium text-gray-900 mb-1">
                              Slug
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.slug}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Product ID
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.id}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Barcode
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.barCode}
                            </dd>
                          </div>
                          {product?.sku && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                SKU
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {product?.sku}
                              </dd>
                            </div>
                          )}
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Category:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <Avatar src={product?.category?.image} />{" "}
                              {product?.category?.title}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Category Slug:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.category?.slug}
                            </dd>
                          </div>

                          {product?.brand && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Brand:
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                <Avatar src={product?.brand?.logo} />{" "}
                                {product?.brand?.name}
                              </dd>
                            </div>
                          )}

                          {product?.campaign && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Campaign:
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                <Avatar src={product?.campaign?.logo} />{" "}
                                {product?.campaign?.name}
                              </dd>
                            </div>
                          )}

                          {product?.city && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                City
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {product?.city}
                              </dd>
                            </div>
                          )}

                          {product?.zone && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Zone
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {product?.zone}
                              </dd>
                            </div>
                          )}

                          {product?.type && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Type
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {product?.type}
                              </dd>
                            </div>
                          )}

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Rattings
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <Rate value={product?.rating || 1} disabled />
                              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                {product?.numberOfRating || 0}
                              </span>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Allow Warranty?
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.isAllowWarranty ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.isAllowWarranty
                                  ? "Active"
                                  : "Inactive"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Is Returnable?
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.isReturnable ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.isReturnable ? "Active" : "Inactive"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Exclude From COD
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.excludeCOD ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.excludeCOD ? "Active" : "Inactive"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Exclude From Promo
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.excludeFromPromo ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.excludeFromPromo
                                  ? "Active"
                                  : "Inactive"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Status
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.isActive ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.isActive ? "Active" : "Inactive"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Booking Enabled
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.bookingEnabled ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.bookingEnabled
                                  ? "Enabled"
                                  : "Disabled"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              isPopular
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.isPopular ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.isPopular ? "Active" : "Inactive"}
                              </div>
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              isPreOrder
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.isPreOrder ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.isPreOrder ? "True" : "False"}
                              </div>
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              prePaidAmount
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.prePaidAmount}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              paymentTimeInSeconds
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.paymentTimeInSeconds}
                            </dd>
                          </div>
                        </dl>
                      </TabPane>

                      {type === "JC_FOOD" && (
                        <TabPane tab="ADDONS" key="ADDONS">
                          <Button
                            type="primary"
                            shape="round"
                            onClick={() => setShowForm(!showForm)}
                            icon={
                              showForm ? <CloseOutlined /> : <PlusOutlined />
                            }
                            className="mb-4 px-8 bg-sky-500"
                            danger={showForm}
                          >
                            <span className="font-semibold">
                              {showForm ? "Cancel" : "Add"}
                            </span>
                          </Button>
                          {showForm ? (
                            <AddAddon
                              addonData={selectedAddonForEdit}
                              productId={product?.id}
                              refetch={onclose}
                              edit={showForm}
                            />
                          ) : (
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                              {product?.addOns?.length ? (
                                product?.addOns?.map(
                                  (addon: any, index: string) => (
                                    <AddAddon
                                      addonData={addon}
                                      productId={product?.id}
                                      refetch={onclose}
                                      edit={showForm}
                                      key={index}
                                      extra={
                                        <Button
                                          type="dashed"
                                          shape="circle"
                                          onClick={() =>
                                            setSelectedAddonForEdit(addon)
                                          }
                                        >
                                          <EditOutlined />
                                        </Button>
                                      }
                                    />
                                  )
                                )
                              ) : (
                                <Empty />
                              )}
                            </div>
                          )}
                        </TabPane>
                      )}

                      <TabPane tab="VARIATIONS" key="Variations">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                          {product?.variations?.length ? (
                            product?.variations?.map(
                              (variant: any, index: string) => (
                                <>
                                  <div
                                    key={index}
                                    className="flex flex-col p-3 max-w-md rounded-2xl shadow-sm transform cursor-move mb-5 bg-slate-50"
                                  >
                                    <div className="flex justify-start items- ncenter pb-4">
                                      <Avatar
                                        size={80}
                                        className="bg-contain rounded-2xl"
                                        src={variant?.imageUrl}
                                        alt="attachment"
                                      />
                                    </div>

                                    <div className="flex justify-between py-1">
                                      <div className="font-medium">
                                        Variation:
                                      </div>
                                      <div className="text-sm font-normal text-gray-700">
                                        {variant?.variationName}
                                      </div>
                                    </div>

                                    {variant?.barCode && (
                                      <div className="flex justify-between py-1">
                                        <div className="font-medium">
                                          Barcode
                                        </div>
                                        <div className="text-sm font-normal text-gray-700">
                                          {variant?.barCode}
                                        </div>
                                      </div>
                                    )}

                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">
                                        Variation Id
                                      </span>
                                      <span className="text-sm font-normal text-gray-700">
                                        {variant?.variationId}
                                      </span>
                                    </div>

                                    {variant?.maximumOrderLimit && (
                                      <div className="flex justify-between py-1">
                                        <span className="font-medium">
                                          Maximum Order Limit
                                        </span>
                                        <span className="text-sm font-normal text-gray-700">
                                          {variant?.maximumOrderLimit}
                                        </span>
                                      </div>
                                    )}

                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">Stock</span>
                                      <span className="text-sm font-normal text-gray-700">
                                        <span
                                          className={`font-bold bg-white_border rounded-full py-1_px-3 ${
                                            variant?.stock < 10
                                              ? `text-red-600`
                                              : `text-green-600`
                                          }`}
                                        >
                                          {variant?.stock}
                                        </span>
                                      </span>
                                    </div>

                                    <Divider orientation="left">
                                      Product Price
                                    </Divider>

                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">MRP</span>
                                      <span className="text-sm font-normal text-gray-700">
                                        {variant?.price?.mrp}{" "}
                                        {variant?.price?.currencyCode}
                                      </span>
                                    </div>

                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">
                                        TP{" "}
                                        <span className="text-xs font-light">
                                          (Merchant Price)
                                        </span>
                                      </span>
                                      <span className="text-sm font-normal text-gray-700">
                                        {variant?.price?.tp}{" "}
                                        {variant?.price?.currencyCode}
                                      </span>
                                    </div>

                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">
                                        Discount in Amount
                                      </span>
                                      <span className="text-sm font-normal text-gray-700">
                                        {variant?.productDiscount?.flat || 0}{" "}
                                        {variant?.price?.currencyCode}
                                      </span>
                                    </div>

                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">
                                        Discount in Percentage
                                      </span>
                                      <span className="text-sm font-normal text-gray-700">
                                        {variant?.productDiscount?.percentage ||
                                          0}{" "}
                                        {"%"}
                                      </span>
                                    </div>

                                    {
                                      // data.item?.products?.length ?
                                      variant?.quantitativeProductDiscount
                                        ?.freeProduct ? (
                                        <div className="">
                                          <h6 className="mt-5 border-bottom pb-2">
                                            <b>
                                              Quantitative Product Discount:
                                            </b>
                                          </h6>
                                          <div className="flex justify-between py-1">
                                            <span className="font-medium">
                                              Free Product
                                            </span>
                                            <span className="text-sm font-normal text-gray-700">
                                              {
                                                variant
                                                  ?.quantitativeProductDiscount
                                                  ?.freeProduct
                                              }
                                            </span>
                                          </div>
                                          <div className="flex justify-between py-1">
                                            <span className="font-medium">
                                              Minimum Quantity
                                            </span>
                                            <span className="text-sm font-normal text-gray-700">
                                              {
                                                variant
                                                  ?.quantitativeProductDiscount
                                                  ?.minimumQuantity
                                              }
                                            </span>
                                          </div>
                                          <div className="flex justify-between py-1">
                                            <span className="font-medium">
                                              Product Discount in Amount
                                            </span>
                                            <span className="text-sm font-normal text-gray-700">
                                              {
                                                variant
                                                  ?.quantitativeProductDiscount
                                                  ?.productDiscount?.flat
                                              }
                                            </span>
                                          </div>
                                          <div className="flex justify-between py-1">
                                            <span className="font-medium">
                                              Product Discount in Percent
                                            </span>
                                            <span className="text-sm font-normal text-gray-700">
                                              {
                                                variant
                                                  ?.quantitativeProductDiscount
                                                  ?.productDiscount?.percentage
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    }

                                    {variant?.price?.sellerDiscountedPrice && (
                                      <div className="flex justify-between py-1">
                                        <span className="font-medium">
                                          Discounted Price
                                        </span>
                                        <span className="text-sm font-normal text-gray-700">
                                          {
                                            variant?.price
                                              ?.sellerDiscountedPrice
                                          }{" "}
                                          {variant?.price?.currencyCode}
                                        </span>
                                      </div>
                                    )}

                                    {variant?.price?.discountedPrice && (
                                      <>
                                        <div className="flex justify-between py-1">
                                          <span className="font-medium">
                                            Sale Price -{" "}
                                            <span className="text-xs font-light">
                                              Subsidy (-
                                              {variant?.subsidyAmount}
                                              {variant?.subsidyType == "FLAT"
                                                ? "Tk"
                                                : "%"}
                                              )
                                            </span>
                                          </span>
                                          <span className="text-sm font-normal text-gray-700">
                                            {variant?.price?.discountedPrice}{" "}
                                            {variant?.price?.currencyCode}
                                          </span>
                                        </div>
                                        <div className="flex justify-between py-1">
                                          <span className="font-medium">
                                            Revenue
                                          </span>
                                          <span className="text-sm font-normal text-gray-700">
                                            {Math.round(
                                              variant?.price?.discountedPrice -
                                                variant?.price?.tp
                                            )}
                                            {variant?.price?.currencyCode}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              )
                            )
                          ) : (
                            <Empty />
                          )}
                        </div>
                      </TabPane>

                      {product?.description && (
                        <TabPane tab="DESCRIPTIONS" key="Description">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                            <div>
                              <dt className="text-md font-bold text-gray-700">
                                Description
                              </dt>
                              <dd
                                className="mt-1 space-y-3 text-sm text-gray-500"
                                dangerouslySetInnerHTML={{
                                  __html: product?.description,
                                }}
                              ></dd>
                            </div>
                          </dl>
                        </TabPane>
                      )}

                      {product?.faq && (
                        <TabPane tab="FAQ" key="FAQ">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                            <div>
                              <dt className="text-md font-bold text-gray-700">
                                FAQ's
                              </dt>
                              <dd
                                className="mt-1 space-y-3 text-sm text-gray-500"
                                dangerouslySetInnerHTML={{
                                  __html: product?.faq,
                                }}
                              ></dd>
                            </div>
                          </dl>
                        </TabPane>
                      )}

                      <TabPane tab="SEO" key="SEO">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                         
                            <div>
                              <dt className="text-md font-bold text-gray-700">
                                Meta Title
                              </dt>
                              <dd className="text-sm font-light text-gray-500">
                                {product?.metaTitle}
                              </dd>
                            </div>
                         
                          <div>
                            <dt className="text-md font-bold text-gray-700">
                              Meta Keyword
                            </dt>
                            <dd className="text-sm font-light text-gray-500">
                              {product?.keyword}
                            </dd>
                          </div>
                         
                            <div>
                              <dt className="text-md font-bold text-gray-700">
                                Meta Description
                              </dt>
                              <dd className="text-sm font-light text-gray-500">
                                {product?.metaDescription}
                              </dd>
                            </div>
                         
                        </dl>
                      </TabPane>

                      {!inShop && (
                        <TabPane tab="SHOP INFO" key="ShopInfo">
                          <div className="flex flex-col max-w-md bg-white rounded-2xl shadow-sm transform cursor-move">
                            <div className="flex justify-center items- ncenter pb-4">
                              <img
                                className="bg-contain rounded-2xl"
                                src={product?.shop?.banner}
                                alt="attachment"
                              />
                            </div>
                            <div className="p-4">
                              <div className="flex flex-col-1 -mt-20">
                                <Avatar
                                  src={product?.shop?.logo}
                                  size={100}
                                  className="shadow-lg border-4 border-white"
                                />{" "}
                                <div className="pb-4 text-lg font-bold text-gray-700 mt-14 mb-8 ml-4 text-">
                                  {product?.shop?.name?.toUpperCase()}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Shop Slug:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {product?.shop?.slug}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Shop ID:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {product?.shop?.id}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">
                                  Contact Number:
                                </div>
                                <div className="text-sm font-normal text-gray-700">
                                  {product?.shop?.contactNumber}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Address:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {product?.shop?.address}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Description:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {product?.shop?.description}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Prepare Time:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {product?.shop?.prepareTime}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">
                                  Base Delivery Charge:
                                </div>
                                <div className="text-sm font-normal text-gray-700">
                                  &#2547;{product?.shop?.baseDeliveryCharge}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">
                                  Delivery Condition:
                                </div>
                                <div className="text-sm font-normal text-gray-700">
                                  {product?.shop?.isFreeDelivery ? (
                                    <div>Free Delivert</div>
                                  ) : (
                                    <div>Paid Delivert</div>
                                  )}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Status:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {product?.shop?.status}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Rattings:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  <Rate
                                    value={product?.rating || 1}
                                    className="mt-1"
                                    disabled
                                  />
                                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                    {product?.numberOfRating || 0}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                      )}

                      <TabPane tab="LOGS" key="Logs">
                        <div className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 mb-10">
                          {product?.createdBy && (
                            <div className="rounded-md border text-center p-4 max-w-5xl">
                              <div className="text-lg font-bold text-gray-700">
                                Created By
                              </div>
                              <Avatar
                                src={product?.createdBy?.profilePicture}
                                size={80}
                                className="shadow-md border my-4"
                              />
                              <div className="text-sm font-semibold text-gray-500">
                                {product?.createdBy?.name}
                              </div>
                              <div className="text-sm font-light text-gray-500">
                                {product?.createdBy?.mobileNumber?.replace(
                                  "+88",
                                  ""
                                )}
                              </div>
                            </div>
                          )}

                          {product?.updatedBy && (
                            <div className="rounded-md border text-center p-4 max-w-5xl">
                              <div className="text-lg font-bold text-gray-700">
                                Updated By
                              </div>
                              <Avatar
                                src={product?.updatedBy?.profilePicture}
                                size={80}
                                className="shadow-md border my-4"
                              />
                              <div className="text-sm font-semibold text-gray-500">
                                {product?.updatedBy?.name}
                              </div>
                              <div className="text-sm font-light text-gray-500">
                                {product?.updatedBy?.mobileNumber}
                              </div>
                            </div>
                          )}

                          {product?.approvedBy && (
                            <div className="rounded-md border text-center p-4 max-w-5xl">
                              <div className="text-lg font-bold text-gray-700">
                                Approved By
                              </div>
                              <Avatar
                                src={product?.approvedBy?.profilePicture}
                                size={80}
                                className="shadow-md border my-4"
                              />
                              <div className="text-sm font-semibold text-gray-500">
                                {product?.approvedBy?.name}
                              </div>
                              <div className="text-sm font-light text-gray-500">
                                {product?.approvedBy?.mobileNumber}
                              </div>
                            </div>
                          )}
                        </div>

                        {product?.updatedLogs && (
                          <>
                            <h4 className="mb-4 font-bold text-lg text-sky-900 border-b pb-2 ">
                              Updated Logs
                            </h4>
                            <Steps direction="vertical" size="default">
                              {product?.updatedLogs?.map(
                                (data: any, i: string) => (
                                  <Step
                                    key={i}
                                    icon={
                                      <Avatar
                                        src={data?.updatedBy?.profilePicture}
                                        className="border shadow-md"
                                      />
                                    }
                                    title={data?.updatedBy?.name}
                                    subTitle={moment(data?.updatedAt).format(
                                      "lll"
                                    )}
                                    description={data?.updatedBy?.mobileNumber}
                                  />
                                )
                              )}
                            </Steps>
                          </>
                        )}
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </Content>
            </Layout>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

SingleProduct.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
  selectedProduct: PropTypes.string,
};
export default SingleProduct;
