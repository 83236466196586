import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  AddBtn,
  Loader,
  LoaderFull,
  PaginationThree,
  SubmitResetBtn,
} from "../../common";
import moment from "moment";
import axios from "axios";
import styles from "../../../styles/tailwind/List.module.css";
import { getPage, getParamValue } from "../../../utils";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Radio,
  Select,
  Switch,
  Tabs,
  Tag,
} from "antd";
import classNames from "classnames";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { error } from "console";
import { responseNotification } from "../../../utils/notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import AddRate from "../../common/AddRate";
import EditProfile from "./EditProfile";
const MyShippingProfileList = (): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [productType, setProductType] = useState(initialState?.productType);
  const pageType: any = getParamValue(loc.search, "page-type");
  const [brandId, setBrandId] = useState<any>();
  const [key, setKey] = useState("");
  const [stockInOut, setStockInOut] = useState<any>();
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const [campaignId, setCampaignId] = useState<any>();
  const [isActive, setIsActive] = useState<any>();
  const [categoryId, setCategoryId] = useState<any>();
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [size, setSize] = useState<SizeType>("large");
  const [barCode, setBarCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [allChecked, setAllChecked] = useState<any>(false);
  const [isChecked, setIsChecked] = useState<any>([]);
  const [selectedBrandForEdit, setSelectedBrandForEdit] = useState(undefined);
  const [domesticZoneType, setDomesticZoneType] = useState<any>("DOMESTIC");
  const [internationalZoneType, setInternationalZoneType] =
    useState<any>("INTERNATIONAL");
  const [zoneData, setZoneData] = useState<any>({
    loading: false,
    data: null,
  });
  
  const [internationalZoneData, setInternationalZoneData] = useState<any>({
    loading: false,
    data: null,
  });
  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });
  const [visible, setVisible] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [packingUnitType, setPackingUnitType] = useState<any>();
  const [whoPrepsUnit, setWhoPrepsUnit] = useState<any>(undefined);
  const [whoLabelUnits, setWhoLabelUnits] = useState<any>(undefined);
  const [defaultSellerPackage, setDefaultSellerPackage] = useState(false);
  const [packingType, setPackingType] = useState<any>();
  const route = useParams();
  const shopId = (route as any)?.shopId;
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [shopData, setShopData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [profileData, setProfileData] = useState<any>({
    loading: false,
    data: null,
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      packageUnitType: {
        caseUnits: packingUnitType === "caseUnits" ? true : false,
        groupUnits: packingUnitType === "groupUnits" ? true : false,
        individualUnits: packingUnitType === "individualUnits" ? true : false,
      },
      packageType: {
        box: packingType === "box" ? true : false,
        envelope: packingType === "envelope" ? true : false,
        softPackageOrSatchel:
          packingType === "softPackageOrSatchel" ? true : false,
      },
      isCustomPackage: true,
      shopId: (route as any)?.shopId,
      whoLabelsUnits: whoLabelUnits,
      whoPrepsUnits: whoPrepsUnit,
      packageSizeAndWeight: {
        height: data.height,
        length: data.length,
        totalBoxWeight: data.weight,
        unitPerBox: data.unitPerBox,
        unitType: data.unit,
        width: data.width,
      },
      isMakeThisTheDefaultSellerPackage: defaultSellerPackage,
    };
    await fetch(`${process.env.REACT_APP_ORDER_API}/package/add-package`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Package Create Successfully", "success");
          form.resetFields();
          navigate(-1);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };



  const getProfileConfiguration = useCallback(async () => {
    setProfileData({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/delivery-profile-configuration/all?shopId=${(route as any)?.shopId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProfileData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setProfileData({ loading: false, data: [] });
        console.error("Brand: Error", err);
      });
  }, []);
  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/brand`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getProfileConfiguration();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
  };
  useEffect(() => {
    getProfileConfiguration();
  }, [getProfileConfiguration]);

  const onClose = () => {
    getProfileConfiguration();
    setVisible(undefined);
    setSelectedBrandForEdit(undefined);
  };

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Profile List"
        subTitle="Profile List"
        extra={
          <>
            <AddRate
              onClick={() => {
                navigate(`/shops/${shopId}/my-add-rate`);
              }}
            />
          </>
        }
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    <div className="content-body">
                    {profileData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                          <th scope="col">Action</th>
                          <th scope="col">Created Info</th>
                          <th scope="col">Updated Info</th>
                      
                    </tr>
                  </thead>

                  <tbody>
                    {profileData?.data?.deliveryConfigurationCreateProfiles?.length ? (
                      profileData?.data?.deliveryConfigurationCreateProfiles?.map((profiles: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() =>
                            navigate(`/brands/${profiles?.id}/products`)
                          }
                          draggable="true"
                        >
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {profiles?.name}
                            </span>
                          </td>
             
                          

                              <td>
                                <div className="flex gap-2">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setSelectedBrandForEdit(profiles);
                                    }}
                                  >
                                    <EditOutlined />
                                  </Button>
                                </div>
                              </td>
                              <td>
                                <small className="font-bold uppercase text-gray-500">
                                  {profiles?.createdBy?.name ||
                                    profiles?.createdBy?.mobileNumber}{" "}
                                  -{" "}
                                </small>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(
                                      profiles?.createdAt,
                                      "minutes"
                                    ) >= 60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(profiles?.createdAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(profiles?.createdAt).format("lll")}
                                </span>
                              </td>
                              <td>
                                <small className="font-bold uppercase text-gray-500">
                                  {profiles?.updatedBy?.name ||
                                    profiles?.updatedBy?.mobileNumber}{" "}
                                  -{" "}
                                </small>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(
                                      profiles?.updatedAt,
                                      "minutes"
                                    ) >= 60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(profiles?.updatedAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(profiles?.updatedAt).format("lll")}
                                </span>
                              </td>
                      
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}



<Drawer
        title={
          visible
            ? "Add Profile"
            : selectedBrandForEdit
            ? "Edit Profile"
            : "Profile Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedBrandForEdit}
        width={450}
      >
        {visible || selectedBrandForEdit ? (
          <EditProfile
            onCloseMethod={onClose}
            visibleData={selectedBrandForEdit}
          />
        ) : (
          ""
        )}
      </Drawer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

MyShippingProfileList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default MyShippingProfileList;
