import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Input,
  Rate,
  Select,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { debounce } from "lodash";
import BreadCrumb from "../../Layouts/Breadcrumb";
import AddBtn from "../../common/AddBtn";
import { DriverStatus, getPage, getParamValue } from "../../../utils";
import Loader from "../../common/Loader";
import AddStore from "./AddStore";
import Pagination from "../../common/Pagination/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../../styles/tailwind/List.module.css";
import React from "react";
import { useSelector } from "react-redux";
const { Option } = Select;

const PathaoStoreList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [visible, setVisible] = useState<any>(undefined);
  const [showSearch, setShowSearch] = useState(true);
  const [selectedPathaoStore, setSelectedPathaoStore] = useState(undefined);
  const [mobileNumber, setMobileNumber] = useState("");
  const navigate = useNavigate();

  const [pathaoStoreData, setPathaoStoreData] = useState<any>({
    loading: false,
    data: null,
  });
  const [pathaoStoresOptions, setPathaoStoresOptions] = useState({
    loading: false,
    list: null,
  });

  const getPathaoStores = useCallback(async () => {
    setPathaoStoreData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(`${encodedUrl}/pathao/pathao-store`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPathaoStoreData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setPathaoStoreData({ loading: false, data: [] });
        console.error("Pathao Store: Error", err);
      });
  }, []);

  const getPathaoStoresOptions = useCallback(
    async (mobileNumber: string) => {
      setPathaoStoresOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUri}/admin/driver?` +
            (mobileNumber
              ? `&mobileNumberOrName=${mobileNumber?.replace("+88", "")}`
              : ``) +
            (status ? `&status=${status}` : ``) +
            `&page=${0}` +
            `&limit=${20}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setPathaoStoresOptions({
            loading: false,
            list: res.data?.drivers?.map((driver: any) => ({
              label: `${driver.mobileNumber.replace("+88", "")} - ${
                driver.name
              }`,
              value: driver.mobileNumber.replace("+88", ""),
            })),
          });
        })
        .catch((err) => {
          setPathaoStoresOptions({ loading: false, list: null });
          console.error("PathaoStores: Error", err);
        });
    },
    [status]
  );

  const fetchRef = useRef<any>(null);
  const getPathaoStoresOptionsDebounce = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setPathaoStoresOptions({ loading: false, list: null });
      if (fetchId !== fetchRef.current) {
        return;
      }
      getPathaoStoresOptions(value);
    };

    return debounce(loadOptions, 800);
  }, [getPathaoStoresOptions]);

  useEffect(() => {
    getPathaoStores();
  }, [getPathaoStores]);

  useEffect(() => {
    if (showSearch) getPathaoStoresOptions("");
  }, [showSearch]);

  const onClose = () => {
    setSelectedPathaoStore(undefined);
    setVisible(undefined);
  };

  const reseAllFieldData = () => {
    form.resetFields();
    setStatus("");
    setMobileNumber("");
  };

  return (
    <>
      <BreadCrumb
        title="Pathao Store List"
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {pathaoStoreData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Address</th>
                    </tr>
                  </thead>

                  <tbody>
                    {pathaoStoreData?.data?.data?.data?.length ? (
                      pathaoStoreData?.data?.data?.data?.map(
                        (pathaoStore: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>{pathaoStore?.store_name}</td>
                            <td>{pathaoStore?.store_address}</td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title={
          selectedPathaoStore ? "Pathao Store Details" : "Add Pathao Store"
        }
        placement="right"
        onClose={onClose}
        open={selectedPathaoStore || visible}
        width={500}
      >
        <AddStore StoreDetails={selectedPathaoStore} onCloseMethod={onClose} />
      </Drawer>
    </>
  );
};

export default PathaoStoreList;
