import { Button, DatePicker, Drawer, Empty, Form, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import Loader from "../common/Loader";
import { debounce } from "lodash";
import Pagination from "../common/Pagination/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import React from "react";
import { useSelector } from "react-redux";
import AddHistory from "./AddHistory";
import SingleHistory from "./VRHistoryDetails";
const { RangePicker } = DatePicker;

const VrHistoryList = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(20);
  const [visible, setVisible] = useState<any>(undefined);
  const [visibleModal, setVisibleModal] = useState<any>(undefined);
  const [showSearch, setShowSearch] = useState(true);
  const [secondDrawer, setSecondDrawer] = useState(false);
  const [vrHistoryData, setVrHistoryData] = useState<any>({
    loading: false,
    data: null,
  });
  const [selectedHistory, setSelectedHistory] = useState(undefined);
  const [selectedHistoryForEdit, setSelectedHistoryForEdit] =
    useState(undefined);

  const [vrData, setVrData] = useState<any>({
    loading: false,
    data: null,
  });

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const getVrHistory = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setVrHistoryData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(
        `${encodedUri}/admin/topup/vr-history?` +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``) +
          (page ? `&page=${page}` : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setVrHistoryData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setVrHistoryData({ loading: false, data: [] });
        console.error(" Error", err);
      });
  }, [range.from, range.to, page, limit]);

  const getVRBalance = useCallback(async () => {
    setVrData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(`${encodedUri}/admin/topup/vr-balance`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setVrData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setVrData({ loading: false, data: [] });
        console.error("Promo: Error", err);
      });
  }, []);

  useEffect(() => {
    getVrHistory();
  }, [getVrHistory]);

  useEffect(() => {
    if (visibleModal) {
      getVRBalance();
    }
  }, [getVRBalance, visibleModal]);

  const onChange = (newValue: string) => {};

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
      }
    };

    return debounce(loadOptions, 800);
  }, []);

  useEffect(() => {
    if (showSearch) {
    }
  }, [showSearch]);

  useEffect(() => {
    if (secondDrawer) {
      form.resetFields();
    }
  }, [secondDrawer, form]);

  useEffect(() => {
    getVrHistory();
  }, [getVrHistory]);

  useEffect(() => {
    if (visibleModal) {
      getVRBalance();
    }
  }, [getVRBalance, visibleModal]);

  const reseAllFieldData = () => {
    form.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };

  const showModal = () => {
    setVisibleModal(true);
  };

  const handleOk = () => {
    setVisibleModal(undefined);
  };

  const handleCancel = () => {
    setVisibleModal(undefined);
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(undefined);
    getVrHistory();
  };
  return (
    <>
      <BreadCrumb
        title="VR History"
        subTitle={`${vrHistoryData?.data?.totalElements} Data(s)`}
        extra={[
          <Button
            type="dashed"
            shape="round"
            onClick={showModal}
            className="mr-2"
          >
            Balance
          </Button>,
          <Button type="dashed" shape="round" danger onClick={showDrawer}>
            Add History
          </Button>,
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {vrHistoryData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {vrHistoryData?.data?.history?.length ? (
                      vrHistoryData?.data?.history?.map(
                        (data: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="name">
                                {moment(data?.createdAt)?.format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {data?.paymentMethod}
                              </span>
                            </td>

                            <td>
                              <span className="name">
                                &#2547;{data?.amount}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...vrHistoryData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />

        <Modal
          title="VR BALANCE"
          visible={visibleModal}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Got It !"
        >
          <div className="text-center text-uppercase">
            <p>Client: {vrData?.data?.balance?.clientId}</p>
            <p>
              Available Credit: &#2547;
              {vrData?.data?.balance?.availableCredit}
            </p>
            <p>
              Updated Date:{" "}
              {moment(vrData?.data?.balance?.lastUpdatedTime).format("lll")}
            </p>
          </div>
        </Modal>

        <Drawer
          title={
            selectedHistoryForEdit || visible
              ? "Add/Edit History"
              : "VR Details"
          }
          width={500}
          onClose={onClose}
          visible={selectedHistory || selectedHistoryForEdit || visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            ></div>
          }
        >
          {selectedHistoryForEdit || visible ? (
            <AddHistory
              visibleData={selectedHistoryForEdit}
              onCloseMethod={onClose}
            />
          ) : (
            <SingleHistory HistoryDetails={selectedHistory} />
          )}
        </Drawer>
      </div>
    </>
  );
};

export default VrHistoryList;
