import { authenticateToken } from "../../utils/auth";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { Avatar, Button, Drawer, Empty, Form, Select, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue, getImgUrl } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { debounce } from "lodash";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";
import React from "react";
import usePrevious from "../../hooks/PreviousState";
import AddCorporate from "./AddCorporate";
import AddHub from "./AddCorporate";
import { AddBtn, Loader, LoaderFull, Pagination } from "../common";
import { useSelector } from "react-redux";

const CorporateList = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);

  const router = useLocation();
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [visible, setvisible] = useState<boolean>(false);

  const showDrawer = () => {
    setvisible(true);
  };

  const [corporateOptions, setCorporateOptions] = useState<any>({
    loading: false,
    list: [],
  });
  const [key, setKey] = useState(getParamValue(router.search, "key"));
  const [selectedCorporate, setSelectedCorporate] = useState<any>(undefined);
  const [selectedCorporateForEdit, setSelectedCorporateForEdit] =
    useState<any>(undefined);
  const [status, setStatus] = useState("");
  const [limit, setLimit] = useState(16);
  const [corporateData, setCorporateData] = useState<any>({
    loading: false,
    data: null,
  });
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  // const previousType = usePrevious(type);
  const previousKey = usePrevious(key);

  window.onbeforeunload = () => {
    saveCurrentStatesInLocalStorage();
  };
  function checkLocalStorageAndSetParams() {
    var retrievedObject = JSON.parse(
      localStorage.getItem("rememberCorporateList") as string
    );

    if (retrievedObject.mobileNumber) setKey(retrievedObject.key);
    // if (retrievedObject.type) setType(retrievedObject.type);
  }

  const saveCurrentStatesInLocalStorage = () => {
    localStorage.setItem(
      "rememberCorporateList",
      JSON.stringify({
        key: key,
        // type: type,
        page: page,
      })
    );
  };

  const getCorporate = useCallback(async () => {
    setCorporateData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/company/all?` +
          (page ? `&page=${page}` : ``) +
          (limit ? `&limit=${limit}` : ``) +
          (key ? `&name=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCorporateData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCorporateData({ loading: false, data: [] });
        console.error("Brand: Error", err);
      });
  }, [key, limit, page]);

  useEffect(() => {
    getCorporate();
  }, [getCorporate]);

  const getCorporateOptions = useCallback(async (key?: string) => {
    setCorporateOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    const res = await axios.get(
      `${encodedUri}/company/all?` +
        (page ? `&page=${page}` : ``) +
        (limit ? `&limit=${limit}` : ``) +
        +(key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCorporateOptions({
      loading: false,
      list: res?.data?.companies?.map((company: any) => ({
        label: company.name,
        value: company.name,
      })),
    });
  }, []);

  //
  const onStatusChange = async (id, val: any) => {
    setConfirmLoading(id);
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/company`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConfirmLoading(undefined);
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getCorporate();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "corporate") getCorporateOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCorporateOptions]);

  useEffect(() => {
    if (showSearch) {
      getCorporateOptions("");
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    setStatus("");
    setKey("");
    form.resetFields();
    navigate(router.pathname);
    window.location.reload();
  };

  useEffect(() => {
    if (visible) {
      setSelectedCorporate(undefined);
      setSelectedCorporateForEdit(undefined);
    }
  }, [selectedCorporate, selectedCorporateForEdit, visible]);

  useEffect(() => {
    setSelectedCorporate(undefined);
    setSelectedCorporateForEdit(undefined);
  }, [visible]);

  const onClose = () => {
    setSelectedCorporate(null);
    setSelectedCorporateForEdit(null);
    setvisible(false);
    getCorporate();
  };

  const fetchRef = useRef(0);

  return (
    <>
      <BreadCrumb
        title={`Corporate List`}
        subTitle={`${
          corporateData?.data?.totalElements ||
          corporateData?.data?.companies?.length ||
          0
        } Corporate(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <>
            <div className="single-button">
              <AddBtn onClick={showDrawer} />
            </div>
            <Drawer
              destroyOnClose={true}
              title="Add Corporate"
              width={600}
              onClose={onClose}
              open={!!visible}
              bodyStyle={{ paddingBottom: 0 }}
              footer={
                <div
                  style={{
                    textAlign: "left",
                  }}
                ></div>
              }
            >
              <AddHub onCloseMethod={onClose} />
            </Drawer>
          </>,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Name"
                optionFilterProp="children"
                onChange={(val) => setKey(val)}
                onSearch={(e) => handleSearch(e, "corporate")}
                filterOption={() => {
                  return true;
                }}
                options={corporateOptions?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {corporateData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Company Name</th>
                      <th scope="col">Created Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Balance</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {corporateData?.data?.companies?.length ? (
                      corporateData?.data?.companies?.map(
                        (company: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                          >
                            <td
                              onClick={() =>
                                navigate(`/corporates/${company?.id}/details`)
                              }
                              className="flex gap-2 items-center"
                            >
                              <Avatar
                                shape="circle"
                                size={45}
                                src={getImgUrl(company?.image)}
                                className="mr-3"
                              />
                              <span className="name">
                                {company?.name?.toUpperCase()}
                              </span>
                            </td>

                            <td>
                              <span className="name">
                                {moment(company?.createdAt).format("lll")}
                              </span>
                            </td>

                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={company?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  onStatusChange(company?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  company?.id === confirmLoading
                                }
                              />
                            </td>
                            <td>
                              <span className="name">{company?.balance}</span>
                            </td>

                            <td>
                              <Link
                                to=""
                                className="btn btn-sm btn-warning mx-1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedCorporateForEdit(company?.id);
                                }}
                              >
                                <EditOutlined />
                              </Link>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Drawer
          title={"Edit Company"}
          width={600}
          onClose={() => {
            setSelectedCorporate(null);
            setSelectedCorporateForEdit(null);
          }}
          open={selectedCorporate || selectedCorporateForEdit}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            ></div>
          }
        >
          {selectedCorporateForEdit ? (
            <AddCorporate
              onCloseMethod={onClose}
              visibleData={selectedCorporateForEdit}
            />
          ) : undefined}
        </Drawer>
        <Pagination
          {...corporateData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default CorporateList;
function setShowSearch(arg0: boolean): void {
  throw new Error("Function not implemented.");
}
function setVisible(arg0: boolean) {
  throw new Error("Function not implemented.");
}
