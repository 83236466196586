import {
  AutoComplete,
  Button,
  DatePicker,
  DatePickerProps,
  Form, 
  Input,
  Select,
  Spin,
} from "antd";

import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FullFillByType, getParamValue, ShopStatus } from "../../utils";
import { responseNotification } from "../../utils/notify";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { SET_SHOP_INFO } from "../../redux/auth/authType";
import React from "react";
import moment from "moment";
import { MapBox } from "../common";
const { TextArea } = Input;
import dayjs from "dayjs";

const AddMergeOrder = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef<any>(0);
  const route = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [error, setError] = useState("");
  const [limit, setLimit] = useState(16);
  const [key, setKey] = useState("");
  const [fullFillBy, setFullFillBy] = useState("");
  const [countryId, setCountryId] = useState<any>();
  const [orderId, setOrderId] = useState([]);
  const [mergeOrderList, setMergeOrderList] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState<DatePickerProps["value"]>();
  const [shopData, setShopData] = useState<any>({ loading: false, data: null });
  const [mergeOrderData, setMergeOrderData] = useState<any>({ loading: false, data: null });
  const [merchantOptions, setMerchantOptions] = useState({
    loading: false,
    list: [],
  });

  const [geoAddress, setGeoAddress] = useState<any>({
    loading: false,
    data: null,
  });

  const [locationOptions, setLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [locationListOptions, setLocationListOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [orderData, setOrderData] = useState({
    loading: false,
    list: [],
  });
  const [dataOptions, setDataOptions] = useState({
    loading: false,
    list: [],
  });
  const [currentLocation, setCurrentLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });
  // **************************************

  // **************************************

  const getOrders = useCallback(async () => {
    setOrderData({ loading: true, list: [] });

    const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/order/v2?type=${type}&&page=${page || 0}` +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrderData({
          loading: false,
          list: res.data?.orders?.map(
            (order: { baseOrderId: string; orderId: string }) => ({
              label: `${order?.baseOrderId} || ${order?.orderId}`,
              value: order?.orderId,
            })
          ),
        });
      })
      .catch((err) => {
        setOrderData({ loading: false, list: [] });
        console.error("Order: Error", err);
      });
  }, [limit, page, type]);

  const getLocationOptions = useCallback(async (key?: string) => {
    setLocationOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_MAP_API}`;
    const res = await axios.get(
      `${encodedUri}/autocomplete` + (key ? `?key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setLocationOptions({
      loading: false,
      list: res?.data?.places,
    });

    setLocationListOptions({
      loading: false,
      list: res?.data?.places?.map((place: any) => {
        return {
          value: place?.address,
          label: place?.address,
        };
      }),
    });
  }, []);


  const onSubmit = async (data: any) => {
      setLoading(true);

      const readyData = data && {
        fullFillBy: fullFillBy,
        countryName: countryId,
        deliveryDate: deliveryDate || data.deliveryDate,
        earnAmount: data.earnAmount,
        totalWeight: data.totalWeight,
        orderIds: orderId,
        location: {
            latitude: data.latitude * 1,
            longitude: data.longitude * 1,
          },
      }; 

      if (mergeOrderData.data) {
        await fetch(`${process.env.REACT_APP_ORDER_API}/admin/combine-order`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: mergeOrderData?.data?.id,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);
            if (res.statusCode === 200) {
              responseNotification("Updated Successfully", "success");
              form.resetFields();
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_ORDER_API}/admin/combine-order/combine-order`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...readyData}),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Merge Order Create Successfully", "success");
              form.resetFields();
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
 
  };


  const fetchMergeOrderDetails = useCallback((orderId: any) => {
    if (orderId) {
      try {
        setMergeOrderData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_ORDER_API}/admin/combine-order/details-combine-order?id=${orderId}`,
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //     "Content-Type": "application/json",
            //   },
            // }
          )
          .then((data) => {
            if (data.status === 200) {
         

              setMergeOrderData({
                loading: false,
                data: data?.data?.orderMerged,
              });
              setFullFillBy(data?.data?.orderMerged?.fullFillBy);

          const orderList = {} as any
          data?.data?.orderMerged?.orderList.map((va: any, i: any) => {
            orderList[i] = {
            orderId: va?.orderId,
            }
          });
          setOrderId(orderList);
            } else {
              setMergeOrderData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })

          .catch((err) => {
            setMergeOrderData({ loading: false, data: null });
          });
      } catch (error) {
        setMergeOrderData({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchMergeOrderDetails((route as any)?.orderId);
  }, [fetchMergeOrderDetails]);

  const fetchGeoAddress = useCallback(() => {
    try {
      setGeoAddress({ loading: true, data: null });
      axios
        .get(
          `${process.env.REACT_APP_MAP_API}/geocode?latitude=${currentLocation?.lat}&longitude=${currentLocation?.lng}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setGeoAddress({
              loading: false,
              data: data?.data?.place,
            });
          } else {
            setGeoAddress({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setGeoAddress({ loading: false, data: null });
        });
    } catch (error) {
      setGeoAddress({ loading: false, data: null });
    }
  }, [currentLocation?.lat, currentLocation?.lng]);
  const getCountryOptions = useCallback(
    async (key: any) => {
      setDataOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/countries${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDataOptions({
            loading: false,
            list: res.data?.countries?.map((country:any) => ({
              label: country.name,
              value: country.id,
            })),
          });
        })
        .catch((err) => {
          setDataOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [key]
  );

  const handleSearch = useMemo<any>(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef!.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "order") getOrders();
        if (field === "country") getCountryOptions(value);
        if (field === "locations") getLocationOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getLocationOptions,getCountryOptions, getOrders]);

  useEffect(() => {
    if (key) {
      getLocationOptions();
    }
  }, [getLocationOptions, key]);

  useEffect(() => {
    getCountryOptions("");
}, [getCountryOptions]);


  useEffect(() => {
    getOrders();
  }, [getOrders]);

  useEffect(() => {
    if (shopData.data) {
      form.resetFields(Object.keys(shopData.data as any));
      setCurrentLocation({
        lat: shopData?.data?.location?.y,
        lng: shopData?.data?.location?.x,
      });
    }
  }, [form, shopData]);

  useEffect(() => {
    if (
      shopData?.data &&
      currentLocation.lat !== shopData?.data?.location?.y &&
      currentLocation.lng !== shopData?.data?.location?.x
    ) {
      fetchGeoAddress();
    }
  }, [fetchGeoAddress, currentLocation]);

  const resetData = () => {
    form?.resetFields();
  };

  useEffect(() => {
    form.resetFields(["latitude", "longitude", "address"]);
  }, [currentLocation]);

  useEffect(() => {
    if (mergeOrderData?.data) {
      form.resetFields();
      setFullFillBy(mergeOrderData?.data?.fullFillBy);
      setDeliveryDate(mergeOrderData?.data?.deliveryDate);
    }
  }, [mergeOrderData]);

  useEffect(() => {
    form.resetFields(["address"]);
  }, [geoAddress]);

  return (
    <div className="content-body rounded-2xl w-full mt-10">
      <div className="">
        {" "}
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...mergeOrderData?.data,
            deliveryDate: dayjs(mergeOrderData?.data?.deliveryDate),
            fullFillBy: fullFillBy,
            setOrderId:setOrderId,
            totalWeight: mergeOrderData?.data?.totalWeight,
            earnAmount: mergeOrderData?.data?.earnAmount,
            latitude: mergeOrderData?.data?.area?.latitude,
            longitude: mergeOrderData?.data?.area?.longitude,
            
          }}
        >
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
            <div className="grid grid-cols-2 gap-x-6">
              <div>
                <Form.Item
                  hasFeedback
                  label={`FullFillBy`}
                  rules={[
                    {
                      required: true,
                      message: "fullFillBy is required!",
                    },
                  ]}
                  name="fullFillBy"
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select fullFillBy"
                    optionFilterProp="children"
                    onChange={(val) => setFullFillBy(val)}
                    onSearch={(val) => {
                      handleSearch?.(val, "fullFillBy");
                    }}
                    filterOption={() => {
                      return true;
                    }}
                  >
                    {Object.values(FullFillByType)?.map((status, i) => (
                      <Select.Option value={status} key={i}>
                        {status?.replace("_", " ")}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  hasFeedback
                  label={`Orders`}
                  rules={[
                    {
                      required: true,
                      message: "Orders is required!",
                    },
                  ]}
                  name="merchantId"
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Order"
                    optionFilterProp="children"
                    mode="multiple"
                    onChange={(val) => setOrderId(val)}
                    onSearch={(val) => {
                      handleSearch?.(val, "order");
                    }}
                    filterOption={() => {
                      return true;
                    }}
                    options={orderData?.list}
                  ></Select>  
                </Form.Item>
              </div>

              <div>
                <Form.Item hasFeedback label="Total Weight" name="totalWeight">
                  <Input
                    id="totalWeight"
                    type="text"
                    placeholder="Enter Total Weight"
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item hasFeedback label="Earn Amount" name="earnAmount">
                  <Input
                    id="earnAmount"
                    type="text"
                    placeholder="Enter Earn Amount"
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  hasFeedback
                  label="Delivery Date"
                  name="deliveryDate"
                >
                   <DatePicker
                  showTime
                  style={{ minWidth: "100%" }}
                  showNow={false}
                  placeholder="Starting Date"
                  onChange={(val: DatePickerProps["value"]) =>
                  setDeliveryDate(val)
                  }
                />
                 
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
            <h3 className="mb-4 text-xl font-bold">Address</h3>
            <div className="grid grid-cols-2 gap-x-6">
              <div>
                <Form.Item
                  hasFeedback
                  label="Country"
                  name="country"
                >
<Select
            allowClear
            showSearch
            placeholder={`Filter by country`}
            optionFilterProp="children"
            defaultValue={countryId}
            onChange={(val) => setCountryId(val)}
            onSearch={(e) => handleSearch(e, "country")}
            filterOption={() => {
              return true;
            }}
            options={dataOptions?.list}
          ></Select>
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  hasFeedback
                  label="Address"
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: "Address is required!",
                    },
                  ]}
                >
                  <AutoComplete
                    autoClearSearchValue={false}
                    allowClear
                    showSearch
                    onSearch={(val) => {
                      handleSearch?.(val, "locations");
                      setKey(val);
                    }}
                    onSelect={(val: any) => {
                      const matchData = locationOptions?.list?.find(
                        (place: any) => val === place.address
                      );
                      setCurrentLocation({
                        lat: matchData?.location?.latitude,
                        lng: matchData?.location?.longitude,
                      });
                    }}
                    options={locationListOptions?.list}
                    notFoundContent={
                      locationListOptions?.loading ? (
                        <Spin size="small" />
                      ) : null
                    }
                    //loading={locationListOptions.loading}
                    placeholder="Search Address"
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  hasFeedback
                  label="Latitude"
                  name="latitude"
                  initialValue={currentLocation?.lat}
                >
                  <Input id="latitude" type="text" placeholder="23.86450" />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  hasFeedback
                  label="Longitude"
                  name="longitude"
                  initialValue={currentLocation?.lng}
                >
                  <Input id="longitude" type="text" placeholder="90.53680" />
                </Form.Item>
              </div>

              <div className="col-span-2">
                <MapBox
                  draggable={true}
                  coordinate={currentLocation}
                  setCoordinator={(val: any) => setCurrentLocation(val)}
                />
              </div>
            </div>
          </div>
          <div className="buttons-container">
            <Button
              disabled={loading || !!error}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>

            <Button
              //type="ghost"
              htmlType="button"
              onClick={() => {
                form?.resetFields();
              }}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddMergeOrder;
