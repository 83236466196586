import React from "react";
import SubUrbList from "../../components/SubUrb/index";
import MainLayout from "../../components/Layouts";

const SubUrbPage = () => {
  return (
    <MainLayout>
      <SubUrbList />
    </MainLayout>
  );
};

export default SubUrbPage;
