import {
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    SearchOutlined,
  } from "@ant-design/icons";
  import axios from "axios";
  import React, {
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";
  import {
    AutoComplete,
    Avatar,
    Button,
    Drawer,
    Empty,
    Form,
    Input,
    Select,
    Spin,
    Switch,
  } from "antd";
  import BreadCrumb from "../../Layouts/Breadcrumb";
  import { getPage, getParamValue, jcTypeArray } from "../../../utils";
  import { useLocation, useNavigate } from "react-router";
  import styles from "../../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  import _, { debounce } from "lodash";
  import { isAllowedService } from "../../../utils/services";
  import { responseNotification } from "../../../utils/notify";
  import { AddBtn, Loader, LoaderFull, Pagination } from "../../common";
  import SingleVehivleBrandModel from "./Details";
  import AddVehivleBrandModel from "./AddVehicleBrandModel";
  
  const VehiclesBrandModelList = (): ReactElement => {
    const { token } = useSelector((state) => (state as any)?.authReducer);
    const initialState = useSelector((state) => (state as any)?.authReducer);
    const [type, setType] = useState(initialState?.type);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const loc = useLocation();
    const page = getParamValue(loc.search, "page");
    const [serviceType, setServiceType] = useState<any>("JC_COMMERCE");
    const [brandId, setBrandId] = useState<any>();
    const [vehicleTypeId, setVehicleTypeId] = useState<any>();
    const [showSearch, setShowSearch] = useState(true);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isActive, setIsActive] = useState<any>(true);
    const [key, setKey] = useState("");
    const [vehicleType, setVehicleType] = useState("");
    const [limit, setLimit] = useState(10);
    const [visible, setVisible] = useState<any>(undefined);
    const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
    const [selectedVehicleBrandModel, setSelectedVehicleBrandModel] = useState(undefined);
    const [selectedVehicleBrandModelForEdit, setSelectedVehicleBrandModelForEdit] =
      useState(undefined);
    const showPopconfirm = (id: any) => {
      setVisiblePopconfirm(id);
    };
    const [brandsOptions, setBrandsOptions] = useState<any>({
      list: [],
      loading: false,
    });
    const [vehicleBrandModelData, setVehicleBrandModelData] = useState<any>({
      loading: false,
      data: null,
    });
    const [vehicleOptions, setVehicleOptions] = useState({
      loading: false,
      list: [],
    });
    const isParent = "false";
    const getVehicleBrandModelAll = useCallback(async () => {
      setVehicleBrandModelData({ loading: true, data: null });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      axios
        .get(
          `${encodedUri}/brand-model` +
          (serviceType ? `?type=${serviceType}` : ``)+
          (vehicleTypeId ? `&vehicleTypeId=${vehicleTypeId}` : ``)+
          (brandId ? `&brandId=${brandId}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setVehicleBrandModelData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setVehicleBrandModelData({ loading: false, data: [] });
          console.error("Page: Error", err);
        });
    }, [serviceType,brandId, vehicleTypeId]);
  

    const getBrandsOptions = useCallback(async (val?: string) => {
      setBrandsOptions({ loading: true, list: [] });
  
      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/brand/search?type=${serviceType}&page=0&limit=20` +
          (val ? `&key=${val}` : ``),
  
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setBrandsOptions({
        loading: false,
        list: res?.data?.brands?.map((brand: any) => {
          return {
            value: brand?.id,
            label: brand?.name,
          };
        }),
      });
    }, [serviceType]);
    const getVehicleTypeOptions = useCallback(
      async (key: any) => {
        setVehicleOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
        let url = `?page=0&limit=20` + (key ? `&key=${key}` : ``);
        url = encodeURI(url);
  
        return axios
          .get(`${encodedUri}/admin/vehicle-type/all${url}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setVehicleOptions({
              loading: false,
              list: res.data?.vehicleTypes?.map((vehicle: any) => ({
                label: vehicle.vehicleType,
                value: vehicle.id,
              })),
            });
          })
          .catch((err) => {
            setVehicleOptions({ loading: false, list: [] });
            console.error("Products: Error", err);
          });
      },
      [key]
    );
    const onStatusChange = async (id: any, val: any) => {
      if (id) {
        await fetch(`${process.env.REACT_APP_RIDER_API}/admin/vehicle-type`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            isActive: val,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.statusCode === 200) {
              responseNotification("Status Updated Successfully", "success");
              getVehicleBrandModelAll();
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    };
  
    useEffect(() => {
      getVehicleBrandModelAll();
    }, [getVehicleBrandModelAll]);
    const reseAllFieldData = () => {
      form?.resetFields();
      setServiceType("");
      setBrandId("");
      setVehicleTypeId("");
    };
    const fetchRef = useRef(0);
    const handleSearch = useMemo(() => {
      const loadOptions = (value: string, field: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
  
        if (fetchId !== fetchRef.current) {
          return;
        }
        if (value) {
          //if (field === "vehicle") getVehicleBrandModelOptions(value);
        }
      };
  
      return debounce(loadOptions, 800);
    }, []);
  

    const onClose = () => {
      getVehicleBrandModelAll();
      setVisible(undefined);
      setSelectedVehicleBrandModel(undefined);
      setSelectedVehicleBrandModelForEdit(undefined);
    };
    const handleCancel = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setVisiblePopconfirm(undefined);
    };
    useEffect(() => {
      getBrandsOptions("");
      getVehicleTypeOptions("");
    }, [getBrandsOptions,getVehicleTypeOptions]);
    return (
      <>
        <BreadCrumb
          title="Vehicle Brand Model List"
          subTitle={`${
            vehicleBrandModelData?.data?.totalElements ||
            vehicleBrandModelData?.data?.brandModel?.length ||
            0
          } Model(s)`}
          extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
        />
        {showSearch && (
          <div className={styles?.searchBox}>
            <Form layout="inline" form={form}>
            <Form.Item
          name="serviceType"
          initialValue={"JC_COMMERCE"}
        >
          <Select
           allowClear
           showSearch
            placeholder="Service Type" 
            defaultValue={serviceType}
            onChange={(val) => setServiceType(val)}
            filterOption={() => {
              return true;
            }}
          >
            {Object.values(jcTypeArray)?.map((type, i) => (
              <Select.Option value={type} key={i}>
                {type?.replace("_", " ")}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="brandId"
        >
          <Select
            allowClear
            showSearch
            placeholder={`Brand`}
            optionFilterProp="children"
            defaultValue={brandId}
            onChange={(val) => setBrandId(val)}
            onSearch={(e) => handleSearch(e, "brand")}
            filterOption={() => {
              return true;
            }}
            options={brandsOptions?.list}
          ></Select>
        </Form.Item>
        
        <Form.Item
          name="vehicleTypeId"
        >
          <Select
            allowClear
            showSearch
            placeholder={`Filter by vehicle type`}
            optionFilterProp="children"
            defaultValue={vehicleTypeId}
            onChange={(val) => setVehicleTypeId(val)}
            onSearch={(e) => handleSearch(e, "vehicle")}
            filterOption={() => {
              return true;
            }}
            options={vehicleOptions?.list}
          ></Select>
        </Form.Item>
            </Form>
            <Button
              type="primary"
              danger
              size="large"
              htmlType="reset"
              onClick={reseAllFieldData}
            >
              Reset
            </Button>
          </div>
        )}
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className={`content-body`}>
                {confirmLoading && <LoaderFull />}
                {vehicleBrandModelData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                      <th scope="col">Banner</th>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Brand</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {vehicleBrandModelData?.data?.brandModel?.length ? (
                        vehicleBrandModelData?.data?.brandModel?.map(
                          (brandModel: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100 cursor-pointer"
                              key={index}
                            >
                              <td>
                              <Avatar size={40} src={brandModel?.logo} />
                              </td>
                              <td>
                                <span className="font-medium text-gray-500">
                                  {brandModel?.name}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500">
                                  {brandModel?.type?.replace("_", " ")}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500">
                                  {brandModel?.brand?.name}
                                </span>
                              </td>
                              <td>
                                <div className="flex flex-grow">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={() =>
                                      setSelectedVehicleBrandModelForEdit(brandModel)
                                    }
                                  >
                                    <EditOutlined />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
  
        <Drawer
          title={
            visible
              ? "Add Vehicle Brand Model"
              : selectedVehicleBrandModelForEdit
              ? "Edit Vehicle Brand Model"
              : "Vehicle Brand Model Details"
          }
          placement="right"
          onClose={onClose}
          open={visible || selectedVehicleBrandModel || selectedVehicleBrandModelForEdit}
          width={450}
        >
          {visible || selectedVehicleBrandModelForEdit ? (
            <AddVehivleBrandModel
              onCloseMethod={onClose}
              visibleData={selectedVehicleBrandModelForEdit}
            />
          ) : (
            <SingleVehivleBrandModel VehivleBrandModelDetails={selectedVehicleBrandModel} />
          )}
        </Drawer>
      </>
    );
  };
  
  export default VehiclesBrandModelList;
  