import { Divider, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../../common";
import styles from "../../../styles/tailwind/Add.module.css";
import React from "react";

const AddRecruiter = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [recruiterData, setRecruiterData] = useState<any>(undefined);

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
      setLoading(true);
      const readyData = data && {
        name: data.name,
        mobileNumber: `+88${data?.mobileNumber?.replace("+88", "")}`,
        agencyName: data.agencyName,
        profilePicture: generatedImgUrl
          ? generatedImgUrl
          : data?.profilePicture,
      };
 
      if (recruiterData) {
        await fetch(`${process.env.REACT_APP_RIDER_API}/recruiter`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: recruiterData?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Recruiter Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_RIDER_API}/recruiter`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Recruiter Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }

  };

  useEffect(() => {
    if (visibleData) {
      setRecruiterData(visibleData);
      setGeneratedImgUrl(visibleData?.profilePicture);
    }
  }, [visibleData]);

  useEffect(() => {
    if (recruiterData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.profilePicture);
    }
  }, [recruiterData, form, visibleData]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...recruiterData,
          mobileNumber: recruiterData?.mobileNumber?.replace("+88", ""),
        }}
      >
        <Form.Item
          hasFeedback
          label="Recruiter Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Recruiter Name" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Mobile Number"
          rules={[
            {
              required: !visibleData,
              message: "Mobile Number is Required!",
            },
            {
              validator: async (_, names) => {
                if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                  return Promise.reject(new Error("Enter valid mobile number"));
                }
              },
            },
          ]}
          name="mobileNumber"
          initialValue={"01"}
        >
          <Input addonBefore={"+88"} size="large" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Agency Name"
          rules={[
            {
              required: true,
              message: "Agency Name is Required!",
            },
          ]}
          name="agencyName"
        >
          <Input type="text" id="agencyName" placeholder="Enter Agency Name" />
        </Form.Item>

        <Divider orientation="left">Image</Divider>
        <div className="flex items-center pb-4">
          <ImageUploader
            imgUrl={generatedImgUrl || ""}
            startUpload={startUpload}
            setStartUpload={(val: any) => setStartUpload(val)}
            setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            // crop={1}
          />
        </div>

        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
          }}
          onClickReset={() => {
            form?.resetFields();
            setGeneratedImgUrl(undefined);
            setStartUpload(undefined);
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddRecruiter;
