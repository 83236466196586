import { UserOutlined } from "@ant-design/icons";
import { Form, Input, Select } from "antd";
import React, { useCallback, useMemo, useRef } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import SubmitResetBtn from "../../common/SubmitBtn";
import { debounce } from "lodash";
import axios from "axios";

const AddDriver = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);

  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [driverData, setDriverData] = useState<any>(undefined);
  const [cityId, setCityId] = useState<any>("");
  const [zoneId, setZoneId] = useState<any>();
  const [areaId, setAreaId] = useState<any>();
  const [key, setKey] = useState("");
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [zoneOptions, setZoneOptions] = useState({
    loading: false,
    list: [],
  });
  const [areaOptions, setAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      name: data.name,
      contact_name: data.contact_name,
      contact_number: data.contact_number,
      secondary_contact: data.secondary_contact,
      address: data.address,
      city_id: cityId,
      zone_id: zoneId,
      area_id: areaId,
    };
    await fetch(`${process.env.REACT_APP_ORDER_API}/pathao/create-store`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Store Create Successfully", "success");
          form.resetFields();
          if (onCloseMethod) {
            onCloseMethod();
          }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const getCityOptions = useCallback(async (countryName?: string) => {
    setCityOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ORDER_API}/pathao/city-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCityOptions({
      loading: false,
      list: res?.data?.data?.data?.map((city: any) => {
        return {
          value: city?.city_id,
          label: city?.city_name,
        };
      }),
    });
  }, []);

  const getZoneOptions = useCallback(
    async (key: any) => {
      setZoneOptions({ loading: true, list: [] });
      return axios
        .get(
          `${process.env.REACT_APP_ORDER_API}/pathao/zone-list?city_id=${cityId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setZoneOptions({
            loading: false,
            list: res.data?.data?.data?.map((zone: any) => ({
              label: zone.zone_name,
              value: zone.zone_id,
            })),
          });
        })
        .catch((err) => {
          setZoneOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [cityId]
  );

  const getAreaOptions = useCallback(async () => {
    setAreaOptions({ loading: true, list: [] });
    return axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/pathao/area-list?zone_id=${zoneId}`
      )
      .then((res) => {
        setAreaOptions({
          loading: false,
          list: res.data?.data?.data?.map((area: any) => ({
            label: area.area_name,
            value: area.area_id,
          })),
        });
      })
      .catch((err) => {
        setAreaOptions({ loading: false, list: [] });
        console.error("area: Error", err);
      });
  }, [zoneId]);

  useEffect(() => {
    if (visibleData) {
      setDriverData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (driverData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [driverData, form, visibleData]);
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "city") getCityOptions(value);
        if (field === "zone") getZoneOptions(value);
        if (field === "area") getAreaOptions();
      }
    };

    return debounce(loadOptions, 800);
  }, [getCityOptions, getZoneOptions, getAreaOptions]);
  useEffect(() => {
    getCityOptions("");
    getZoneOptions("");
    getAreaOptions();
  }, [getCityOptions, getZoneOptions, getAreaOptions]);

  return (
    <div className="">
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...driverData,
          mobileNumber: driverData?.mobileNumber?.replace("+88", ""),
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" size="large" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Contact Name"
          rules={[
            {
              required: true,
              message: "Contact Name is Required!",
            },
          ]}
          name="contact_name"
        >
          <Input
            id="contact_name"
            type="text"
            placeholder="Enter Contract Name"
            size="large"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Contract Number"
          rules={[
            {
              required: true,
              message: "Contract Number is Required!",
            },
            {
              validator: async (_, names) => {
                if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                  return Promise.reject(new Error("Enter valid mobile number"));
                }
              },
            },
          ]}
          name="contact_number"
          initialValue={"01"}
        >
          <Input addonBefore={"+88"} size="large" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Secondary Contract Number"
          rules={[
            {
              required: false,
              message: "Secondary Contract Number is Required!",
            },
            {
              validator: async (_, names) => {
                if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                  return Promise.reject(new Error("Enter valid mobile number"));
                }
              },
            },
          ]}
          name="secondary_contact"
          initialValue={"01"}
        >
          <Input addonBefore={"+88"} size="large" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Address"
          rules={[
            {
              required: true,
              message: "Address Name is Required!",
            },
            { min: 15, message: 'Address must be minimum 15 characters.' },
          ]}
          name="address"
        >
          <Input
            id="address"
            type="text"
            placeholder="Enter Address"
            size="large"
            minLength={15}
            showCount
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label={`City`}
          rules={[
            {
              required: true,
              message: "City is required!",
            },
          ]}
          name="city"
        >
          <Select
            allowClear
            showSearch
            placeholder="Select City"
            optionFilterProp="children"
            onChange={(val) => setCityId(val)}
            onSearch={(val) => {
              handleSearch?.(val, "city");
            }}
            filterOption={() => {
              return true;
            }}
            options={cityOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item name="zone_search" label="Zone">
          <Select
            allowClear
            showSearch
            placeholder={`Filter by Zone`}
            optionFilterProp="children"
            onChange={(val) => setZoneId(val)}
            onSearch={(e) => handleSearch(e, "zone")}
            filterOption={() => {
              return true;
            }}
            options={zoneOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          label={`Area`}
          rules={[
            {
              required: false,
              message: "Area is required!",
            },
          ]}
          name="area"
        >
          <Select
            allowClear
            showSearch
            placeholder="Select area"
            optionFilterProp="children"
            onChange={(val) => setAreaId(val)}
            onSearch={(val) => {
              handleSearch?.(val, "area");
            }}
            filterOption={() => {
              return true;
            }}
            options={areaOptions?.list}
          ></Select>
        </Form.Item>
        <SubmitResetBtn
          onClickReset={() => {
            form?.resetFields();
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </div>
  );
};

export default AddDriver;
