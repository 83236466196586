import { Image } from "antd";
import moment from "moment";
import React from "react";
import { ReactElement } from "react";

const SingleType = ({ SingleType }: { SingleType: any }): ReactElement => {
  return (
    <>
      <div className="">
        <Image
          // height={140}
          width="auto"
          src={SingleType?.image}
          className="rounded-lg shadow-lg"
        />
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{SingleType?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Description</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{SingleType?.description}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Created At</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{moment(SingleType?.createdAt).format("lll")}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleType;
