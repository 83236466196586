import {
  GiftFilled,
  SearchOutlined,
  ShopOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Avatar,
  Button,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  Popover,
  Segmented,
  Select,
  Spin,
  Tag,
} from "antd";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import axios from "axios";
import {
  CampaignProductStatus,
  getImgUrl,
  getPage,
  getParamValue,
} from "../../../../utils/index";
import { responseNotification } from "../../../../utils/notify";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../../../styles/tailwind/List.module.css";
import CampaignLayout from "../Layout";
import cn from "classnames";
import { faCheck, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TreeSelectTwo,
  LoaderFull,
  Loader,
  PaginationThree,
} from "../../../common";

const CampaignProductsList = ({ onAssignModal }: any): ReactElement => {
  const { type, token, campaignInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [visible, setVisible] = useState(false);
  const liveUrl = `${process.env.REACT_APP_LIVE_URL}`;
  const [confirmLoading, setConfirmLoading] = useState<any>(undefined);
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // --------------------------------
  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  // --------------------------------

  // --------------------------------
  const campaignId = campaignInfo?.id;
  const [status, setStatus] = useState("APPROVED");
  const [sortBy, setSortBy] = useState<any>("createdAt");
  const [sortOrder, setSortOrder] = useState<any>("DESC");
  const [changeStatus, setChangeStatus] = useState<any>();
  const [key, setKey] = useState("");
  const [barCode, setBarCode] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [categoryId, setCategoryId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [shopId, setShopId] = useState<any>();

  const isDeleted = false;
  const isActive = false;

  // --------------------------------

  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [productOptions, setProductOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [campaignProductData, setCampaignProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [productMap, setProductMap] = useState<{ [key: string]: any }>({});
  const [productDataMap, setProductDataMap] = useState<{ [key: string]: any }>(
    {}
  );

  const reseAllFieldData = () => {
    setKey("");
    setBarCode("");
    setBrandId("");
    setShopId("");
    setCategoryId("");
    setSortBy("");
    setSortOrder("");
    form.resetFields();
  };

  const getBrandsOptions = useCallback(
    async (key?: string) => {
      setBrandOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/brand/by-campaign?type=${type}&page=0&limit=20` +
          (campaignId ? `&campaignId=${campaignId}` : ``) +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setBrandOptions({
        loading: false,
        list: res?.data?.brands?.map((brand: any) => ({
          label: brand.name,
          value: brand.id,
        })),
      });
    },
    [campaignId]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(`${encodedUri}/admin/shop/by-campaign?campaignId=${campaignId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [campaignId]
  );

  const getProductByBarcodeOptions = useCallback(
    async (bCode: any) => {
      setProductBarcodeOptions({ loading: false, list: [] });

      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/admin/campaign/products?type=${type}&campaignId=${campaignId}&page=0&limit=20&deleted=${false}` +
          (bCode ? `&barCode=${bCode}` : ``) +
          (key ? `&key=${key}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProductBarcodeOptions({
        loading: false,
        list: res?.data?.keys?.map((product: { name: any; barCode: any }) => {
          return {
            value: product?.barCode,
            label: product?.name,
          };
        }),
      });
    },
    [status, type, key, isActive, campaignId]
  );

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&campaignId=${campaignId}&page=0&limit=20` +
        (key ? `&key=${key}` : ``) +
        (status ? `&status=${status}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [type, key, page, limit, campaignId]
  );

  const getAllProduct = useCallback(() => {
    if (campaignId) {
      try {
        setCampaignProductData({ loading: true, data: null });
        axios
          .get(
            `${
              process.env.REACT_APP_CATALOG_READER_API
            }/admin/campaign/products?campaignId=${campaignId}&page=${
              page || 0
            }&isActive=${
              status === "APPROVED" ? true : false
            }&isDeleted=${false}` +
              `&limit=${limit || 16}` +
              (type ? `&type=${type}` : ``) +
              (shopId ? `&shopId=${shopId}` : ``) +
              (brandId ? `&brandId=${brandId}` : ``) +
              (categoryId ? `&categoryId=${categoryId}` : ``) +
              (sortBy ? `&sortBy=${sortBy}` : ``) +
              (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
              (key ? `&key=${key}` : ``) +
              (status ? `&status=${status}` : ``),

            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setCampaignProductData({
                loading: false,
                data: data?.data,
              });

              const tempProductMap = {};
              data?.data?.campaignProducts?.forEach((p) => {
                if (p?.product?.barCode)
                  tempProductMap[p?.product?.barCode] = {
                    barCode: p?.product?.barCode,
                    shopId: p?.shopId,
                    commission: 0,
                    subsidy: 0,
                    status: changeStatus,
                  };
              });
              setProductMap(tempProductMap);

              const tempProductDataMap = {};
              data?.data?.campaignProducts?.forEach((p) => {
                if (p?.product?.barCode)
                  tempProductDataMap[p?.product?.barCode] = p;
              });
              setProductDataMap(tempProductDataMap);

              setCheckedList(
                data.data.campaignProducts.map(
                  (data: any) => data?.product?.barCode
                )
              );
            } else {
              setCampaignProductData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            // setCampaignProductData({ loading: false, data: null });
          });
      } catch (error) {
        setCampaignProductData({ loading: false, data: null });
      }
    }
  }, [
    limit,
    page,
    key,
    status,
    type,
    isDeleted,
    isActive,
    shopId,
    brandId,
    categoryId,
    sortBy,
    sortOrder,
    campaignId,
  ]);

  // **********************************
  // Search Options
  // **********************************

  const onSubmit = async () => {
    setConfirmLoading(true);
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/${
        changeStatus === "REMOVE" ? "remove" : "approve"
      }-products`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignId: campaignId,
          products: selectedProductBarcode?.map((barCode) => ({
            ...(productMap[barCode] || {}),
            status: changeStatus,
          })),
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Campaign Product Assign Successfully",
            "success"
          );
          form.resetFields();
          getAllProduct();
          onClose();
          setConfirmLoading(false);
          navigate(`/promotion/campaign/${campaignId}/products`);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const onChange = (list: any) => {
    setSelectedProductBarcode(list);
    setIndeterminate(!!checkedList.length || checkedList.length <= list.length);
    setCheckAll(checkedList.length === list.length);
  };

  const onCheckAllChange = (e: any) => {
    setSelectedProductBarcode(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const fetchRef = useRef<any>(0);

  const handleSearch = React.useMemo<any>(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "productByBarcode")
          getProductByBarcodeOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "shop") getShopOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getBrandsOptions,
    getProductOptions,
    getProductByBarcodeOptions,
    getShopOptions,
  ]);

  useEffect(() => {
    getProductOptions("");
    getAllProduct();
    getShopOptions("");
    getBrandsOptions("");
  }, [
    getAllProduct,
    getBrandsOptions,
    getShopOptions,
    getProductOptions,
    campaignId,
  ]);

  useEffect(() => {
    if (status === "APPROVED") {
      setChangeStatus("REMOVE");
    } else if (status === "WAITING_FOR_APPROVAL" || status === "REJECTED") {
      setChangeStatus("APPROVED");
    }
  }, [status]);

  useEffect(() => {
    if (status) {
      setSelectedProductBarcode(null);
      setCheckAll(false);
      setIndeterminate(false);
    }
  }, [status]);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setSelectedProductBarcode(null);
    setCheckAll(false);
    setIndeterminate(false);
  };

  confirmLoading && <LoaderFull />;

  return (
    <React.Fragment>
      <CampaignLayout
        campaignId={campaignInfo.id}
        title={campaignInfo.name}
        subTitle="Product"
        extra={
          <>
            <Button
              key={1}
              type="primary"
              danger={selectedProductBarcode?.length}
              title="Approve/Reject"
              className="ml-2 bg-slate-300"
              onClick={() => setVisible(true)}
              disabled={!selectedProductBarcode?.length}
            >
              {status === "APPROVED" ? "REMOVE" : "ACTION"}
            </Button>
            <Button
              key={2}
              type="primary"
              title="Add/Assign"
              className="ml-2 bg-green-600"
              onClick={() =>
                navigate(`/promotion/campaign/${campaignId}/products-assign`)
              }
            >
              Add / Assign
            </Button>
          </>
        }
      >
        <>
          <section className="content-body rounded-2xl">
            <div className="bg-white shadow-sm shadow-gray-200 rounded-2xl">
              <div className="sticky shadow-sm border border-slate-100 z-30 bg-white rounded-lg px-2 py-1 mb-4">
                {!onAssignModal && (
                  <div className="flex justify-between items-center ">
                    <h3 className="text-xl text-gray-700 font-bold capitalize">
                      {status?.split("_")?.join(" ")?.toLowerCase()} Product
                      <span className="text-xs font-light ml-2">
                        {campaignProductData?.data?.totalElements ||
                          campaignProductData?.data?.length ||
                          0}
                        Product(s)
                      </span>
                    </h3>
                    <div className="d-flex items-center">
                      <Button
                        type="dashed"
                        shape="circle"
                        onClick={() => setShowSearch(!showSearch)}
                        key={1}
                      >
                        <SearchOutlined />
                      </Button>
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-between w-full">
                  {showSearch && (
                    <div className="flex py-1 mt-2 w-full">
                      <Form
                        layout="inline"
                        form={form}
                        className={styles.formInline}
                      >
                        <Form.Item name="status_search">
                          <Select
                            allowClear
                            showSearch
                            placeholder="Filter by Status"
                            optionFilterProp="children"
                            onChange={(val) => setStatus(val)}
                            filterOption={() => {
                              return true;
                            }}
                            options={CampaignProductStatus.map((city) => ({
                              label: city,
                              value: city,
                            }))}
                            defaultValue={status}
                          ></Select>
                        </Form.Item>

                        <Form.Item name="search">
                          <AutoComplete
                            style={{ width: 300 }}
                            onSearch={(e) => handleSearch(e, "product")}
                            onSelect={(val: {
                              toString: () => SetStateAction<string>;
                            }) => setKey(val?.toString())}
                            options={productOptions?.list}
                            defaultActiveFirstOption={false}
                            notFoundContent={
                              productOptions?.loading ? (
                                <Spin size="small" />
                              ) : null
                            }
                          >
                            <Input.Search
                              placeholder="Search by Name"
                              onSearch={(val) => setKey(val)}
                              enterButton
                              loading={productOptions.loading}
                            />
                          </AutoComplete>
                        </Form.Item>

                        <Form.Item name="shop_search">
                          <Select
                            allowClear
                            showSearch
                            // defaultValue={getShopName}
                            placeholder="Filter by Shop"
                            optionFilterProp="children"
                            onChange={(val) => setShopId(val)}
                            onSearch={(e) => handleSearch(e, "shop")}
                            filterOption={() => {
                              return true;
                            }}
                            options={shopOptions.list}
                          ></Select>
                        </Form.Item>

                        <Form.Item name="brand_search">
                          <Select
                            allowClear
                            showSearch
                            placeholder="Filter by Brand"
                            optionFilterProp="children"
                            onChange={(val) => setBrandId(val)}
                            onSearch={(e) => handleSearch(e, "brand")}
                            filterOption={() => {
                              return true;
                            }}
                            options={brandOptions?.list}
                          ></Select>
                        </Form.Item>

                        <Form.Item name="category_search">
                          <TreeSelectTwo
                            setCategoryId={setCategoryId}
                            value={categoryId}
                          />
                        </Form.Item>

                        <Form.Item name="status_search">
                          <Select
                            allowClear
                            showSearch
                            placeholder="Filter by Status"
                            optionFilterProp="children"
                            onChange={(val) => setStatus(val)}
                            filterOption={() => {
                              return true;
                            }}
                            // options={Object.values(CampaignProductStatus)}
                            options={CampaignProductStatus.map((city) => ({
                              label: city,
                              value: city,
                            }))}
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          name="orderP_by_search"
                          initialValue={
                            sortBy == "displayOrder"
                              ? "DISPLAY ORDER"
                              : sortBy == "createdAt"
                              ? "CREATED AT"
                              : sortBy == "updatedAt"
                              ? "UPDATED AT"
                              : "SORTING BY"
                          }
                        >
                          <Select
                            showSearch
                            placeholder="Status"
                            onChange={(val) => setSortBy(val as string)}
                          >
                            <Select.Option value={undefined}>
                              SORTING BY
                            </Select.Option>
                            <Select.Option
                              value={"displayOrder"}
                              title="DISPLAY ORDER"
                            >
                              DISPLAY ORDER
                            </Select.Option>
                            <Select.Option
                              value={"createdAt"}
                              title="CREATED AT"
                            >
                              CREATED AT
                            </Select.Option>
                            <Select.Option
                              value={"updatedAt"}
                              title="UPDATED AT"
                            >
                              UPDATED AT
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="sorting_order_search"
                          initialValue={
                            sortOrder == "ASC"
                              ? "ASC"
                              : sortOrder == "DESC"
                              ? "DESC"
                              : "SORTING ORDER"
                          }
                        >
                          <Select
                            showSearch
                            placeholder="sorting order"
                            onChange={(val) => setSortOrder(val as string)}
                          >
                            <Select.Option value={undefined}>
                              SORTING ORDER
                            </Select.Option>
                            <Select.Option value={"ASC"} title="ASC">
                              ASC
                            </Select.Option>
                            <Select.Option value={"DESC"} title="DESC">
                              DESC
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Form>
                      <Button
                        type="primary"
                        size="large"
                        danger
                        onClick={reseAllFieldData}
                      >
                        Reset
                      </Button>
                    </div>
                  )}

                  {/* <Button
                  type="primary"
                  size="large"
                  ghost
                  onClick={() => onSubmit()}
                >
                  Submit
                </Button> */}
                </div>
              </div>

              <div className={styles.contentWrapper}>
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div
                      className={
                        showSearch ? `content-body-withSearch` : `content-body`
                      }
                    >
                      <div className="">
                        <Checkbox
                          indeterminate={indeterminate}
                          onChange={onCheckAllChange}
                          checked={
                            checkAll ||
                            selectedProductBarcode?.length ===
                              checkedList?.length
                          }
                          className="mr-3"
                        >
                          Check all
                        </Checkbox>
                      </div>
                      <Checkbox.Group
                        value={selectedProductBarcode}
                        onChange={onChange}
                      >
                        <table className={styles.mainTable}>
                          <thead className="bg-white border-b">
                            <tr>
                              <th scope="col">Product</th>
                              <th scope="col">Status</th>
                              <th scope="col">Shop</th>
                              {campaignInfo?.campaignType === "DISCOUNT" ? (
                                <>
                                  <th scope="col">Price & Stock</th>
                                  <th scope="col">Commission</th>
                                  <th scope="col">Subsidy</th>
                                </>
                              ) : campaignInfo?.campaignType ===
                                "PURCHASE_VOUCHER" ? (
                                <th scope="col">Voucher Amount</th>
                              ) : campaignInfo?.campaignType === "GROUP_BUY" ? (
                                <>
                                  <th scope="col">Price & Stock</th>
                                  <th scope="col">Total Participator</th>
                                </>
                              ) : (
                                <th scope="col">Gifts Items</th>
                              )}
                            </tr>
                          </thead>

                          <tbody>
                            {campaignProductData?.loading ? (
                              <Loader />
                            ) : Object.entries(productDataMap)?.length ? (
                              Object.entries(productDataMap)?.map(
                                (
                                  [barCode, campaignProduct]: [
                                    barCode: string,
                                    campaignProduct: any
                                  ],
                                  index: any
                                ) => (
                                  <tr
                                    className="border-t hover:bg-gray-100"
                                    key={index}
                                  >
                                    <td>
                                      <Checkbox
                                        onClick={(e) => {
                                          onChange(
                                            campaignProduct?.product?.barCode
                                          );
                                        }}
                                        className="mr-3"
                                        value={
                                          campaignProduct?.product?.barCode
                                        }
                                        // disabled={
                                        //   campaignProduct?.product
                                        //     ?.campaignId ||
                                        //   campaignProduct?.product
                                        //     ?.campaignId === campaignId
                                        // }
                                      />

                                      <Avatar
                                        size={45}
                                        src={getImgUrl(
                                          campaignProduct?.product?.productImage
                                        )}
                                        shape="square"
                                      />
                                      <span className="font-medium text-gray-500 ml-2">
                                        {campaignProduct?.product?.name?.slice(
                                          0,
                                          50
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={cn(
                                          `name ml-1 text-uppercase ${campaignProduct?.status?.toLowerCase()}`
                                        )}
                                      >
                                        {campaignProduct?.status
                                          ?.split("_")
                                          ?.join(" ")}
                                      </span>
                                    </td>

                                    <td>
                                      <ShopOutlined />
                                      <span className="name ml-1 text-uppercase">
                                        {campaignProduct?.product?.shop?.name}
                                      </span>
                                    </td>
                                    {campaignInfo?.campaignType ===
                                    "DISCOUNT" ? (
                                      status === "APPROVED" ? (
                                        <>
                                          <td>
                                            <div className="flex_">
                                              {campaignProduct?.variations
                                                ?.length
                                                ? campaignProduct?.variations?.map(
                                                    (
                                                      variantPrice: any,
                                                      index: string
                                                    ) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <div className="flex justify-content-start mb-1">
                                                          <Tag>
                                                            Discount Type :{" "}
                                                            {
                                                              variantPrice?.campaignDiscountType
                                                            }
                                                          </Tag>

                                                          <Tag
                                                            color={
                                                              variantPrice?.stock <
                                                              10
                                                                ? `red`
                                                                : `green`
                                                            }
                                                          >
                                                            Stock :{" "}
                                                            <b>
                                                              {variantPrice?.stock ||
                                                                0}
                                                            </b>
                                                          </Tag>

                                                          <Tag>
                                                            MRP{" "}
                                                            {
                                                              variantPrice?.currencyCode
                                                            }
                                                            {variantPrice?.sellPrice ||
                                                              variantPrice?.mrp}
                                                            {variantPrice?.sellPrice &&
                                                              variantPrice?.sellPrice !==
                                                                variantPrice?.mrp && (
                                                                <span className="text-red-600 ml-1">
                                                                  <del>
                                                                    {variantPrice?.currencyCode ||
                                                                      "Tk"}
                                                                    {
                                                                      variantPrice?.mrp
                                                                    }
                                                                  </del>
                                                                </span>
                                                              )}
                                                          </Tag>
                                                          <Tag>
                                                            TP{" "}
                                                            {
                                                              variantPrice?.merchantPrice
                                                            }{" "}
                                                            {
                                                              variantPrice?.currencyCode
                                                            }
                                                          </Tag>
                                                        </div>
                                                      </React.Fragment>
                                                    )
                                                  )
                                                : undefined}
                                            </div>
                                          </td>

                                          <td>
                                            <span className="font-medium text-gray-500">
                                              {campaignProduct?.campaign
                                                ?.minimumDiscountType ===
                                              "PERCENTAGE" ? (
                                                <span className="font-medium text-gray-500">
                                                  {campaignProduct?.campaign
                                                    ?.minimumDiscountType ===
                                                    "PERCENTAGE"}
                                                  {campaignProduct?.commission ||
                                                    0}
                                                  %
                                                </span>
                                              ) : (
                                                <span className="font-medium text-gray-500">
                                                  {campaignProduct?.campaign
                                                    ?.minimumDiscountType ===
                                                    "PERCENTAGE"}
                                                  &#2547;
                                                  {campaignProduct?.commission ||
                                                    0}
                                                </span>
                                              )}
                                            </span>
                                          </td>
                                          <td>
                                            {campaignProduct?.campaign
                                              ?.minimumDiscountType ===
                                            "PERCENTAGE" ? (
                                              <span className="font-medium text-gray-500">
                                                {campaignProduct?.campaign
                                                  ?.minimumDiscountType ===
                                                  "PERCENTAGE"}
                                                {campaignProduct?.subsidy || 0}%
                                              </span>
                                            ) : (
                                              <span className="font-medium text-gray-500">
                                                {campaignProduct?.campaign
                                                  ?.minimumDiscountType ===
                                                  "PERCENTAGE"}
                                                &#2547;
                                                {campaignProduct?.subsidy || 0}
                                              </span>
                                            )}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td>
                                            <Input
                                              name="commission"
                                              type="number"
                                              min={0}
                                              placeholder="Enter Commission Amount"
                                              onChange={(e) => {
                                                setProductMap((prevVal) => {
                                                  let temp = {
                                                    ...(prevVal || {}),
                                                  };
                                                  temp[barCode] = {
                                                    ...(temp[barCode] || {}),
                                                    commission: parseFloat(
                                                      e.target.value
                                                    ),
                                                  };
                                                  return temp;
                                                });
                                              }}
                                              defaultValue={
                                                campaignProduct?.shop
                                                  ?.commissionAmount || 0
                                              }
                                              addonAfter={
                                                campaignInfo?.shop
                                                  ?.commissionType ===
                                                "PERCENTAGE"
                                                  ? "%"
                                                  : "tk"
                                              }
                                              style={{ minWidth: 100 }}
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              name="subsidy"
                                              type="number"
                                              min={0}
                                              placeholder="Enter Product Subsidy"
                                              onChange={(e) => {
                                                setProductMap((prevVal) => {
                                                  let temp = {
                                                    ...(prevVal || {}),
                                                  };
                                                  temp[barCode] = {
                                                    ...(temp[barCode] || {}),
                                                    subsidy: parseFloat(
                                                      e.target.value
                                                    ),
                                                  };
                                                  return temp;
                                                });
                                              }}
                                              defaultValue={
                                                campaignProduct?.shop
                                                  ?.subsidy || 0
                                              }
                                              style={{ minWidth: 80 }}
                                            />
                                          </td>
                                        </>
                                      )
                                    ) : campaignInfo?.campaignType ===
                                      "PURCHASE_VOUCHER" ? (
                                      <td>
                                        &#2547;
                                        {campaignProduct?.voucherAmount || 0}
                                      </td>
                                    ) : campaignInfo?.campaignType ===
                                      "GROUP_BUY" ? (
                                      //group bye start
                                      status === "APPROVED" ? (
                                        <>
                                          <td>
                                            <div className="flex_">
                                              {campaignProduct
                                                ?.campaignVariations?.length
                                                ? campaignProduct?.campaignVariations?.map(
                                                    (
                                                      variantPrice: any,
                                                      index: string
                                                    ) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <div className="flex justify-content-start mb-1">
                                                          <Tag>
                                                            Discount Type :
                                                            {variantPrice
                                                              ?.groupBuyProductDiscount
                                                              ?.flat
                                                              ? " Flat"
                                                              : " Percentage"}
                                                          </Tag>

                                                          <Tag
                                                            color={
                                                              variantPrice?.stock <
                                                              10
                                                                ? `red`
                                                                : `green`
                                                            }
                                                          >
                                                            Stock :{" "}
                                                            <b>
                                                              {variantPrice?.stock ||
                                                                0}
                                                            </b>
                                                          </Tag>
                                                          <Tag>
                                                            MRP{" "}
                                                            {
                                                              variantPrice
                                                                ?.groupBuyPrice
                                                                ?.currencyCode
                                                            }
                                                            {variantPrice
                                                              ?.groupBuyPrice
                                                              ?.discountedPrice ||
                                                              variantPrice
                                                                ?.groupBuyPrice
                                                                ?.mrp}
                                                            {variantPrice
                                                              ?.groupBuyPrice
                                                              ?.discountedPrice &&
                                                              variantPrice
                                                                ?.groupBuyPrice
                                                                ?.discountedPrice !==
                                                                variantPrice
                                                                  ?.groupBuyPrice
                                                                  ?.mrp && (
                                                                <span className="text-red-600 ml-1">
                                                                  <del>
                                                                    {variantPrice
                                                                      ?.groupBuyPrice
                                                                      ?.currencyCode ||
                                                                      "Tk"}
                                                                    {
                                                                      variantPrice
                                                                        ?.groupBuyPrice
                                                                        ?.mrp
                                                                    }
                                                                  </del>
                                                                </span>
                                                              )}
                                                          </Tag>

                                                          <Tag>
                                                            TP
                                                            {Math.round(
                                                              variantPrice
                                                                ?.groupBuyPrice
                                                                ?.tp
                                                            )}
                                                            {
                                                              variantPrice
                                                                ?.groupBuyPrice
                                                                ?.currencyCode
                                                            }
                                                          </Tag>
                                                        </div>
                                                      </React.Fragment>
                                                    )
                                                  )
                                                : undefined}
                                            </div>
                                          </td>

                                          <td>
                                            <span className="font-medium text-gray-500">
                                              {campaignProduct?.noOfParticipatorEachGroup ||
                                                0}
                                            </span>
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td>
                                            <Input
                                              name="commission"
                                              type="number"
                                              min={0}
                                              placeholder="Enter Commission Amount"
                                              onChange={(e) => {
                                                setProductMap((prevVal) => {
                                                  let temp = {
                                                    ...(prevVal || {}),
                                                  };
                                                  temp[barCode] = {
                                                    ...(temp[barCode] || {}),
                                                    commission: parseFloat(
                                                      e.target.value
                                                    ),
                                                  };
                                                  return temp;
                                                });
                                              }}
                                              defaultValue={
                                                campaignProduct?.shop
                                                  ?.commissionAmount || 0
                                              }
                                              addonAfter={
                                                campaignInfo?.shop
                                                  ?.commissionType ===
                                                "PERCENTAGE"
                                                  ? "%"
                                                  : "tk"
                                              }
                                              style={{ minWidth: 100 }}
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              name="subsidy"
                                              type="number"
                                              min={0}
                                              placeholder="Enter Product Subsidy"
                                              onChange={(e) => {
                                                setProductMap((prevVal) => {
                                                  let temp = {
                                                    ...(prevVal || {}),
                                                  };
                                                  temp[barCode] = {
                                                    ...(temp[barCode] || {}),
                                                    subsidy: parseFloat(
                                                      e.target.value
                                                    ),
                                                  };
                                                  return temp;
                                                });
                                              }}
                                              defaultValue={
                                                campaignProduct?.shop
                                                  ?.subsidy || 0
                                              }
                                              style={{ minWidth: 80 }}
                                            />
                                          </td>
                                        </>
                                      )
                                    ) : (
                                      // group bye end
                                      <td>
                                        {!!campaignProduct?.additionalProducts
                                          ?.length ? (
                                          <Popover
                                            content={
                                              <>
                                                {campaignProduct?.additionalProducts?.map(
                                                  (data: any) => (
                                                    <div className="flex items-center gap-4 mt-2 border-b">
                                                      <div className="">
                                                        <Avatar
                                                          src={getImgUrl(
                                                            data?.productImage
                                                          )}
                                                          size={60}
                                                        />
                                                      </div>
                                                      <div className="">
                                                        <span className="font-semibold text-gray-500">
                                                          {data?.name}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </>
                                            }
                                            title="Gift Items"
                                          >
                                            <GiftFilled />
                                          </Popover>
                                        ) : (
                                          "N/A"
                                        )}
                                      </td>
                                    )}
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td>
                                  <Empty />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Checkbox.Group>
                    </div>
                  </div>
                </div>
              </div>
              <PaginationThree
                {...campaignProductData?.data}
                limit={limit}
                page={getPage(loc.search)}
              />
            </div>
          </section>
        </>
      </CampaignLayout>

      <Drawer
        title="Change Status"
        placement="right"
        onClose={onClose}
        open={visible}
        width={480}
      >
        <Segmented
          block
          // defaultValue={status === "APPROVED" ? "REMOVE" : "APPROVED"}
          // defaultValue={changeStatus}
          // default={changeStatus}
          // default
          value={changeStatus}
          onChange={(val) => setChangeStatus(val as any)}
          key={changeStatus}
          options={[
            {
              label: (
                <div className="flex items-center gap-2 py-2">
                  <Avatar
                    className="bg-green-500"
                    icon={<FontAwesomeIcon icon={faCheck} />}
                  />
                  <span className="text-green-500 font-bold">APPROVE</span>
                </div>
              ),
              value: "APPROVED",
              disabled:
                campaignInfo?.campaignType !== "DISCOUNT" ||
                status === "APPROVED",
            },
            {
              label: (
                <div className="flex items-center gap-2 py-2">
                  <Avatar
                    className="bg-orange-500"
                    icon={<FontAwesomeIcon icon={faTimes} />}
                  />
                  <span className="text-orange-500 font-bold">REJECT</span>
                </div>
              ),
              value: "REJECTED",
              disabled:
                campaignInfo?.campaignType !== "DISCOUNT" ||
                status === "APPROVED",
            },
            {
              label: (
                <div className="flex items-center gap-2 py-2">
                  <Avatar
                    className="bg-red-500"
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  />
                  <span className="text-red-500 font-bold">REMOVE</span>
                </div>
              ),
              value: "REMOVE",
            },
          ]}
        />
        <div className="mt-6">
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            onFinish={onSubmit}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item label="Products Barcodes">
              <Input.TextArea
                value={selectedProductBarcode}
                disabled
                rows={8}
              />
            </Form.Item>

            <Button
              type="primary"
              size="large"
              danger={status !== "APPROVED"}
              title="Product Approval"
              disabled={!changeStatus || loading}
              htmlType="submit"
              className="border-none"
              loading={(loading ? "loading" : undefined) as any}
            >
              {changeStatus || "Take Action"}
            </Button>
          </Form>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default CampaignProductsList;
