import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import UserManagementList from "../../components/Administration";
import MerchantServiceList from "../../components/MerchantAdministration/UserService/index";
import React from "react";
const MerchantServicePage = (): ReactElement => {
  return (
    <MainLayout>
      <MerchantServiceList />
    </MainLayout>
  );
};

export default MerchantServicePage;
