import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
  } from "@ant-design/icons";
  import axios from "axios";
  import { ReactElement, useCallback, useEffect, useState } from "react";
  import { Avatar, Button, Drawer, Empty, Popconfirm, Switch } from "antd";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import { responseNotification } from "../../utils/notify";
  import { getPage, getParamValue } from "../../utils";
  import { useLocation } from "react-router";
  import styles from "../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  import SingleServiceList from "./Details";
  import AddServiceList from "./AddServiceList";
  import moment from "moment";
  import React from "react";
  import { AddBtn, Loader, Pagination } from "../common";
  
  const ServiceList = (): ReactElement => {
    const { type, token } = useSelector((state) => (state as any)?.authReducer);
    const loc = useLocation();
    const page = getParamValue(loc.search, "page");
    const [limit, setLimit] = useState(16);
    const [status, setStatus] = useState("");
    const [showSearch, setShowSearch] = useState(true);
    const [isForLanding, setIsForLanding] = useState<boolean>(false);
    const [visible, setVisible] = useState<any>(undefined);
    const [selectedServiceList, setSelectedServiceList] = useState(undefined);
    const [selectedServiceListForEdit, setSelectedServiceListForEdit] =
      useState(undefined);
    const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = (id: any) => {
      setVisiblePopconfirm(id);
    };
    const [serviceListData, setServiceListData] = useState<any>({
      loading: false,
      data: null,
    });
  
  
    const getServiceTypes = useCallback(async () => {
      setServiceListData({ loading: true, data: null });
  
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUri}/service-type/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setServiceListData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setServiceListData({ loading: false, data: [] });
          console.error("ServiceTypes: Error", err);
        });
    }, [isForLanding]);
  
    //
    const onDelete = async (id: any, val: any) => {
      if (id) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            deleted: !val,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.statusCode === 200) {
              responseNotification("Status Updated Successfully", "success");
              getServiceTypes();
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    };
    const onServiceTypeRemove = async (id: any) => {
      if (id) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            deleted: true,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.statusCode === 200) {
              responseNotification(
                "Service Remove Successfully",
                "success"
              );
              getServiceTypes();
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    };
    useEffect(() => {
      getServiceTypes();
    }, [getServiceTypes]);
  
    const onClose = () => {
      getServiceTypes();
      setVisible(undefined);
      setSelectedServiceList(undefined);
      setSelectedServiceListForEdit(undefined);
    };
    const handleCancel = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setVisiblePopconfirm(undefined);
    };
  
    return (
      <>
        <BreadCrumb
          title="Service List"
          subTitle={`${
            serviceListData?.data?.totalElements ||
            serviceListData?.data?.serviceTypes?.length ||
            0
          } ServiceList(s)`}
          extra={[
            <AddBtn onClick={() => setVisible(true)} key={2} />,
          ]}
        />
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {serviceListData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Value</th>
                        <th scope="col">Status</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {serviceListData?.data?.serviceTypes?.length ? (
                        serviceListData?.data?.serviceTypes?.map(
                          (serviceList: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                              onClick={() => setSelectedServiceList(serviceList)}
                            >
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {serviceList?.name}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {serviceList?.value}
                                </span>
                              </td>
                            
                              <td>
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={!serviceList?.deleted}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDelete(serviceList?.id, val);
                                  }}
                                />
                              </td>
                             
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {serviceList?.updatedBy?.name ||
                                    serviceList?.updatedBy?.mobileNumber}
                                </span>
                              </td>
                              <td>
                                <div className="flex gap-2">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={() =>
                                      setSelectedServiceListForEdit(serviceList)
                                    }
                                  >
                                    <EditOutlined />
                                  </Button>
                                 
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
  
          <Pagination
            {...serviceListData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
  
        <Drawer
          title={
            visible
              ? "Add Service List"
              : selectedServiceListForEdit
              ? "Edit Service List"
              : "Service List Details"
          }
          placement="right"
          onClose={onClose}
          open={visible || selectedServiceList || selectedServiceListForEdit}
          width={450}
        >
          {visible || selectedServiceListForEdit ? (
            <AddServiceList
              onCloseMethod={onClose}
              visibleData={selectedServiceListForEdit}
            />
          ) : (
            <SingleServiceList ServiceDetails={selectedServiceList} />
          )}
        </Drawer>
      </>
    );
  };
  
  export default ServiceList;
  