import PropTypes from "prop-types";
import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ShopLayout from "../Layout";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  AddBtn,
  Loader,
  LoaderFull,
  PaginationThree,
  SubmitResetBtn,
  TreeSelectTwo,
} from "../../common";
import moment from "moment";
import axios from "axios";
import styles from "../../../styles/tailwind/List.module.css";
import {
  getPage,
  getParamValue,
  jcOrderTypeArray,
  jcTypeArray,
} from "../../../utils";
import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  Pagination,
  Radio,
  Select,
  Spin,
  Switch,
  Tabs,
  Tag,
} from "antd";
import classNames from "classnames";
import { BarcodeOutlined, SwapOutlined } from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { error } from "console";
import { responseNotification } from "../../../utils/notify";
import ProductAssign from "./productAssign";
const ShopShippingProfileCrate = (): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [indeterminate, setIndeterminate] = useState(true);
  const [key, setKey] = useState("");
  const [categoryId, setCategoryId] = useState<any>();
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allChecked, setAllChecked] = useState<any>(false);
  const [checkAll, setCheckAll] = useState(false);
  const [shopToShopVisible, setShopToShopVisible] = useState(false);
  const [isChecked, setIsChecked] = useState<any>([]);
  const [checkedList, setCheckedList] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState<any>([]);
  const [activeClone, setActiveClone] = useState(undefined);
  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });
  const [startUpload, setStartUpload] = useState<
  "Initiated" | "Uploading" | "Uploaded" | any
>(undefined);

const [startUpload2, setStartUpload2] = useState<
  "Initiated" | "Uploading" | "Uploaded" | any
>(undefined);
  const route = useParams();
  const shopId = (route as any)?.shopId;
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");

  const onSubmit = async (data: any) => {
    alert("okk");
    setLoading(true);
    const readyData = data && {
      name: data.name,
      productIds: selectedProductId,
      shopId: (route as any)?.shopId,
    };
    await fetch(
      `${process.env.REACT_APP_ORDER_API}/delivery-configuration-create-profile`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Profile Create Successfully", "success");
          form.resetFields();
          navigate(-1);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const getProducts = useCallback(async () => {
    setProductData({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/product/v2?type=${type}` +
          (shopId ? `&shopId=${shopId}` : ``) +
          (categoryId ? `&categoryId=${categoryId}` : ``) +
          (page
            ? `&page=${
                page == productData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProductData({ loading: false, data: res.data });
        setCheckedList(res.data.products.map((product: any) => product?.id));
      })
      .catch((err) => {
        setProductData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [shopId, categoryId, type, page, limit]);

  const onCheckAllChange = (e: any) => {
    setSelectedProductId(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  const onChangeCheckbox = (e: any) => {
    setSelectedProductId((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate(
      !!checkedList.length && checkedList.length <= selectedProductId.length
    );
    setCheckAll(checkedList.length === selectedProductId.length);
  };
  const handleAllCheck = (e) => {
    if (allChecked) {
      setAllChecked(false);
      return setIsChecked([]);
    }
    setAllChecked(true);
  };

  const handleSingleCheck = (e) => {
    const { name } = e.target;
    if (isChecked.includes(name)) {
      setIsChecked(isChecked.filter((checked_name) => checked_name !== name));
      return setAllChecked(false);
    }
    isChecked.push(name);
    setIsChecked([...isChecked]);
  };
  const onClose = () => {
    getProducts();
    setShopToShopVisible(false);
  };
  const showDrawer = () => {
    setShopToShopVisible(true);
  };
  
  const fetchRef = useRef(0);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setCategoryId("");
  };

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Brand List"
        subTitle="BrandList"
        extra={
          <>
             <Button
                shape="round"
                type="primary"
                onClick={() => showDrawer()}
                disabled={!selectedProductId.length}
                title="Clone Product"
                className="bg-blue-600"
              >
                <SwapOutlined /> Action
              </Button>
            {/* <div className="buttons-container">
              <Button
                disabled={loading}
                loading={(loading ? "loading" : undefined) as any}
                type="primary"
                htmlType="submit"
                className="add-submit-btn text-center mr-2"
              >
                Submit
              </Button>

              <Button
                htmlType="button"
                onClick={() => {
                  form?.resetFields();
                }}
                className="reset-submit-btn text-center mr-2"
              >
                Reset
              </Button>
            </div> */}
          </>
        }
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    <div className="content-body">
                      <>
                        <Form
                          name="control-hooks"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          className={styles.formStyles}
                          form={form} // like ref
                          onFinish={onSubmit}
                          layout="horizontal"
                          autoComplete="off"
                        >

                          <Card className="mx-8 my-4 rounded-xl">
                            {showSearch && (
                              <div className={styles?.searchBox}>
                                <Form layout="inline" form={form}>
                                  <Form.Item name="category_search">
                                    <TreeSelectTwo
                                      setCategoryId={setCategoryId}
                                    />
                                  </Form.Item>
                                </Form>
                                <Button
                                  type="primary"
                                  danger
                                  size="large"
                                  htmlType="reset"
                                  onClick={reseAllFieldData}
                                >
                                  Reset
                                </Button>
                              </div>
                            )}

                            <div className={styles.contentWrapper}>
                              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                  <div
                                    className={
                                      showSearch
                                        ? `content-body-withSearch`
                                        : `content-body`
                                    }
                                  >
                                    {confirmLoading && <LoaderFull />}
                                    {productData?.loading ? (
                                      <Loader />
                                    ) : (
                                      <table className={styles.mainTable}>
                                        <thead>
                                          <tr>
                                            <th scope="col">
                                              <div className="checkbox-large">
                                                <Checkbox
                                                  indeterminate={indeterminate}
                                                  onChange={onCheckAllChange}
                                                  checked={
                                                    checkAll ||
                                                    selectedProductId?.length ===
                                                      checkedList?.length
                                                  }
                                                  className="mr-3"
                                                  disabled={
                                                    !productData.data?.products
                                                      ?.length
                                                  }
                                                >
                                                  Check all
                                                </Checkbox>
                                              </div>
                                            </th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Price & Stock</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {productData?.data?.products
                                            ?.length ? (
                                            productData?.data?.products?.map(
                                              (product: any, index: any) => (
                                                <tr
                                                  className="border-t hover:bg-gray-100"
                                                  key={index}
                                                >
                                                  <td>
                                                    <div className="checkbox-large flex items-center gap-2">
                                                      <Checkbox
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          onChangeCheckbox(
                                                            product?.id
                                                          );
                                                        }}
                                                        checked={selectedProductId?.find(
                                                          (item: any) =>
                                                            product?.id === item
                                                        )}
                                                        value={product?.id}
                                                      ></Checkbox>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <Avatar
                                                      size={45}
                                                      src={
                                                        product?.productImage
                                                      }
                                                      shape="square"
                                                    />
                                                    <div className="flex flex-col">
                                                      <span className="font-medium text-gray-500">
                                                        {product?.name?.slice(
                                                          0,
                                                          50
                                                        )}
                                                      </span>
                                                      {product?.barCode && (
                                                        <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                                          <BarcodeOutlined />{" "}
                                                          {product?.barCode}
                                                        </span>
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <span className="font-medium text-gray-500">
                                                      {product?.category?.title?.slice(
                                                        0,
                                                        50
                                                      )}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <div className="flex_">
                                                      {product?.variations
                                                        ?.length
                                                        ? product?.variations?.map(
                                                            (
                                                              variant: any,
                                                              index: string
                                                            ) => (
                                                              <React.Fragment
                                                                key={index}
                                                              >
                                                                <div className="flex justify-content-start mb-1">
                                                                  <Tag
                                                                    color={
                                                                      variant?.stock <
                                                                      10
                                                                        ? `red`
                                                                        : `green`
                                                                    }
                                                                  >
                                                                    {variant?.variationName?.toUpperCase()}
                                                                    :{" "}
                                                                    <b>
                                                                      {variant?.stock ||
                                                                        0}
                                                                    </b>
                                                                  </Tag>

                                                                  <Tag>
                                                                    MRP{" "}
                                                                    {
                                                                      variant
                                                                        ?.price
                                                                        ?.currencyCode
                                                                    }
                                                                    {variant
                                                                      ?.price
                                                                      ?.discountedPrice ||
                                                                      variant
                                                                        ?.price
                                                                        ?.mrp}
                                                                    {variant
                                                                      ?.price
                                                                      ?.discountedPrice &&
                                                                      variant
                                                                        ?.price
                                                                        ?.discountedPrice !==
                                                                        variant
                                                                          ?.price
                                                                          ?.mrp && (
                                                                        <span className="text-red-600 ml-1">
                                                                          <del>
                                                                            {variant
                                                                              ?.price
                                                                              ?.currencyCode ||
                                                                              "Tk"}
                                                                            {
                                                                              variant
                                                                                ?.price
                                                                                ?.mrp
                                                                            }
                                                                          </del>
                                                                        </span>
                                                                      )}
                                                                  </Tag>
                                                                  <Tag>
                                                                    TP{" "}
                                                                    {
                                                                      variant
                                                                        ?.price
                                                                        ?.tp
                                                                    }
                                                                    {
                                                                      variant
                                                                        ?.price
                                                                        ?.currencyCode
                                                                    }
                                                                  </Tag>
                                                                  <Tag className="text-red-600 ">
                                                                    Revenue{" "}
                                                                    {Math.round(
                                                                      variant
                                                                        ?.price
                                                                        ?.discountedPrice -
                                                                        variant
                                                                          ?.price
                                                                          ?.tp
                                                                    )}
                                                                    {
                                                                      variant
                                                                        ?.price
                                                                        ?.currencyCode
                                                                    }
                                                                  </Tag>
                                                                </div>
                                                              </React.Fragment>
                                                            )
                                                          )
                                                        : undefined}
                                                    </div>
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td>
                                                <Empty />
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <PaginationThree
                                {...productData?.data}
                                limit={limit}
                                page={getPage(loc.search)}
                              />
                            </div>
                          </Card>
                        </Form>

                        <Drawer
        title={activeClone ? "Clone Product" : "Change Option"}
        placement="right"
        onClose={onClose}
        open={shopToShopVisible}
        width={550}
      >
        <ProductAssign barcodes={selectedProductId} />
      </Drawer>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};


ShopShippingProfileCrate.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopShippingProfileCrate;
