import {
  Rate,
  Image,
  Avatar,
  Dropdown,
  Menu,
  Modal,
  Radio,
  Form,
  Row,
  Col,
} from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../../utils/notify";
import { useParams } from "react-router";
import DriverLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_DRIVER_INFO } from "../../../../redux/auth/authType";
import moment from "moment";
import { DriverStatus, getImgUrl } from "../../../../utils";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;

const DriverDetails = (): ReactElement => {
  const { token, driverInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const dispatch = useDispatch();
  const route = useParams();
  const [singleDriverInfo, setSingleDriverInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [dmType, setDmType] = useState<string>(``);
  const [deliveryManTypeId, setDeliveryManTypeId] = useState<any>(``);
  const [dmTypeData, setDmTypeData] = useState<any>({
    loading: false,
    list: null,
  });

  const getDmType = useCallback(async () => {
    setDmTypeData({ loading: true, list: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(`${encodedUri}/dm-type/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDmTypeData({
          loading: false,
          list: res.data?.types?.map((type: any) => ({
            label: type?.name,
            value: type?.id,
          })),
        });
      })
      .catch((err) => {
        setDmTypeData({ loading: false, list: [] });
        console.error("Dmtype: Error", err);
      });
  }, []);

  const onStatusChange = async (status: string, deliveryManTypeId?: any) => {
    alert("okey");
    const readyData = {
      driverId: driverInfo?.driverId,
      deliveryManTypeId: deliveryManTypeId || null,
      status: status,
    };
    if (readyData) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/driver/status`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            dispatch({
              type: SET_DRIVER_INFO,
              payload: {
                driverInfo: {
                  status: status,
                },
              },
            });
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  function showConfirm(status: any) {
    confirm({
      title: "Do you Want to change Status?",
      icon: <ExclamationCircleOutlined />,
      content:
        status === "VERIFIED" ? (
          <Radio.Group
            onChange={(e) => {
              setDeliveryManTypeId(e.target.value);
            }}
            value={deliveryManTypeId}
            style={{ width: "100%" }}
          >
            <Row>
              <Col span={12}>
                <Radio value={1}>2021</Radio>
              </Col>
              <Col span={12}>
                <Radio value={2}>2022</Radio>
              </Col>
            </Row>
          </Radio.Group>
        ) : // <Radio.Group
        //   name="Type"
        //   options={dmTypeData?.list}
        //   value={1}
        //   onChange={(e: any) => setDmType(e.target.value)}
        // />
        undefined,
      onOk() {
        onStatusChange(status, deliveryManTypeId);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
      okButtonProps: {
        danger: true,
        disabled: status === "VERIFIED" && !!deliveryManTypeId,
      },
    });
  }

  const fetchDriverDetails = useCallback((getDriverId: any) => {
    if (getDriverId) {
      try {
        setSingleDriverInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/admin/driver/${getDriverId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleDriverInfo({
                loading: false,
                data: data?.data?.driver,
              });
              dispatch({
                type: SET_DRIVER_INFO,
                payload: {
                  driverInfo: {
                    driverId: data?.data?.driver?.id,
                    driverOwnId: data?.data?.driver?.driverId,
                    deliveryCharge:
                      data?.data?.driver?.baseDeliveryChargeForDeliveryMan,
                    name: data?.data?.driver?.name,
                    image: data?.data?.driver?.profilePictureUrl,
                    mobileNumber: data?.data?.driver?.mobileNumber,
                    balance: data?.data?.driver?.balance,
                    rating: data?.data?.driver?.rating,
                    status: data?.data?.driver?.status,
                    deliveryManTypeId: data?.data?.driver?.deliveryManTypeId,
                  },
                },
              });
            } else {
              setSingleDriverInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleDriverInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleDriverInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    getDmType();
  }, [getDmType]);

  useEffect(() => {
    fetchDriverDetails((route as any)?.id);
  }, [fetchDriverDetails]);

  const driver = singleDriverInfo?.data;


  return (
    <React.Fragment>
      <DriverLayout
        driverId={(route as any)?.id as string}
        title="Driver&lsquo;s Profile"
        extra={
          <Dropdown.Button
            className="bg-white"
            overlay={
              <Menu>
                {Object?.values(DriverStatus)?.map((status, i) => (
                  <Menu.Item
                    key={status}
                    onClick={(val) => {
                      showConfirm(val?.key);
                    }}
                  >
                    {status?.split("_").join(" ") || "Status"}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            {driverInfo?.status?.split("_").join(" ") || "Status"}
          </Dropdown.Button>
        }
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
            <h3 className="mb-4 text-xl font-bold">General information</h3>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              
              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Driver Name
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {driver?.name || ''}
                </dd>
              </div>

              {!!driver?.driverId && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Driver ID
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {driver?.driverId || ''}
                  </dd>
                </div>
              )}

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Mobile Number
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {driver?.mobileNumber?.split("+88") || ''}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Current Balance
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  &#2547; {driver?.balance || 0}
                </dd>
              </div>

              {driver?.city && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">City</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {driver?.city || 0}
                  </dd>
                </div>
              )}

              {driver?.zone?.name && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">Zone</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {driver?.zone?.name || 0}
                  </dd>
                </div>
              )}

              {driver?.type && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">Type</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {driver?.type}
                  </dd>
                </div>
              )}

              {driver?.vehicleType && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Vehicle Type
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {driver?.vehicleType}
                  </dd>
                </div>
              )}

              {!!driver?.personalInfoStatus && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Personal Info Status
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {driver?.personalInfoStatus}
                  </dd>
                </div>
              )}

              {!!driver?.status && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">Status</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {driver?.status?.split("_").join(" ")}
                  </dd>
                </div>
              )}

              {/* <div>
                <dt className="text-sm font-medium text-gray-900">
                  Joining Date
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {moment(driver?.createdAt).format("lll")}
                </dd>
              </div> */}

              <div>
                <dt className="text-sm font-medium text-gray-900">Rattings</dt>
                <dd className="text-sm font-semibold text-gray-500">
                  <Rate value={driver?.rating || 1} disabled />
                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                    {driver?.numberOfRating || 0}
                  </span>
                </dd>
              </div>
            </dl>
          </div>

          {!!driver?.nid && (
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
              <h3 className="mb-4 text-xl font-bold">NID Details</h3>
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                {driver?.nid?.nidNumber && (
                  <div className="col-span-full">
                    <dt className="text-sm font-medium text-gray-900">NID</dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {driver?.nid?.nidNumber}
                    </dd>
                  </div>
                )}

                {driver?.nid?.frontUrl && (
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      NID Front
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      <Avatar
                        shape="square"
                        size={100}
                        src={
                          <Image
                            src={getImgUrl(driver?.nid?.frontUrl)}
                            height={100}
                          />
                        }
                        className="mr-3"
                      />
                    </dd>
                  </div>
                )}

                {driver?.nid?.backUrl && (
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      NID Back
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      <Avatar
                        shape="square"
                        size={100}
                        src={
                          <Image
                            src={getImgUrl(driver?.nid?.backUrl)}
                            height={100}
                          />
                        }
                        className="mr-3"
                      />
                    </dd>
                  </div>
                )}
              </dl>
            </div>
          )}

          {!!driver?.drivingLicense && (
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
              <h3 className="mb-4 text-xl font-bold">Driving License</h3>
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                {driver?.drivingLicense?.drivingLicenseNumber && (
                  <div className="col-span-full">
                    <dt className="text-sm font-medium text-gray-900">
                      Driving License
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {driver?.drivingLicense?.drivingLicenseNumber}
                    </dd>
                  </div>
                )}

                {driver?.drivingLicense?.frontUrl && (
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Driving License Front
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      <Avatar
                        shape="square"
                        size={100}
                        src={
                          <Image
                            src={getImgUrl(driver?.drivingLicense?.frontUrl)}
                            height={100}
                          />
                        }
                      />
                    </dd>
                  </div>
                )}

                {driver?.drivingLicense?.backUrl && (
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      NID Back
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      <Avatar
                        shape="square"
                        size={100}
                        src={
                          <Image
                            src={getImgUrl(driver?.drivingLicense?.backUrl)}
                            height={100}
                          />
                        }
                        className="mr-3"
                      />
                    </dd>
                  </div>
                )}

                {driver?.registrationPaper && (
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Registration Paper
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      <Avatar
                        shape="square"
                        size={100}
                        src={
                          <Image
                            src={getImgUrl(driver?.registrationPaper)}
                            height={100}
                          />
                        }
                        className="mr-3"
                      />
                    </dd>
                  </div>
                )}
              </dl>
            </div>
          )}

          {!!driver?.car && (
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
              <h3 className="mb-4 text-xl font-bold">Car Details</h3>
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                {driver?.car?.carRegistrationNumber && (
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Registration Number
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {driver?.car?.carRegistrationNumber}
                    </dd>
                  </div>
                )}
                {driver?.car?.manufacturer && (
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Manufacturer
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {driver?.car?.manufacturer}
                    </dd>
                  </div>
                )}
                {driver?.car?.model && (
                  <div>
                    <dt className="text-sm font-medium text-gray-900">Model</dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {driver?.car?.model}
                    </dd>
                  </div>
                )}
                {driver?.car?.year && (
                  <div>
                    <dt className="text-sm font-medium text-gray-900">Year</dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {driver?.car?.year}
                    </dd>
                  </div>
                )}
              </dl>
            </div>
          )}
        </div>
      </DriverLayout>
    </React.Fragment>
  );
};

DriverDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default DriverDetails;
