import { Image } from "antd";
import React from "react";
import { ReactElement } from "react";

const SingleDeposit = ({
    DepositDetails,
}: {
    DepositDetails: any;
}): ReactElement => {
  return (
    <>
      <div className="">
        <Image
          // height={140}
          width="auto"
          src={DepositDetails?.bannerImage}
          className="rounded-lg shadow-lg"
        />
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Deposit Title</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{DepositDetails?.id}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{DepositDetails?.type?.replace("_", " ")}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Position</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{DepositDetails?.id}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleDeposit;
