import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Empty, Form, Select, Switch } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getPage, getParamValue, jcTypeArray } from "../../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import _ from "lodash";
import { isAllowedService } from "../../../utils/services";
import { responseNotification } from "../../../utils/notify";
import { AddBtn, Loader, LoaderFull, Pagination } from "../../common";
import SingleVehivleType from "./Details";
import AddVehivleType from "./AddBrta";

const BrtaList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [isActive, setIsActive] = useState<any>(true);
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [visible, setVisible] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [selectedBrta, setSelectedBrta] = useState(undefined);
  const [selectedBrtaForEdit, setSelectedBrtaForEdit] = useState(undefined);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [brtaData, setBrtaData] = useState<any>({
    loading: false,
    data: null,
  });
  const isParent = "false";
  const getBrtaAll = useCallback(async () => {
    setBrtaData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(`${encodedUri}/admin/brta/all?isActive=${isActive}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBrtaData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setBrtaData({ loading: false, data: [] });
        console.error("Page: Error", err);
      });
  }, [isActive, limit, page]);

  const onStatusChange = async (id: any, val: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/admin/brta
`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            isActive: val,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getBrtaAll();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getBrtaAll();
  }, [getBrtaAll]);
  const reseAllFieldData = () => {
    form?.resetFields();
    setType("");
    setIsActive("");
  };

  const onClose = () => { 
    getBrtaAll();
    setVisible(undefined);
    setSelectedBrta(undefined);
    setSelectedBrtaForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Brta List"
        subTitle={`${
          brtaData?.data?.totalElements ||
          brtaData?.data?.vehicleRTAS?.length ||
          0
        } Type(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form}>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className={`content-body`}>
              {confirmLoading && <LoaderFull />}
              {brtaData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Country</th>
                      <th scope="col">State</th>
                      <th scope="col">City</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {brtaData?.data?.vehicleRTAS?.length ? (
                      brtaData?.data?.vehicleRTAS?.map(
                        (brta: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                          >
                            <td>
                              <span className="font-medium text-gray-500">
                                {brta?.rtaName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {brta?.countryName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {brta?.stateName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {brta?.cityName}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={brta?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(brta?.id, val);
                                }}
                              />
                            </td>

                            <td>
                              <div className="flex flex-grow">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() => setSelectedBrtaForEdit(brta)}
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title={
          visible
            ? "Add Brta"
            : selectedBrtaForEdit
            ? "Edit Brta"
            : "Brta Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedBrta || selectedBrtaForEdit}
        width={450}
      >
        {visible || selectedBrtaForEdit ? (
          <AddVehivleType
            onCloseMethod={onClose}
            visibleData={selectedBrtaForEdit}
          />
        ) : (
          <SingleVehivleType VehivleTypeDetails={selectedBrta} />
        )}
      </Drawer>
    </>
  );
};

export default BrtaList;
