import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row, Tooltip } from "antd";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../../common";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../../utils/editor-config";

const AddProfile = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const editor = useRef(null);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState<any>(undefined);

  const onSubmit = async (data: any) => {
      setLoading(true);
      const readyData = data && {
        name: data.name,
      };
        await fetch(`${process.env.REACT_APP_ORDER_API}/delivery-configuration-create-profile`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: profileData?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Profile Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      

  };

  useEffect(() => {
    if (visibleData) {
      setProfileData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (profileData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [profileData, form, visibleData]);

  const resetData = () => {
    form?.resetFields();
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...profileData, 
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>



        <Button
            type="primary"
            danger
            title="Clone Selected Items"
            htmlType="submit"
            size="large"
            disabled={
              
              loading
            }
            loading={(loading ? "loading" : undefined) as any}
          >
            Submit
          </Button>
          
      </Form>
    </>
  );
};

export default AddProfile;
