import React from "react";
import MainLayout from "../../../components/Layouts";
import CorporateTopUpApprovedRequestList from "../../../components/Corporate/TopUp/TopupRequestDetails";

const CorporateTopUpApprovedRequestPage = () => {
  return (
    <MainLayout>
      <CorporateTopUpApprovedRequestList />
    </MainLayout>
  );
};

export default CorporateTopUpApprovedRequestPage;
