import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { responseNotification } from "../../../utils/notify";
import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import BreadCrumb from "../../Layouts/Breadcrumb";
import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import { ImageUploader, SubmitResetBtn } from "../../common";
import { jodiConfig } from "../../../utils/editor-config";
import { debounce } from "lodash";
import Styles from "./CustomeStyle.module.css";

const AddCampaign = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const navigate = useNavigate();
  const editor = useRef(null);
  const [appsImgUrl, setAppsImgUrl] = useState<string>();
  const [webImgUrl, setWebImgUrl] = useState<string>();
  const [campaignType, setCampaignType] = useState("DISCOUNT");
  const [memberShipType, setMemberShipType] = useState(
    "SELLER_MEMBERSHIP_CARDS"
  );
  const [campaignApplyType, setCampaignApplyType] = useState(
    "ALL_PRODUCT_CATEGORY"
  );
  const [cashbackType, setCashbackType] = useState("PROMO");
  const [campaignVailidType, setCampaignVailidType] = useState(1);
  const [discountType, setDiscountType] = useState("PERCENTAGE");
  const [startingDate, setStartingDate] = useState<DatePickerProps["value"]>();
  const [endingDate, setEndingDate] = useState<DatePickerProps["value"]>();
  const [isFlashSale, setIsFlashSale] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [productId, setProductId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [isPayFirst, setIsPayFirst] = useState<boolean>(false);

  const [convertedText, setConvertedText] = useState("");
  const [convertedTextForTnC, setConvertedTextForTnC] = useState("");
  const [productOptions, setProductOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const paymentTimePreventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const [error, setError] = useState("");
  const [campaignData, setCampaignData] = useState<any>({
    loading: false,
    data: null,
  });

  const getProductOptions = useCallback(async (val?: string) => {
    setProductOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/admin/product/v2?type=${type}&page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setProductOptions({
      loading: false,
      list: res?.data?.products?.map((product: any) => {
        return {
          value: product?.id,
          label: product?.name,
        };
      }),
    });
  }, []);

  const fetchCampaignDetails = useCallback((getCampaignId: any) => {
    if (getCampaignId) {
      try {
        setCampaignData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign/details/${getCampaignId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setCampaignData({
                loading: false,
                data: data?.data?.campaign,
              });
              setCashbackType(data?.data?.campaign?.cashback?.cashbackType);
            } else {
              setCampaignData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setCampaignData({ loading: false, data: null });
          });
      } catch (error) {
        setCampaignData({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    if (appsImgUrl || webImgUrl) {
      setLoading(true);

      const readyData = data && {
        name: data.name,
        banner: appsImgUrl ? appsImgUrl : data?.banner,
        bannerWeb: webImgUrl ? webImgUrl : data?.bannerWeb,
        campaignType: campaignType || "DISCOUNT",
        colorCode: data.colorCode || "#FFFFFF",
        displayOrder: data.displayOrder,
        keyword: data.keyword,
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        startAt: startingDate || data.startingDate,
        endAt: endingDate || data.endDate,
        cashback: {
          cashbackType: cashbackType,
          expireInDays: data.expireInDays,
          noOfClaimLimit: data.noOfClaimLimit,
        },
        groupBuy: {
          isPayFirst: isPayFirst,
          lastPaymentTimeInMinute: isPayFirst
            ? data.lastPaymentTimeInMinute * 60
            : 0,
          noOfParticipatorEachGroup: isPayFirst
            ? data.noOfParticipatorEachGroup
            : 0,
        },
        // isFlashSale: isFlashSale || data.isFlashSale,
        minimumDiscountType: discountType || data.minimumDiscountType,
        minimumDiscount: parseFloat(data.minimumDiscount),
        type: type || data?.type,
        description: convertedText || data.description,
        termAndConditions: convertedTextForTnC || data.termAndConditions,
        campaignMaximumDiscountAmount: data.campaignMaximumDiscountAmount,
        campaignOrdersOverAmount: data.campaignOrdersOverAmount,
        campaignApplyType: campaignApplyType,
        campaignValidFor: campaignVailidType,
        membershipCardType: memberShipType,
      };
      if (campaignData.data) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: campaignData?.data?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Campaign Updated Successfully", "success");
              form.resetFields();
              navigate("/promotion/campaign");
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            deleted: false,
            status: true,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("campaign Create Successfully", "success");
              form.resetFields();
              navigate("/promotion/campaign");
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "product") getProductOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, type]);
  useEffect(() => {
    getProductOptions();
  }, [getProductOptions]);

  useEffect(() => {
    fetchCampaignDetails((route as any)?.campaignId);
  }, [fetchCampaignDetails]);

  useEffect(() => {
    if (campaignData?.data) {
      form.resetFields();
      setAppsImgUrl(campaignData?.data?.banner);
      setWebImgUrl(campaignData?.data?.bannerWeb);
      setStartingDate(campaignData?.data?.startAt);
      setEndingDate(campaignData?.data?.endAt);
      setCampaignType(campaignData?.data?.campaignType);
      setDiscountType(campaignData?.data?.minimumDiscountType);
      setIsFlashSale(campaignData?.data?.isFlashSale);
      setIsPayFirst(campaignData?.data?.groupBuy?.isPayFirst);
      setCashbackType(campaignData?.data?.cashback?.cashbackType);
      setCampaignVailidType(campaignData?.data?.campaignValidFor);
      setMemberShipType(campaignData?.data?.membershipCardType);
      setCampaignApplyType(campaignData?.data?.campaignApplyType);
    }
  }, [campaignData]);
  const resetData = () => {
    form?.resetFields();
    setAppsImgUrl(undefined);
    setWebImgUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={campaignData?.data ? "Edit Campaign" : "Add Campaign"}
        extra={
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
            loading={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
          />
        }
      />

      <div className="content-body bg-white rounded-2xl p-4 shadow">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          hideRequiredMark
          initialValues={{
            ...campaignData?.data,
            startDate: dayjs(campaignData?.data?.startAt),
            endDate: dayjs(campaignData?.data?.endAt),
            lastPaymentTimeInMinute:
              campaignData?.data?.groupBuy?.lastPaymentTimeInMinute / 60,
            noOfParticipatorEachGroup:
              campaignData?.data?.groupBuy?.noOfParticipatorEachGroup,
            campaignType: campaignType,
            expireInDays: campaignData?.data?.cashback?.expireInDays,
            noOfClaimLimit: campaignData?.data?.cashback?.noOfClaimLimit,
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <div>
              <Form.Item
                hasFeedback
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Campaign Title is Required!",
                  },
                ]}
                name="name"
              >
                <Input
                  id="name"
                  type="text"
                  placeholder="Enter Campaign Title"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Campaign Type"
                rules={[
                  {
                    required: true,
                    message: "Campaign Type is Required!",
                  },
                ]}
                name="campaignType"
              >
                <Select
                  defaultValue={campaignType}
                  onChange={(val) => setCampaignType(val)}
                  options={[
                    { value: "REGULAR", label: "REGULAR" },
                    { value: "DISCOUNT", label: "DISCOUNT" },
                    { value: "QUNTITY", label: "QUNTITY" },
                    { value: "FLASH_SALE", label: "FLASH SALE" },
                    { value: "PRE_ORDER", label: "PRE ORDER" },
                    { value: "BOGO", label: "BUY ONE GET ONE" },
                    { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                    { value: "GROUP_BUY", label: "GROUP BUY" },
                    {
                      value: "PURCHASE_VOUCHER",
                      label: "PURCHASE VOUCHER",
                    },
                    { value: "PROMO", label: "PROMO" },
                    { value: "MEMBERSHIP_CARD", label: "MEMBERSHIP CARD" },
                    { value: "POINT_CODE", label: "POINT CODE" },
                    { value: "CASH_BACK", label: "CASH BACK" },
                    { value: "GIFT_CARD", label: "GIFT CARD" },
                    { value: "FREE_DELIVERY", label: "FREE DELIVERY" },
                  ]}
                  placeholder="Enter Campaign Type"
                />
              </Form.Item>
            </div>

            {campaignType === "MEMBERSHIP_CARD" ? (
              <>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Membership Card Type"
                    rules={[
                      {
                        required: false,
                        message: "Membership Type is Required!",
                      },
                    ]}
                    name="membershipType"
                  >
                    <Select
                      defaultValue={memberShipType}
                      onChange={(val) => setMemberShipType(val)}
                      options={[
                        {
                          value: "SELLER_MEMBERSHIP_CARDS",
                          label: "SELLER MEMBERSHIP CARDS",
                        },
                        {
                          value: "PLATFORM_MEMBERSHIP_CARDS",
                          label: "PLATFORM MEMBERSHIP CARDS",
                        },
                        {
                          value: "PARTNER_MEMBERSHIP_CARDS",
                          label: "PARTNER MEMBERSHIP CARDS",
                        },
                        {
                          value: "CUSTOMER_MEMBERSHIP_CARDS",
                          label: "CUSTOMER MEMBERSHIP CARDS",
                        },
                      ]}
                      placeholder="Enter Membership Type"
                    />
                  </Form.Item>
                </div>
              </>
            ) : campaignType === "GIFT_CARD" ? (
              <>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Membership Card Type"
                    rules={[
                      {
                        required: false,
                        message: "Membership Type is Required!",
                      },
                    ]}
                    name="membershipType"
                  >
                    <Select
                      defaultValue={memberShipType}
                      onChange={(val) => setMemberShipType(val)}
                      options={[
                        {
                          value: "SELLER_MEMBERSHIP_CARDS",
                          label: "SELLER MEMBERSHIP CARDS",
                        },
                        {
                          value: "PLATFORM_MEMBERSHIP_CARDS",
                          label: "PLATFORM MEMBERSHIP CARDS",
                        },
                        {
                          value: "PARTNER_MEMBERSHIP_CARDS",
                          label: "PARTNER MEMBERSHIP CARDS",
                        },
                        {
                          value: "CUSTOMER_MEMBERSHIP_CARDS",
                          label: "CUSTOMER MEMBERSHIP CARDS",
                        },
                      ]}
                      placeholder="Enter Membership Type"
                    />
                  </Form.Item>
                </div>
              </>
            ) : campaignType === "POINT_CODE" ? (
              <>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Membership Card Type"
                    rules={[
                      {
                        required: false,
                        message: "Membership Type is Required!",
                      },
                    ]}
                    name="membershipType"
                  >
                    <Select
                      defaultValue={memberShipType}
                      onChange={(val) => setMemberShipType(val)}
                      options={[
                        {
                          value: "SELLER_MEMBERSHIP_CARDS",
                          label: "SELLER MEMBERSHIP CARDS",
                        },
                        {
                          value: "PLATFORM_MEMBERSHIP_CARDS",
                          label: "PLATFORM MEMBERSHIP CARDS",
                        },
                        {
                          value: "PARTNER_MEMBERSHIP_CARDS",
                          label: "PARTNER MEMBERSHIP CARDS",
                        },
                        {
                          value: "CUSTOMER_MEMBERSHIP_CARDS",
                          label: "CUSTOMER MEMBERSHIP CARDS",
                        },
                      ]}
                      placeholder="Enter Membership Type"
                    />
                  </Form.Item>
                </div>
              </>
            ) : campaignType === "CASH_BACK" ? (
              <>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Membership Card Type"
                    rules={[
                      {
                        required: false,
                        message: "Membership Type is Required!",
                      },
                    ]}
                    name="membershipType"
                  >
                    <Select
                      defaultValue={memberShipType}
                      onChange={(val) => setMemberShipType(val)}
                      options={[
                        {
                          value: "SELLER_MEMBERSHIP_CARDS",
                          label: "SELLER MEMBERSHIP CARDS",
                        },
                        {
                          value: "PLATFORM_MEMBERSHIP_CARDS",
                          label: "PLATFORM MEMBERSHIP CARDS",
                        },
                        {
                          value: "PARTNER_MEMBERSHIP_CARDS",
                          label: "PARTNER MEMBERSHIP CARDS",
                        },
                        {
                          value: "CUSTOMER_MEMBERSHIP_CARDS",
                          label: "CUSTOMER MEMBERSHIP CARDS",
                        },
                      ]}
                      placeholder="Enter Membership Type"
                    />
                  </Form.Item>
                </div>
              </>
            ) : (
              <></>
            )}

            {campaignType === "BOGO" && (
              <>
                <div>
                  <Form.List name="product">
                    {(productFields, { add, remove }) => (
                      <>
                        {productFields.map(({ key, name, ...restField }) => (
                          <div
                            className="col-span-2 mb-2"
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                          >
                            <Space
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "first"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "Missing product name",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Select product"
                                  optionFilterProp="children"
                                  onSearch={(val) => {
                                    handleSearch(val, "product");
                                  }}
                                  defaultValue={productId}
                                  onChange={(val) => setProductId(val)}
                                  filterOption={() => {
                                    return true;
                                  }}
                                  options={productOptions?.list}
                                ></Select>
                              </Form.Item>

                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Space>
                          </div>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Product
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </>
            )}

            {campaignType === "GIFT_ITEMS" && (
              <>
                <div>
                  <Form.List name="giftproduct">
                    {(giftproductFields, { add, remove }) => (
                      <>
                        {giftproductFields.map(
                          ({ key, name, ...restField }) => (
                            <div
                              className="col-span-2 mb-2"
                              key={key}
                              style={{ display: "flex", marginBottom: 8 }}
                            >
                              <div className={Styles.productTitle}>
                                <Space
                                  style={{ display: "flex", marginBottom: 8 }}
                                  align="baseline"
                                >
                                  <Form.Item
                                    {...restField}
                                    name={[name, "first"]}
                                    label="Main Product"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Missing product name",
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select product"
                                      optionFilterProp="children"
                                      onSearch={(val) => {
                                        handleSearch(val, "product");
                                      }}
                                      defaultValue={productId}
                                      onChange={(val) => setProductId(val)}
                                      filterOption={() => {
                                        return true;
                                      }}
                                      options={productOptions?.list}
                                    ></Select>
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "first"]}
                                    label="Gift Items"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Missing product name",
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select product"
                                      optionFilterProp="children"
                                      onSearch={(val) => {
                                        handleSearch(val, "product");
                                      }}
                                      defaultValue={productId}
                                      onChange={(val) => setProductId(val)}
                                      filterOption={() => {
                                        return true;
                                      }}
                                      options={productOptions?.list}
                                    ></Select>
                                  </Form.Item>
                                </Space>
                              </div>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                                className={Styles.productremoveButton}
                              />
                            </div>
                          )
                        )}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Product
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </>
            )}

            <div>
              <Form.Item
                hasFeedback
                label="Campaign Apply Type"
                rules={[
                  {
                    required: true,
                    message: "Campaign Apply Type is Required!",
                  },
                ]}
                name="campaignApplyType"
              >
                <Select
                  defaultValue={campaignApplyType}
                  onChange={(val) => setCampaignApplyType(val)}
                  options={[
                    {
                      value: "ALL_PRODUCT_CATEGORY",
                      label: "ALL PRODUCT CATEGORY",
                    },
                    {
                      value: "SPECIFIC_PRODUCT_CATEGORY",
                      label: "SPECIFIC PRODUCT CATEGORY",
                    },
                    { value: "PRODUCT", label: "PRODUCT" },
                    { value: "CUSTOMER", label: "CUSTOMER" },
                    {
                      value: "PAYMENT_PARTNER",
                      label: "PAYMENT PARTNER",
                    },
                    { value: "DELIVERY_PARTNER", label: "DELIVERY PARTNER" },
                    { value: "NEW_USER_BENIFIT", label: "NEW USER BENIFIT" },
                    { value: "MEMBERSHIP_CARD", label: "MEMBERSHIP CARD" },
                    { value: "SPECIFIC_SELLER", label: "SPECIFIC SELLER" },
                  ]}
                  placeholder="Enter Campaign Apply Type"
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item hasFeedback label="Position" name="displayOrder">
                <Input
                  id="displayOrder"
                  type="number"
                  placeholder="Enter Campaign Position"
                  min={0}
                />
              </Form.Item>
            </div>
          </div>
          {campaignType === "CASH_BACK" && (
            <>
              <Divider orientation="left" className="w-full">
                Cashback Options
              </Divider>
              <div className="grid grid-cols-2 xl:gap-x-4">
                <div>
                  <Form.Item
                    hasFeedback
                    label="Cashback Type"
                    rules={[
                      {
                        required: false,
                        message: "Cashback Type is Required!",
                      },
                    ]}
                    name="cashbackType"
                  >
                    <Select
                      defaultValue={cashbackType}
                      onChange={(val) => setCashbackType(val)}
                      options={[
                        { value: "PROMO", label: "PROMO" },
                        { value: "AUTO", label: "AUTO" },
                      ]}
                      placeholder="Enter Cashback Type"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Expire In Days"
                    name="expireInDays"
                  >
                    <Input
                      id="expireInDays"
                      type="number"
                      placeholder="Enter Cachback Expire In Days"
                      min={0}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="No Of Claim Limit"
                    name="noOfClaimLimit"
                  >
                    <Input
                      id="noOfClaimLimit"
                      type="number"
                      placeholder="Enter No Of Claim Limit"
                      min={0}
                    />
                  </Form.Item>
                </div>
               </div>
               </>
)}
               <div className="grid grid-cols-2 xl:gap-x-4">
                {campaignType === "GROUP_BUY" && (
                  <>
                    <div className="">
                      <Form.Item
                        hasFeedback
                        label="Is Pay First?"
                        name="isPayFirst"
                        className="mr-5"
                      >
                        <Switch
                          checkedChildren={`true`}
                          unCheckedChildren={`false`}
                          defaultChecked={
                            (campaignData as any)?.data?.groupBuy?.isPayFirst
                          }
                          onChange={() => setIsPayFirst(!isPayFirst)}
                        />
                      </Form.Item>
                    </div>
                    {isPayFirst && (
                      <>
                        <div className="isPayFirst">
                          <div className="">
                            <Form.Item
                              hasFeedback
                              label="Last Payment Time In Hours"
                              name="lastPaymentTimeInMinute"
                            >
                              <Input
                                id="lastPaymentTimeInMinute"
                                type="number"
                                min={0}
                                placeholder="Enter Last Payment Time In Hours"
                                onKeyPress={paymentTimePreventMinus}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}
                    <div>
                      <Form.Item
                        hasFeedback
                        label="No Of Participator Each Group"
                        rules={[
                          {
                            required: false,
                            message: "Campaign Title is Required!",
                          },
                        ]}
                        name="noOfParticipatorEachGroup"
                      >
                        <Input
                          id="noOfParticipatorEachGroup"
                          type="number"
                          min={0}
                          placeholder="Enter No Of Participator Each Group"
                        />
                      </Form.Item>
                    </div>
                  </>
                )}

                <div>
                  <Form.Item
                    hasFeedback
                    label="Starting Date"
                    rules={[
                      {
                        required: true,
                        message: "Starting Date is Required!",
                      },
                    ]}
                    name="startDate"
                  >
                    <DatePicker
                      showTime
                      style={{ minWidth: "100%" }}
                      showNow={false}
                      placeholder="Starting Date"
                      onChange={(val: DatePickerProps["value"]) =>
                        setStartingDate(val)
                      }
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="Ending Date"
                    rules={[
                      {
                        required: true,
                        message: "Ending Date is Required!",
                      },
                    ]}
                    name="endDate"
                  >
                    <DatePicker
                      showTime
                      id="endDate"
                      placeholder="Enter Ending Date"
                      showNow={false}
                      style={{ minWidth: "100%" }}
                      onChange={(val: DatePickerProps["value"]) =>
                        setEndingDate(val)
                      }
                      disabled={!startingDate}
                      disabledDate={(currentDate) =>
                        currentDate.isBefore(dayjs(startingDate))
                      }
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Campaign Valid Type"
                    rules={[
                      {
                        required: true,
                        message: "Campaign Valid Type is Required!",
                      },
                    ]}
                    name="campaignValidType"
                  >
                    <Select
                      // disabled={campaignData?.data?.campaignType}
                      defaultValue={campaignVailidType}
                      onChange={(val) => setCampaignVailidType(val)}
                      options={[
                        { value: "1", label: "1 TIME" },
                        { value: "2", label: "2 TIMES" },
                        { value: "3", label: "3 TIMES" },
                        { value: "4", label: "4 TIMES" },
                        { value: "5", label: "5 TIMES" },
                        { value: "6", label: "6 TIMES" },
                        { value: "7", label: "7 TIMES" },
                        { value: "8", label: "8 TIMES" },
                        { value: "9", label: "9 TIMES" },
                        { value: "10", label: "10 TIMES" },
                      ]}
                      placeholder="Enter Campaign Type"
                    />
                  </Form.Item>
                </div>

                {campaignType === "DISCOUNT" ? (
                  <>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Discount Type"
                        rules={[
                          {
                            required: true,
                            message: "Discount Type is Required!",
                          },
                        ]}
                        name="minimumDiscountType"
                        initialValue={discountType}
                      >
                        <Select
                          defaultValue={discountType}
                          onChange={(val) => setDiscountType(val)}
                          options={[
                            { value: "PERCENTAGE", label: "PERCENTAGE" },
                            { value: "FLAT", label: "FLAT" },
                          ]}
                          placeholder="Enter Discount Type"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Minimum Discount"
                        rules={[
                          {
                            required: true,
                            message: "Minimum Discount is Required!",
                          },
                        ]}
                        name="minimumDiscount"
                      >
                        <Input
                          id="minimumDiscount"
                          type="number"
                          min={0}
                          placeholder="Enter Minimum Discount"
                          addonAfter={
                            discountType === "PERCENTAGE" ? "%" : "tk"
                          }
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Maximum Discount  Amount"
                        name="campaignMaximumDiscountAmount"
                      >
                        <Input
                          id="campaignMaximumDiscountAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Maximum Discount  Amount"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Orders over Amount"
                        name="campaignOrdersOverAmount"
                      >
                        <Input
                          id="campaignOrdersOverAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Orders over Amount"
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : campaignType === "QUNTITY" ? (
                  <>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Discount Type"
                        rules={[
                          {
                            required: true,
                            message: "Discount Type is Required!",
                          },
                        ]}
                        name="minimumDiscountType"
                        initialValue={discountType}
                      >
                        <Select
                          defaultValue={discountType}
                          onChange={(val) => setDiscountType(val)}
                          options={[
                            { value: "PERCENTAGE", label: "PERCENTAGE" },
                            { value: "FLAT", label: "FLAT" },
                          ]}
                          placeholder="Enter Discount Type"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Minimum Discount"
                        rules={[
                          {
                            required: true,
                            message: "Minimum Discount is Required!",
                          },
                        ]}
                        name="minimumDiscount"
                      >
                        <Input
                          id="minimumDiscount"
                          type="number"
                          min={0}
                          placeholder="Enter Minimum Discount"
                          addonAfter={
                            discountType === "PERCENTAGE" ? "%" : "tk"
                          }
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Maximum Discount  Amount"
                        name="campaignMaximumDiscountAmount"
                      >
                        <Input
                          id="campaignMaximumDiscountAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Maximum Discount  Amount"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Orders over Amount"
                        name="campaignOrdersOverAmount"
                      >
                        <Input
                          id="campaignOrdersOverAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Orders over Amount"
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : campaignType === "PURCHASE_VOUCHER" ? (
                  <>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Discount Type"
                        rules={[
                          {
                            required: true,
                            message: "Discount Type is Required!",
                          },
                        ]}
                        name="minimumDiscountType"
                        initialValue={discountType}
                      >
                        <Select
                          defaultValue={discountType}
                          onChange={(val) => setDiscountType(val)}
                          options={[
                            { value: "PERCENTAGE", label: "PERCENTAGE" },
                            { value: "FLAT", label: "FLAT" },
                          ]}
                          placeholder="Enter Discount Type"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Minimum Discount"
                        rules={[
                          {
                            required: true,
                            message: "Minimum Discount is Required!",
                          },
                        ]}
                        name="minimumDiscount"
                      >
                        <Input
                          id="minimumDiscount"
                          type="number"
                          min={0}
                          placeholder="Enter Minimum Discount"
                          addonAfter={
                            discountType === "PERCENTAGE" ? "%" : "tk"
                          }
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Maximum Discount  Amount"
                        name="campaignMaximumDiscountAmount"
                      >
                        <Input
                          id="campaignMaximumDiscountAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Maximum Discount  Amount"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Orders over Amount"
                        name="campaignOrdersOverAmount"
                      >
                        <Input
                          id="campaignOrdersOverAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Orders over Amount"
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : campaignType === "PROMO" ? (
                  <>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Discount Type"
                        rules={[
                          {
                            required: true,
                            message: "Discount Type is Required!",
                          },
                        ]}
                        name="minimumDiscountType"
                        initialValue={discountType}
                      >
                        <Select
                          defaultValue={discountType}
                          onChange={(val) => setDiscountType(val)}
                          options={[
                            { value: "PERCENTAGE", label: "PERCENTAGE" },
                            { value: "FLAT", label: "FLAT" },
                          ]}
                          placeholder="Enter Discount Type"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Minimum Discount"
                        rules={[
                          {
                            required: true,
                            message: "Minimum Discount is Required!",
                          },
                        ]}
                        name="minimumDiscount"
                      >
                        <Input
                          id="minimumDiscount"
                          type="number"
                          min={0}
                          placeholder="Enter Minimum Discount"
                          addonAfter={
                            discountType === "PERCENTAGE" ? "%" : "tk"
                          }
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Maximum Discount  Amount"
                        name="campaignMaximumDiscountAmount"
                      >
                        <Input
                          id="campaignMaximumDiscountAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Maximum Discount  Amount"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Orders over Amount"
                        name="campaignOrdersOverAmount"
                      >
                        <Input
                          id="campaignOrdersOverAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Orders over Amount"
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : campaignType === "MEMBERSHIP_CARD" ? (
                  <>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Discount Type"
                        rules={[
                          {
                            required: true,
                            message: "Discount Type is Required!",
                          },
                        ]}
                        name="minimumDiscountType"
                        initialValue={discountType}
                      >
                        <Select
                          defaultValue={discountType}
                          onChange={(val) => setDiscountType(val)}
                          options={[
                            { value: "PERCENTAGE", label: "PERCENTAGE" },
                            { value: "FLAT", label: "FLAT" },
                          ]}
                          placeholder="Enter Discount Type"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Minimum Discount"
                        rules={[
                          {
                            required: true,
                            message: "Minimum Discount is Required!",
                          },
                        ]}
                        name="minimumDiscount"
                      >
                        <Input
                          id="minimumDiscount"
                          type="number"
                          min={0}
                          placeholder="Enter Minimum Discount"
                          addonAfter={
                            discountType === "PERCENTAGE" ? "%" : "tk"
                          }
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Maximum Discount  Amount"
                        name="campaignMaximumDiscountAmount"
                      >
                        <Input
                          id="campaignMaximumDiscountAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Maximum Discount  Amount"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Orders over Amount"
                        name="campaignOrdersOverAmount"
                      >
                        <Input
                          id="campaignOrdersOverAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Orders over Amount"
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : campaignType === "GIFT_CARD" ? (
                  <>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Discount Type"
                        rules={[
                          {
                            required: true,
                            message: "Discount Type is Required!",
                          },
                        ]}
                        name="minimumDiscountType"
                        initialValue={discountType}
                      >
                        <Select
                          defaultValue={discountType}
                          onChange={(val) => setDiscountType(val)}
                          options={[
                            { value: "PERCENTAGE", label: "PERCENTAGE" },
                            { value: "FLAT", label: "FLAT" },
                          ]}
                          placeholder="Enter Discount Type"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Minimum Discount"
                        rules={[
                          {
                            required: true,
                            message: "Minimum Discount is Required!",
                          },
                        ]}
                        name="minimumDiscount"
                      >
                        <Input
                          id="minimumDiscount"
                          type="number"
                          min={0}
                          placeholder="Enter Minimum Discount"
                          addonAfter={
                            discountType === "PERCENTAGE" ? "%" : "tk"
                          }
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Maximum Discount  Amount"
                        name="campaignMaximumDiscountAmount"
                      >
                        <Input
                          id="campaignMaximumDiscountAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Maximum Discount  Amount"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Orders over Amount"
                        name="campaignOrdersOverAmount"
                      >
                        <Input
                          id="campaignOrdersOverAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Orders over Amount"
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : campaignType === "POINT_CODE" ? (
                  <>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Discount Type"
                        rules={[
                          {
                            required: true,
                            message: "Discount Type is Required!",
                          },
                        ]}
                        name="minimumDiscountType"
                        initialValue={discountType}
                      >
                        <Select
                          defaultValue={discountType}
                          onChange={(val) => setDiscountType(val)}
                          options={[
                            { value: "PERCENTAGE", label: "PERCENTAGE" },
                            { value: "FLAT", label: "FLAT" },
                          ]}
                          placeholder="Enter Discount Type"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Minimum Discount"
                        rules={[
                          {
                            required: true,
                            message: "Minimum Discount is Required!",
                          },
                        ]}
                        name="minimumDiscount"
                      >
                        <Input
                          id="minimumDiscount"
                          type="number"
                          min={0}
                          placeholder="Enter Minimum Discount"
                          addonAfter={
                            discountType === "PERCENTAGE" ? "%" : "tk"
                          }
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Maximum Discount  Amount"
                        name="campaignMaximumDiscountAmount"
                      >
                        <Input
                          id="campaignMaximumDiscountAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Maximum Discount  Amount"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Orders over Amount"
                        name="campaignOrdersOverAmount"
                      >
                        <Input
                          id="campaignOrdersOverAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Orders over Amount"
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : campaignType === "CASH_BACK" ? (
                  <>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Discount Type"
                        rules={[
                          {
                            required: true,
                            message: "Discount Type is Required!",
                          },
                        ]}
                        name="minimumDiscountType"
                        initialValue={discountType}
                      >
                        <Select
                          defaultValue={discountType}
                          onChange={(val) => setDiscountType(val)}
                          options={[
                            { value: "PERCENTAGE", label: "PERCENTAGE" },
                            { value: "FLAT", label: "FLAT" },
                          ]}
                          placeholder="Enter Discount Type"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Minimum Discount"
                        rules={[
                          {
                            required: true,
                            message: "Minimum Discount is Required!",
                          },
                        ]}
                        name="minimumDiscount"
                      >
                        <Input
                          id="minimumDiscount"
                          type="number"
                          min={0}
                          placeholder="Enter Minimum Discount"
                          addonAfter={
                            discountType === "PERCENTAGE" ? "%" : "tk"
                          }
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Maximum Discount  Amount"
                        name="campaignMaximumDiscountAmount"
                      >
                        <Input
                          id="campaignMaximumDiscountAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Maximum Discount  Amount"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Campaign Orders over Amount"
                        name="campaignOrdersOverAmount"
                      >
                        <Input
                          id="campaignOrdersOverAmount"
                          type="number"
                          min={0}
                          placeholder="Enter Campaign Orders over Amount"
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div>
                  <Form.Item hasFeedback label="Color Code" name="colorCode">
                    <Input
                      id="colorCode"
                      type="color"
                      placeholder="Enter Color Code"
                    />
                  </Form.Item>
                </div>
                {campaignType === "QUNTITY" && (
                  <>
                    <div>
                      <Form.List name="users" initialValue={[{}]}>
                        {(quantityLevelfields, { add, remove }) => (
                          <>
                            {quantityLevelfields.map(
                              ({ key, name, ...restField }) => (
                                <div
                                  className="col-span-2 mb-2"
                                  key={key}
                                  style={{ display: "flex", marginBottom: 8 }}
                                >
                                  <Space
                                    style={{ display: "flex", marginBottom: 8 }}
                                    align="baseline"
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "first"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing first name",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Buy" />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "last"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing last name",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Discount" />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  </Space>
                                </div>
                              )
                            )}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                Add A Quantity Level
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </div>
                  </>
                )}

                <div>
                  <Form.Item hasFeedback label="Meta Title" name="metaTitle">
                    <Input
                      id="metaTitle"
                      type="text"
                      placeholder="Enter Meta Title"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Meta Description"
                    name="metaDescription"
                  >
                    <Input.TextArea
                      id="metaDescription"
                      placeholder="Enter Meta Description"
                      rows={3}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item hasFeedback label="keyword" name="keyword">
                    <Input.TextArea
                      id="keyWord"
                      placeholder="Enter Product Keywords"
                      rows={3}
                    />
                  </Form.Item>
                </div>

                <div className="col-span-2">
                  <Form.Item hasFeedback label="Description" name="description">
                    <JoditEditor
                      ref={editor}
                      value={convertedText}
                      config={jodiConfig as any}
                      onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
                    />
                  </Form.Item>
                </div>

                <div className="col-span-2">
                  <Form.Item
                    hasFeedback
                    label="Term & Conditions"
                    name="termAndConditions"
                  >
                    <JoditEditor
                      ref={editor}
                      value={convertedTextForTnC}
                      config={jodiConfig as any}
                      onBlur={(newContent) =>
                        setConvertedTextForTnC(newContent)
                      } // preferred to use only this option to update the content for performance reasons
                    />
                  </Form.Item>
                </div>
              </div>
         

          <Row gutter={20} className="mb-6">
            <Col span={12}>
              <Divider orientation="left">
                Apps Image
                <Tooltip
                  placement="bottom"
                  title={"Web image should 500px/180px (Ratio 2.8)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                imgUrl={appsImgUrl || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => setStartUpload(val)}
                setGeneratedImgUrl={(url: any) => setAppsImgUrl(url)}
                // crop={2.8}
              />
            </Col>
            <Col span={12}>
              <Divider orientation="left">
                Web Image
                <Tooltip
                  placement="bottom"
                  title={"Web image should 1200px/750px (Ratio 1.6)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                // crop={1.6}
                imgUrl={webImgUrl || ""}
                startUpload={startUpload2}
                setStartUpload={(val: any) => setStartUpload2(val)}
                setGeneratedImgUrl={(url: any) => setWebImgUrl(url)}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default AddCampaign;
