import {
  Rate,
  Avatar,
  Layout,
  Tabs,
  Empty,
  Steps,
  Row,
  Col,
  Form,
  Button,
  Input,
  Image,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../utils/notify";
import { useLocation, useNavigate, useParams } from "react-router";
import { SET_ORDER_INFO } from "../../redux/auth/authType";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../Layouts/Breadcrumb";
import { Loader, PaginationThree } from "../common";
import moment from "moment";
import { getParamValue, getPage } from "../../utils";
import styles from "../../styles/tailwind/List.module.css";
const { Content } = Layout;

const SingleGroup = ({
  selectedGroup,
}: {
  selectedGroup?: any;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const route = useParams();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [showSearch, setShowSearch] = useState(true);
  const [singleGroupInfo, setSingleGroupInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const fetchGroupDetails = useCallback((getGroupId: any) => {
    if (getGroupId) {
      try {
        setSingleGroupInfo({ loading: true, data: undefined });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign/group-buy/groups/details?groupId=${getGroupId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              setSingleGroupInfo({ loading: false, data: res.data });
            } else {
              setSingleGroupInfo({ loading: false, data: null });
              responseNotification(
                res.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleGroupInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleGroupInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchGroupDetails((route as any)?.groupId);
  }, [fetchGroupDetails, route]);

  return (
    <>
      <BreadCrumb title="Group Buy Order Details" />

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {singleGroupInfo?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Status</th>
                      <th scope="col">Payment Status</th>
                      <th scope="col">Created Info</th>
                    </tr>
                  </thead>

                  <tbody>
                    {singleGroupInfo?.data?.groupBuyParticipators?.length ? (
                      singleGroupInfo?.data?.groupBuyParticipators?.map(
                        (order: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() =>
                              navigate(`/orders/${order?.orderId}/details`)
                            }
                          >
                            <td>
                              <span className="font-medium text-slate-700">
                                {order?.orderId}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-slate-700">
                                {order?.user?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-slate-700">
                                {order?.user?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-slate-700">
                                {order?.status}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-slate-700">
                                {order?.paymentStatus}
                              </span>
                            </td>

                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(order?.createdAt, "minutes") >=
                                  60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(order?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(order?.createdAt).format("lll")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...singleGroupInfo?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

SingleGroup.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
  selectedProduct: PropTypes.string,
};
export default SingleGroup;
