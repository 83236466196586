
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import BreadCrumb from "../Layouts/Breadcrumb";
import { useNavigate, useParams } from "react-router";
import { ImageUploader, SubmitResetBtn } from "../common";
import { debounce } from "lodash";
import Styles from "./CustomeStyle.module.css";
import { jcTypeArray } from "../../utils";
import dayjs from "dayjs";
const AddVehicle = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fitnessCertificateImage, setFitnessCertificateImage] =
    useState<string>();
  const [taxTokenImage, setTaxTokenImage] = useState<string>();
  const [registrationPaperImage, setRegistrationPaperImage] =
    useState<string>();
  const [registrationPaperFront, setRegistrationPaperFront] =
    useState<string>();
  const [uploadFitnessCertificate, setUploadFitnessCertificate] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [uploadTaxToken, setUploadTaxToken] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [uploadRegistrationPaper, setUploadRegistrationPaper] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [uploadRegistrationPaperFront, setUploadRegistrationPaperFront] =
    useState<"Initiated" | "Uploading" | "Uploaded">();
  const [serviceType, setServiceType] = useState<any>("JC_COMMERCE");
  const [shopId, setShopId] = useState<any>();
  const [driverId, setDriverId] = useState<any>();
  const [brtaId, setBrtaId] = useState<any>();
  const [vehicleTypeId, setVehicleTypeId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [fuelTypeId, setFuelTypeId] = useState<any>();
  const [fuelGradeId, setFuelGradeId] = useState<any>();
  const [brandModelId, setBrandModelId] = useState<any>();
  const [featureId, setFeatureId] = useState<any>([]);
  const [key, setKey] = useState("");
  const [fitnessCertificateExpiryDate, setFitnessCertificateExpiryDate] =
    useState<DatePickerProps["value"]>();
  const [discountType, setDiscountType] = useState("PERCENTAGE");
  const [certificateExpiryDate, setCertificateExpiryDate] = useState<DatePickerProps["value"]>();
  const [taxExpiryDate, setTaxExpiryDate] = useState<DatePickerProps["value"]>();
  const [loading, setLoading] = useState(false);
  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });
  const [driverOptions, setDriverOptions] = useState({
    loading: false,
    list: [],
  });
  const [brtaOptions, setBrtaOptions] = useState({
    loading: false,
    list: [],
  });
  const [vehicleOptions, setVehicleOptions] = useState({
    loading: false,
    list: [],
  });
  const [brandsOptions, setBrandsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [fuelTypeOptions, setFuelTypeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [fuelGradeOptions, setFuelGradeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [featureOptions, setFeatureOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [brandModelOptions, setBrandModelOptions] = useState({
    loading: false,
    list: [],
  });

  const [error, setError] = useState("");
  const [vehicleData, setVehicleData] = useState<any>({
    loading: false,
    data: null,
  });
  const fetchVehicleDetails = useCallback((getVehicleId: any) => {
    if (getVehicleId) {
      try {
        setVehicleData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/admin/vehicle/details?id=${getVehicleId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setVehicleData({
                loading: false,
                data: data?.data?.vehicle,
              });
            } else {
              setVehicleData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setVehicleData({ loading: false, data: null });
          });
      } catch (error) {
        setVehicleData({ loading: false, data: null });
      }
    }
  }, []);



  useEffect(() => {
    if (
      uploadFitnessCertificate === "Uploaded" &&
      uploadTaxToken === "Uploaded" &&
      uploadRegistrationPaper === "Uploaded" &&
      uploadRegistrationPaperFront === "Uploaded"
    ) {
      form?.submit();
    }
  }, [uploadFitnessCertificate, uploadTaxToken,uploadRegistrationPaper,uploadRegistrationPaperFront]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      brandId: brandId,
      brandModelId: brandModelId,
      cityMpg: data.cityMpg,
      driverId: driverId,
      featureIds: featureId,
      fitnessCertificateExpiryDate: certificateExpiryDate || data.certificateExpiryDate,
      fitnessCertificateImage: fitnessCertificateImage,
      fitnessCertificateNumber: data.fitnessCertificateNumber,
      fuelConsumptionPerLitter: data.fuelConsumptionPerLitter,
      fuelGradeId: fuelGradeId,
      fuelTypeId: fuelTypeId,
      hwyMpg: data.hwyMpg,
      modelYear: data.modelYear,
      noOfSeat: data.noOfSeat,
      registrationPaperImage: {
        backUrl: registrationPaperImage,
        frontUrl: registrationPaperFront,
      },
      rtaId: brtaId,
      shopId: shopId,
      taxTokenExpiryDate: taxExpiryDate || data.taxExpiryDate,
      taxTokenImage: taxTokenImage,
      taxTokenNumber: data.taxTokenNumber,
      type: serviceType,
      vehicleRegNo: data.vehicleRegNo,
      vehicleTypeId: vehicleTypeId,
    };
    if (vehicleData.data) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/vehicle`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: vehicleData?.data?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Vehicle Updated Successfully", "success");
            form.resetFields();
            navigate("/vehicles/vehicle-list");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/vehicle/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Vehicle Create Successfully", "success");
            form.resetFields();
            navigate("/vehicles/vehicle-list");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const getShopOptions = useCallback(async () => {
    setShopOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/v2?type=${serviceType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setShopOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any, shopIndex: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, [serviceType]);
  const getDriverOptions = useCallback(async () => {
    setDriverOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/driver?page=0&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setDriverOptions({
      loading: false,
      list: res?.data?.drivers?.map((driver: any, driverIndex: any) => {
        return {
          value: driver?.id,
          label: driver?.name,
        };
      }),
    });
  }, []);
  const getBrtaOptions = useCallback(async () => {
    setBrtaOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/brta/all?page=0&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrtaOptions({
      loading: false,
      list: res?.data?.vehicleRTAS?.map((brta: any, brtaIndex: any) => {
        return {
          value: brta?.id,
          label: brta?.rtaName,
        };
      }),
    });
  }, []);
  const getVehicleTypeOptions = useCallback(
    async (key: any) => {
      setVehicleOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url = `?page=0&limit=20` + (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/vehicle-type/all${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setVehicleOptions({
            loading: false,
            list: res.data?.vehicleTypes?.map((vehicle: any) => ({
              label: vehicle.vehicleType,
              value: vehicle.id,
            })),
          });
        })
        .catch((err) => {
          setVehicleOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [key]
  );
  const getBrandsOptions = useCallback(
    async (val?: string) => {
      setBrandsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/brand/search?type=${serviceType}&page=0&limit=20` +
          (val ? `&key=${val}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setBrandsOptions({
        loading: false,
        list: res?.data?.brands?.map((brand: any) => {
          return {
            value: brand?.id,
            label: brand?.name,
          };
        }),
      });
    },
    [serviceType]
  );
  const getBrandModelOptions = useCallback(async () => {
    setBrandModelOptions({ loading: true, list: [] });
    await axios
      .get(
        `${process.env.REACT_APP_CATALOG_READER_API}/brand-model` +
          (serviceType ? `?type=${serviceType}` : ``) +
          (vehicleTypeId ? `&vehicleTypeId=${vehicleTypeId}` : ``) +
          (brandId ? `&brandId=${brandId}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBrandModelOptions({
          loading: false,
          list: res.data?.brandModel?.map(
            (brandModel: any, modelIndex: any) => ({
              label: brandModel.name,
              value: brandModel.id,
            })
          ),
        });
      })
      .catch((err) => {
        setBrandModelOptions({ loading: false, list: [] });
        console.error("Products: Error", err);
      });
  }, [serviceType, brandId, vehicleTypeId]);
  const getFuelTypeOptions = useCallback(
    async (val?: string) => {
      setFuelTypeOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/fuel-type/all?vehicleTypeId	=${vehicleTypeId}&page=0&limit=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setFuelTypeOptions({
        loading: false,
        list: res?.data?.vehicleFuelTypes?.map((fuelType: any) => {
          return {
            value: fuelType?.id,
            label: fuelType?.name,
          };
        }),
      });
    },
    [vehicleTypeId]
  );
  const getFuelGradeOptions = useCallback(async () => {
    setFuelGradeOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/fuel-grade/all?vehicleFuelTypeId=${fuelTypeId}&page=0&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setFuelGradeOptions({
      loading: false,
      list: res?.data?.vehicleFuelGrades?.map(
        (fuelGrade: any, gradeIndex: any) => {
          return {
            value: fuelGrade?.id,
            label: fuelGrade?.name,
          };
        }
      ),
    });
  }, [fuelTypeId]);
  const getFeatureOptions = useCallback(async () => {
    setFeatureOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/vehicle-features/all?vehicleTypeId=${vehicleTypeId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setFeatureOptions({
      loading: false,
      list: res?.data?.vehicleFeature?.map(
        (feature: any, featureIndex: any) => {
          return {
            value: feature?.id,
            label: feature?.name,
          };
        }
      ),
    });
  }, [vehicleTypeId]);
  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "shop") getShopOptions();
          if (field === "driver") getDriverOptions();
          if (field === "brta") getBrtaOptions();
          if (field === "vehicle") getVehicleTypeOptions(value);
          if (field === "brand") getBrandsOptions(value);
          if (field === "brandModel") getBrandModelOptions();
          if (field === "fuelType") getFuelTypeOptions();
          if (field === "fuelGrade") getFuelGradeOptions();
          if (field === "feature") getFeatureOptions();
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getShopOptions,
    getDriverOptions,
    getBrtaOptions,
    getVehicleTypeOptions,
    getBrandsOptions,
    getBrandModelOptions,
    getFuelTypeOptions,
    getFuelGradeOptions,
    getFeatureOptions,
    type,
  ]);
  useEffect(() => {
    fetchVehicleDetails((route as any)?.vehicleId);
  }, [fetchVehicleDetails]);

  useEffect(() => {
    if (vehicleData?.data) {
      form.resetFields();
      setCertificateExpiryDate(vehicleData?.data?.fitnessCertificateExpiryDate || "2024-10-31 17:08:18");
      setTaxExpiryDate(vehicleData?.data?.taxTokenExpiryDate || "2024-10-31 17:08:18");
      setShopId(vehicleData?.data?.shopId);
      setDriverId(vehicleData?.data?.driverId);
      setBrtaId(vehicleData?.data?.rtaId);
      setVehicleTypeId(vehicleData?.data?.vehicleTypeId);
      setBrandId(vehicleData?.data?.brandId);
      setBrandModelId(vehicleData?.data?.brandModelId);
      setFitnessCertificateImage(vehicleData?.data?.fitnessCertificateImage);
      setTaxTokenImage(vehicleData?.data?.taxTokenImage);
      setRegistrationPaperImage(vehicleData?.data?.registrationPaperImage?.backUrl);
      setRegistrationPaperFront(vehicleData?.data?.registrationPaperImage?.frontUrl);
    }
  }, [vehicleData]);
  const resetData = () => {
    form?.resetFields();
  };

  useEffect(() => {
    getShopOptions();
    getDriverOptions();
    getBrtaOptions();
    getVehicleTypeOptions("");
    getBrandsOptions("");
    getBrandModelOptions();
    getFuelTypeOptions("");
    getFuelGradeOptions();
    getFeatureOptions();
  }, [
    getShopOptions,
    getDriverOptions,
    getBrtaOptions,
    getVehicleTypeOptions,
    getBrandsOptions,
    getBrandModelOptions,
    getFuelTypeOptions,
    getFuelGradeOptions,
    getFeatureOptions,
  ]);
  return (
    <>
      <BreadCrumb
        title={vehicleData?.data ? "Edit Vehicle" : "Add Vehicle"}
        extra={
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setUploadFitnessCertificate("Uploading");
              setUploadTaxToken("Uploading");
              setUploadRegistrationPaper("Uploading");
              setUploadRegistrationPaperFront("Uploading");
            }}
            onClickReset={resetData}
            disabled={
              loading ||
              uploadFitnessCertificate === "Uploading" ||
              uploadTaxToken === "Uploading" ||
              uploadRegistrationPaper === "Uploading" ||
              uploadRegistrationPaperFront === "Uploading" ||
              !!error
            }
            loading={
              loading ||
              uploadFitnessCertificate === "Uploading" ||
              uploadTaxToken === "Uploading" ||
              uploadRegistrationPaper === "Uploading" ||
              uploadRegistrationPaperFront === "Uploading" ||
              !!error
            }
          />
        }
      />

      <div className="content-body bg-white rounded-2xl p-4 shadow">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          hideRequiredMark
          initialValues={{
            ...vehicleData?.data,
            certificateExpiryDate:dayjs(vehicleData?.data?.fitnessCertificateExpiryDate),
            taxExpiryDate:dayjs(vehicleData?.data?.taxTokenExpiryDate),
            modelYear:vehicleData?.data?.year,
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <div>
              <Form.Item
                hasFeedback
                label="Vehicle Registration Number"
                rules={[
                  {
                    required: false,
                    message: "Vehicle Registration Number is Required!",
                  },
                ]}
                name="vehicleRegNo"
              >
                <Input
                  id="vehicleRegNo"
                  type="text"
                  placeholder="Enter Vehicle Registration Number"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Service Type"
                rules={[
                  {
                    required: false,
                    message: "Type is required!",
                  },
                ]}
                name="serviceType"
                initialValue={"JC_COMMERCE"}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Service Type"
                  defaultValue={serviceType}
                  onChange={(val) => setServiceType(val)}
                  filterOption={() => {
                    return true;
                  }}
                >
                  {Object.values(jcTypeArray)?.map((type, i) => (
                    <Select.Option value={type} key={i}>
                      {type?.replace("_", " ")}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="shopId"
                label="Shop"
                rules={[
                  {
                    required: false,
                    message: "Shop is Required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by shop`}
                  optionFilterProp="children"
                  defaultValue={shopId}
                  onChange={(val) => setShopId(val)}
                  onSearch={(e) => handleSearch(e, "shop")}
                  filterOption={() => {
                    return true;
                  }}
                  options={shopOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="driverId"
                label="Driver"
                rules={[
                  {
                    required: false,
                    message: "Driver is Required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by Driver`}
                  optionFilterProp="children"
                  defaultValue={driverId}
                  onChange={(val) => setDriverId(val)}
                  onSearch={(e) => handleSearch(e, "driver")}
                  filterOption={() => {
                    return true;
                  }}
                  options={driverOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="brtaId"
                label="BRTA"
                rules={[
                  {
                    required: false,
                    message: "BRTA is Required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by BRTA`}
                  optionFilterProp="children"
                  defaultValue={brtaId}
                  onChange={(val) => setBrtaId(val)}
                  onSearch={(e) => handleSearch(e, "brta")}
                  filterOption={() => {
                    return true;
                  }}
                  options={brtaOptions?.list}
                ></Select>
              </Form.Item>
            </div>

            <div>
              <Form.Item
                name="vehicleTypeId"
                label="Vehicle Type"
                rules={[
                  {
                    required: false,
                    message: "value is Required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by vehicle type`}
                  optionFilterProp="children"
                  defaultValue={vehicleTypeId}
                  onChange={(val) => setVehicleTypeId(val)}
                  onSearch={(e) => handleSearch(e, "vehicle")}
                  filterOption={() => {
                    return true;
                  }}
                  options={vehicleOptions?.list}
                ></Select>
              </Form.Item>
            </div>

            <div>
              <Form.Item
                name="brandId"
                label="Brand"
                rules={[
                  {
                    required: false,
                    message: "Brand is Required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by brand`}
                  optionFilterProp="children"
                  defaultValue={brandId}
                  onChange={(val) => setBrandId(val)}
                  onSearch={(e) => handleSearch(e, "brand")}
                  filterOption={() => {
                    return true;
                  }}
                  options={brandsOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="brandModelId"
                label="Brand Model"
                rules={[
                  {
                    required: false,
                    message: "Brand Model is Required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by Brand Model`}
                  optionFilterProp="children"
                  defaultValue={brandModelId}
                  onChange={(val) => setBrandModelId(val)}
                  onSearch={(e) => handleSearch(e, "brandModel")}
                  filterOption={() => {
                    return true;
                  }}
                  options={brandModelOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="fuelTypeId"
                label="Fuel Type"
                rules={[
                  {
                    required: false,
                    message: "Fuel Type is Required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by Fuel Type`}
                  optionFilterProp="children"
                  onChange={(val) => setFuelTypeId(val)}
                  onSearch={(e) => handleSearch(e, "fuelType")}
                  filterOption={() => {
                    return true;
                  }}
                  options={fuelTypeOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="fuelGradeId"
                label="Fuel Grade"
                rules={[
                  {
                    required: false,
                    message: "Fuel Grade is Required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by Fuel Grade`}
                  optionFilterProp="children"
                  onChange={(val) => setFuelGradeId(val)}
                  onSearch={(e) => handleSearch(e, "fuelGrade")}
                  filterOption={() => {
                    return true;
                  }}
                  options={fuelGradeOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="featureIds"
                label="Feature"
                rules={[
                  {
                    required: false,
                    message: "Feature is Required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder={`Filter by Feature`}
                  optionFilterProp="children"
                  onChange={(val) => setFeatureId(val)}
                  onSearch={(e) => handleSearch(e, "feature")}
                  mode="multiple"
                  filterOption={() => {
                    return true;
                  }}
                  options={featureOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item hasFeedback label="Number of Seats" name="noOfSeat">
                <Input
                  id="noOfSeat"
                  type="number"
                  placeholder="Enter Number of Seats"
                  min={0}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item hasFeedback label="Model of Year" name="modelYear">
                <Input
                  id="modelYear"
                  type="number"
                  placeholder="Enter Model of Year"
                  min={0}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Highway Miles per Gallon"
                name="hwyMpg"
              >
                <Input
                  id="hwyMpg"
                  type="number"
                  placeholder="Enter Highway Miles per Gallon"
                  min={0}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="City Miles per Gallon"
                name="cityMpg"
              >
                <Input
                  id="cityMpg"
                  type="number"
                  placeholder="Enter City Miles per Gallon"
                  min={0}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Fuel Consumption Per Litter"
                name="fuelConsumptionPerLitter"
              >
                <Input
                  id="fuelConsumptionPerLitter"
                  type="number"
                  placeholder="Enter Fuel Consumption Per Litter"
                  min={0}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Fitness Certificate Number"
                name="fitnessCertificateNumber"
              >
                <Input
                  id="fitnessCertificateNumber"
                  type="number"
                  placeholder="Enter Fitness Certificate Number"
                  min={0}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Fitness Certificate Expiry Date"
                rules={[
                  {
                    required: false,
                    message: "Starting Date is Required!",
                  },
                ]}
                name="fitnessCertificateExpiryDate"
              > 
                <DatePicker
                  showTime
                  style={{ minWidth: "100%" }}
                  showNow={false}
                  placeholder="Fitness Certificate Expiry Date"
                  onChange={(val: DatePickerProps["value"]) =>
                    setCertificateExpiryDate(val)
                  }
                />
              </Form.Item>
            </div>
            <Row gutter={20} className="mb-6">
              <Col span={12}>
                <Divider orientation="left">
                  Fitness Certificate Image
                  <Tooltip
                    placement="bottom"
                    title={
                      "Fitness Certificate Image should 500px/180px (Ratio 2.8)"
                    }
                  >
                    <QuestionCircleOutlined className="px-2 -mt-2" />
                  </Tooltip>
                </Divider>
                <ImageUploader
                  imgUrl={fitnessCertificateImage || ""}
                  startUpload={uploadFitnessCertificate}
                  setStartUpload={(val: any) =>
                    setUploadFitnessCertificate(val)
                  }
                  setGeneratedImgUrl={(url: any) =>
                    setFitnessCertificateImage(url)
                  }
                  // crop={2.8}
                />
              </Col>
            </Row>
            <div>
              <Form.Item
                hasFeedback
                label="Tax Token Number"
                name="taxTokenNumber"
              >
                <Input
                  id="taxTokenNumber"
                  type="number"
                  placeholder="Enter Tax Token Number"
                  min={0}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Tax Token Expiry Date"
                rules={[
                  {
                    required: false,
                    message: "Tax Token Expiry Date is Required!",
                  },
                ]}
                name="taxTokenExpiryDate"
              >
                <DatePicker
                  showTime
                  style={{ minWidth: "100%" }}
                  showNow={false}
                  placeholder="Tax Token Expiry Date"
                  onChange={(val: DatePickerProps["value"]) =>
                    setTaxExpiryDate(val)
                  }
                />
              </Form.Item>
            </div>
            <Row gutter={20} className="mb-6">
              <Col span={12}>
                <Divider orientation="left">
                  Tax Token Image
                  <Tooltip
                    placement="bottom"
                    title={"Tax Token Image should 500px/180px (Ratio 2.8)"}
                  >
                    <QuestionCircleOutlined className="px-2 -mt-2" />
                  </Tooltip>
                </Divider>
                <ImageUploader
                  imgUrl={taxTokenImage || ""}
                  startUpload={uploadTaxToken}
                  setStartUpload={(val: any) => setUploadTaxToken(val)}
                  setGeneratedImgUrl={(url: any) => setTaxTokenImage(url)}
                  // crop={2.8}
                />
              </Col>
            </Row>
          </div>
          <div className="grid grid-cols-1 xl:gap-x-4">
            <Row gutter={20} className="mb-6">
              <Col span={12}>
                <Divider orientation="left">
                  Registration Paper Back Side Image
                  <Tooltip
                    placement="bottom"
                    title={"image should 500px/180px (Ratio 2.8)"}
                  >
                    <QuestionCircleOutlined className="px-2 -mt-2" />
                  </Tooltip>
                </Divider>
                <ImageUploader
                  imgUrl={registrationPaperImage || ""}
                  startUpload={uploadRegistrationPaper}
                  setStartUpload={(val: any) => setUploadRegistrationPaper(val)}
                  setGeneratedImgUrl={(url: any) =>
                    setRegistrationPaperImage(url)
                  }
                  // crop={2.8}
                />
              </Col>
              <Col span={12}>
                <Divider orientation="left">
                  Registration Paper Front Side Image
                  <Tooltip
                    placement="bottom"
                    title={"image should 1200px/750px (Ratio 1.6)"}
                  >
                    <QuestionCircleOutlined className="px-2 -mt-2" />
                  </Tooltip>
                </Divider>
                <ImageUploader
                  // crop={1.6}
                  imgUrl={registrationPaperFront || ""}
                  startUpload={uploadRegistrationPaperFront}
                  setStartUpload={(val: any) =>
                    setUploadRegistrationPaperFront(val)
                  }
                  setGeneratedImgUrl={(url: any) =>
                    setRegistrationPaperFront(url)
                  }
                />
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddVehicle;
