import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopShippingProfileList from "../../../components/Shop/ShippingProfile/index";

const ShopShippingProfilePage = () => {
  return (
    <MainLayout>
      <ShopShippingProfileList />
    </MainLayout>
  );
};

export default ShopShippingProfilePage;
