import {
  AutoComplete,
  Avatar,
  Badge,
  Button,
  DatePicker,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Spin,
} from "antd";
import {
  ExclamationCircleOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, imageOrderStatus } from "../../utils";
import { debounce } from "lodash";
import styles from "../../styles/tailwind/List.module.css";
import { responseNotification } from "../../utils/notify";
import { useSelector } from "react-redux";
import React from "react";
import { PaginationThree, Loader } from "../common";
import AddOrder from "./AddOrder";
import SingleOrder from "./Details";
const { RangePicker } = DatePicker;
const { confirm } = Modal;

const OrderList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedOrderForEdit, setSelectedOrderForEdit] = useState(undefined);
  const [mobileNumber, setMobileNumber] = useState("");
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [orderImageData, setOrderImageData] = useState<any>({
    loading: false,
    data: null,
  });
  const [usersOptions, setUsersOptions] = useState({
    loading: false,
    list: [],
  });
  const onClose = () => {
    setSelectedOrder(undefined);
  };

  const onStatusChange = async (imageOrderId: any) => {
    await fetch(
      `${process.env.REACT_APP_ORDER_API}/image-order/update-by-admin`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          imageOrderId: imageOrderId,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          getOrders();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(imageOrderId: any) {
    confirm({
      title: "Do you Want to change Status?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onStatusChange(imageOrderId);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
      okButtonProps: {
        danger: true,
      },
    });
  }
  // const getUsersOptions = useCallback(
  //   async (mobileNumber: any) => {
  //     setUsersOptions({ loading: true, list: [] });
  //     const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
  //     axios
  //       .get(
  //         `${encodedUri}/admin/user?` +
  //           `page=${0}` +
  //           `&limit=${20}` +
  //           (status ? `&status=${status}` : ``) +
  //           (mobileNumber
  //             ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
  //             : ``),
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         setUsersOptions({
  //           loading: false,
  //           list: res.data?.users?.map(
  //             (user: { fullName: any; mobileNumber: string }) => ({
  //               label: user.fullName,
  //               value: user.mobileNumber.replace("+88", ""),
  //             })
  //           ),
  //         });
  //       })
  //       .catch((err) => {
  //         setUsersOptions({ loading: false, list: [] });
  //         console.error("Users: Error", err);
  //       });
  //   },
  //   [status, mobileNumber]
  // );
  const getOrders = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setOrderImageData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUrl}/image-order/all-by-admin` +
          `?page=${page || 0}` +
          (page
            ? `?page=${
                page == orderImageData?.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``) +
          (mobileNumber ? `&mobileNumberOrName=${mobileNumber}` : ``) +
          (status ? `?status=${status}` : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrderImageData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setOrderImageData({ loading: false, data: [] });
        console.error("Order: Error", err);
      });
  }, [limit, page, mobileNumber, status,range]);

  const fetchRef = useRef(0);

  const getuserOptionsDebounce = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      // getUsersOptions({ loading: false, list: null });
      if (fetchId !== fetchRef.current) {
        return;
      }
      // getUsersOptions(value);
    };
    return debounce(loadOptions, 800);
  }, []);
  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const reseAllFieldData = () => {
    form?.resetFields();
    setStatus("");
    setMobileNumber("");
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <>
      <BreadCrumb
        title="Order Image List"
        subTitle={`${orderImageData?.data?.totalElements} Order(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            {/* <Form.Item name="phone_search" initialValue={mobileNumber}>
              <AutoComplete
                style={{ width: 250 }}
                onSearch={getuserOptionsDebounce}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setMobileNumber(val?.toString())
                }
                options={usersOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  usersOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by User Phone"
                  onSearch={(val) => setMobileNumber(val)}
                  enterButton
                  loading={usersOptions.loading}
                />
              </AutoComplete>
            </Form.Item> */}
            <Form.Item name="status_search" initialValue={status}>
              <Select
                showSearch
                placeholder="Filter by Status"
                onChange={(val) => setStatus(val)}
              >
                <Select.Option value="">ALL STATUS</Select.Option>
                {Object.values(imageOrderStatus)?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                 setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {orderImageData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Created Info</th>
                      <th scope="col">Updated Info</th>
                      <th scope="col">Details</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderImageData?.data?.orders?.length ? (
                      orderImageData?.data?.orders?.map(
                        (order: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() =>
                              navigate(
                                `/orders/order-image/${order?.id}/details`
                              )
                            }
                          >
                            <td>
                              <span className="font-medium text-slate-700">
                                {order?.customer?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-slate-700">
                                {order?.customer?.mobileNumber}
                              </span>
                            </td>

                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(order?.createdAt, "minutes") >=
                                  60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(order?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(order?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {order?.orderManuallyPlaced?.name ||
                                  order?.orderManuallyPlaced?.mobileNumber?.replace(
                                    "+88",
                                    ""
                                  )}
                                {" - "}
                              </small>
                            </td>

                            <td>
                              <div className="flex flex-grow">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white mr-1"
                                  onClick={() => setSelectedOrder(order)}
                                >
                                  <EyeOutlined />
                                </Button>
                              </div>
                            </td>
                            <td>
                              {order?.status === "INITIATED" ? (
                                <Dropdown.Button
                                  className="bg-white"
                                  overlay={
                                    <Menu>
                                      {Object?.values(imageOrderStatus)?.map(
                                        (status) => (
                                          <Menu.Item
                                            key={status}
                                            onClick={(val) => {
                                              //showConfirm(val?.key);
                                              showConfirm(order?.id);
                                            }}
                                          >
                                            {status?.split("_").join(" ") ||
                                              "Status"}
                                          </Menu.Item>
                                        )
                                      )}
                                    </Menu>
                                  }
                                >
                                  {order?.status?.split("_").join(" ") ||
                                    "Status"}
                                </Dropdown.Button>
                              ) : (
                                <span className="border p-1 px-5 rounded-2xl bg-white">
                                  {order?.status}
                                </span>
                              )}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...orderImageData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Order"
            : selectedOrderForEdit
            ? "Edit Oeder"
            : "Order Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedOrder || selectedOrderForEdit}
        width={450}
        extra={
          <Button
            type="primary"
            danger
            shape="round"
            onClick={() => navigate(`/orders/create`)}
          >
            Create Order
          </Button>
        }
      >
        {visible || selectedOrderForEdit ? (
          <AddOrder
            onCloseMethod={onClose}
            visibleData={selectedOrderForEdit}
          />
        ) : (
          ""
        )}
      </Drawer>

      <Modal
        width={`80%`}
        title={`ORDER DETAILS - ${selectedOrder?.id}`}
        open={selectedOrder}
        onCancel={onClose}
        okButtonProps={{ type: "primary", danger: true }}
        footer={false}
      >
        <SingleOrder selectedOrder={selectedOrder} />
      </Modal>
    </>
  );
};

export default OrderList;
