import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopShippingSetting from "../../../components/Shop/ShippingSetting/index";

const ShopPickupZoneListPage = () => {
  return (
    <MainLayout>
      <ShopShippingSetting />
    </MainLayout>
  );
};

export default ShopPickupZoneListPage;
