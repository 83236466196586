import React from "react";
import BrtaList from "../../../components/Vehicles/Brta/index";
import MainLayout from "../../../components/Layouts";

const BrtaListPage = () => {
  return (
    <MainLayout>
      <BrtaList />
    </MainLayout>
  );
};

export default BrtaListPage;
