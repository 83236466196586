import { Divider, Flex, Form, Input } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../utils/notify";
import React from "react";
import BreadCrumb from "../Layouts/Breadcrumb";
import { SubmitResetBtn } from "../common";
import { useSelector } from "react-redux";

const RevenueDistributionConfig = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [singleConfigInfo, setSingleConfigInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [deliveryChargeSubsidy, setDeliveryChargeSubsidy] = useState<any>(0);
  const [referralSubsidy, setReferralSubsidy] = useState<any>(0);
  const [retainedRevenue, setRetainedRevenue] = useState<any>(0);
  const [deliveryReferralSubsidy, setDeliveryReferralSubsidy] =
    useState<any>(0);
  const [merchantReferralSubsidy, setMerchantReferralSubsidy] =
    useState<any>(0);
  const [userReferralSubsidy, setUserReferralSubsidy] = useState<any>(0);
  const TotalValue =
    parseInt(deliveryChargeSubsidy) +
    parseInt(referralSubsidy) +
    parseInt(retainedRevenue);

  // **************************************

  const fetchConfigDetails = useCallback(() => {
    try {
      setSingleConfigInfo({ loading: true, data: null });
      axios
        .get(
          `${process.env.REACT_APP_ORDER_API}/admin/revenue-distribution-config?serviceType=${type}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setSingleConfigInfo({
              loading: false,
              data: data?.data?.revenueDistributionConfig,
            });
          } else {
            setSingleConfigInfo({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setSingleConfigInfo({ loading: false, data: null });
        });
    } catch (error) {
      setSingleConfigInfo({ loading: false, data: null });
    }
  }, []);

  useEffect(() => {
    fetchConfigDetails();
  }, [fetchConfigDetails]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      deliveryChargeSubsidyOfTotalRevenueInPercentage:
        data.deliveryChargeSubsidyOfTotalRevenueInPercentage,
      retainedOfTotalRevenueInPercentage:
        data.retainedOfTotalRevenueInPercentage,
      referralSubsidyInPercentage: {
        deliveryReferralSubsidy: data.deliveryReferralSubsidy,
        merchantReferralSubsidy: data.merchantReferralSubsidy,
        userReferralSubsidy: data.userReferralSubsidy,
      },
      retainedOfTotalRevenue: {
        flat: data.flat,
        maxRevenueForSurpriseDiscount: data.maxRevenueForSurpriseDiscount,
        percentage: data.percentage,
        surpriseDiscountInPercentage: data.surpriseDiscountInPercentage,
      },
      serviceType: type,
    };
    if (singleConfigInfo.data) {
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/revenue-distribution-config`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: singleConfigInfo?.data?.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Revenue Distribution Config Updated Successfully",
              "success"
            );
            form.resetFields();
            fetchConfigDetails();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/revenue-distribution-config`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...readyData }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Revenue Distribution Config Create Successfully",
              "success"
            );
            form.resetFields();
            fetchConfigDetails();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (singleConfigInfo.data) {
      form.resetFields(Object.keys(singleConfigInfo.data as any));
    }
  }, [form, singleConfigInfo]);

  return (
    <div className="content-body rounded-2xl">
      <BreadCrumb title="Revenue Distribution Config" />
      <div className="bg-red-200 block p-3 mb-6 mt-2 rounded-lg shadow-lg ">
        <h2 className="text-red-500 font-medium text-lg text-center">
          Summation of Delivery Charge Subsidy, Referral Subsidy, Retained must
          be equal to 100
        </h2>
      </div>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-6">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onSubmit}
          initialValues={{
            ...singleConfigInfo?.data,
            deliveryReferralSubsidy:singleConfigInfo?.data?.referralSubsidyInPercentage?.deliveryReferralSubsidy,
            merchantReferralSubsidy:singleConfigInfo?.data?.referralSubsidyInPercentage?.merchantReferralSubsidy,
            userReferralSubsidy:singleConfigInfo?.data?.referralSubsidyInPercentage?.userReferralSubsidy,
            flat:singleConfigInfo?.data?.retainedOfTotalRevenue?.flat,
            maxRevenueForSurpriseDiscount:singleConfigInfo?.data?.retainedOfTotalRevenue?.maxRevenueForSurpriseDiscount,
            percentage:singleConfigInfo?.data?.retainedOfTotalRevenue?.percentage,
            surpriseDiscountInPercentage:singleConfigInfo?.data?.retainedOfTotalRevenue?.surpriseDiscountInPercentage,
          }}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
            <div>
              <Form.Item
                hasFeedback
                label="Delivery Charge Subsidy In Percentage (%) Done"
                rules={[
                  {
                    required: true,
                    message: "Delivery Charge Subsidy In Percentage!",
                  },
                ]}
                name="deliveryChargeSubsidyOfTotalRevenueInPercentage"
              >
                <Input
                  id="deliveryChargeSubsidyOfTotalRevenueInPercentage"
                  type="number"
                  placeholder="Enter Delivery Charge Subsidy In Percentage"
                  onChange={(e) => setDeliveryChargeSubsidy(e.target.value)}
                />
              </Form.Item>
            </div>
            {/* <div>
              <Form.Item
                hasFeedback
                label="Referral Subsidy In Percentage (%)"
                rules={[
                  {
                    required: true,
                    message: "Referral Subsidy In Percentage!",
                  },
                ]}
                name="referralSubsidyOfTotalRevenueInPercentage"
              >
                <Input
                  id="referralSubsidyOfTotalRevenueInPercentage"
                  type="number"
                  placeholder="Enter Referral Subsidy In Percentage"
                  onChange={(e) => setReferralSubsidy(e.target.value)}
                />
              </Form.Item>
            </div> */}

            <div>
              <Form.Item
                hasFeedback
                label="Retained Revenue In Percentage (%) Done"
                rules={[
                  {
                    required: true,
                    message: "Retained Revenue In Percentage!",
                  },
                ]}
                name="retainedOfTotalRevenueInPercentage"
              >
                <Input
                  id="retainedOfTotalRevenueInPercentage"
                  type="number"
                  placeholder="Enter Retained Revenue In Percentage"
                  onChange={(e) => setRetainedRevenue(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="col-span-2 mb-1">
              <Divider className="float-right w-full">
                Referral Subsidy In Percentage
              </Divider>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Delivery Referral Subsidy (%)"
                rules={[
                  {
                    required: true,
                    message: "Delivery Referral Subsidy!",
                  },
                ]}
                initialValue={0}
                name="deliveryReferralSubsidy"
              >
                <Input
                  id="deliveryReferralSubsidy"
                  type="number"
                  placeholder="Enter Delivery Referral Subsidy"
                  onChange={(e) => setDeliveryReferralSubsidy(e.target.value)}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Merchant Referral Subsidy (%)"
                rules={[
                  {
                    required: true,
                    message: "Merchant Referral Subsidy!",
                  },
                ]}
                initialValue={0}
                name="merchantReferralSubsidy"
              >
                <Input
                  id="merchantReferralSubsidy"
                  type="number"
                  placeholder="Merchant Referral Subsidy"
                  onChange={(e) => setMerchantReferralSubsidy(e.target.value)}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="User Referral Subsidy (%)"
                rules={[
                  {
                    required: true,
                    message: "User Referral Subsidy!",
                  },
                ]}
                initialValue={0}
                name="userReferralSubsidy"
              >
                <Input
                  id="userReferralSubsidy"
                  type="number"
                  placeholder="Enter User Referral Subsidy"
                  onChange={(e) => setUserReferralSubsidy(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="col-span-2 mb-1">
              <Divider className="float-right w-full">
                Retained Of Total Revenue
              </Divider>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Flat"
                rules={[
                  {
                    required: true,
                    message: "User flat!",
                  },
                ]}
                initialValue={0}
                name="flat"
              >
                <Input id="flat" type="number" placeholder="Enter flat" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Max Revenue For Surprise Discount"
                rules={[
                  {
                    required: true,
                    message: "Max Revenue For Surprise Discount!",
                  },
                ]}
                initialValue={0}
                name="maxRevenueForSurpriseDiscount"
              >
                <Input
                  id="maxRevenueForSurpriseDiscount"
                  type="number"
                  placeholder="Enter Max Revenue For Surprise Discount"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Percentage (%)"
                rules={[
                  {
                    required: true,
                    message: "Percentage!",
                  },
                ]}
                initialValue={0}
                name="percentage"
              >
                <Input
                  id="percentage"
                  type="number"
                  placeholder="Enter Percentage"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Surprise Discount In Percentage (%)"
                rules={[
                  {
                    required: true,
                    message: "Surprise Discount In Percentage!",
                  },
                ]}
                initialValue={0}
                name="surpriseDiscountInPercentage"
              >
                <Input
                  id="surpriseDiscountInPercentage"
                  type="number"
                  placeholder="Enter Surprise Discount In Percentage"
                />
              </Form.Item>
            </div>
          </div>
          <SubmitResetBtn
              onClickSubmit={(e) => {
                e.preventDefault();
                form.submit();
              }}
              disabled={loading || !!error}
              loading={(loading ? "loading" : undefined) as any}
            />
          {/* {TotalValue == 100 && (
            <SubmitResetBtn
              onClickSubmit={(e) => {
                e.preventDefault();
                form.submit();
              }}
              disabled={loading || !!error}
              loading={(loading ? "loading" : undefined) as any}
            />
          )} */}
        </Form>
      </div>
    </div>
  );
};

export default RevenueDistributionConfig;
