import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Empty, Form, Input, Select } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";
import Papa from "papaparse";
import builkcsv from "../../demo-files/topUp_failed_corrected.xlsx";
import builkAreacsv from "../../demo-files/demo_area.xlsx";
import builkCitycsv from "../../demo-files/demo_city.xlsx";
import builkStatecsv from "../../demo-files/demo_state.xlsx";
import builkSubUrbcsv from "../../demo-files/demo_sub_urb.xlsx";
import * as xlsx from "xlsx";
import { debounce } from "lodash";

const CityBulkUploadList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [areaData, setAreaData] = useState(new Array());
  const [showSearch, setShowSearch] = useState(true);
  const [areaResponseMessage, setAreaResponseMessage] = useState("");
  const [countryId, setCountryId] = useState<any>();
  const [stateId, setStateId] = useState<any>();
  const [cityId, setCityId] = useState<any>();
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState({
    loading: false,
    list: [],
  });

  const [key, setKey] = useState("");
  const postArea = async (e) => {
    setLoading(true);
    const readyData = e && {
      countryId: countryId,
      stateId: stateId,
      locations: areaData?.map((areaData, index) => ({
        name: areaData?.name,
        latitude: areaData?.latitude,
        longitude: areaData?.longitude,
      })),
    };
    const response = await fetch(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/add-city-bulk`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(readyData),
      }
    ).catch((e) => {
      setLoading(false);
    });
    if (response && response.status == 200) {
      setLoading(false);
      responseNotification("City Bulk upload Successfully", "success");
      navigate(`/country/area`);
    } else {
      setLoading(false);
      setAreaResponseMessage(
        "Something went wrong. Please, try again later...."
      );
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "latitude", headerName: "Latitude", width: 250 },
    { field: "longitude", headerName: "Longitude", width: 150 },
  ];

  const makeLowercaseFirstLetterAndRemoveSpaceFromKeys = (obj: any) =>
    Object.keys(obj).reduce((acc: any, key: any) => {
      acc[(key.charAt(0).toLowerCase() + key.slice(1)).replace(/\s/g, "")] =
        obj[key];
      return acc;
    }, {});

  const readFileAndPostArea = (e: any) => {
    e.preventDefault();
    if (e?.target?.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e?.target?.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        let res = new Array(json.length);
        for (let index in json) {
          res[index] = makeLowercaseFirstLetterAndRemoveSpaceFromKeys(
            json[index]
          );
        }

        setAreaData(res);
        const fileName = (
          document.getElementById("areaFileUpload") as HTMLInputElement
        )?.value
          ?.split("\\")
          .pop();

        //postArea(fileName, res)
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const simpleFileDownload = () => {
    window.location.href = `${builkCitycsv}`;
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const getCountryOptions = useCallback(async () => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);

  const getStateOptions = useCallback(
    async (key: any) => {
      setStateOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?page=0&limit=20` +
        (countryId ? `&countryId=${countryId}` : ``) +
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/states${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStateOptions({
            loading: false,
            list: res.data?.states?.map((state: any) => ({
              label: state.name,
              value: state.id,
            })),
          });
        })
        .catch((err) => {
          setStateOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [countryId, key]
  );



  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "country") getCountryOptions();
        if (field === "state") getStateOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCountryOptions, getStateOptions]);
  useEffect(() => {
    getCountryOptions();
    getStateOptions("");
  }, [getCountryOptions, getStateOptions]);

  return (
    <>
      <BreadCrumb title="City Bulk Upload" extra={""} />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              <div className="w-full py-10 h-screen px-2">
                {areaData.length === 0 ? (
                  <>
                    <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden ">
                      <div className="w-full row-span-2 md:col-span-4">
                        <div className="w-full row-span-2 md:col-span-4">
                          <div className="p-4 border-b-2">
                            <span className="text-lg font-bold text-gray-600">
                              Upload your attachments
                            </span>
                          </div>

                          {!loading && areaResponseMessage?.length === 0 ? (
                            <div className="p-3">
                              <div className="mb-2">
                                <>
                                  <span>Attachments </span>
                                  <form>
                                    <Input
                                      type="file"
                                      className="mb-4"
                                      placeholder="Type product name here"
                                      id="areaFileUpload"
                                      onChange={readFileAndPostArea}
                                    />
                                  </form>

                                  <div className="flex justify-between items-center text-gray-400">
                                    <span>
                                      Accepted file type: .xlsx files only
                                    </span>
                                  </div>
                                  <div className="d-flex">
                                    <Button
                                      type="dashed"
                                      shape="round"
                                      danger
                                      onClick={simpleFileDownload}
                                    >
                                      <DownloadOutlined />
                                      Sample File
                                    </Button>
                                  </div>
                                </>
                              </div>
                            </div>
                          ) : (
                            <p
                              style={{
                                marginTop: 20,
                                fontWeight: "bold",
                              }}
                            >
                              {areaResponseMessage}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {areaData.length ? (
                      <div
                        className="overflow-auto"
                        style={{
                          width: "100%",
                          height: "650px",
                          marginTop: 15,
                          backgroundColor: "rgb(173 173 173 / 12%)",
                        }}
                      >
                        <Form
                          name="control-hooks"
                          form={form} // like ref
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          onFinish={postArea}
                          layout="vertical"
                          autoComplete="off"
                        >
                          <div className="grid grid-cols-2 gap-x-6">
                            <div>
                              <Form.Item
                                hasFeedback
                                label={`Country`}
                                rules={[
                                  {
                                    required: true,
                                    message: "Country is required!",
                                  },
                                ]}
                                name="country"
                              >
                                <Select
                                  allowClear
                                  showSearch
                                  placeholder="Select Country"
                                  optionFilterProp="children"
                                  defaultValue={countryId}
                                  onChange={(val) => setCountryId(val)}
                                  onSearch={(val) => {
                                    handleSearch?.(val, "country");
                                  }}
                                  filterOption={() => {
                                    return true;
                                  }}
                                  options={countryOptions?.list}
                                ></Select>
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item name="state_search" label="State">
                                <Select
                                  allowClear
                                  showSearch
                                  placeholder={`Filter by State`}
                                  optionFilterProp="children"
                                  onChange={(val) => setStateId(val)}
                                  defaultValue={stateId}
                                  onSearch={(e) => handleSearch(e, "state")}
                                  filterOption={() => {
                                    return true;
                                  }}
                                  options={stateOptions?.list}
                                ></Select>
                              </Form.Item>
                            </div>
                          
                          </div>

                          <table className={styles.mainTable}>
                            <thead className="bg-white border-b">
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Latitude</th>
                                <th scope="col">Longitude</th>
                              </tr>
                            </thead>

                            <tbody>
                              {areaData?.length ? (
                                areaData?.map((area: any, index: any) => (
                                  <tr
                                    className="border-t hover:bg-gray-100"
                                    key={index}
                                  >
                                    <td>
                                      <span className="font-medium text-gray-500 ml-2">
                                        {area?.name}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="font-medium text-gray-500 ml-2">
                                        {area?.latitude}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="font-medium text-gray-500 ml-2">
                                        {area?.longitude}
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td>
                                    <Empty />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <div className="p-10">
                            <Button
                              disabled={loading}
                              loading={(loading ? "loading" : undefined) as any}
                              type="primary"
                              htmlType="submit"
                              className="add-submit-btn text-center mr-2"
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                        <div>
                          {/* <Button onClick={postArea} disabled={loading}>
                  Area 
                </Button> */}
                        </div>
                      </div>
                    ) : (
                      ""
                      // <h3>Please check before you proceed</h3>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CityBulkUploadList;
