import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Empty, Popconfirm, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleCarrier from "./CarrierDetails";
import AddCarrier from "./AddCarrier";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";

const CarrierList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [isForLanding, setIsForLanding] = useState<boolean>(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedCarrier, setSelectedCarrier] = useState(undefined);
  const [selectedCarrierForEdit, setSelectedCarrierForEdit] =
    useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [carrierData, setCarrierData] = useState<any>({
    loading: false,
    data: null,
  });

  const getCarriers = useCallback(async () => {
    setCarrierData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(`${encodedUri}/delivery-carrier/all?page=0&limit=20`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      })
      .then((res) => {
        setCarrierData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCarrierData({ loading: false, data: [] });
        console.error("Carriers: Error", err);
      });
  }, []);

  //
  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/delivery-carrier`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getCarriers();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onCarrierRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/delivery-carrier`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Carrier Remove Successfully", "success");
            getCarriers();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    getCarriers();
  }, [getCarriers]);

  const onClose = () => {
    getCarriers();
    setVisible(undefined);
    setSelectedCarrier(undefined);
    setSelectedCarrierForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Carrier List"
        subTitle={`${
          carrierData?.data?.totalElements ||
          carrierData?.data?.deliveryCarriers?.length ||
          0
        } Carrier(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {carrierData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Carrier</th>
                      <th scope="col">Country Name</th>
                      <th scope="col">Country Code</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">E-mail</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {carrierData?.data?.deliveryCarriers?.length ? (
                      carrierData?.data?.deliveryCarriers?.map(
                        (carrier: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedCarrier(carrier)}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {carrier?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {carrier?.countryName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {carrier?.countryCode}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {carrier?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {carrier?.email}
                              </span>
                            </td>
                            <td>
                            <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={carrier?.isActive}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onStatusChange(carrier?.id, val);
                                  }}
                                  loading={
                                    confirmLoading &&
                                    carrier?.id === confirmLoading
                                  }
                                />
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    carrier?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(carrier?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(carrier?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {carrier?.updatedBy?.name ||
                                  carrier?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedCarrierForEdit(carrier)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={carrier?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onCarrierRemove(carrier?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(carrier?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...carrierData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Carrier"
            : selectedCarrierForEdit
            ? "Edit Carrier"
            : "Carrier Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedCarrier || selectedCarrierForEdit}
        width={450}
      >
        {visible || selectedCarrierForEdit ? (
          <AddCarrier
            onCloseMethod={onClose}
            visibleData={selectedCarrierForEdit}
          />
        ) : (
          <SingleCarrier CarrierDetails={selectedCarrier} />
        )}
      </Drawer>
    </>
  );
};

export default CarrierList;
