import {
  CheckOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  ShopOutlined,
} from "@ant-design/icons";

import {
  AutoComplete,
  Avatar,
  Button,
  Checkbox,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Tag,
} from "antd";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import axios from "axios";
import { getImgUrl, getPage, getParamValue } from "../../../../utils/index";
import { responseNotification } from "../../../../utils/notify";
import { debounce, round } from "lodash";
import { useSelector } from "react-redux";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../../../styles/tailwind/List.module.css";
import CampaignLayout from "../Layout";
import {
  TreeSelectTwo,
  Loader,
  LoaderFull,
  PaginationThree,
} from "../../../common";

const CampaignProductAssign = ({
  onAssignModal,
  submitForm,
  onCloseMethod,
}: any): ReactElement => {
  const { type, token, campaignInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );

  const [confirmLoading, setConfirmLoading] = useState<any>(undefined);
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cashbackType, setCashbackType] = useState("PERCENTAGE");
  // --------------------------------
  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  // --------------------------------

  // --------------------------------
  const campaignId = campaignInfo?.id;
  const campaignType = campaignInfo?.campaignType;
  const [status, setStatus] = useState("");
  const [key, setKey] = useState("");
  const [barCode, setBarCode] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [categoryId, setCategoryId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [shopId, setShopId] = useState<any>();
  const isDeleted = false;
  const isActive = true;
  const [openGiftModal, setOpenGiftModal] = useState<boolean>(false);
  const [selectedItemsForGift, setSelectedItemsForGift] = useState<any>();
  const [giftProducts, setGiftProducts] = useState<any>();
  const [discountType, setDiscountType] = useState("PERCENTAGE");
  const [giftProductOptions, setGiftProductOptions] = useState<any>({
    list: [],
    loading: false,
  });
  // --------------------------------

  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [productOptions, setProductOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [campaignProductData, setCampaignProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const deleted = false;
  const [productMap, setProductMap] = useState<{ [key: string]: any }>({});
  const [productDataMap, setProductDataMap] = useState<{ [key: string]: any }>(
    {}
  );

  const reseAllFieldData = () => {
    setKey("");
    setBarCode("");
    setBrandId("");
    setShopId("");
    setCategoryId("");
    form.resetFields();
  };

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (deleted ? `&deleted=${deleted}` : ``) +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type, deleted]
  );

  const getProductByBarcodeOptions = useCallback(
    async (bCode: any) => {
      setProductBarcodeOptions({ loading: false, list: [] });

      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/admin/product/search?type=${type}&page=0&limit=20&deleted=${false}` +
          (bCode ? `&barCode=${bCode}` : ``) +
          (campaignId ? `&campaignId=${campaignId}` : ``) +
          (key ? `&key=${key}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProductBarcodeOptions({
        loading: false,
        list: res?.data?.keys?.map((product: { name: any; barCode: any }) => {
          return {
            value: product?.barCode,
            label: product?.name,
          };
        }),
      });
    },
    [status, type, key, isActive, campaignId]
  );

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [isActive, type, key, page, limit]
  );

  const getGiftProductOptions = useCallback(
    async (key: any) => {
      setGiftProductOptions({ loading: true, list: [] });
      setGiftProducts(undefined);
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=5` +
        (key ? `&key=${key}` : ``) +
        (selectedItemsForGift?.shopId
          ? `&shopId=${selectedItemsForGift?.shopId}`
          : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/v2${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setGiftProductOptions({
            loading: false,
            list: res.data?.products?.map((product) => ({
              label: product.name,
              value: product.id,
            })),
          });
          setGiftProducts(res.data?.products);
        })
        .catch((err) => {
          setGiftProducts(undefined);
          setGiftProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [isActive, type, key, page, limit, selectedItemsForGift?.shopId]
  );

  const getAllProduct = useCallback(() => {
    if (campaignId) {
      try {
        setCampaignProductData({ loading: true, data: null });
        axios
          .get(
            `${
              process.env.REACT_APP_CATALOG_READER_API
            }/admin/product/v2?page=${
              page || 0
            }&isActive=${true}&isDeleted=${false}` +
              `&limit=${limit || 16}` +
              (type ? `&type=${type}` : ``) +
              (shopId ? `&shopId=${shopId}` : ``) +
              (brandId ? `&brandId=${brandId}` : ``) +
              (categoryId ? `&categoryId=${categoryId}` : ``) +
              (key ? `&key=${key}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setCampaignProductData({
                loading: false,
                data: data?.data,
              });

              const tempProductMap = {};
              data?.data?.products?.forEach((p) => {
                if (p?.barCode)
                  tempProductMap[p?.barCode] = {
                    barCode: p?.barCode,
                    shopId: p?.shopId,
                    commission: 0,
                    subsidy: 0,
                    voucherAmount: 0,
                    groupBuyDiscount: {
                      discountAmount: 0,
                      discountType: "",
                      maxParticipants: 0,
                    },
                    minimumDiscountAmount: campaignInfo?.minimumDiscount || 0,
                    campaignDpPrice:
                      campaignInfo?.minimumDiscountType === "PERCENTAGE"
                        ? (
                            (p?.variations[0]?.price?.sellerDiscountedPrice *
                              (100 - campaignInfo?.minimumDiscount)) /
                            100
                          )?.toFixed(2)
                        : p?.variations[0]?.price?.sellerDiscountedPrice -
                          campaignInfo?.minimumDiscount,
                    //parcentage: campaignInfo?.minimumDiscount,
                    stock: p?.variations[0].stock || 0,
                    additionalProducts: [
                      {
                        barcode: undefined,
                        variationId: undefined,
                      },
                    ],
                  };
              });

              setProductMap(tempProductMap);

              const tempProductDataMap = {};
              data?.data?.products?.forEach((p) => {
                if (p?.barCode) tempProductDataMap[p?.barCode] = p;
              });
              setProductDataMap(tempProductDataMap);

              setCheckedList(
                data.data.products.map(
                  (product: { barCode: any }) => product?.barCode
                )
              );
            } else {
              setCampaignProductData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            // setCampaignProductData({ loading: false, data: null });
          });
      } catch (error) {
        setCampaignProductData({ loading: false, data: null });
      }
    }
  }, [
    limit,
    page,
    key,
    type,
    isDeleted,
    isActive,
    shopId,
    brandId,
    categoryId,
  ]);

  // **********************************
  // Search Options
  // **********************************

  const onChange = (list: any) => {
    setSelectedProductBarcode(list);
    setIndeterminate(!!checkedList.length || checkedList.length <= list.length);
    setCheckAll(checkedList.length === list.length);
  };
  const onCheckAllChange = (e: any) => {
    setSelectedProductBarcode(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onSubmit = async () => {
    setConfirmLoading(true);
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/add-products`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignId: campaignId,
          barCodeShopIds: selectedItemsForGift
            ? [selectedItemsForGift]
            : selectedProductBarcode?.map((barCode) => productMap[barCode]),
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Campaign Product Assign Successfully",
            "success"
          );
          form.resetFields();
          getAllProduct();
          if (onCloseMethod) {
            onCloseMethod();
          }
          setOpenGiftModal(false);
          setSelectedItemsForGift(undefined);
          setConfirmLoading(false);
          navigate(`/promotion/campaign/${campaignId}/products`);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const fetchRef = useRef<any>(0);

  const handleSearch = React.useMemo<any>(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "productByBarcode")
          getProductByBarcodeOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "shop") getShopOptions(value);
        else if (field === "giftProduct") getGiftProductOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getBrandsOptions,
    getProductOptions,
    getProductByBarcodeOptions,
    getShopOptions,
    getGiftProductOptions,
  ]);

  useEffect(() => {
    if (submitForm) {
      // form.submit();
      onSubmit();
    }
  }, [submitForm]);

  useEffect(() => {
    getProductOptions("");
    getAllProduct();
    getShopOptions("");
    getBrandsOptions("");
    getGiftProductOptions(``);
  }, [
    getAllProduct,
    getBrandsOptions,
    getShopOptions,
    getProductOptions,
    getGiftProductOptions,
    campaignId,
  ]);

  useEffect(() => {
    if (selectedItemsForGift) {
      setOpenGiftModal(true);
    }
  }, [selectedItemsForGift, openGiftModal]);

  const onClose = () => {
    setOpenGiftModal(false);
    setSelectedItemsForGift(undefined);
  };

  confirmLoading && <LoaderFull />;

  return (
    <React.Fragment>
      <CampaignLayout
        campaignId={campaignInfo.id}
        title={campaignInfo.name}
        subTitle="Assign Product"
      >
        <>
          <section className="content-body rounded-2xl">
            <div className="bg-white shadow-sm shadow-gray-200 rounded-2xl">
              <div className="sticky shadow-sm border border-slate-100 z-30 bg-white rounded-lg px-2 py-1 mb-4">
                {!onAssignModal && (
                  <div className="flex justify-between items-center ">
                    <h3 className="text-xl text-gray-700 font-bold">
                      Assign Product
                      <span className="text-xs font-light ml-2">
                        {campaignProductData?.data?.totalElements ||
                          campaignProductData?.data?.length ||
                          0}
                        Product(s)
                      </span>
                    </h3>
                    <div className="d-flex items-center">
                      <Button
                        type="dashed"
                        shape="circle"
                        onClick={() => setShowSearch(!showSearch)}
                        key={1}
                      >
                        <SearchOutlined />
                      </Button>

                      {campaignInfo?.campaignType !== "GIFT_ITEMS" && (
                        <Button
                          type="primary"
                          danger={selectedProductBarcode?.length}
                          shape="round"
                          title="Buld Upload/Remove"
                          className="ml-2 bg-slate-300"
                          onClick={() => {
                            onSubmit();
                          }}
                        >
                          {selectedProductBarcode?.length
                            ? `Assign ${
                                selectedProductBarcode?.length || 0
                              } items`
                            : "Upload"}
                        </Button>
                      )}
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-between w-full">
                  {showSearch && (
                    <div className="flex py-1 mt-2 w-full">
                      <Form
                        layout="inline"
                        form={form}
                        className={styles.formInline}
                      >
                        <Form.Item name="search">
                          <AutoComplete
                            style={{ width: 300 }}
                            onSearch={(e) => handleSearch(e, "product")}
                            onSelect={(val: {
                              toString: () => SetStateAction<string>;
                            }) => setKey(val?.toString())}
                            options={productOptions?.list}
                            defaultActiveFirstOption={false}
                            notFoundContent={
                              productOptions?.loading ? (
                                <Spin size="small" />
                              ) : null
                            }
                          >
                            <Input.Search
                              placeholder="Search by Name"
                              onSearch={(val) => setKey(val)}
                              enterButton
                              loading={productOptions.loading}
                            />
                          </AutoComplete>
                        </Form.Item>

                        <Form.Item name="shop_search">
                          <Select
                            allowClear
                            showSearch
                            // defaultValue={getShopName}
                            placeholder="Filter by Shop"
                            optionFilterProp="children"
                            onChange={(val) => setShopId(val)}
                            onSearch={(e) => handleSearch(e, "shop")}
                            filterOption={() => {
                              return true;
                            }}
                            options={shopOptions.list}
                          ></Select>
                        </Form.Item>
                        <Form.Item name="brand_search">
                          <Select
                            allowClear
                            showSearch
                            placeholder="Filter by Brand"
                            optionFilterProp="children"
                            onChange={(val) => setBrandId(val)}
                            onSearch={(e) => handleSearch(e, "brand")}
                            filterOption={() => {
                              return true;
                            }}
                            options={brandOptions?.list}
                          ></Select>
                        </Form.Item>
                        <Form.Item name="category_search">
                          <TreeSelectTwo
                            setCategoryId={setCategoryId}
                            value={campaignId}
                          />
                        </Form.Item>
                      </Form>
                      <Button
                        type="primary"
                        size="large"
                        danger
                        onClick={reseAllFieldData}
                      >
                        Reset
                      </Button>
                    </div>
                  )}

                  {/* <Button
                  type="primary"
                  size="large"
                  ghost
                  onClick={() => onSubmit()}
                >
                  Submit
                </Button> */}
                </div>
              </div>

              <div className={styles.contentWrapper}>
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div
                      className={
                        showSearch ? `content-body-withSearch` : `content-body`
                      }
                    >
                      {campaignInfo?.campaignType !== "GIFT_ITEMS" && (
                        <Checkbox
                          indeterminate={indeterminate}
                          onChange={onCheckAllChange}
                          checked={
                            checkAll ||
                            selectedProductBarcode?.length ===
                              checkedList?.length
                          }
                          className="mr-3"
                        >
                          Check all
                        </Checkbox>
                      )}
                      <Checkbox.Group
                        value={selectedProductBarcode}
                        onChange={onChange}
                      >
                        <table className={styles.mainTable}>
                          <thead className="bg-white border-b">
                            <tr>
                              <th scope="col">Product</th>
                              <th scope="col">Price & Stock</th>
                              <th scope="col">Stock</th>
                              <th scope="col">Cashback</th>
                              {campaignType == "FREE_DELIVERY" && (
                                <>
                                  {campaignType == "DISCOUNT" ? (
                                    <>
                                      <th scope="col">Commission</th>
                                      <th scope="col">Subsidy</th>
                                      <th scope="col">
                                        Minimum Discount Amount
                                      </th>
                                      <th scope="col">Campaign DP Price</th>
                                    </>
                                  ) : campaignType == "PURCHASE_VOUCHER" ? (
                                    <th scope="col">Voucher Amount</th>
                                  ) : campaignType == "GROUP_BUY" ? (
                                    <>
                                      <th scope="col">Discount Type</th>
                                      <th scope="col">Discount Amount</th>
                                      <th scope="col">Max Participants</th>
                                    </>
                                  ) : (
                                    campaignType == "GIFT_ITEMS" && (
                                      <th scope="col">GIFT ITEMS</th>
                                    )
                                  )}
                                </>
                              )}

                              <th scope="col">Shop Info</th>
                            </tr>
                          </thead>

                          <tbody>
                            {campaignProductData?.loading ? (
                              <Loader />
                            ) : Object.entries(productDataMap)?.length ? (
                              Object.entries(productDataMap)?.map(
                                (
                                  [barCode, product]: [
                                    barCode: string,
                                    product: any
                                  ],
                                  index: any
                                ) => (
                                  <tr
                                    className="border-t hover:bg-gray-100"
                                    key={index}
                                    // onClick={(e) => {
                                    //   e.preventDefault();
                                    //   e.stopPropagation();
                                    //   onChange(product?.barCode);
                                    // }}
                                  >
                                    <td>
                                      {campaignInfo?.campaignType !==
                                        "GIFT_ITEMS" && (
                                        <Checkbox
                                          onClick={(e) => {
                                            // e.preventDefault();
                                            // e.stopPropagation();
                                            onChange(product?.barCode);
                                          }}
                                          className="mr-3"
                                          value={
                                            // product?.campaignId ||
                                            product?.campaignId !==
                                              campaignId && barCode
                                          }
                                          disabled={
                                            product?.campaignId ||
                                            product?.campaignId === campaignId
                                          }
                                        />
                                      )}
                                      <Avatar
                                        size={45}
                                        src={getImgUrl(product?.productImage)}
                                        shape="square"
                                      />
                                      <span className="font-medium text-gray-500 ml-2">
                                        {product?.name?.slice(0, 50)}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="flex_">
                                        {product?.variations?.length
                                          ? product?.variations?.map(
                                              (variant: any, index: string) => (
                                                <React.Fragment key={index}>
                                                  <div className="flex justify-content-start mb-1">
                                                    <Tag
                                                      color={
                                                        variant?.stock < 10
                                                          ? `red`
                                                          : `green`
                                                      }
                                                    >
                                                      <b>
                                                        {variant?.variationName}
                                                      </b>
                                                      : {variant?.stock || 0}
                                                    </Tag>

                                                    <Tag>
                                                      MRP{" "}
                                                      {
                                                        variant?.price
                                                          ?.currencyCode
                                                      }
                                                      {variant?.price
                                                        ?.discountedPrice ||
                                                        variant?.price?.mrp}
                                                      {variant?.price
                                                        ?.discountedPrice &&
                                                        variant?.price
                                                          ?.discountedPrice !==
                                                          variant?.price
                                                            ?.mrp && (
                                                          <span className="text-red-600 ml-1">
                                                            <del>
                                                              {variant?.price
                                                                ?.currencyCode ||
                                                                "Tk"}
                                                              {
                                                                variant?.price
                                                                  ?.mrp
                                                              }
                                                            </del>
                                                          </span>
                                                        )}
                                                    </Tag>
                                                    <Tag>
                                                      TP {variant?.price?.tp}{" "}
                                                      {
                                                        variant?.price
                                                          ?.currencyCode
                                                      }
                                                    </Tag>
                                                    <Tag>
                                                      DP{" "}
                                                      {
                                                        variant?.price
                                                          ?.sellerDiscountedPrice
                                                      }
                                                      {
                                                        variant?.price
                                                          ?.currencyCode
                                                      }
                                                    </Tag>
                                                  </div>
                                                </React.Fragment>
                                              )
                                            )
                                          : undefined}
                                      </div>
                                    </td>
                                    {campaignType !== "FREE_DELIVERY" && (
                                      <>
                                        <td>
                                          <Form.Item
                                            name="stock"
                                            className="mb-0"
                                            initialValue={
                                              product?.variations[0].stock
                                            }
                                          >
                                            <Input
                                              type="number"
                                              min={0}
                                              placeholder="Stock"
                                              onChange={(e) =>
                                                setProductMap((prevVal) => {
                                                  let temp = {
                                                    ...(prevVal || {}),
                                                  };
                                                  temp[barCode] = {
                                                    ...(temp[barCode] || {}),
                                                    stock: parseInt(
                                                      e.target.value
                                                    ),
                                                  };
                                                  return temp;
                                                })
                                              }
                                              defaultValue={
                                                product?.variations[0].stock
                                              }
                                              // value={product?.variations[0].stock}
                                              // max={product?.variations[0].stock}
                                              style={{ minWidth: 80 }}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item
                                            rules={[
                                              {
                                                required: false,
                                                message:
                                                  "Cashback Type is Required!",
                                              },
                                            ]}
                                            name="cashbackType"
                                            initialValue={cashbackType}
                                          >
                                            <Select
                                              options={[
                                                { value: null, label: "NONE" },
                                                {
                                                  value: "PERCENTAGE",
                                                  label: "PERCENTAGE",
                                                },
                                                {
                                                  value: "FLAT",
                                                  label: "FLAT",
                                                },
                                              ]}
                                              placeholder="Enter Cashback Type"
                                              onChange={(val) =>
                                                setCashbackType(val)
                                              }
                                            />
                                          </Form.Item>
                                          <Input
                                                name="cachbackAmount"
                                                type="number"
                                                min={0}
                                                placeholder="Enter Amount"
                                                defaultValue={
                                                  product?.shop
                                                    ?.commissionAmount || 0
                                                }
                                                addonAfter={
                                                  campaignInfo?.shop
                                                    ?.commissionType ===
                                                  "PERCENTAGE"
                                                    ? "%"
                                                    : "tk"
                                                }
                                                style={{ minWidth: 100 }}
                                              />
                                        </td>
                                        {campaignType == "DISCOUNT" ? (
                                          <>
                                            <td>
                                              <Input
                                                name="commission"
                                                type="number"
                                                min={0}
                                                placeholder="Enter Commission Amount"
                                                onChange={(e) => {
                                                  setProductMap((prevVal) => {
                                                    let temp = {
                                                      ...(prevVal || {}),
                                                    };
                                                    temp[barCode] = {
                                                      ...(temp[barCode] || {}),
                                                      commission: parseFloat(
                                                        e.target.value
                                                      ),
                                                    };
                                                    return temp;
                                                  });
                                                }}
                                                defaultValue={
                                                  product?.shop
                                                    ?.commissionAmount || 0
                                                }
                                                addonAfter={
                                                  campaignInfo?.shop
                                                    ?.commissionType ===
                                                  "PERCENTAGE"
                                                    ? "%"
                                                    : "tk"
                                                }
                                                style={{ minWidth: 100 }}
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                name="subsidy"
                                                type="number"
                                                min={0}
                                                placeholder="Enter Product Subsidy"
                                                onChange={(e) => {
                                                  setProductMap((prevVal) => {
                                                    let temp = {
                                                      ...(prevVal || {}),
                                                    };
                                                    temp[barCode] = {
                                                      ...(temp[barCode] || {}),
                                                      subsidy: parseFloat(
                                                        e.target.value
                                                      ),
                                                    };
                                                    return temp;
                                                  });
                                                }}
                                                defaultValue={
                                                  product?.shop?.subsidy || 0
                                                }
                                                style={{ minWidth: 80 }}
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                type="number"
                                                disabled={true}
                                                min={
                                                  campaignInfo?.minimumDiscount ||
                                                  0
                                                }
                                                value={round(
                                                  productMap[barCode]
                                                    ?.minimumDiscountAmount
                                                )}
                                                placeholder="Enter Minimum Discount Amount"
                                                onChange={(e) => {
                                                  setProductMap((prevVal) => {
                                                    let temp = {
                                                      ...(prevVal || {}),
                                                    };
                                                    temp[barCode] = {
                                                      ...(temp[barCode] || {}),
                                                      minimumDiscountAmount:
                                                        parseFloat(
                                                          e.target.value
                                                        ),
                                                    };
                                                    return temp;
                                                  });
                                                }}
                                                style={{ minWidth: 100 }}
                                                addonAfter={
                                                  campaignInfo?.minimumDiscountType ===
                                                  "PERCENTAGE"
                                                    ? "%"
                                                    : "tk"
                                                }
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                type="text"
                                                // min={
                                                //   campaignInfo?.minimumDiscount ||
                                                //   0
                                                // }
                                                value={
                                                  productMap[barCode]
                                                    ?.campaignDpPrice || 0
                                                }
                                                placeholder="Discounted Price"
                                                onChange={(e) => {
                                                  setProductMap((prevVal) => {
                                                    let temp = {
                                                      ...(prevVal || {}),
                                                    };
                                                    temp[barCode] = {
                                                      ...(temp[barCode] || {}),
                                                      campaignDpPrice:
                                                        parseFloat(
                                                          e.target.value
                                                        ),
                                                      minimumDiscountAmount:
                                                        campaignInfo?.minimumDiscountType ===
                                                        "PERCENTAGE"
                                                          ? 100 -
                                                            ((parseFloat(
                                                              e.target.value
                                                            ) || 0) /
                                                              product
                                                                ?.variations[0]
                                                                ?.price
                                                                ?.sellerDiscountedPrice) *
                                                              100
                                                          : product
                                                              ?.variations[0]
                                                              ?.price
                                                              ?.sellerDiscountedPrice -
                                                            parseFloat(
                                                              e.target.value
                                                            ),
                                                    };
                                                    return temp;
                                                  });
                                                }}
                                                style={{ minWidth: 100 }}
                                              />
                                            </td>
                                          </>
                                        ) : campaignType ==
                                          "PURCHASE_VOUCHER" ? (
                                          <td>
                                            <Input
                                              name="voucherAmount"
                                              type="number"
                                              min={0}
                                              placeholder="Voucher Amount"
                                              onChange={(e) => {
                                                setProductMap((prevVal) => {
                                                  let temp = {
                                                    ...(prevVal || {}),
                                                  };
                                                  temp[barCode] = {
                                                    ...(temp[barCode] || {}),
                                                    voucherAmount: parseFloat(
                                                      e.target.value
                                                    ),
                                                  };
                                                  return temp;
                                                });
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                              }}
                                              style={{ minWidth: 80 }}
                                            />
                                          </td>
                                        ) : campaignType == "GROUP_BUY" ? (
                                          <>
                                            <td>
                                              <Select
                                                onChange={(val) => {
                                                  setProductMap((prevVal) => {
                                                    let temp = {
                                                      ...(prevVal || {}),
                                                    };
                                                    temp[barCode] = {
                                                      ...(temp[barCode] || {}),
                                                      groupBuyDiscount: {
                                                        ...(temp[barCode] || {})
                                                          ?.groupBuyDiscount,
                                                        discountType: val,
                                                      },
                                                    };
                                                    return temp;
                                                  });
                                                }}
                                                options={[
                                                  {
                                                    value: "PERCENTAGE",
                                                    label: "PERCENTAGE",
                                                  },
                                                  {
                                                    value: "FLAT",
                                                    label: "FLAT",
                                                  },
                                                ]}
                                                placeholder="Enter Discount Type"
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                type="number"
                                                min={0}
                                                placeholder="Discount Amount"
                                                onChange={(e: any) => {
                                                  setProductMap((prevVal) => {
                                                    let temp = {
                                                      ...(prevVal || {}),
                                                    };
                                                    temp[barCode] = {
                                                      ...(temp[barCode] || {}),
                                                      groupBuyDiscount: {
                                                        ...(temp[barCode] || {})
                                                          ?.groupBuyDiscount,
                                                        discountAmount:
                                                          e.target.value * 1,
                                                      },
                                                    };
                                                    return temp;
                                                  });
                                                }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                }}
                                                style={{ minWidth: 80 }}
                                              />
                                            </td>

                                            <td>
                                              <Input
                                                type="number"
                                                min={0}
                                                placeholder="Max Participants"
                                                onChange={(e: any) => {
                                                  setProductMap((prevVal) => {
                                                    let temp = {
                                                      ...(prevVal || {}),
                                                    };
                                                    temp[barCode] = {
                                                      ...(temp[barCode] || {}),
                                                      groupBuyDiscount: {
                                                        ...(temp[barCode] || {})
                                                          ?.groupBuyDiscount,
                                                        maxParticipants:
                                                          e.target.value * 1,
                                                      },
                                                    };
                                                    return temp;
                                                  });
                                                }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                }}
                                                style={{ minWidth: 80 }}
                                              />
                                            </td>
                                          </>
                                        ) : (
                                          campaignType == "GIFT_ITEMS" && (
                                            <td>
                                              <Button
                                                type="dashed"
                                                onClick={() => {
                                                  setSelectedItemsForGift({
                                                    barCode: product?.barCode,
                                                    shopId: product?.shop?.id,
                                                    additionalProducts: [],
                                                  });
                                                }}
                                                disabled={
                                                  // product?.campaignId ||
                                                  product?.campaignId ===
                                                  campaignId
                                                }
                                              >
                                                <div className="flex items-center gap-2">
                                                  <PlusCircleOutlined />
                                                  <span>Add</span>
                                                </div>
                                              </Button>
                                            </td>
                                          )
                                        )}

                                        <td>
                                          <ShopOutlined />
                                          <span className="name ml-1 text-uppercase">
                                            {product?.shop?.name}
                                          </span>
                                        </td>
                                        {/*    <td>
                                      <Input
                                        name="stock"
                                        type="number"
                                        min={0}
                                        placeholder="Enter Product Stock"
                                        onChange={(e) => {
                                          setProductMap((prevVal) => {
                                            let temp = { ...(prevVal || {}) };
                                            temp[barCode] = {
                                              ...(temp[barCode] || {}),
                                              stock: parseInt(e.target.value),
                                            };
                                            return temp;
                                          });
                                        }}
                                        style={{ minWidth: 80 }}
                                      />
                                    </td>
                                    {campaignType == "DISCOUNT" && (
                                      <>
                                        <td>
                                          <Input
                                            name="commission"
                                            type="number"
                                            min={0}
                                            placeholder="Enter Commission Amount"
                                            onChange={(e) => {
                                              setProductMap((prevVal) => {
                                                let temp = {
                                                  ...(prevVal || {}),
                                                };
                                                temp[barCode] = {
                                                  ...(temp[barCode] || {}),
                                                  commission: parseFloat(
                                                    e.target.value
                                                  ),
                                                };
                                                return temp;
                                              });
                                            }}
                                            style={{ minWidth: 80 }}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            name="subsidy"
                                            type="number"
                                            min={0}
                                            placeholder="Enter Product Subsidy"
                                            onChange={(e) => {
                                              setProductMap((prevVal) => {
                                                let temp = {
                                                  ...(prevVal || {}),
                                                };
                                                temp[barCode] = {
                                                  ...(temp[barCode] || {}),
                                                  subsidy: parseFloat(
                                                    e.target.value
                                                  ),
                                                };
                                                return temp;
                                              });
                                            }}
                                            style={{ minWidth: 80 }}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            name="minimumDiscountAmount"
                                            type="number"
                                            min={0}
                                            placeholder="Enter Minimum Discount Amount"
                                            onChange={(e) => {
                                              setProductMap((prevVal) => {
                                                let temp = {
                                                  ...(prevVal || {}),
                                                };
                                                temp[barCode] = {
                                                  ...(temp[barCode] || {}),
                                                  minimumDiscountAmount:
                                                    parseFloat(e.target.value),
                                                };
                                                return temp;
                                              });
                                            }}
                                            style={{ minWidth: 80 }}
                                          />
                                        </td>
                                      </>
                                    )} */}

                                        {campaignType == "PURCHASE_VOUCHER" && (
                                          <td>
                                            <Input
                                              name="voucherAmount"
                                              type="number"
                                              min={0}
                                              placeholder="Enter Voucher Amount"
                                              onChange={(e) => {
                                                setProductMap((prevVal) => {
                                                  let temp = {
                                                    ...(prevVal || {}),
                                                  };
                                                  temp[barCode] = {
                                                    ...(temp[barCode] || {}),
                                                    voucherAmount: parseFloat(
                                                      e.target.value
                                                    ),
                                                  };
                                                  return temp;
                                                });
                                              }}
                                              style={{ minWidth: 80 }}
                                            />
                                          </td>
                                        )}
                                      </>
                                    )}
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td>
                                  <Empty />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Checkbox.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <PaginationThree
            {...campaignProductData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </>
      </CampaignLayout>

      <Modal
        title="Assign Gift Item"
        centered
        open={openGiftModal}
        okText="Assign"
        onCancel={onClose}
        width={"96%"}
        onOk={onSubmit}
      >
        <div style={{ minHeight: 500 }} className="my-10">
          <Form.Item name="search">
            <AutoComplete
              style={{ width: 300 }}
              onSearch={(val) => getGiftProductOptions(val)}
              onSelect={(val: { toString: () => SetStateAction<string> }) =>
                setKey(val?.toString())
              }
              options={giftProductOptions?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                giftProductOptions?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                placeholder="Search by Name"
                onSearch={(val) => setKey(val)}
                enterButton
                loading={giftProductOptions.loading}
              />
            </AutoComplete>
          </Form.Item>

          <div className="grid grid-cols-5 gap-4 py-2">
            {giftProducts?.length ? (
              Object?.entries(giftProducts)?.map(
                (
                  [barCode, giftProduct]: [barCode: string, giftProduct: any],
                  i: any
                ) => (
                  <div className="shadow-md rounded-md" key={i}>
                    <div className="">
                      <img
                        src={giftProduct?.productImage}
                        alt=""
                        className="rounded-t-md w-full"
                      />
                    </div>
                    <div className="p-3">
                      <span className="font-semibold text-gray-600 line-clamp-2">
                        {giftProduct?.name || 324}
                      </span>

                      <div className="grid grid-cols-2 gap-3 mt-2">
                        {giftProduct?.variations?.map(
                          (variant: any, i: string) => (
                            <div
                              className="shadow rounded-md bg-gray-50 hover:bg-green-50 border border-gray-200 hover:border-green-200 p-2 flex flex-col text-slate-600 hover:text-green-700 relative cursor-pointer"
                              key={i}
                              onClick={() => {
                                setSelectedItemsForGift((prevVal) => {
                                  let temp = { ...(prevVal || {}) };
                                  let list = temp?.additionalProducts.find(
                                    (item) =>
                                      item.barcode === giftProduct?.barCode &&
                                      item.variationId === variant?.variationId
                                  )
                                    ? temp?.additionalProducts.filter(
                                        (item) =>
                                          item.barcode !==
                                            giftProduct?.barCode &&
                                          item.variationId !==
                                            variant?.variationId
                                      )
                                    : [
                                        ...temp?.additionalProducts,
                                        {
                                          barcode: giftProduct?.barCode,
                                          variationId: variant?.variationId,
                                        },
                                      ];

                                  temp = {
                                    ...(temp || {}),
                                    additionalProducts: list,
                                  };
                                  return temp;
                                });
                              }}
                            >
                              <div className="absolute right-1 top-1">
                                <CheckOutlined />
                              </div>
                              <span className="font-bold">
                                {variant?.variationName}
                              </span>
                              <span>Stock: {variant?.stock}</span>
                              <span>MRP: {variant?.price?.mrp}</span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <Empty />
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CampaignProductAssign;
