import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopShippingProfileCreate from "../../../components/Shop/ShippingProfile/Create";

const ShopShippingProfileCreatePage = () => {
  return (
    <MainLayout>
      <ShopShippingProfileCreate />
    </MainLayout>
  );
};

export default ShopShippingProfileCreatePage;
