import { Button, DatePicker, Empty, Form, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, OrderTypeArray } from "../../utils";
import Loader from "../common/Loader";
import { debounce } from "lodash";
import { DailySalesExportToExcel } from "./DailySalesExportToExcel";
import Pagination from "../common/Pagination/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { DefaultOptionType } from "antd/lib/select";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TopHeading from "./TopHeading";
import React from "react";

const { RangePicker } = DatePicker;

const OrderSalesReportList = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState("");
  const [shopId, setShopId] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState<any>();
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [secondDrawer, setSecondDrawer] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [orderCSV, setOrderCSV] = useState([]);
  const [dailySalesReport, setDailySalesReport] = useState([]);
  const [data, setData] = useState([]);

  const [ordersData, setOrdersData] = useState<any>({
    loading: false,
    data: null,
  });
  const [paymentMethodList, setPaymentMethodList] = useState<any>({
    loading: false,
    list: [],
  });
  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>(
    []
  );
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [orderOptions, setOrderOptions] = useState({
    loading: false,
    list: [],
  });
  const [downloadData, setDownloadData] = useState({
    loading: false,
    data: null,
  });
  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  //excel sheet export start
  const [getOrderData, setGetOrderData] = useState([]);
  const fileName = "daily_sales_report"; // here enter filename for your excel file

  const csvDataGet = ordersData?.data?.orders;
  //excel sheet export end

  const onClose = () => {
    setSelectedOrder(undefined);
    setSecondDrawer(false);
    setVisibleDetails(false);
  };

  const onSecondDrawerClose = () => {
    setSecondDrawer(false);
  };

  const fatchPaymentMethod = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_PAY_API}/payment-method/all?` +
        (type ? `&type=${type}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      }
    );
    const response = await data.json();

    setPaymentMethodList({
      loading: false,
      list: response?.methods?.map((method: { name: any }) => ({
        value: method.name,
        label: method.name,
      })),
    });
  };

  useEffect(() => {
    fatchPaymentMethod();
  }, []);

  const getRevenueReport = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setOrdersData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/finance/revenue?limit=16` +
          (shopId ? `&shopId=${shopId}` : ``) +
          (paymentMethod ? `&paymentMethod=${paymentMethod}` : ``) +
          (status ? `&status=${status}` : ``) +
          (from ? `&fromDate=${from}` : ``) +
          (to ? `&toDate=${to}` : ``) +
          (page ? `&page=${page}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrdersData({ loading: false, data: res.data });
        // setDailySalesReport(res.data?.orders);
      })
      .catch((err) => {
        setOrdersData({ loading: false, data: [] });
        console.error("Data: Error", err);
      });
  }, [range.from, range.to, shopId, paymentMethod, status, page, limit]);

  const getRevenueReportDownload = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setDownloadData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    fetch(
      `${encodedUri}/finance/revenue/download` +
        (from ? `?fromDate=${from}` : ``) +
        (to ? `&toDate=${to}` : ``) +
        (shopId ? `&shopId=${shopId}` : ``) +
        (paymentMethod ? `&paymentMethod=${paymentMethod}` : ``) +
        (status ? `&status=${status}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((res) => {
        setDownloadData({ loading: false, data: res });
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Revenue_Report-${moment(range.from).format("YYYY_MM_DD")}-${moment(
            range.to
          ).format("YYYY_MM_DD")}.xlsx`
        );
        link.click();
        // saveAs(res, `revenue-report-${Math.random().toString(36)}.xlsx`);
      })
      .catch((err) => {
        console.error("Data: Error", err);
      });
  }, [range.from, range.to, shopId, paymentMethod, status]);

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?page=0&limit=20` +
            (key ? `&key=${key}` : ``) +
            (type ? `&type=${type}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: { id: any; name: any }) => ({
              value: shop.id,
              label: shop.name,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Shops: Error", err);
        });
    },
    [type]
  );

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "shop") getShopOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getShopOptions]);

  useEffect(() => {
    getRevenueReport();
  }, [getRevenueReport]);

  useEffect(() => {
    if (showSearch) {
      getShopOptions("");
      getShopOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    if (secondDrawer) {
      form.resetFields();
    }
  }, [secondDrawer, form]);

  const reseAllFieldData = () => {
    form.resetFields();
    setShopId("");
    setStatus("");
    setPaymentMethod("");
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <>
      <BreadCrumb
        title="Revenue Report"
        subTitle={`${ordersData?.data?.totalElements} Data(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <TopHeading
            {...ordersData?.data?.totalElements}
            refetch={getRevenueReport}
            title={"Revenue Report"}
            download={() => getRevenueReportDownload()}
            dissable={!ordersData?.data?.orders?.length ? true : false}
            loading={downloadData?.loading}
          />,
          // <DailySalesExportToExcel apiData={data} fileName={fileName} />,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>

            <Form.Item name="shop_search">
              <Select
                showSearch
                placeholder="Filter by Shop"
                optionFilterProp="children"
                onChange={(val) => setShopId(val)}
                onSearch={(e) => handleSearch(e, "shop")}
                filterOption={(input, option) => {
                  return true;
                }}
                options={shopOptions?.list}
              ></Select>
            </Form.Item>

            <Form.Item name="status_search">
              <Select
                showSearch
                placeholder="Filter by Status"
                onChange={(val) => setStatus(val)}
                filterOption={() => {
                  return true;
                }}
              >
                <Select.Option value="">ALL STATUS</Select.Option>
                {Object.values(OrderTypeArray)?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="payment_method_search">
              <Select
                showSearch
                placeholder="Filter by Payment Method"
                onChange={(val) => setPaymentMethod(val)}
                filterOption={() => {
                  return true;
                }}
                options={paymentMethodList?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {ordersData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Shop Name</th>
                      <th scope="col">Delivery Man</th>
                      <th scope="col">Order Id</th>
                      <th scope="col">Status</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">MRP</th>
                      <th scope="col">TP</th>
                      <th scope="col">Revenue</th>
                    </tr>
                  </thead>

                  <tbody>
                    {ordersData?.data?.orders?.length ? (
                      ordersData?.data?.orders?.map(
                        (orderData: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="name">
                                {moment(orderData?.date).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {orderData?.shopName?.slice(0, 50)}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {orderData?.deliveryMan?.name}
                              </span>
                            </td>
                            <td>
                              <span className="name">{orderData?.orderId}</span>
                            </td>
                            <td>
                              <span
                                className={`radius-btn ${orderData?.status?.toLowerCase()}-btn text-nowrap`}
                              >
                                {orderData?.status?.split("_").join(" ")}
                              </span>
                            </td>

                            <td>
                              <span className="name">
                                {orderData?.paymentMethod}
                              </span>
                            </td>

                            <td>
                              <span className="name">
                                {parseFloat(orderData?.mrp)}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {parseFloat(orderData?.tp)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`name ${
                                  orderData?.revenue <= 0
                                    ? `text-danger font-weight-bold`
                                    : ``
                                }`}
                              >
                                {orderData?.revenue}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...ordersData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default OrderSalesReportList;
