import axios from "axios";
import moment from "moment";
import React from "react";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";

const SinglePromo = ({ promoId }: { promoId: any }): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);

  const [promoData, setPromoData] = useState<any>({
    loading: false,
    data: null,
  });

  const getSinglePromo = useCallback(() => {
    if (promoId) {
      try {
        setPromoData({ loading: true, data: undefined });
        axios
          .get(
            `${process.env.REACT_APP_ORDER_API}/admin/promo/details/${promoId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setPromoData({
                loading: false,
                data: data?.data?.promoCode,
              });
            } else {
              setPromoData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setPromoData({ loading: false, data: null });
          });
      } catch (error) {
        setPromoData({ loading: false, data: null });
      }
    }
  }, [promoId]);

  useEffect(() => {
    getSinglePromo();
  }, [getSinglePromo]);

  return (
    <>
      {false ? (
        ""
      ) : (
        <div className="">
          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Promo Code</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{promoData?.data?.promoCode}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Promo Id</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{promoData?.data?.id}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Starting Date</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{moment(promoData?.data?.startFrom).format("lll")}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Ending Date</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{moment(promoData?.data?.endAt).format("lll")}</span>
              </div>
            </div>
          </div>

          {promoData?.data?.maximumAllowedUsage && (
            <div className="flex flex-no-wrap py-2 mt-2">
              <div className="w-2/5 flex-none">
                <div className="text-gray-500">
                  <span className="font-bold">Maximum Allowed Usage</span>
                </div>
              </div>
              <div className="w-2/5 flex-none">
                <div className="text-gray-700">
                  <span>{promoData?.data?.maximumAllowedUsage}</span>
                </div>
              </div>
            </div>
          )}

          {promoData?.data?.minimumAmountPurchase && (
            <div className="flex flex-no-wrap py-2 mt-2">
              <div className="w-2/5 flex-none">
                <div className="text-gray-500">
                  <span className="font-bold">Minimum Amount Purchase</span>
                </div>
              </div>
              <div className="w-2/5 flex-none">
                <div className="text-gray-700">
                  <span>&#2547; {promoData?.data?.minimumAmountPurchase}</span>
                </div>
              </div>
            </div>
          )}

          {promoData?.data?.paymentMethod && (
            <div className="flex flex-no-wrap py-2 mt-2">
              <div className="w-2/5 flex-none">
                <div className="text-gray-500">
                  <span className="font-bold">Payment Method</span>
                </div>
              </div>
              <div className="w-2/5 flex-none">
                <div className="text-gray-700">
                  <span>{promoData?.data?.paymentMethod}</span>
                </div>
              </div>
            </div>
          )}

          {promoData?.data?.usedFor && (
            <div className="flex flex-no-wrap py-2 mt-2">
              <div className="w-2/5 flex-none">
                <div className="text-gray-500">
                  <span className="font-bold">Used For</span>
                </div>
              </div>
              <div className="w-2/5 flex-none">
                <div className="text-gray-700">
                  <span>{promoData?.data?.usedFor?.split("_")?.join(" ")}</span>
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Message</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{promoData?.data?.message}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Type</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{promoData?.data?.type?.replace("_", " ")}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SinglePromo;
