import React from "react";
import MainLayout from "../../components/Layouts";
import AddVehicle from "../../components/Vehicles/AddVehicle";

const VehicleAddPage = () => {
  return (
    <MainLayout>
      <AddVehicle />
    </MainLayout>
  );
};

export default VehicleAddPage;
