import {
  Rate,
  Avatar,
  Layout,
  Tabs,
  Empty,
  Steps,
  Row,
  Col,
  Form,
  Button,
  Input,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router";
import {
  DmOrderTypeArray,
  getImgUrl,
  MerchantOrderTypeArray,
  OrderTypeArray,
} from "../../../utils";
import { EditOutlined, PhoneOutlined } from "@ant-design/icons";
import moment from "moment";
import OrderExtra from "./extra";
import MapBox from "../../common/MapBox";
import { useSelector } from "react-redux";
const { Content } = Layout;
const { TabPane } = Tabs;
const { Step } = Steps;
const { TextArea } = Input;

const SingleOrder = ({
  selectedOrder,
  inShop = false,
  inCampaign = false,
}: {
  selectedOrder?: any;
  inShop: boolean;
  inCampaign: boolean;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const route = useParams();
  const [edit, setEdit] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [singleOrderInfo, setSingleOrderInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchOrderDetails = useCallback((getOrderId: any) => {
    if (getOrderId) {
      try {
        setSingleOrderInfo({ loading: true, data: undefined });
        axios
          .get(
            `${process.env.REACT_APP_ORDER_API}/admin/order/details?orderId=${getOrderId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleOrderInfo({
                loading: false,
                data: data?.data?.order,
              });
              setSelectedProducts(data?.data?.order?.products || []);
            } else {
              setSingleOrderInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleOrderInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleOrderInfo({ loading: false, data: null });
      }
    }
  }, []);

  const editOrder = useCallback(
    (data) => {
      try {
        axios
          .put(
            `${process.env.REACT_APP_ORDER_API}/admin/order`,
            {
              orderId: (route as any)?.orderId || selectedOrder,
              shippingAddress: data?.shippingAddress,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              responseNotification(
                "Sipping Address updated successfully!",
                "success"
              );
              setEdit(false);
              fetchOrderDetails((route as any)?.orderId || selectedOrder);
            } else {
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            console.log(err); 
          });
      } catch (error) {
        console.log(error, "error");
      }
    },
    [fetchOrderDetails, route, selectedOrder]
  );

  useEffect(() => {
    fetchOrderDetails((route as any)?.orderId);
  }, [fetchOrderDetails, route]);

  useEffect(() => {
    fetchOrderDetails(selectedOrder?.orderId);
  }, [fetchOrderDetails, selectedOrder]);

  const order = singleOrderInfo?.data;

  return (
    <React.Fragment>
      <main>
        <div
          className={
            inShop
              ? "grid grid-cols-1 xl:grid-cols-1 xl:gap-0"
              : "grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6"
          }
        >
          <div className="col-span-full">
            <PageHeader
              className="site-page-header px-0 py-0 mb-0"
              onBack={() => navigate(-1)}
              title={
                <h1 className="text-xl font-medium text-gray-900 sm:text-2xl">
                  Order Details
                </h1>
              }
              extra={
                <OrderExtra
                  orderDetails={singleOrderInfo?.data}
                  selectedProducts={selectedProducts}
                  refetch={() =>
                    fetchOrderDetails(
                      singleOrderInfo?.data?.orderId || selectedOrder
                    )
                  }
                />
              }
            />
          </div>

          {!inShop && (
            <div className="col-span-full grid grid-cols-2 gap-6 xl:block xl:col-auto">
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
                <div className="xl:block sm:space-x-4 xl:space-x-0">
                  <img
                    src={getImgUrl(order?.shop?.banner)}
                    className="mb-5 rounded-2xl _shadow-lg shadow-gray-300"
                  />
                  <Avatar
                    src={order?.shop?.logo}
                    size={100}
                    className="shadow-lg border-4 border-white -mt-20 border-5 mb-4 bg-gray-50"
                  />{" "}
                  <div>
                    <h2 className="text-md font-medium">
                      {order?.shop?.name?.toUpperCase()}
                    </h2>

                    <ul className="mt-2 space-y-1">
                      <li className="flex items-center text-sm font-normal text-gray-500">
                        <span className="font-semibold mr-1 -mt-1">
                          <PhoneOutlined />
                        </span>{" "}
                        <span className="text-md">
                          {order?.shop?.contactNumber?.split("+88")}
                        </span>
                      </li>
                    </ul>

                    <Rate
                      value={order?.shop?.rating || 1}
                      className="mt-1"
                      disabled
                    />
                    <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                      {order?.shop?.numberOfRating || 0}
                    </span>
                  </div>
                </div>
              </div>

              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 ">
                <div className="flow-root">
                  <h3 className="text-xl font-bold mb-4">Status Logs</h3>
                  <div className="max-h-80 overflow-scroll">
                    <Steps
                      current={Object.values(OrderTypeArray).indexOf(
                        order?.status
                      )}
                      direction="vertical"
                      percent={80}
                      style={{ width: "100%", overflowX: "scroll" }}
                      size="small"
                    >
                      {Object.values(OrderTypeArray)?.map((type, i) => (
                        <Step
                          style={{ minWidth: 300 }}
                          key={i}
                          title={type?.split("_").join(" ")}
                          subTitle={
                            order?.statusLogs?.find(
                              (log: any) => log?.value === OrderTypeArray[i]
                            )
                              ? moment(
                                  order?.statusLogs?.find(
                                    (log: any) =>
                                      log?.value === OrderTypeArray[i]
                                  )?.datetime
                                ).format("lll")
                              : undefined
                          }
                          description={
                            <>
                              {order?.statusLogs?.find(
                                (log: any) => log?.value === OrderTypeArray[i]
                              ) ? (
                                <>
                                  <i className="mb-0">
                                    {
                                      order?.statusLogs?.find(
                                        (log: any) =>
                                          log?.value === OrderTypeArray[i]
                                      )?.createdBy?.name
                                    }{" "}
                                  </i>
                                  <br />
                                </>
                              ) : undefined}

                              <small>
                                {order?.statusLogs?.[i]
                                  ? order?.statusLogs?.[i]?.note
                                  : undefined}
                              </small>
                            </>
                          }
                        />
                      ))}
                    </Steps>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-span-2">
            <Layout>
              <Content className="main-content-layout">
                <div className="content-body_ rounded-2xl">
                  <div
                    className={`${
                      inShop
                        ? "bg-white rounded- p-2"
                        : "bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6"
                    }`}
                  >
                    <Tabs tabPosition="top">
                      <TabPane tab="ORDER DETAILS" key="Details">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                          <div className="">
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Base Order ID
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {order?.baseOrderId}
                            </dd>
                          </div>
                          <div className="">
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Order ID
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {order?.orderId}
                            </dd>
                          </div>
                          <div className="">
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Order Date:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {moment(order?.createdAt).format("lll")}
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Customer Info:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500 flex gap-2 items-center">
                              <Avatar
                                src={order?.customer?.profilePicture}
                                size="large"
                              />{" "}
                              <span>
                                <span className="font-bold">
                                  {order?.customer?.name?.toUpperCase()}
                                </span>{" "}
                                <br />
                                {order?.customer?.mobileNumber.split("+88")}
                              </span>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              <div className="flex items-center gap-8 justify-between">
                                <span>Shipping Address</span>
                                <Button
                                  type="dashed"
                                  danger
                                  shape="round"
                                  size="small"
                                >
                                  <EditOutlined
                                    onClick={() => setEdit(!edit)}
                                    disabled
                                  />
                                </Button>
                              </div>
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {edit ? (
                                <div style={{ width: "100%" }}>
                                  <Form
                                    name="control-hooks"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    className="ant-form ant-form-vertical"
                                    onFinish={editOrder}
                                    // form={form} // like ref
                                    layout="vertical"
                                    autoComplete="off"
                                  >
                                    <Form.Item
                                      hasFeedback
                                      name="shippingAddress"
                                      initialValue={order?.shippingAddress}
                                    >
                                      <TextArea
                                        placeholder="Shipping Address"
                                        rows={3}
                                      />
                                    </Form.Item>
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      danger
                                    >
                                      Done
                                    </Button>
                                  </Form>
                                </div>
                              ) : (
                                <span>{order?.shippingAddress}</span>
                              )}
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Payment Status
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {order?.paymentStatus?.replace("_", " ")}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Payment Method
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {order?.paymentMethod}
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Total Paid:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              &#2547;{order?.totalPaid}
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              DUE:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              &#2547;{order?.total - order?.totalPaid}
                            </dd>
                          </div>
                        </dl>

                        <dl className="grid grid-cols-7 gap-x-4 gap-y-8 sm:grid-cols-7 bg-slate-50 p-2 mt-4">
                          <dt className="col-span-2 font-semibold">
                            <span>Product</span>
                          </dt>
                          <dt className="font-semibold">
                            <span>Variation</span>
                          </dt>
                          <dt className="font-semibold">
                            <span>TP</span>
                          </dt>
                          <dt className="font-semibold">
                            <span>Price</span>
                          </dt>
                          <dt className="font-semibold">
                            <span>Quantity</span>
                          </dt>
                          <dt className="font-semibold text-right">
                            <span className="">Total</span>
                          </dt>
                        </dl>

                        <div>
                          {selectedProducts?.length ? (
                            selectedProducts?.map((product: any, index) => (
                              <React.Fragment key={index}>
                                <dl className="grid grid-cols-7 gap-x-4 gap-y-8 sm:grid-cols-7 border-t p-2 hover:bg-red-50 align-middle">
                                  <dd className="col-span-2 flex items-center gap-2">
                                    <Avatar
                                      shape="square"
                                      size={45}
                                      alt={product?.name}
                                      src={product?.productImage}
                                      className="mr-2"
                                    />

                                    <small>{product?.name?.slice(0, 30)}</small>
                                  </dd>

                                  {/* <div className="single-product text-center">
                                              {product?.supplier?.name && (
                                                <div
                                                  className="text-center name"
                                                  style={{ width: "100%" }}
                                                >
                                                  <Popover
                                                    placement="rightBottom"
                                                    title={
                                                      product?.supplier?.name
                                                    }
                                                    content={
                                                      <>
                                                        <PhoneOutlined />{" "}
                                                        <span>
                                                          {product?.supplier?.mobileNumber?.split(
                                                            "+88"
                                                          )}
                                                        </span>
                                                        <br />
                                                        <PushpinOutlined />{" "}
                                                        <span className="name">
                                                          <small>
                                                            {
                                                              product?.supplier
                                                                ?.name
                                                            }
                                                          </small>
                                                        </span>
                                                      </>
                                                    }
                                                    trigger="click"
                                                  >
                                                    <small>
                                                      {product?.supplier?.name}
                                                    </small>
                                                  </Popover>
                                                </div>
                                              )}
                                            </div> */}
                                  <div className="single-product">
                                    <span className="name">
                                      {product?.variation?.variationName}
                                    </span>
                                  </div>
                                  <dd>
                                    &#2547;
                                    {product?.variation?.price?.tp}
                                  </dd>

                                  <dd>
                                    <span className="name">
                                      &#2547;
                                      {product?.variation?.price?.mrp}
                                      {product?.variation?.price
                                        ?.discountedPrice !==
                                      product?.variation?.price?.mrp ? (
                                        <>
                                          <span className="text-danger px-1">
                                            {product?.variation?.productDiscount
                                              ?.flat ? (
                                              <>
                                                {" - "}
                                                <del>
                                                  &#2547;
                                                  {
                                                    product?.variation
                                                      ?.productDiscount?.flat
                                                  }
                                                </del>
                                              </>
                                            ) : undefined}
                                            {product?.variation?.productDiscount
                                              ?.percentage ? (
                                              <>
                                                -
                                                <del>
                                                  {
                                                    product?.variation
                                                      ?.productDiscount
                                                      ?.percentage
                                                  }
                                                  %
                                                </del>
                                              </>
                                            ) : undefined}
                                          </span>
                                          {product?.variation?.price
                                            ?.discountedPrice ? (
                                            <span className=" px-1">
                                              {"= "}
                                              &#2547;
                                              {
                                                product?.variation?.price
                                                  ?.discountedPrice
                                              }
                                            </span>
                                          ) : (
                                            <span className="px-1">
                                              {"= "}
                                              &#2547;
                                              {product?.variation?.price?.mrp}
                                            </span>
                                          )}
                                        </>
                                      ) : undefined}
                                    </span>
                                  </dd>

                                  {order?.status === "COMPLETED" ||
                                  order?.status === "DELIVERED" ||
                                  order?.status === "CANCELLED" ||
                                  order?.status === "ON_DELIVERY" ? (
                                    <span className="name major">
                                      x{product?.quantity}
                                    </span>
                                  ) : (
                                    <span className="name major">
                                      x{product?.quantity}
                                    </span>
                                    // <span className="order_input_area">
                                    //   <div className="input_area">
                                    //     <Form.Item
                                    //       hasFeedback
                                    //       name="productId"
                                    //       initialValue={
                                    //         product?.id
                                    //       }
                                    //     >
                                    //       <Input
                                    //         type="hidden"
                                    //         style={{
                                    //           minWidth: 100,
                                    //         }}
                                    //       />
                                    //     </Form.Item>

                                    //     <Button
                                    //       onClick={() => {
                                    //         setSelectedProducts(
                                    //           (
                                    //             prevProducts: any
                                    //           ) => {
                                    //             const arr =
                                    //               Array.from(
                                    //                 prevProducts
                                    //               );
                                    //             const newArr =
                                    //               arr.map(
                                    //                 (
                                    //                   item: any
                                    //                 ) => {
                                    //                   if (
                                    //                     item?.id ===
                                    //                     product?.id
                                    //                   ) {
                                    //                     if (
                                    //                       item &&
                                    //                       (
                                    //                         item as any
                                    //                       )
                                    //                         .quantity >
                                    //                         0
                                    //                     ) {
                                    //                       return {
                                    //                         ...item,
                                    //                         quantity:
                                    //                           ((
                                    //                             item as any
                                    //                           )
                                    //                             .quantity ||
                                    //                             0) -
                                    //                           1,
                                    //                       };
                                    //                     } else {
                                    //                       return item;
                                    //                     }
                                    //                   }
                                    //                   return item;
                                    //                 }
                                    //               );

                                    //             return newArr;
                                    //           }
                                    //         );
                                    //       }}
                                    //       disabled={
                                    //         product?.quantity <= 1
                                    //       }
                                    //     >
                                    //       <MinusOutlined />
                                    //     </Button>

                                    //     <Input
                                    //       name="quantity"
                                    //       type="number"
                                    //       value={
                                    //         product?.quantity
                                    //       }
                                    //     />

                                    //     <Button
                                    //       onClick={() => {
                                    //         setSelectedProducts(
                                    //           (
                                    //             prevProducts: any
                                    //           ) => {
                                    //             const arr =
                                    //               Array.from(
                                    //                 prevProducts
                                    //               );
                                    //             const newArr =
                                    //               arr.map(
                                    //                 (
                                    //                   item: any
                                    //                 ) => {
                                    //                   if (
                                    //                     item?.id ===
                                    //                     product?.id
                                    //                   ) {
                                    //                     if (
                                    //                       item &&
                                    //                       (
                                    //                         item as any
                                    //                       )
                                    //                         .quantity >
                                    //                         0
                                    //                     ) {
                                    //                       return {
                                    //                         ...item,
                                    //                         quantity:
                                    //                           ((
                                    //                             item as any
                                    //                           )
                                    //                             .quantity ||
                                    //                             0) +
                                    //                           1,
                                    //                       };
                                    //                     } else {
                                    //                       return item;
                                    //                     }
                                    //                   }
                                    //                   return item;
                                    //                 }
                                    //               );

                                    //             return newArr;
                                    //           }
                                    //         );
                                    //       }}
                                    //       disabled={
                                    //         product?.quantity >=
                                    //         product?.variation
                                    //           ?.stock
                                    //       }
                                    //     >
                                    //       <PlusOutlined />
                                    //     </Button>
                                    //     <Button
                                    //       type="dashed"
                                    //       danger
                                    //       className="ml-2"
                                    //       onClick={async () => {
                                    //         await setSelectedProducts(
                                    //           (
                                    //             prevProducts: any
                                    //           ) => {
                                    //             const arr =
                                    //               Array.from(
                                    //                 prevProducts
                                    //               );
                                    //             const newArr =
                                    //               arr.filter(
                                    //                 (item: any) =>
                                    //                   item?.id !==
                                    //                   product?.id
                                    //               );
                                    //             return newArr;
                                    //           }
                                    //         );
                                    //       }}
                                    //     >
                                    //       <CloseOutlined />
                                    //     </Button>
                                    //   </div>
                                    // </span>
                                  )}

                                  <dd className="text-right">
                                    {product?.variation?.price
                                      ?.discountedPrice ? (
                                      <span className="name">
                                        &#2547;
                                        {product?.variation?.price
                                          ?.discountedPrice * product?.quantity}
                                      </span>
                                    ) : (
                                      <span className="name">
                                        &#2547;
                                        {product?.variation?.price?.mrp *
                                          product?.quantity}{" "}
                                      </span>
                                    )}
                                  </dd>
                                </dl>
                              </React.Fragment>
                            ))
                          ) : (
                            <Empty />
                          )}
                        </div>

                        <Row justify="end" align="top">
                          <Col span={9} className="mb-5">
                            <div className="flex justify-between p-1">
                              <span className="font-medium">Sub Total:</span>
                              <span className="info-desc text-right">
                                &#2547;{order?.subTotal || 0}
                                {/* {selectedProducts?.reduce((a, b) => {
                                  return (
                                    a +
                                    b?.quantity *
                                      b?.variation?.price?.discountedPrice
                                  );
                                }, 0)} */}
                              </span>
                            </div>
                            <div className="flex justify-between p-1">
                              <span className="font-medium">Vat :</span>
                              <span className="info-desc text-right">
                                &#2547;{order?.vat}
                              </span>
                            </div>
                            <div className="flex justify-between p-1">
                              <span className="font-medium">
                                Delivery Charge:
                              </span>
                              <span className="info-desc text-right">
                                &#2547;{order?.deliveryCharge}
                              </span>
                            </div>
                            <div className="flex justify-between p-1">
                              <span className="font-medium">Discount:</span>
                              <span className="info-desc text-right">
                                &#2547;
                                {/* {selectedProducts?.reduce((a, b) => {
                                      return (
                                        a +
                                        b?.quantity *
                                          (b?.variation?.price?.mrp -
                                            (b?.variations?.[0]?.price
                                              ?.discountedPrice ||
                                              b?.variation?.price
                                                ?.discountedPrice))
                                      );
                                    }, 0) + order?.discount} */}
                                {parseInt(order?.discount) -
                                  parseInt(order?.promoDiscount)}
                              </span>
                            </div>
                            <div className="flex justify-between p-1">
                              <span className="font-medium">
                                Promo ({order?.promo?.promoCode}):
                              </span>
                              <span className="info-desc text-right">
                                &#2547;
                                {parseInt(order?.promoDiscount)}
                              </span>
                            </div>
                            <div className="flex justify-between p-1">
                              <span className="font-medium">Total Paid</span>
                              <span className="info-desc text-right">
                                &#2547;
                                {parseInt(order?.totalPaid)}
                              </span>
                            </div>
                            <div className="flex justify-between p-1">
                              <span className="font-medium">Total:</span>
                              <span className="info-desc text-right">
                                &#2547;
                                {parseInt(order?.total)}
                                {/* {selectedProducts?.reduce((a, b) => {
                                      return (
                                        a +
                                        b?.quantity *
                                          (b?.variation?.price?.discountedPrice ||
                                            b?.variations?.[0]?.price?.mrp)
                                      );
                                    }, 0) +
                                      order?.deliveryCharge +
                                      order?.vat -
                                      order?.promoDiscount} */}
                              </span>
                            </div>
                            {/* <Button
                                  type="primary"
                                  danger
                                  block
                                  onClick={() => {
                                    editOrderProducts();
                                  }}
                                  disabled={
                                    selectedProducts?.reduce((a, b) => {
                                      return (
                                        a +
                                        b?.quantity *
                                          (b?.variation?.price?.discountedPrice ||
                                            b?.variations?.[0]?.price?.mrp)
                                      );
                                    }, 0) +
                                      order?.deliveryCharge +
                                      order?.vat ===
                                      order?.total ||
                                    order?.status === "COMPLETED" ||
                                    order?.status === "DELIVERED" ||
                                    order?.status === "CANCELLED"
                                      ? true
                                      : false
                                  }
                                >
                                  Update Order
                                </Button> */}
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tab="REVENUE" key="Revenue">
                        <dl className="grid grid-cols-5 gap-x-4 gap-y-8 sm:grid-cols-5 bg-slate-50 p-2 mt-4">
                          <dt className="col-span-2 font-semibold">
                            <span>Items</span>
                          </dt>
                          <dt className="font-semibold text-right">
                            <span>Customer Price</span>
                          </dt>
                          <dt className="font-semibold text-right">
                            <span>Merchant Price</span>
                          </dt>

                          <dt className="font-semibold text-right">
                            <span className="">Revenue</span>
                          </dt>
                        </dl>

                        <div>
                          {selectedProducts?.length ? (
                            selectedProducts?.map((product: any, index) => (
                              <React.Fragment key={index}>
                                <dl className="grid grid-cols-5 gap-x-4 gap-y-8 sm:grid-cols-5 border-t p-2 hover:bg-red-50 align-middle">
                                  <dd className="col-span-2">
                                    {product?.name?.slice(0, 30)}
                                  </dd>

                                  <dd className="text-right">
                                    {product?.variation?.price
                                      ?.discountedPrice ? (
                                      <span className="name">
                                        &#2547;
                                        {product?.variation?.price
                                          ?.discountedPrice * product?.quantity}
                                      </span>
                                    ) : (
                                      <span className="name">
                                        &#2547;
                                        {product?.variation?.price?.mrp *
                                          product?.quantity}{" "}
                                      </span>
                                    )}
                                  </dd>

                                  <dd className="text-right">
                                    &#2547;
                                    {product?.variation?.price?.tp *
                                      product?.quantity}
                                  </dd>

                                  <dd className="text-right">
                                    &#2547;
                                    {product?.variation?.price
                                      ?.discountedPrice *
                                      product?.quantity -
                                      product?.variation?.price?.tp *
                                        product?.quantity}
                                  </dd>
                                </dl>
                              </React.Fragment>
                            ))
                          ) : (
                            <Empty />
                          )}
                        </div>

                        <dl className="grid grid-cols-5 gap-x-4 gap-y-8 sm:grid-cols-5 bg-slate-50 p-2 mt-4">
                          <dt className="col-span-2 font-semibold">
                            <span>Total</span>
                          </dt>
                          <dt className="font-semibold text-right">
                            <span>
                              &#2547;
                              {selectedProducts?.reduce((a: any, b: any) => {
                                return (
                                  a +
                                  b?.quantity *
                                    (b?.variation?.price?.discountedPrice ||
                                      b?.variations?.[0]?.price
                                        ?.discountedPrice)
                                );
                              }, 0)}
                            </span>
                          </dt>
                          <dt className="font-semibold text-right">
                            <span>
                              &#2547;
                              {selectedProducts?.reduce((a: any, b: any) => {
                                return (
                                  a +
                                  b?.quantity *
                                    (b?.variation?.price?.tp ||
                                      b?.variations?.[0]?.price?.tp)
                                );
                              }, 0)}
                            </span>
                          </dt>
                          <dt className="font-semibold text-right">
                            &#2547;
                            {selectedProducts?.reduce((a: any, b: any) => {
                              return (
                                a +
                                b?.quantity *
                                  (b?.variation?.price?.discountedPrice ||
                                    b?.variations?.[0]?.price?.discountedPrice)
                              );
                            }, 0) -
                              selectedProducts?.reduce((a: any, b: any) => {
                                return (
                                  a +
                                  b?.quantity *
                                    (b?.variation?.price?.tp ||
                                      b?.variations?.[0]?.price?.tp)
                                );
                              }, 0)}
                          </dt>
                        </dl>
                      </TabPane>

                      <TabPane tab="STATUS LOGS" key="Logs">
                        <div className="grid grid-cols-3 gap-x-6 gap-y-8 sm:grid-cols-3">
                          <div className="flex flex-col p-3 max-w-md rounded-2xl shadow-sm transform cursor-move mb-5 bg-slate-50">
                            <h4 className="title text-lg mb-3">Status Logs</h4>
                            <Steps
                              responsive={true}
                              current={order?.statusLogs?.length - 1}
                              direction="vertical"
                              percent={70}
                              size="small"
                            >
                              {order?.statusLogs?.length
                                ? order?.statusLogs?.map(
                                    (log: any, index: any) => (
                                      <Step
                                        key={index}
                                        title={log?.value?.split("_").join(" ")}
                                        subTitle={moment(log?.datetime).format(
                                          "lll"
                                        )}
                                        description={
                                          <>
                                            {order?.statusLogs?.[index]
                                              ? order?.statusLogs?.[index]
                                                  ?.createdBy?.name
                                              : undefined}
                                            (
                                            <small>
                                              {order?.statusLogs?.[index]
                                                ? order?.statusLogs?.[index]
                                                    ?.note
                                                : undefined}
                                            </small>
                                            )
                                          </>
                                        }
                                      />
                                    )
                                  )
                                : undefined}
                            </Steps>
                          </div>

                          <div className="flex flex-col p-3 max-w-md rounded-2xl shadow-sm transform cursor-move mb-5 bg-slate-50">
                            <h4 className="title text-lg mb-3">
                              Status of Merchant
                            </h4>
                            <Steps
                              current={Object.values(
                                MerchantOrderTypeArray
                              ).indexOf(order?.statusOfMerchant)}
                              direction="vertical"
                              percent={80}
                              style={{
                                width: "100%",
                                overflowX: "scroll",
                              }}
                              size="small"
                            >
                              {Object.values(MerchantOrderTypeArray)?.map(
                                (type, i) => (
                                  <Step
                                    style={{ minWidth: 300 }}
                                    key={i}
                                    title={type?.split("_").join(" ")}
                                    description={
                                      order?.statusLogs?.find(
                                        (log: any) =>
                                          log?.value ===
                                          MerchantOrderTypeArray[i]
                                      )
                                        ? moment(
                                            order?.statusLogs?.find(
                                              (log: any) =>
                                                log?.value ===
                                                MerchantOrderTypeArray[i]
                                            )?.datetime
                                          ).format("lll")
                                        : undefined
                                    }
                                  />
                                )
                              )}
                            </Steps>
                          </div>

                          <div className="flex flex-col p-3 max-w-md rounded-2xl shadow-sm transform cursor-move mb-5 bg-slate-50">
                            <h4 className="title text-lg mb-3">
                              Status of Delivery Man
                            </h4>
                            <Steps
                              current={Object.values(DmOrderTypeArray).indexOf(
                                order?.statusOfDeliveryMan
                              )}
                              direction="vertical"
                              percent={80}
                              style={{
                                width: "100%",
                                overflowX: "scroll",
                              }}
                              size="small"
                            >
                              {Object.values(DmOrderTypeArray)?.map(
                                (type, i) => (
                                  <Step
                                    style={{ minWidth: 300 }}
                                    key={i}
                                    title={type?.split("_").join(" ")}
                                    description={
                                      order?.statusLogs?.find(
                                        (log: any) =>
                                          log?.value === DmOrderTypeArray[i]
                                      )
                                        ? moment(
                                            order?.statusLogs?.find(
                                              (log: any) =>
                                                log?.value ===
                                                DmOrderTypeArray[i]
                                            )?.datetime
                                          ).format("lll")
                                        : undefined
                                    }
                                  />
                                )
                              )}
                            </Steps>
                          </div>
                        </div>
                      </TabPane>
                      {!inShop && (
                        <TabPane tab="SHOP INFO" key="ShopInfo">
                          <div className="flex flex-col max-w-md bg-white rounded-2xl shadow-sm transform cursor-move">
                            <div className="flex justify-center items- ncenter pb-4">
                              <img
                                className="bg-contain rounded-2xl"
                                src={order?.shop?.banner}
                                alt="attachment"
                              />
                            </div>
                            <div className="p-4">
                              <div className="flex flex-col-1 -mt-20">
                                <Avatar
                                  src={order?.shop?.logo}
                                  size={100}
                                  className="shadow-lg border-4 border-white bg-gray-50"
                                />{" "}
                                <div className="pb-4 text-lg font-bold text-gray-700 mt-14 mb-8 ml-4 text-">
                                  {order?.shop?.name?.toUpperCase()}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Shop Slug:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {order?.shop?.slug}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Shop ID:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {order?.shop?.id}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">
                                  Contact Number:
                                </div>
                                <div className="text-sm font-normal text-gray-700">
                                  {order?.shop?.contactNumber}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Address:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {order?.shop?.address}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Description:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {order?.shop?.description}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Prepare Time:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {order?.shop?.prepareTime}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">
                                  Base Delivery Charge:
                                </div>
                                <div className="text-sm font-normal text-gray-700">
                                  &#2547;{order?.shop?.baseDeliveryCharge}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">
                                  Delivery Condition:
                                </div>
                                <div className="text-sm font-normal text-gray-700">
                                  {order?.shop?.isFreeDelivery ? (
                                    <div>Free Delivert</div>
                                  ) : (
                                    <div>Paid Delivert</div>
                                  )}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Status:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  {order?.shop?.status}
                                </div>
                              </div>

                              <div className="flex justify-between py-1">
                                <div className="font-medium">Rattings:</div>
                                <div className="text-sm font-normal text-gray-700">
                                  <Rate
                                    value={order?.rating || 1}
                                    className="mt-1"
                                    disabled
                                  />
                                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                    {order?.numberOfRating || 0}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                      )}

                      {order?.deliveryMan && (
                        <TabPane tab="DRIVER INFO" key="Driver">
                          <div className="bg-white shadow-sm shadow-gray-200 rounded-2xl p-4 mb-6">
                            <div className="items-center flex-1 sm:flex xl:flex 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
                              <Avatar
                                size={80}
                                className="rounded-lg mr-4 shadow-lg shadow-gray-300"
                                src={order?.deliveryMan?.profilePicture}
                                alt={order?.deliveryMan?.name}
                              />
                              <div>
                                <h3 className="text-xl font-bold text-gray-600">
                                  {order?.deliveryMan?.name}
                                </h3>
                                <div className="text-base font-normal text-gray-500">
                                  {order?.deliveryMan?.mobileNumber?.split(
                                    "+88"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <MapBox
                            draggable={false}
                            coordinate={{
                              lat: order?.deliveryManLocation?.latitude,
                              lng: order?.deliveryManLocation?.longitude,
                            }}
                          />
                        </TabPane>
                      )}
                    </Tabs>
                  </div>
                </div>
              </Content>
            </Layout>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

SingleOrder.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
  selectedProduct: PropTypes.string,
};
export default SingleOrder;
