import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopPickupZoneList from "../../../components/Shop/PickupZone/index";

const ShopPickupZoneListPage = () => {
  return (
    <MainLayout>
      <ShopPickupZoneList />
    </MainLayout>
  );
};

export default ShopPickupZoneListPage;
