import { Button, Form, Input, Select, Switch } from "antd";
import { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import styles from "../../../styles/tailwind/Add.module.css";
import { useParams } from "react-router";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";

const AddZone = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const shopID = (route as any)?.shopId;
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [zoneData, setZoneData] = useState<any>(undefined);
  const [deliveryZoneId, setDeliveryZoneId] = useState<any>('');
  const [zoneOptions, setZoneOptions] = useState<any>({
    list: [],
    loading: false, 
  });
  const getZoneOptions = useCallback(async (key?: string) => {
    setZoneOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    const res = await axios.get(`${encodedUri}/delivery-zone/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setZoneOptions({
      loading: false,
      list: res?.data?.deliveryZones,
    });

    setZoneOptions({
      loading: false,
      list: res?.data?.deliveryZones?.map((zone: any) => {
        return {
          value: zone?.id,
          label: zone?.name,
        };
      }),
    });
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
        addShippingRules: data.addShippingRules,
        deliveryZoneId: deliveryZoneId,
        isActive: data.isActive,
        // isShippingZone: data.isShippingZone,
        shopId: shopID,
      };

    if (zoneData) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/delivery-configuration/update-shipping-zone`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: zoneData?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Zone Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ORDER_API}/delivery-configuration/add-shipping-zone`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Zone Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setZoneData(visibleData); 
     // setDeliveryZoneId(visibleData?.bannerType);
    }
  }, [visibleData]);

  useEffect(() => {
    if (zoneData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [zoneData, form, visibleData]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "zone") getZoneOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getZoneOptions, type]);

  // **********************************
  // Search Options
  // **********************************

  useEffect(() => {
    getZoneOptions();
  }, [getZoneOptions]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...zoneData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Add Shipping Rules"
          rules={[
            {
              required: false,
              message: "Add Shipping Rules is Required!",
            },
          ]}
          name="addShippingRules"
        >
          <Input
            id="addShippingRules"
            type="text"
            placeholder="Enter Add Shipping Rules"
          />
        </Form.Item>
        <Form.Item hasFeedback label="Zone" name="zoneId">
          <Select
            showSearch
            allowClear
            placeholder="Select Zone"
            optionFilterProp="children"
            onChange={(val) => setDeliveryZoneId(val)}
            onSearch={(e) => {
              console.log(e);
            }}
            filterOption={() => {
              return true;
            }}
            options={zoneOptions?.list}
            //mode="multiple"
          ></Select>
        </Form.Item>
        {/* <Form.Item
          hasFeedback
          label="IS Shipping Zone?"
          name="isShippingZone"
          className="mr-5"
        >
          <Switch
            checkedChildren={`true`}
            unCheckedChildren={`false`}
           // defaultChecked={(singleProductInfo as any)?.data?.isShippingZone}
          />
        </Form.Item> */}
        <Form.Item
          hasFeedback
          label="IS Active?"
          name="isActive"
          className="mr-5"
        >
          <Switch
            checkedChildren={`true`}
            unCheckedChildren={`false`}
            //defaultChecked={(singleProductInfo as any)?.data?.isActive}
          />
        </Form.Item>

        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddZone;
