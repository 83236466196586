import React from "react";
import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import CompanyBlanceDepositList from "../../components/Finance/CompanyBlanceDeposit";
const ComapnyBlanceDepositPage = (): ReactElement => {
  return (
    <MainLayout>
      <CompanyBlanceDepositList />
    </MainLayout>
  );
};

export default ComapnyBlanceDepositPage;
