import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import GetShopBrandsList from "../../Brands";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import {
  Button,
  Drawer,
  Empty,
  Form,
  Pagination,
  Popconfirm,
  Radio,
} from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import Loader from "../../common/Loader";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getPage, getParamValue } from "../../../utils";
import axios from "axios";
import styles from "../../../styles/tailwind/List.module.css";
import { responseNotification } from "../../../utils/notify";
import AddBtn from "../../common/AddBtn";
import moment from "moment";
import SinglePickupZone from "../PickupZone/PickupDetails";
import AddPickupZone from "../PickupZone/AddPickupZone";

const ShopShippingSettingList = (): ReactElement => {
  const [form] = Form.useForm();
  const route = useParams();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const shopID = (route as any)?.shopId;
  const [checkDeliveryType, setCheckDeliveryType] = useState();
  const [checkPackageType, setCheckPackageType] = useState();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [singleShopInfo, setSingleShopInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [pickupZoneData, setPickupZoneData] = useState<any>({
    loading: false,
    data: null,
  });

  //shipping setting start
  const fetchShopDetails = useCallback((getShopId: any) => {
    if (getShopId) {
      try {
        setSingleShopInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/shop/details?shopId=${getShopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleShopInfo({
                loading: false,
                data: data?.data?.shop,
              });
           
            } else { 
              setSingleShopInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleShopInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleShopInfo({ loading: false, data: null });
      }
    }
  }, []);

  const onShippingAndDeliveryTypeChange = async (
    shippingAndDeliveryType: any
  ) => {
    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: shopID,
        deliveryFulFillBy: shippingAndDeliveryType,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setConfirmLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Updated Successfully", "success");
          //getShops();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  const onShippingAndDeliveryPackageChange = async (packageValue: any) => {
    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: shopID,
        deliveryPackageFulfillBy: packageValue,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setConfirmLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Updated Successfully", "success");
          //getShops();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  //shipping setting end
  useEffect(() => {
    fetchShopDetails((route as any)?.shopId);
  }, [fetchShopDetails]);
  const onClose = () => {
    setVisible(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  useEffect(() => {
    if (singleShopInfo?.data) {
      form.resetFields();
      setCheckDeliveryType(singleShopInfo?.data?.deliveryFulfillBy);
      setCheckPackageType(singleShopInfo?.data?.deliveryPackageFulfillBy);
    
    }
  }, [form, singleShopInfo?.data]);

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Shipping Setting"
        subTitle="Shipping Setting"
        extra={<></>}
      >
        <>
          <BreadCrumb title="Shipping Setting" extra={""} />
          <div className={styles.contentWrapper}>
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div
                  className={
                    showSearch ? `content-body-withSearch` : `content-body`
                  }
                >
                  <>
                    <Form
                      name="control-hooks"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className={styles.formStyles}
                      form={form} // like ref
                      layout="horizontal"
                    >
                      <div className="px-8">
                        <Form.Item
                          hasFeedback
                          label="Shipping & Delivery Type"
                          rules={[
                            {
                              required: true,
                              message: "Shipping & Delivery Type is Required!",
                            },
                          ]}
                          name="shippingDeliveryType"
                        >
                          <Radio.Group
                            onChange={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onShippingAndDeliveryTypeChange(e.target.value);
                            }}
                            defaultValue={checkDeliveryType}
                          >
                            <Radio value={"JACHAI"} checked>
                              Fulfillment By Jachai (FBJ)
                            </Radio>
                            <Radio value="SELLER">
                              Fulfillment By Seller (FBS)
                            </Radio>
                            <Radio value={"REFERRER"}>
                              Fulfillment By Agent/Referral
                            </Radio>
                            <Radio value={"DELIVERYMAN"}>
                              Fulfillment By Delivery Hero
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="px-8">
                        <Form.Item
                          hasFeedback
                          label="Shipping & Delivery Packeging"
                          rules={[
                            {
                              required: true,
                              message:
                                "Shipping & Delivery Packeging is Required!",
                            },
                          ]}
                          name="shippingDeliveryPackeging"
                        >
                          <Radio.Group
                            onChange={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onShippingAndDeliveryPackageChange(
                                e.target.value
                              );
                            }}
                            defaultValue={checkPackageType}
                          >
                            <Radio value={"JACHAI"}>
                              Packaging By Jachai (PBJ)
                            </Radio>
                            <Radio value={"SELLER"}>
                              Packaging By Seller (PBS)
                            </Radio>
                            <Radio value={"REFERRER"}>
                              Packaging By Agent/Referral
                            </Radio>
                            <Radio value={"DELIVERYMAN"}>
                              Packaging By Delivery Hero
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopShippingSettingList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopShippingSettingList;
