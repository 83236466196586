import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  DatePicker,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Menu,
  Modal,
  Select,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue, jcTypeArray, MerchantProductStatus } from "../../utils";
import { useLocation, useParams } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_SEARCHBAR } from "../../redux/search/searchType";
import QuickUpdate from "./QuickUpdate";
import moment from "moment";
import SingleRequestedProduct from "./Details";
import React from "react";
import { Loader, LoaderFull, PaginationThree } from "../common";
const { confirm } = Modal;
const { RangePicker } = DatePicker;

const RequestedProductList = ({
  inShop = false,
  inMerchant = false,
  getShopId,
  getMerchantId,
}: {
  inShop?: boolean;
  inMerchant?: boolean;
  getShopId?: string;
  getMerchantId?: string;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [form] = Form.useForm();
  const route = useParams();
  const dispatch = useDispatch();

  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [key, setKey] = useState("");
  const [shopId, setShopId] = useState<any>(getShopId);
  const [categoryId, setCategoryId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);
  const [showSearch, setShowSearch] = useState(true);

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };
  let now = new Date();
  let today = now.toISOString();
  let previous30days = new Date(now.setDate(now.getDate() - 30)).toISOString();

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  useEffect(() => {
    setBrandId((route as any)?.brandId || "");
  }, [route]);

  const getProducts = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setProductData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/requested-stock/products?type=${type}` +
          (from ? `&fromDate=${from}` : ``) +
          (to ? `&toDate=${to}` : ``) +
          (key ? `&name=${key}` : ``) +
          (page
            ? `&page=${
                page == productData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProductData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setProductData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [range, key,type, page, limit]);

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get( 
          `${encodedUri}/requested-stock/products?type=${type}&isDeleted=false&page=0&limit=20` +
            (key ? `&name=${key}` : ``) +
            (shopId ? `&shopId=${shopId}` : ``) +
            (categoryId ? `&categoryId=${categoryId}` : ``) +
            (brandId ? `&brandId=${brandId}` : ``) +
            (page ? `&page=${page}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.products?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [shopId, categoryId, brandId, key, page, limit]
  );

  const onStatusChange = async (status: any) => {
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/merchant/product`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedProductForEdit?.id,
          status: status,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          getProducts();
          onClose();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(status: any) {
    confirm({
      title: "Do you Want to change Status?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onStatusChange(status);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
    });
  }

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setShopId("");
    setCategoryId("");
    setBrandId("");
    setType("");
  };

  function UnLoadWindow() {
    dispatch({
      type: SHOW_SEARCHBAR,
      payload: {
        shopId: shopId,
        categoryId: categoryId,
        brandId: brandId,
      },
    });
  }

  const initBeforeUnLoad = (showExitPrompt: any) => {
    window.onbeforeunload = (event) => {
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        UnLoadWindow();
        return "";
      }
    };
  };

  window.onload = function () {
    initBeforeUnLoad(shopId);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(shopId);
  }, [shopId]);

  const onClose = () => {
    getProducts();
    // setChildrenDrawer(false);
    setSelectedProductForEdit(undefined);
  };

  return (
    <>
      {!inMerchant && (
        <BreadCrumb
          title="Requested Order's Product"
          subTitle={`${productData?.data?.totalElements || 0} Product(s)`}
          childComponent={inShop ? true : false}
          extra={[
            <Button
              type="dashed"
              shape="circle"
              onClick={() => setShowSearch(!showSearch)}
              key={1}
            >
              <SearchOutlined />
            </Button>,
          ]}
        />
      )}

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
          <Form.Item initialValue={type}>
                    <Select
                      placeholder="Shop Type"
                      onChange={(val) => setType(val)}
                      value={type}
                      style={{ width: 250 }}
                    >
                      {Object.values(jcTypeArray)?.map((type, i) => (
                        <Option value={type} key={i}>
                          {type.replace("_", " ")}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
            {/* <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={productOptions.loading}
                />
              </AutoComplete>
            </Form.Item> */}
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {productData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Requested At</th>
                      {/* <th scope="col">Status</th> */}
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.data?.requestedStockResponses?.length ? (
                      productData?.data?.requestedStockResponses?.map(
                        (data: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // navigate(
                                //   `${
                                //     inShop ? `/shops/${getShopId}` : ``
                                //   }/products/${product?.slug}/details`
                                // );
                              }}
                            >
                              <Avatar
                                size={45}
                                src={data?.product?.productImage}
                                shape="square"
                              />
                              <span className="font-medium text-gray-500 ml-2">
                                {data?.product?.name?.slice(0, 50)}
                              </span>
                            </td>
                            <td>{moment(data?.createdAt).format("lll")}</td>

                            {/* <td>
                              <div className="flex flex-grow">
                                <Button
                                  shape="round"
                                  type="primary"
                                  danger
                                  className="ml-1"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedProductForEdit(data?.product);
                                  }}
                                >
                                  <FileDoneOutlined />
                                </Button>
                              </div>
                            </td> */}
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...productData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title="Review Product"
        placement="right"
        onClose={onClose}
        open={selectedProductForEdit}
        width={600}
        extra={
          <div className="flex flex-1">
            <Dropdown.Button
              className="bg-white"
              overlay={
                <Menu>
                  {Object?.values(MerchantProductStatus)?.map((status) => (
                    <Menu.Item
                      key={status}
                      onClick={(val) => {
                        showConfirm(val?.key);
                      }}
                    >
                      {status?.split("_").join(" ") || "Status"}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              {selectedProductForEdit?.status?.split("_").join(" ") || "Status"}
            </Dropdown.Button>{" "}
            {/* <Button className="ml-2" onClick={showChildrenDrawer}>
              <EditOutlined />
            </Button> */}
          </div>
        }
      >
        <SingleRequestedProduct ProductDetails={selectedProductForEdit} />

        <Drawer
          title="Edit Product"
          width={420}
          closable={false}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
        >
          <QuickUpdate
            onCloseMethod={() => setChildrenDrawer(false)}
            visibleData={selectedProductForEdit}
          />
        </Drawer>
      </Drawer>
    </>
  );
};

export default RequestedProductList;
